import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";
import { CalculatorService } from "src/app/services/calculator.service";
import { DataService } from "src/app/services/data.service";
import { OfflineService } from "src/app/services/offline.service";
import { ShiftService } from "src/app/services/shift.service";
import { TextService } from "src/app/services/text.service";
import {
  EtransaltionGroups,
  EShiftSteps,
  Ecalculator,
  ShiftMathAction,
  ShiftNote,
} from "src/app/shared/enum";
import {
  CalculatorSection,
  DeviceLessCalcTableDataUnit,
} from "src/app/shared/types";
import {
  CALCULATOR_SECTION,

} from "src/app/shared/consts";
import {ShiftCharacteristicsLabels} from "src/app/shared/characteristics/calc-labels.types"

@Component({
  selector: "app-shift-calculator-page",
  templateUrl: "./shift-calculator-page.component.html",
  styleUrls: ["./shift-calculator-page.component.scss"],
})
export class ShiftCalculatorPageComponent implements OnInit {
  user_id;
  Ecalculator = Ecalculator;

  // for mobile
  calculator_section = CALCULATOR_SECTION;
  show_section_mobile: CalculatorSection = CALCULATOR_SECTION.SHIFT;

  eTranslationsGroups = EtransaltionGroups;
  eShiftSteps = EShiftSteps;

  shiftForm: FormGroup;
  showResults: boolean = false;
  isShiftPropsInitiated: boolean = false;
  labels: ShiftCharacteristicsLabels;
  noteForResults: string;

  tableColumnsNames: string[] = ["numberOfShifts"];

  emptyDeviceLessCalcTableDataUnit: DeviceLessCalcTableDataUnit = {
    headerDisplay: "",
    units: "",
    result: 0,
  };

  tableData: {
    numberOfShifts: DeviceLessCalcTableDataUnit;
  } = {
    numberOfShifts: this.emptyDeviceLessCalcTableDataUnit,
  };

  mySubscription: Subscription;

  constructor(
    public router: Router,
    public dataService: DataService,
    public textService: TextService,
    public calculatorService: CalculatorService,
    public offlineService: OfflineService,
    public shiftService: ShiftService,
    private fb: FormBuilder
  ) {
    this.dataService.resetShiftCalculator.subscribe((res) => {
      this.router.navigateByUrl("calc/shift");
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit(): void {
    this.getPageDataAndInitProps();
  }

  async getPageDataAndInitProps() {
    this.dataService.showLoader = true;
    if (!this.offlineService.isItOfflineMode) {
      this.calculatorService
        .getCalculatorPageDataDeviceLessCalcs()
        .subscribe((resp) => {
          this.dataService.showLoader = false;
          this.setResponsePageData(resp);
          this.initShiftProps();
        });
    }
  }

  initShiftProps() {
    this.initValidatorsOnFormFields();
    this.setInitialTableData();
    this.setCharacteristicsLabels();
    if (this.dataService.irrigationRate) {
      this.shiftForm
        .get("irrigationRate")
        .setValue(this.dataService.irrigationRate, { emitEvent: false });
      this.dataService.irrigationRate = null;
    }

    this.isShiftPropsInitiated = true;
  }

  setResponsePageData(resp) {
    if (resp.success) {
      this.user_id = resp.result.page_data.user.id;
      this.dataService.user_name =
        resp.result.page_data.user.first_name +
        " " +
        resp.result.page_data.user.last_name;

      this.dataService.regions_list = resp.result.page_data.regions;
      this.dataService.selected_units = this.dataService.units_list.find(
        (value) => {
          return value.value === Number(resp.result.page_data.user.units);
        }
      );
      this.dataService.selected_region = this.dataService.regions_list.find(
        (value) => {
          return resp.result.page_data.user.region === value.region_id;
        }
      );
      this.dataService.languages_list = resp.result.page_data.languages;
      this.dataService.selected_language = this.dataService.languages_list.find(
        (value) => {
          return (
            value.row_id === resp.result.page_data.user.language.toString()
          );
        }
      );
    }
  }

  setInitialTableData() {
    this.tableData["numberOfShifts"] = {
      headerDisplay: this.textService.getText(
        this.eTranslationsGroups.SHIFT,
        "results_number_of_shifts_label"
      ),
      units: "",
      result: 0,
    };
  }

  initValidatorsOnFormFields() {
    this.shiftForm = this.fb.group({
      waterPeakDemand: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.001),
              Validators.max(1000),
            ]),
          ],
          updateOn: "blur",
        },
      ],
      dailyIrrigationTime: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.01),
              Validators.max(24),
            ]),
          ],
          updateOn: "blur",
        },
      ],
      irrigationRate: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.01),
              Validators.max(10000),
            ]),
          ],
          updateOn: "blur",
        },
      ],
    });
  }

  setCharacteristicsLabels() {
    this.labels = {
      water_peak_demand: {
        label: this.textService.getText(
          EtransaltionGroups.SHIFT,
          "water_peak_demand_label"
        ),
        units: this.textService.getUnitsAsText("mmd"),
      },
      daily_irrigation_time: {
        label: this.textService.getText(
          EtransaltionGroups.SHIFT,
          "daily_irrigation_time_label"
        ),
        // check hour unit
        units: "",
      },
      irrigation_rate: {
        label: this.textService.getText(
          EtransaltionGroups.SHIFT,
          "irrigation_rate_label"
        ),
        units: this.textService.getUnitsAsText("mmh"),
      },
    };
  }

  getFormControlValues(controlName: string) {
    return this.shiftForm.get(controlName).value;
  }

  calculate() {
    // get input values
    const waterPeakDemand = this.getFormControlValues("waterPeakDemand");
    const dailyIrrigationTime = this.getFormControlValues(
      "dailyIrrigationTime"
    );
    const irrigationRate = this.getFormControlValues("irrigationRate");

    const units = this.dataService.selected_units.value;

    const calcResult = this.shiftService.calcNumberOfShifts(
      waterPeakDemand,
      dailyIrrigationTime,
      irrigationRate,
      units
    );

    const { action, shifts } = calcResult;

    // update tableData
    this.tableData["numberOfShifts"].result = shifts;
    // update note for result

    const noteStrId: string = this.getResultNoteStrID(action);

    this.noteForResults = noteStrId
      ? this.textService.getText(EtransaltionGroups.SHIFT, noteStrId)
      : noteStrId;

    this.showResults = true;
  }

  // the value of translation_id_str in DB
  getResultNoteStrID(action: ShiftMathAction): string {
    switch (action) {
      case ShiftMathAction.None:
        return null;

      case ShiftMathAction.Ceil:
        return ShiftNote.RoundUp;

      case ShiftMathAction.Floor:
        return null;

      case ShiftMathAction.RoundToOne:
        return ShiftNote.RoundUp;

      case ShiftMathAction.RoundToZero:
        return ShiftNote.NotValid;
    }
  }

  nextStepMobile() {
    switch (this.show_section_mobile) {
      case this.calculator_section.SHIFT:
        this.show_section_mobile = this.calculator_section.RESULT;
        this.calculate();
        break;
      default:
        break;
    }
  }

  backStepMobile() {
    switch (this.show_section_mobile) {
      case this.calculator_section.RESULT:
        this.show_section_mobile = this.calculator_section.SHIFT;
        this.scrollToTop();
        break;
      default:
        break;
    }
  }

  validationByStep() {
    switch (this.show_section_mobile) {
      case this.calculator_section.SHIFT:
        return (
          this.shiftForm.controls.waterPeakDemand.status === "INVALID" ||
          this.shiftForm.controls.dailyIrrigationTime.status === "INVALID" ||
          this.shiftForm.controls.irrigationRate.status === "INVALID"
        );
      default:
        break;
    }
  }

  onClickBackToHome() {
    this.router.navigateByUrl("calc/home");
  }

  scrollToTop() {
    setTimeout(() => {
      const element_elevation = document.querySelector("#scroll_top");
      element_elevation.scrollIntoView({ behavior: "smooth" });
    });
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
