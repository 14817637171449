import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { ValveService } from "src/app/services/valve.service";
import {
  Ecalculator,
  EtransaltionGroups,
  EvalveQueryTypes,
} from "src/app/shared/enum";
import { EenergySteps } from "src/app/shared/enum";
import { EnergyCharacteristicsLabels } from "src/app/shared/characteristics/calc-labels.types";
@Component({
  selector: "app-energy-characteristics",
  templateUrl: "./energy-characteristics.component.html",
  styleUrls: ["./energy-characteristics.component.scss"],
})
export class EnergyCharacteristicsComponent implements OnInit {
  @Input() energyForm: FormGroup;
  @Input() currentStep: number;
  @Input() labels: EnergyCharacteristicsLabels;
  @Input() user_id: string;

  Ecalculator = Ecalculator;
  EtransaltionGroups = EtransaltionGroups;
  EenergySteps = EenergySteps;

  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {}

  get controls() {
    return this.energyForm.controls;
  }

  ngOnInit(): void {}
}
