import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';

import { CognitoApisServiceService } from '../services/cognito-apis-service.service';
import { Observable } from 'rxjs';
import { OfflineService } from '../services/offline.service';

@Injectable()
export class CanActivateLoggedUser implements CanActivate {
  constructor(
    private router: Router,
    public cognitoAWSService: CognitoApisServiceService,
    public offlineService: OfflineService
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.cognitoAWSService.updateConfiguration();
    if (!this.offlineService.isItOfflineMode) {
      const token: any = await this.cognitoAWSService.checkAccessTokenValid();
      if (token) {
        return true;
      } else {
        this.router.navigateByUrl('/login');
        return false;
      }
    } else {
      return true
    }
  }
}
