export const LateralCalculationTypes = {
  maxLengthForPcEmitters: 1,
  maxLengthForNonPcEmitters: 2,
  pressureLossForSelectedLateral: 3,
  fvEuForSelectedLateral: 4,
  matrixMaxLengthForPcEmitters: 5,
  matrixMaxLengthForNonPcEmitters: 6,
} as const;

export const MatrixSelectionLimits = {
  matrixSlopesLimit: 3,
  matrixSpacingLimit: 4,
} as const;
