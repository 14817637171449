import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-site-under-maintenance-popup',
  templateUrl: './site-under-maintenance-popup.component.html',
  styleUrls: ['./site-under-maintenance-popup.component.scss']
})
export class SiteUnderMaintenancePopupComponent implements OnInit {
  constructor(
    public dataService: DataService
  ) {}

  ngOnInit(): void {
  }

  goTo() {
    this.dataService.showSiteUnderMaintenancePopup = false;
    window.location.reload();
    window.scrollTo(0, 0);
  }

}
