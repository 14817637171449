import { PipeSection } from "../../PipeSection";
import { AbsLateralCalculationProcess } from "./AbsLateralCalculationProcess";
import { Segment } from "../../Segment";
import { SlopeSection } from "../../../Entities/SlopeSection";
import { LateralCalculationType } from "../../../enums/LateralCalculationType.enum";
import { ECalculationWarnings } from "../../../errors_and_warnings/warnings";
import { ECalculationErrors } from "../../../errors_and_warnings/errors";

export class FlowVariationAndEmissionUniformity extends AbsLateralCalculationProcess {


    public initSegments(data: any, segments: any, slopes: any, pipes: any, isFlushing: boolean = false) {
        let emitters = data.emitters;
        // Add Emitter section propeties to Segments:
        this.addEmitterSectionsToSegments(segments, emitters);
        // Add Slope section propeties to Segments:
        this.addSlopeSectionsToSegments(segments, slopes);
        // Add Pipe section propeties to Segments:
        this.addPipeSectionsToSegments(segments, pipes);
    }

    public calculate(segments: any, pipes: any, slopes: any, data: any) {
        return this.calcFlowVariationAndEmissionUniformity(segments, pipes, slopes, data);
    }

    protected handleCalculationStops(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false,
            InletPressureUIstop: false
        }
        let { pipes, slopes, calculationType, currSegment, segments, lengthSum, isEndPressureCalculation, inletPressureUI, isFlushingMode, index } = data;

        if (calculationType == LateralCalculationType.FV_EU_FOR_SELECTED_LATERAL) {
            // Design/Technical pressures:
            let currSegmentInletPressure = currSegment.InletPressure;
            let currEmitter = currSegment.EmitterSection;
            if (currSegmentInletPressure > currEmitter.MaxAllowedPressure) {
                if (currSegmentInletPressure > currEmitter.MaxTechnicalPressure) {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_TECHNICAL_PRESSURE_WARNING;
                }
                else {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_DESIGN_PRESSURE_WARNING;
                }
            }
            // Minimum pressures:
            if (currSegmentInletPressure < currEmitter.MinAllowedPressure) {
                if (currSegmentInletPressure > currEmitter.MinTechnicalPressure) {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_TECHNICAL_PRESSURE_WARNING;
                }
                else {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_DESIGN_PRESSURE_WARNING;
                }
            }

        }

        if (this.isSegmentIsOnLateral(lengthSum, data.spacing)) {
            this.setSegmentTravelTime(currSegment, data.spacing);
        }

        return calcStopsResponse;
    }

    protected postCalcActions(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false
        }
        let { calculationType, segmentsCalcData } = data;
        let minFlowRate = segmentsCalcData.minFlowRate;
        let maxFlowRate = segmentsCalcData.maxFlowRate;

        // Calc Flow variation:
        let flowVariation = this.calcFlowVariation(minFlowRate, maxFlowRate);
        segmentsCalcData.calcData.flowVariation = flowVariation;
        return calcStopsResponse;
    }

    // Override
    protected getCurrPipeSectionInit(calculationType: any, pipes: PipeSection[], pipesIndex: number, segments: any = null) {
        return null;
    }


    // ---------------------------------------- PRAIVATE METHODS ----------------------------------------

    private calcFlowVariationAndEmissionUniformity(segments: Segment[], pipes: PipeSection[], slopes: SlopeSection[], calcData: any) {
        let endPressure = Number(calcData.blockChars.end_pressure);
        let isCalcDone = false;
        let systemPL: number;
        let isFlushing = calcData.isFlushingMode;
        let lastCalculationSegmentIndex = isFlushing ? segments.length - 2 : segments.length - 1;
        let lastEmitter = calcData.emitters[calcData.emitters.length - 1];
        let lastFlowRate = this.calcLastEmitterFlowRate(lastEmitter, calcData.blockChars.end_pressure);
        let spacing = Number(calcData.blockChars.distance_between_laterals);
        let lateralLength = this.calcPipeTotalLength(pipes);
        let numOfEmitters = Math.floor(lateralLength / spacing);
        let segment_index_for_desired_inlet_pressure
        let iscalcStopInletPressureUIstop = false

        calcData.lateralLength = lateralLength;
        calcData.spacing = spacing;
        calcData.numOfEmitters = numOfEmitters;
        // Init last calculation segment:
        segments[lastCalculationSegmentIndex].FlowRate = lastFlowRate;
        segments[lastCalculationSegmentIndex].AtomicFlowRate = lastFlowRate;
        if (!isFlushing) {
            segments[lastCalculationSegmentIndex].EndPressure = endPressure;
        }
        else {
            segments[lastCalculationSegmentIndex].FlowRate += calcData.flushing_end_flow_rate;
        }

        // Set segment calculation data:     
        let segmentsCalcData: any = { calcData, pipes, slopes, segments, lastFlowRate, type: LateralCalculationType.FV_EU_FOR_SELECTED_LATERAL };
        do {
            // Handle segments calculation:
            let res = this.handleSegmentsCalculation(segmentsCalcData);
            isCalcDone = res.isCalcDone;
            systemPL = res.systemPL;
            segment_index_for_desired_inlet_pressure = res.segment_index_for_desired_inlet_pressure
            iscalcStopInletPressureUIstop = res.isInletPressureUIstop
        } while (!isCalcDone);


        let results: any = {};

        let err = segmentsCalcData.errors || [];
        if (err.length > 0) {
            results.errors = err;
            return results;
        }

        results = this.summarizeResults(segments, pipes, systemPL, calcData.maxVelocity, calcData.isFlushingMode, calcData.end_velocity, segment_index_for_desired_inlet_pressure);
        pipes.forEach(pipe => {
            pipe.MaxFlowRate *= 1000;
        });
        results.emitter = lastEmitter;
        results.flowVariation = calcData.flowVariation;
        results.totalPressureLoss = systemPL;
        results.endPressure = pipes[pipes.length - 1].EndPressure;
        // results.totalLength = lateralLength;
        let sum = 0;
        segments.map(s => { sum = Math.round((sum + s.Length) * 1e12) / 1e12 });
        results.totalLength = iscalcStopInletPressureUIstop ? sum : lateralLength;
        results.numOfEmitters = numOfEmitters;
        let avgFlowRate: any = Number((((segments[0].FlowRate) * 1000) / numOfEmitters));
        let cv = lastEmitter.cv ? Number(lastEmitter.cv) : lastEmitter.CV;
        results.emissionUniformity = this.calcEmissionUniformity(cv, segmentsCalcData.minFlowRate * 1000, avgFlowRate)
        results.emittersAvgFlowRate = Number(avgFlowRate.toFixed(2));
        results.travelTime = this.calcTravelTime(segments, spacing);

        // Lateral warnings:
        if (results.totalPressureLoss > AbsLateralCalculationProcess.MAX_TOTAL_PL) {
            this.addWarning(results.warnings, ECalculationWarnings.PRESSURE_LOSS_EXCEEDED_WARNING);
        }
        if (results.flowVariation > AbsLateralCalculationProcess.MAX_TOTAL_FV) {
            this.addWarning(results.warnings, ECalculationWarnings.FLOW_VARIATION_WARNING);
        }
        if (results.emissionUniformity < AbsLateralCalculationProcess.MIN_TOTAL_EU) {
            this.addWarning(results.warnings, ECalculationWarnings.EMISSION_UNIFORMITY_WARNING);
        }
        // if(results.travelTime > AbsLateralCalculationProcess.MAX_TOTAL_TRAVEL_TIME){
        //     this.addWarning(results.warnings, ECalculationWarnings.TRAVEL_TIME_WARNING);
        // }

        let diffMeters = results.totalLength * (slopes[0].HeightDiffPrecent / 100);
        slopes[0].HeightDiffInMeters = Number(diffMeters.toFixed(2));
        results.errors = segmentsCalcData.errors || [];
        segmentsCalcData.warnings.forEach(warn => {
            results.warnings.push(warn);
        });
        return results;
    }
}