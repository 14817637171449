import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/data.service';
import { SubmainService } from 'src/app/services/submain.service';
import { MulitpleToggleButtonComponent } from 'src/app/common/mulitple-toggle-button/mulitple-toggle-button.component';
import { ToggleButtonComponent } from 'src/app/common/toggle-button/toggle-button.component';
import { Ecalculator, Einputs_fields, EtransaltionGroups } from 'src/app/shared/enum';
import { TextService } from 'src/app/services/text.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { SlopeComponent } from '../slope/slope.component';
import { OfflineService } from 'src/app/services/offline.service';
import { BlockShape } from 'src/app/shared/characteristics/block-characteristics.types';
import { OBlockShape } from 'src/app/shared/characteristics/block-characteristics.consts';
@Component({
  selector: 'app-block-characteristics',
  templateUrl: './block-characteristics.component.html',
  styleUrls: ['./block-characteristics.component.scss'],
})
export class BlockCharacteristicsComponent implements OnInit, AfterViewInit {

// **************** - Objects Consts and Enums - ****************
  Ecalculator = Ecalculator
  Einputs_fields = Einputs_fields;
  EtransaltionGroups = EtransaltionGroups;
  blockShape = OBlockShape


// **************** - Decorators - ****************
  @ViewChild(MulitpleToggleButtonComponent) MulitpleToggleButtonComponent: MulitpleToggleButtonComponent;
  @ViewChild(ToggleButtonComponent) ToggleButtonComponent: ToggleButtonComponent;
  @ViewChild(SlopeComponent) SlopeComponent: SlopeComponent;

  // Inputs
  @Input() currentSlopeForms: any = [];
  @Input() selectedShape: BlockShape = this.blockShape.rectangular;
  @Input() queryIndex; //0 - Q1, 1 - Q2, 2-Q3
  @Input() blockForm: FormGroup;
  @Input() blockFormInitialValue: any;
  @Input() user_id //for analytics events

  // Outputs
  @Output() addNewSlope = new EventEmitter<string>();
  @Output() deleteSlopeFromCurrentSlopeForms = new EventEmitter<string>();
  @Output() deleteAllSlopes = new EventEmitter<string>();
  @Output() changeBlockShape = new EventEmitter<string>();
  @Output() clearSectionLength = new EventEmitter();

 
  // for snackBar
  message: string = ' has been added to the list of devices';
  actionButtonLabel: string = 'Dismiss';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 5000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  addExtraClass: boolean = false;

  slopeItems: any[];

  public innerWidth: any;
  selectedTopography = 'Flat';
  

  mapLengthInvalid;
  whichPressureInputDisable: string = ''


  //fields-label
  label_pipeLength;
  label_lateralsDist;
  label_numOfLaterals;
  label_lastLatRate;
  label_firstLatRate;
  label_inletPress;
  label_endletPress;
  label_lastLatFlowRate;
  label_totalLatFlowRate;
  x_line_text;
  constructor(
    public snackBar: MatSnackBar,
    public dataService: DataService,
    public submainService: SubmainService,
    private cdr: ChangeDetectorRef,
    public textService: TextService,
    public analyticsService: GoogleAnalyticsService,
    public offlineService: OfflineService
  ) {
    this.dataService.slopeMapLengthHasChanged.subscribe((res) => {
      this.slopeMapLengthHasChanged(res);
    });
    this.dataService.updateBlockFormValidationByQ.subscribe(res => {
     const questionSelectedIndex = res
      setTimeout(() => {
        this.setValidatorsByQueryIndex(questionSelectedIndex);
      });

    })
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    this.slopeItems = this.dataService.sectionItems;
    this.onChanges();
    this.setValidatorsByQueryIndex();
    this.setLabels();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  checkWindowSizeSmall() {
    let innerWidth = window.innerWidth;
    return 1020 < innerWidth && innerWidth <= 1405;
  }

  setLabels() {
    this.x_line_text = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s2_elevation_graph'
      ),
      'm_pressure'
    );

    this.label_pipeLength = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s2_length'
      ),
      'm'
    );

    this.label_lateralsDist = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s2_spacing'
      ),
      'm'
    );
    this.label_numOfLaterals = this.textService.getText(
      this.EtransaltionGroups.SUBMAIN,
      'submain_s2_num_laterals'
    );
    this.label_lastLatRate = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s2_last_lateral_length'
      ),
      'm'
    );
    this.label_firstLatRate = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s2_first_lateral_length'
      ),
      'm'
    );
    this.label_inletPress = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s2_inlet_pressure'
      ),
      'm_pressure'
    );
    this.label_endletPress = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s2_end_pressure'
      ),
      'm_pressure'
    );
    this.label_lastLatFlowRate = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s2_last_lateral_flow_rate'
      ),
      'lh'
    );
    this.label_totalLatFlowRate = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s2_total_flow_rate'
      ),
      'm3h'
    );
  }

  ngAfterViewInit(): void {
    if (this.dataService.isItMobile) {
      setTimeout(() => {
        const element = document.querySelector('#top-submain');
        element.scrollIntoView();
      });
    }
    this.selectedShape = this.dataService.selectedShape;
    this.ToggleButtonComponent.chosen = this.dataService.selectedShape;
    this.selectedTopography = this.dataService.selectedTopography;
    this.MulitpleToggleButtonComponent.chosen = this.dataService.selectedTopography;
    this.cdr.detectChanges();
  }

  addSlope() {
    this.addNewSlope.emit();
  }

  changeShape(option) {
    this.selectedShape = option;
    this.dataService.selectedShape = option;
    this.blockForm.reset(this.blockFormInitialValue,{
      emitEvent: false,
    });
    this.clearSectionLength.emit()
    // setPropertiesWhenPipeLengthIsNotEmpty usually being called by listener(valueChange) when there is a change
    // in pipeLength control.
    // in the code line above blockForm reset is not emitting event so setPropertiesWhenPipeLengthIsNotEmpty is being called
    this.setPropertiesWhenPipeLengthIsNotEmpty()
    this.dataService.clearSubmainCharacteristicsData.next();
    if (this.currentSlopeForms.length > 0) {
      this.deleteAllSlopes.emit();
    }
    this.MulitpleToggleButtonComponent.change('Flat');
    if (option === this.blockShape.trapezoid) {
      this.blockForm.controls['firstLatRate'].setValidators([
        Validators.compose([
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.required,
          Validators.min(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.first_lateral_length
              ],
              'min'
            )
          ),
          Validators.max(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.first_lateral_length
              ],
              'max'
            )
          ),
        ]),
      ]);
      this.blockForm.controls['firstLatRate'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
      this.blockForm.controls['lastLatRate'].setValidators([
        Validators.compose([
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.required,
          Validators.min(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.last_lateral_length
              ],
              'min'
            )
          ),
          Validators.max(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.last_lateral_length
              ],
              'max'
            )
          ),
        ]),
      ]);
      this.blockForm.controls['lastLatRate'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    } else {
      this.blockForm.controls['firstLatRate'].clearValidators();
      this.blockForm.controls['firstLatRate'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
      this.blockForm.controls['lastLatRate'].clearValidators();
      this.blockForm.controls['lastLatRate'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
  }

  changeTopography(option) {
    this.dataService.selectedTopography = option;
    this.selectedTopography = option;
    this.deleteAllSlopes.emit();
    if (
      (option === 'Fixed Slope' || option === 'Multiple Elevations') &&
      this.blockForm.get('pipeLength').valid
    ) {
      this.addSlope();
      this.updateSlopeForm();
    }
  }

  onChanges(): void {
    this.blockForm.valueChanges.subscribe((value => {
      this.dataService.inputs_contents_has_changed = true
    }))
    this.blockForm.get('pipeLength').valueChanges.subscribe((value) => { 
      if (value != null && value != '') {
        this.dataService.submainLength = value;
        setTimeout(() => {
          this.dataService.calculateSubmainRealLength.next()
        });
        if (this.selectedTopography === 'Fixed Slope') {
          this.currentSlopeForms[0].controls.mapLength.setValue(
            this.blockForm.controls.pipeLength.value,
            {
              emitEvent: false,
            }
          );
        }

        if (
          (this.blockForm.get('numOfLaterals').status !== 'VALID' ||
            this.dataService.submainLayoutChangeToReadonly ===
            'numOfLaterals') &&
          this.blockForm.get('lateralsDist').status === 'VALID'
        ) {
          this.blockForm
            .get('numOfLaterals')
            .setValue(
              Math.floor(
                Number(
                  (
                    Number(this.blockForm.get('pipeLength').value) /
                    Number(this.blockForm.get('lateralsDist').value)
                  ).toFixed(2)
                )
              ),
              { emitEvent: false }
            );
          this.blockForm
            .get('numOfLaterals')
            .updateValueAndValidity({ onlySelf: true, emitEvent: false });
          this.dataService.submainLayoutChangeToReadonly = 'numOfLaterals';
          if (
            (this.isTotalFlowRateInputsAreValid() ||
              this.isLastFlowRateInputsAreValid()) &&
            value != null &&
            this.queryIndex != 2
          ) {
            this.calcBlockFlowRate();
          }
        } else if (
          this.blockForm.get('numOfLaterals').status === 'VALID' &&
          (this.blockForm.get('lateralsDist').status !== 'VALID' ||
            this.dataService.submainLayoutChangeToReadonly === 'lateralsDist')
        ) {
          this.blockForm
            .get('lateralsDist')
            .setValue(
              this.blockForm.get('pipeLength').value /
              this.blockForm.get('numOfLaterals').value,
              { emitEvent: false }
            );
          this.dataService.submainLayoutChangeToReadonly = 'lateralsDist';
        }
      } else {
    this.setPropertiesWhenPipeLengthIsNotEmpty()
      }
      this.currentSlopeForms;
      if (this.currentSlopeForms.length > 0) {
        this.currentSlopeForms.forEach((slope) => {
          this.SlopeComponent.validateMapLength(slope.get('mapLength').value);
        });
      }
    });

    this.blockForm.get('lateralsDist').valueChanges.subscribe((value) => {
      if (value != null && value != '') {
        if (
          (!this.blockForm.get('numOfLaterals').valid ||
            this.dataService.submainLayoutChangeToReadonly ==
            'numOfLaterals') &&
          this.blockForm.get('pipeLength').valid
        ) {
          this.blockForm
            .get('numOfLaterals')
            .setValue(
              Math.floor(
                Number(
                  (
                    Number(this.blockForm.get('pipeLength').value) /
                    Number(this.blockForm.get('lateralsDist').value)
                  ).toFixed(2)
                )
              ),
              { emitEvent: false }
            );
          this.dataService.submainLayoutChangeToReadonly = 'numOfLaterals';
          if (
            (this.isTotalFlowRateInputsAreValid() ||
              this.isLastFlowRateInputsAreValid()) &&
            this.queryIndex != 2
          ) {
            this.calcBlockFlowRate();
          }
        } else if (
          this.blockForm.get('numOfLaterals').valid &&
          (!this.blockForm.get('pipeLength').valid ||
            this.dataService.submainLayoutChangeToReadonly == 'pipeLength')
        ) {
          this.blockForm
            .get('pipeLength')
            .setValue(
              (
                this.blockForm.get('lateralsDist').value *
                this.blockForm.get('numOfLaterals').value
              ).toFixed(2),
              { emitEvent: false }
            );
          this.dataService.submainLength = value;
          setTimeout(() => {
            this.dataService.calculateSubmainRealLength.next()
          });

          if (this.selectedTopography === 'Fixed Slope') {
            this.currentSlopeForms[0].controls.mapLength.setValue(
              this.blockForm.controls.pipeLength.value,
              {
                emitEvent: false,
              }
            );
          }

          this.dataService.submainLayoutChangeToReadonly = 'pipeLength';
        }
      } else {
        if (this.dataService.submainLayoutChangeToReadonly === 'pipeLength') {
          this.dataService.submainLayoutChangeToReadonly = '';
          this.blockForm.get('pipeLength').setValue('', { emitEvent: false });
          this.dataService.submainLength = value;
          setTimeout(() => {
            this.dataService.calculateSubmainRealLength.next()
          });
          if (this.selectedTopography === 'Fixed Slope') {
            this.currentSlopeForms[0].controls.mapLength.setValue(
              this.blockForm.controls.pipeLength.value,
              {
                emitEvent: false,
              }
            );
          }
        }
        if (
          this.dataService.submainLayoutChangeToReadonly === 'numOfLaterals'
        ) {
          this.dataService.submainLayoutChangeToReadonly = '';
          this.dataService.blockFlowRateChangeToReadonly = '';
          this.blockForm.controls.numOfLaterals.setValue('', {
            emitEvent: false,
          });
          this.blockForm.controls.lastLatFlowRate.setValue('', {
            emitEvent: false,
          });
          this.blockForm.controls.totalLatFlowRate.setValue('', {
            emitEvent: false,
          });
        }
      }
    });

    this.blockForm.get('numOfLaterals').valueChanges.subscribe((value) => {
      if (value != null && value != '') {
        if (
          this.blockForm.get('pipeLength').status === 'VALID' &&
          (this.blockForm.get('lateralsDist').status !== 'VALID' ||
            this.dataService.submainLayoutChangeToReadonly == 'lateralsDist')
        ) {
          this.blockForm
            .get('lateralsDist')
            .setValue(
              (
                this.blockForm.get('pipeLength').value /
                this.blockForm.get('numOfLaterals').value
              ).toFixed(2),
              { emitEvent: false }
            );
          this.dataService.submainLayoutChangeToReadonly = 'lateralsDist';
        } else if (
          (this.blockForm.get('pipeLength').status !== 'VALID' ||
            this.dataService.submainLayoutChangeToReadonly == 'pipeLength') &&
          this.blockForm.get('lateralsDist').status === 'VALID'
        ) {
          this.blockForm
            .get('pipeLength')
            .setValue(
              (
                this.blockForm.get('lateralsDist').value *
                this.blockForm.get('numOfLaterals').value
              ).toFixed(2),
              { emitEvent: false }
            );
          this.dataService.submainLayoutChangeToReadonly = 'pipeLength';
          this.dataService.submainLength = value;
          setTimeout(() => {
            this.dataService.calculateSubmainRealLength.next()
          });
          if (this.selectedTopography === 'Fixed Slope') {
            this.currentSlopeForms[0].controls.mapLength.setValue(
              this.blockForm.controls.pipeLength.value,
              {
                emitEvent: false,
              }
            );
          }
        }
        if (
          (this.isTotalFlowRateInputsAreValid() ||
            this.isLastFlowRateInputsAreValid()) &&
          this.queryIndex != 2
        ) {
          this.calcBlockFlowRate();
        }
      } else {
        if (this.dataService.submainLayoutChangeToReadonly === 'lateralsDist') {
          this.dataService.submainLayoutChangeToReadonly = '';
          this.blockForm.get('lateralsDist').setValue('', { emitEvent: false });
        }
        if (this.dataService.submainLayoutChangeToReadonly === 'pipeLength') {
          this.dataService.submainLayoutChangeToReadonly = '';
          this.blockForm.get('pipeLength').setValue('', { emitEvent: false });
          this.dataService.submainLength = value;
          setTimeout(() => {
            this.dataService.calculateSubmainRealLength.next()
          });
          if (this.selectedTopography === 'Fixed Slope') {
            this.currentSlopeForms[0].controls.mapLength.setValue(
              this.blockForm.controls.pipeLength.value,
              {
                emitEvent: false,
              }
            );
          }
        }
        this.blockForm
          .get('lastLatFlowRate')
          .setValue('', { emitEvent: false });
        this.blockForm
          .get('totalLatFlowRate')
          .setValue('', { emitEvent: false });
        this.dataService.blockFlowRateChangeToReadonly = '';
        this.blockForm.controls.totalLatFlowRate.updateValueAndValidity()
        this.blockForm.controls.lastLatFlowRate.updateValueAndValidity()
      }
    });

    this.blockForm.get('lastLatFlowRate').valueChanges.subscribe((value) => {
      setTimeout(() => {
        if (
          this.blockForm.controls.lastLatRate.hasError('max') ||
          this.blockForm.controls.lastLatRate.hasError('min')
        ) {
          this.analyticsService.sendEvent(
            'submain',
            'last_flow_rate_out_of_range',
            'validation_error',
            this.user_id
          );
        }
      });

      if (this.queryIndex != 2) {
        if (this.isLastFlowRateInputsAreValid() && value != null) {
          this.calcBlockFlowRate();
        } else {
          this.dataService.blockFlowRateChangeToReadonly = '';
          this.blockForm
            .get('totalLatFlowRate')
            .setValue('', { emitEvent: false });
        }
      }
    });

    this.blockForm.get('totalLatFlowRate').valueChanges.subscribe((value) => {
      setTimeout(() => {
        if (
          this.blockForm.controls.lastLatRate.hasError('max') ||
          this.blockForm.controls.lastLatRate.hasError('min')
        ) {
          this.analyticsService.sendEvent(
            'submain',
            'total_flow_rate_out_of_range',
            'validation_error', this.user_id
          );
        }
      });
      if (this.queryIndex != 2) {
        if (this.isTotalFlowRateInputsAreValid() && value) {
          this.calcBlockFlowRate();
        } else {
          this.dataService.blockFlowRateChangeToReadonly = '';
     
          this.blockForm
            .get('lastLatFlowRate')
            .setValue('', { emitEvent: false });
        }
      }
    });

    this.blockForm.get('firstLatRate').valueChanges.subscribe((value) => {
      if (
        (this.isTotalFlowRateInputsAreValid() ||
          this.isLastFlowRateInputsAreValid()) &&
        value != null &&
        this.queryIndex != 2
      ) {
        this.calcBlockFlowRate();
      } else {
        this.blockForm
          .get('lastLatFlowRate')
          .setValue('', { emitEvent: false });
        this.blockForm
          .get('totalLatFlowRate')
          .setValue('', { emitEvent: false });
        this.dataService.blockFlowRateChangeToReadonly = '';
      }
    });

    this.blockForm.get('lastLatRate').valueChanges.subscribe((value) => {
      if (
        (this.isTotalFlowRateInputsAreValid() ||
          this.isLastFlowRateInputsAreValid()) &&
        this.queryIndex != 2
      ) {
        this.calcBlockFlowRate();
      } else {
        this.blockForm
          .get('lastLatFlowRate')
          .setValue('', { emitEvent: false });
        this.blockForm
          .get('totalLatFlowRate')
          .setValue('', { emitEvent: false });
        this.dataService.blockFlowRateChangeToReadonly = '';

      }
    });
    this.blockForm.get('inletPress').valueChanges.subscribe((value) => {
      if (value && value != '' && this.blockForm.controls.inletPress.valid) {
        this.whichPressureInputDisable = 'endletPress'
        this.blockForm.controls.endletPress.clearValidators();
        this.blockForm.controls.endletPress.reset('', { onlySelf: true, emitEvent: false });
        this.blockForm.controls.endletPress.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      } else {
        this.whichPressureInputDisable = ''
        this.setValidators('')
      }
      setTimeout(() => {
        if (
          this.blockForm.controls.inletPress.hasError('max') ||
          this.blockForm.controls.inletPress.hasError('min')
        ) {
          this.analyticsService.sendEvent(
            'submain',
            'inlet_pressure_out_of_range',
            'validation_error', this.user_id
          );
        }
      });
    });

    this.blockForm.get('endletPress').valueChanges.subscribe((value) => {
      if (value && value != '' && this.blockForm.controls.endletPress.valid) {
        this.whichPressureInputDisable = 'inletPress'
        this.blockForm.controls.inletPress.clearValidators();
        this.blockForm.controls.inletPress.reset('', { onlySelf: true, emitEvent: false });
        this.blockForm.controls.inletPress.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      } else {
        this.whichPressureInputDisable = ''
        this.setValidators('')
      }
      setTimeout(() => {
        if (
          this.blockForm.controls.endletPress.hasError('max') ||
          this.blockForm.controls.endletPress.hasError('min')
        ) {
          this.analyticsService.sendEvent(
            'submain',
            'end_pressure_out_of_range',
            'validation_error', this.user_id
          );
        }
      });
    });
  }

  isLastFlowRateInputsAreValid(): boolean {
    var isvalid: boolean =
      this.blockForm.get('lastLatFlowRate').valid &&
      this.blockForm.get('numOfLaterals').valid &&
      this.blockForm.get('lastLatRate').valid &&
      this.blockForm.get('firstLatRate').valid;

    return isvalid;
  }

  isTotalFlowRateInputsAreValid(): boolean {
    var isvalid: boolean =
      this.blockForm.get('totalLatFlowRate').valid &&
      this.blockForm.get('numOfLaterals').valid &&
      this.blockForm.get('lastLatRate').valid &&
      this.blockForm.get('firstLatRate').valid;

    return isvalid;
  }

  updateSlopeForm() {
    if (!this.blockForm.get('pipeLength').valid) {
    } else {
      if (
        (this.selectedTopography === 'Fixed Slope' ||
          this.selectedTopography === 'Multiple Elevations') &&
        this.currentSlopeForms.length > 0
      ) {
        let form = this.currentSlopeForms[0];
        form.get('mapLength').setValue(this.blockForm.get('pipeLength').value);
      }
    }
  }

  async calcBlockFlowRate() {
    if (this.queryIndex != 2) {
      let isRectangle = this.selectedShape === this.blockShape.rectangular;
      this.dataService.showLoader = true;
      if (this.offlineService.isItOfflineMode) {
        let response = await this.submainService.calcBlockFlowRateOffline(this.dataService.blockFlowRateChangeToReadonly === 'lastLatFlowRate'
          ? ''
          : this.blockForm.get('lastLatFlowRate').value,
          this.dataService.blockFlowRateChangeToReadonly === 'totalLatFlowRate'
            ? ''
            : this.blockForm.get('totalLatFlowRate').value,
          isRectangle,
          this.blockForm.get('numOfLaterals').value,
          this.blockForm.get('lastLatRate').value,
          this.blockForm.get('firstLatRate').value)
        this.dataService.showLoader = false;
        this.setResponseBlockFlowRate(response)
      } else {
        this.submainService
          .calcBlockFlowRate(
            this.dataService.blockFlowRateChangeToReadonly === 'lastLatFlowRate'
              ? ''
              : this.blockForm.get('lastLatFlowRate').value,
            this.dataService.blockFlowRateChangeToReadonly === 'totalLatFlowRate'
              ? ''
              : this.blockForm.get('totalLatFlowRate').value,
            isRectangle,
            this.blockForm.get('numOfLaterals').value,
            this.blockForm.get('lastLatRate').value,
            this.blockForm.get('firstLatRate').value
          )
          .subscribe(
            (resp) => {
              this.dataService.showLoader = false;
              this.setResponseBlockFlowRate(resp)
            },
            (err) => {
              this.dataService.showLoader = false;
              if (err.message) {
                this.dataService.error_result_msg = err.message;
              }
              if (err.ErrorToClient) {
                this.dataService.error_result_msg = err.ErrorToClient;
              }
              this.dataService.showErrorPopup = true;
            }
          );
      }
    }
  }
  setResponseBlockFlowRate(resp) {
    if (resp.success) {
      if (
        this.blockForm.get('lastLatFlowRate').value === '' ||
        this.blockForm.get('lastLatFlowRate').value === null ||
        this.dataService.blockFlowRateChangeToReadonly ===
        'lastLatFlowRate'
      ) {
        this.dataService.last_flow_rate_full_result = Number(
          resp.result.flow_rate
        );
        this.dataService.last_flow_rate_full_result = undefined;
        this.blockForm
          .get('lastLatFlowRate')
          .setValue(Number(resp.result.flow_rate).toFixed(2), {
            emitEvent: false,
          });
        this.blockForm.controls.lastLatFlowRate.markAsTouched()
        this.dataService.blockFlowRateChangeToReadonly =
          'lastLatFlowRate';
      }
      if (
        this.blockForm.get('totalLatFlowRate').value === '' ||
        this.blockForm.get('totalLatFlowRate').value === null ||
        this.dataService.blockFlowRateChangeToReadonly ===
        'totalLatFlowRate'
      ) {
        this.dataService.total_flow_rate_full_result = undefined;
        this.dataService.total_flow_rate_full_result = Number(
          resp.result.flow_rate
        );
        this.blockForm
          .get('totalLatFlowRate')
          .setValue(Number(resp.result.flow_rate).toFixed(2), {
            emitEvent: false,
          });
        this.blockForm.controls.totalLatFlowRate.markAsTouched()
        this.dataService.blockFlowRateChangeToReadonly =
          'totalLatFlowRate';
      }
    }
  }
  deleteSlope(index) {
    this.deleteSlopeFromCurrentSlopeForms.emit(index);
    if (this.currentSlopeForms.length === 0) {
      this.MulitpleToggleButtonComponent.change('Flat');
    }
    if (this.currentSlopeForms.length > 0) {
      setTimeout(() => {
        this.dataService.validationForMapLengthSlope.next();
      }, 0);
    }
  }

  setValidators(controlName) {
    if (controlName === 'inletPress' || controlName === '') {
      this.blockForm.controls['inletPress'].setValidators([
        Validators.compose([
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.required,
          Validators.min(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.inlet_pressure
              ],
              'min'
            )
          ),
          Validators.max(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.inlet_pressure
              ],
              'max'
            )
          ),
        ]),
      ]);
      this.blockForm.controls['inletPress'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
      if (controlName === 'inletPress') {
        this.blockForm.controls['endletPress'].clearValidators();
        this.blockForm.controls['endletPress'].updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      }
    }

    if (controlName === 'endletPress' || controlName === '') {
      this.blockForm.controls['endletPress'].setValidators([
        Validators.compose([
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.required,
          Validators.min(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.end_pressure
              ],
              'min'
            )
          ),
          Validators.max(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.end_pressure
              ],
              'max'
            )
          ),
        ]),
      ]);
      this.blockForm.controls['endletPress'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
      if (controlName === 'endletPress') {
        this.blockForm.controls['inletPress'].clearValidators();
        this.blockForm.controls['inletPress'].updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      }
    }
  }

  setValidatorsByQueryIndex(questionSelectedIndex = null) {
    if(questionSelectedIndex){
      this.queryIndex = questionSelectedIndex
    }
   
    if (this.queryIndex === 2) {
      this.blockForm.controls['pipeLength'].clearValidators();
      this.blockForm.get('pipeLength').setValue('', { emitEvent: false });
      this.blockForm.controls['pipeLength'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });

      this.blockForm.controls['numOfLaterals'].clearValidators();
      this.blockForm.get('numOfLaterals').setValue('', { emitEvent: false });
      this.blockForm.controls['numOfLaterals'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });

      this.blockForm.controls['totalLatFlowRate'].clearValidators();
      this.blockForm.get('totalLatFlowRate').setValue('', { emitEvent: false });
      this.blockForm.controls['totalLatFlowRate'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });

        this.blockForm.controls['firstLatRate'].clearValidators();
        this.blockForm.controls['firstLatRate'].updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
        this.blockForm.controls['lastLatRate'].clearValidators();
        this.blockForm.controls['lastLatRate'].updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });

      if (this.dataService.selectedShape == this.blockShape.trapezoid) {
        this.blockForm.reset();
        this.dataService.selectedShape = this.blockShape.rectangular;
        this.dataService.selectedTopography = 'Flat';
        this.dataService.changeMulitpleToggleButtonChoise.next('Flat');
        this.deleteAllSlopes.emit();
      } else if (this.selectedTopography == 'Multiple Elevations') {
        this.dataService.selectedTopography = 'Flat';
        this.dataService.changeMulitpleToggleButtonChoise.next('Flat');
        this.deleteAllSlopes.emit();
      }
    } else {
      this.blockForm.controls['pipeLength'].setValidators([
        Validators.compose([
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.required,
          Validators.min(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.submain_pipe_length
              ],
              'min'
            )
          ),
          Validators.max(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.submain_pipe_length
              ],
              'max'
            )
          ),
        ]),
      ]);
      this.blockForm.controls['pipeLength'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });

      this.blockForm.controls['numOfLaterals'].setValidators([
        Validators.compose([
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.required,
          Validators.min(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.num_of_laterals
              ],
              'min'
            )
          ),
          Validators.max(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.num_of_laterals
              ],
              'max'
            )
          ),
        ]),
      ]);
      this.blockForm.controls['numOfLaterals'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });

      this.blockForm.controls['totalLatFlowRate'].setValidators([
        Validators.compose([
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.required,
          Validators.min(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.block_flow_rate
              ],
              'min'
            )
          ),
          Validators.max(
            this.dataService.composeMinMaxValidator(
              this.dataService.submain_inputs_data[
              this.Einputs_fields.block_flow_rate
              ],
              'max'
            )
          ),
        ]),
      ]);
      this.blockForm.controls['totalLatFlowRate'].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
    if (this.blockForm.controls.endletPress.value) {
      this.whichPressureInputDisable = 'inletPress'

    } else if (this.blockForm.controls.inletPress.value) {
      this.whichPressureInputDisable = 'endletPress'
    } else {
      this.whichPressureInputDisable = ''
    }
  }

  calculateTotalLengthLeftForSlopes(slopeIndex) {
    this.dataService.totalSlopesLength = 0;
    this.dataService.totalLengthLeftForSlopes = this.blockForm.get(
      'pipeLength'
    ).value;
    this.currentSlopeForms.forEach((slope, index) => {
      this.dataService.totalSlopesLength =
        Number(this.dataService.totalSlopesLength) +
        Number(slope.get('mapLength').value);
      if (index < slopeIndex) {
        this.dataService.totalLengthLeftForSlopes =
          this.dataService.totalLengthLeftForSlopes -
          slope.get('mapLength').value;
      }
    });
  }

  slopeMapLengthHasChanged(currentIndex) {
    if (this.currentSlopeForms.length > Number(currentIndex + 1)) {
      this.currentSlopeForms.forEach((slope, index) => {
        if (currentIndex < index) {
          this.dataService.validationForMapLengthSlope.next();
        }
      });
    }
  }

  firstLateralLargerThenLastLateral() {
    return (
      Number(this.blockForm.value.firstLatRate) >
      Number(this.blockForm.value.lastLatRate)
    );
  }

  get controls() {
    return this.blockForm.controls;
  }

  checkSumOfSlopes() {
    return (
      +this.dataService.totalSlopesLength > +this.blockForm.value.pipeLength &&
      this.selectedTopography == 'Multiple Elevations'
    );
  }




  setPropertiesWhenPipeLengthIsNotEmpty(){
    if (this.dataService.submainLayoutChangeToReadonly === 'lateralsDist') {
      this.dataService.submainLayoutChangeToReadonly = '';
      this.blockForm.get('lateralsDist').setValue('', { emitEvent: false });
    }
    if (
      this.dataService.submainLayoutChangeToReadonly === 'numOfLaterals'
    ) {
      this.dataService.submainLayoutChangeToReadonly = '';
      this.dataService.blockFlowRateChangeToReadonly = '';
      this.blockForm
        .get('numOfLaterals')
        .setValue('', { emitEvent: false });
      this.blockForm.controls.lastLatFlowRate.setValue('', {
        emitEvent: false,
      });
      this.blockForm.controls.totalLatFlowRate.setValue('', {
        emitEvent: false,
      });
    }
  }
}
