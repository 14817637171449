import { SubmainCalculator } from "../calculator/Entities/Calculators/submainCalculator";
import { ErrorMsgs } from "../models/Errors/ErrorMsgs";
import { EUnits } from "../enums/Units.enum";
import { UnitsConverter } from "../UnitsConverter/UnitsConverter";
import { CalculatorFactory } from "../calculator/Entities/Factories/calculator.factory";
import { TranslationsDAL } from "../DAL/TranslationsDAL";
import { ECalculationWarnings } from "../../hydrocalc-code/calculator/errors_and_warnings/warnings";

export class CalculationsService {
  translationsDAL: TranslationsDAL = new TranslationsDAL();

  public async calculate(calculationData: any): Promise<any> {
    let calculatorType = calculationData.calculator;
    let calculator = CalculatorFactory.get(calculatorType);
    if (!calculator) {
      throw new ErrorMsgs("Invalid calculator received");
    }

    let calcResults = calculator.calculate(calculationData);

    //Translate Warnings
    if (
      calcResults.calcResults.calculationResults.warnings &&
      calcResults.calcResults.calculationResults.warnings.length > 0
    ) {
      let isOffline = calculationData.isOffline;
      const units = calculationData.units;
      calcResults = await this.translateWarningsById(
        calcResults,
        calculationData.language,
        units,
        isOffline
      );
    }
    return calcResults;
  }

  public calcSystemEndPressure(inletPressureUI: any, calculationData: any) {
    let calculatorType = calculationData.calculator;
    let calculator = CalculatorFactory.get(calculatorType);
    if (!calculator) {
      throw new ErrorMsgs("Invalid calculator received");
    }
    calculationData.inletPressureUI = inletPressureUI;
    let pressures = calculator.calcEndPressure(calculationData);

    return pressures;
  }

  public calcSubmainBlockFlowRate(
    units: any,
    isReqtangular: boolean,
    lastLateralFlowRate: number,
    totalLateralFlowRate: number,
    numOfLaterals: number,
    firstLateralLength: number,
    lastLateralLength: number
  ) {
    let submainCalculator = new SubmainCalculator();
    let data = {
      isReqtangular,
      lastLateralFlowRate,
      totalLateralFlowRate,
      numOfLaterals,
      firstLateralLength,
      lastLateralLength,
      units,
    };
    if (units == EUnits.US_UNITS) {
      data = UnitsConverter.convertSubmainBlockFlowRateDataToMetric(data);
    }

    let blockFlowRate: number = submainCalculator.calcBlockFlowRate(data);

    if (units == EUnits.US_UNITS) {
      let isTotalFlowRate = totalLateralFlowRate ? false : true;
      blockFlowRate = UnitsConverter.convertFlowRate(
        blockFlowRate,
        isTotalFlowRate
      );
    }
    return blockFlowRate;
  }

  private async translateWarningsById(calcResults, language, units, isOffline) {
    // let translationsDAL: TranslationsDAL = new TranslationsDAL();
    let warnings = calcResults.calcResults.calculationResults.warnings;

    let translations = await this.translationsDAL.getTranslationsByLanguage(
      language,
      isOffline
    );

    // Set warning text translation:
    // warnings.forEach((warning) => {
    //   let id = warning.id_translation;
    //   let trans = translations.find(
    //     ({ translation_id }) => translation_id === id
    //   );
    //   let warning_text = trans.value !== " " ? trans.value : warning.warning;
    //   warning.warning = warning_text;

    //   // special case for valve flow rate warning
    //   // warning string needs to be updated after translated
    //   if (warning.Id === ECalculationWarnings.RECOMMENDED_FLOW_RATE_FOR_VALVE) {
    //     const { valves } = calcResults.calcResults.calculationResults;
    //     const updatedWarning = this.buildValveFlowRateWarning(
    //       warning.warning,
    //       valves[0],
    //       units
    //     );
    //     warning.warning = updatedWarning;
    //   }
    // });
   this.translateWarnings(warnings,translations,calcResults,units)

    calcResults.calcResults.calculationResults.warnings = warnings;
    return calcResults;
  }

  // private async translateNotesById(calcResults, language,units isOffline) {
  //   let notes = calcResults.calcResults.calculationResults.notes;

  //   let translations = await this.translationsDAL.getTranslationsByLanguage(
  //     language,
  //     isOffline
  //   );

  //   // Set notes text translation:
  //   this.translate(notes,translations,'note',calcResults,units)

  //   calcResults.calcResults.calculationResults.notes = notes;
  //   return calcResults;
  // }

  private translateWarnings=(warnings,translations,calcResults,units)=>{
    this.translate(warnings,translations,'warning',calcResults,units)
  }

  private translateNotes=(notes,translations,calcResults,units)=>{
    this.translate(notes,translations,'notes',calcResults,units)
  }

  private translate = (itemsToTranslate, translations, itemType, calcResults,units) => {
    itemsToTranslate.forEach((textToTranslate) => {
      const id = textToTranslate.id_translation;
      const translation = translations.find(
        ({ translation_id }) => translation_id === id
      );
      if (translation.value !== " ") {
        textToTranslate[itemType] = translation.value;
      }
      // special case for valve flow rate warning
      // warning string needs to be updated after translated
      if (itemType === 'warning' && textToTranslate.Id === ECalculationWarnings.RECOMMENDED_FLOW_RATE_FOR_VALVE) {
        const { valves } = calcResults.calcResults.calculationResults;
        const updatedWarning = this.buildValveFlowRateWarning(
          textToTranslate[itemType],
          valves[0],
          units
        );
        textToTranslate[itemType] = updatedWarning;
      }
    });
  };

  private buildValveFlowRateWarning(warning: string, valve: any, units: any) {
    const { maximum_flow_rate, minimum_flow_rate } = valve;
    units = units == EUnits.US_UNITS ? "gpm" : "m^3/h";
    warning = warning
      .replace("{min}",  Number(minimum_flow_rate).toFixed(2))
      .replace("{max}", Number(maximum_flow_rate).toFixed(2))
      .replace("{units}", units);
    return warning;
  }
}
