import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
})
export class ErrorPopupComponent implements OnInit {
  @Input() error_msg = '';
  constructor(
    public dataService: DataService,
    public errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.error_msg = this.dataService.error_result_msg;
  }

  goTo() {
    this.dataService.showErrorPopup = false;
    // window.location.reload();
    window.scrollTo(0, 0);
  }
}
