import { Injectable } from "@angular/core";
import Amplify, { Auth } from "aws-amplify";
import { CognitoIdentityServiceProvider, CodeBuild } from "aws-sdk";
import * as aws from "aws-sdk";
import { HttpService } from "./http.service";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CognitoApisServiceService {
  constructor(public httpService: HttpService) {}

  updateConfiguration() {
    // import awsconfig from '../aws-exports';
    Amplify.configure({
      Auth: {
        // REQUIRED - Amazon Cognito Region
        region: "eu-west-1",

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: environment.cognito_userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: environment.cognito_userPoolWebClientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,
      },
    });
  }

  async signIn(email, password) {
    try {
      const user = await Auth.signIn(email, password);
      return { success: true, user: user };
    } catch (error) {
      return { success: false, error: error };
    }
  }
  async signUp(
    family_name,
    name,
    password,
    email,
    units,
    region,
    country,
    position,
    company,
    isReceiveUpdates,
    isAcceptTerms,
    language
  ) {
    try {
      let username = email;
      let user = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          name,
          family_name,
          "custom:units": units.toString(),
          "custom:region": region,
          "custom:country": country,
          "custom:position": position,
          "custom:company": company,
          "custom:isReceiveUpdates": isReceiveUpdates,
          "custom:isAcceptTerms": isAcceptTerms,
          "custom:language": language,
        },
      });
      return { success: true, user };
    } catch (error) {
      return { success: false, error: error };
    }
  }

  async resendConfirmationCode(email) {
    try {
      await Auth.resendSignUp(email);
    } catch (err) {
      throw err
    }
  }

  async confirmUserCognito(email, code) {
    try {
      let result = await Auth.confirmSignUp(email, code);
      return { success: true, result };
    } catch (error) {
      if (
        error.message !==
        "User cannot be confirmed. Current status is CONFIRMED"
      ) {
        console.log("error confirming sign up", error);
        return { success: false, error };
      }
    }
  }

  async changePassword(oldPassword, newPassword) {
    try {
      let user = await Auth.currentAuthenticatedUser();

      let data = await Auth.changePassword(user, oldPassword, newPassword);
      return { success: true, data };
    } catch (error) {
      return { success: false, error };
    }
  }
  async sendEmailForgetPassword(email) {
    try {
      // Send confirmation code to user's email
      let data = await Auth.forgotPassword(email);
      return { success: true, data: data };
    } catch (error) {
      console.log(error);
      return { success: false, error };
    }
  }

  async setForgetPassword(email, code, new_password) {
    // Collect confirmation code and new password, then
    try {
      let data = await Auth.forgotPasswordSubmit(email, code, new_password);
      return { success: true, data: data };
    } catch (error) {
      console.log(error);
      return { success: false, error };
    }
  }

  async updateUserAttributes(language, region, units, isReceiveUpdates, cb) {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let result = await Auth.updateUserAttributes(user, {
        "custom:units": units.toString(),
        "custom:region": region,
        "custom:language": language,
        "custom:isReceiveUpdates": isReceiveUpdates,
      });
     
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession: any = await Auth.currentSession();
      this.refreshToken(cognitoUser, currentSession, (token_success) => {
        return cb({ success: true, token_success });
      });
    } catch (error) {
      console.log(error);
      return cb({ success: false, error });
    }
  }
  async updateUserLanguage(language, cb) {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let result = await Auth.updateUserAttributes(user, {
        "custom:language": language,
      });
     
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession: any = await Auth.currentSession();

      this.refreshToken(cognitoUser, currentSession, (token_success) => {
        return cb({ success: true, token_success });
      });
    } catch (error) {
      console.log(error);
      return cb({ success: false, error });
    }
  }
  async updateUserRegion(region, cb) {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let result = await Auth.updateUserAttributes(user, {
        "custom:region": region,
      });
     
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession: any = await Auth.currentSession();

      this.refreshToken(cognitoUser, currentSession, (token_success) => {
        return cb({ success: true, token_success });
      });
    } catch (error) {
      console.log(error);
      return { success: false, error };
    }
  }

  async signOut() {
    try {
      await Auth.signOut({ global: true });
      return { success: true };
    } catch (error) {
      console.log("error signing out: ", error);
      return { success: false, error };
    }
  }

  async refreshToken(cognitoUser2, currentSession2, cb) {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession: any = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, (err, data) => {
        if (err) {
          console.log(err);
          // an error occurred
          return cb(false);
        }

        const idToken = "Bearer " + data.idToken.jwtToken;
        const accessToken = data.accessToken.jwtToken;
        const refreshToken = data.refreshToken.token;

        localStorage.setItem("Authorization", idToken);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
      
        return cb(true);
        // do whatever you want to do now :)
      });
    } catch (error) {
      return false;
    }
  }

  async checkAccessTokenValid() {
    const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
      region: "eu-west-1",
    });

    var params = {
      AccessToken: localStorage.getItem("accessToken"),
    };
    try {
      let data = await cognitoidentityserviceprovider.getUser(params).promise();
      return true;
    } catch (error) {
      // const cognitoUser = await Auth.currentAuthenticatedUser();
      // const currentSession: any = await Auth.currentSession();

      this.refreshToken("cognitoUser", "currentSession", (res) => {
        console.log(error, error.stack);
        return res;
      });
    }
  }

  async isUserLoggedIn() {
    try {
      await Auth.currentAuthenticatedUser();
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  }
}
