import { Injectable } from "@angular/core";
import { DeviceLessCalcCharacteristicsLabelConfig } from "../shared/characteristics/calc-labels.types";

@Injectable({
  providedIn: "root",
})
export class EnergyService {
  InchToMmPerHour: number = 0.0393701;
  poundPerSquareInchToMeter: number = 1.42197;
  acreToHectare: number = 2.47105;

  constructor() {}

  // KWHr
  calcAnnualTotalEnergy(
    fieldArea: number,
    annualWaterConsum: number,
    pumpPressure: number,
    pumpEfficiency: number,
    units: number
  ): number {
    const result =
      units === 1
        ? this.calcAnnualTotalEnergyByMetric(
            fieldArea,
            annualWaterConsum,
            pumpPressure,
            pumpEfficiency
          )
        : this.calcAnnualTotalEnergyByUsa(
            fieldArea,
            annualWaterConsum,
            pumpPressure,
            pumpEfficiency
          );
    return result;
  }

  // In $
  calcAnnualTotalEnergyCost(
    annualTotalEnergy: number,
    costOfKWPerHour: number
  ): number {
    return annualTotalEnergy * costOfKWPerHour;
  }

  // Present Value Series Factor
  calcPVSF(interestValue: number, periodOfYear: number): number {
    // Change var name to relevant one
    const interestValCalc = 1 + interestValue / 100;

    return (
      (interestValCalc ** periodOfYear - 1) /
      ((interestValue / 100) * interestValCalc ** periodOfYear)
    );
  }

  // In $
  calcEnergyInvestedAlongPeriod(
    interestValue: number,
    periodOfYear: number,
    annualTotalEnergyCost: number
  ): number {
    return this.calcPVSF(interestValue, periodOfYear) * annualTotalEnergyCost;
  }

  calcUVI(
    interestValue: number,
    periodOfYear: number,
    annualTotalEnergyCost: number,
    pumpPressure: number,
    units: number
  ): number {
    const result =
      units === 1
        ? this.calcUVIByMetric(
            interestValue,
            periodOfYear,
            annualTotalEnergyCost,
            pumpPressure
          )
        : this.calcUVIByUsa(
            interestValue,
            periodOfYear,
            annualTotalEnergyCost,
            pumpPressure
          );

    return result;
  }

  // private functions for Units

  // KWHr
  private calcAnnualTotalEnergyByMetric(
    fieldArea: number,
    annualWaterConsum: number,
    pumpPressure: number,
    pumpEfficiency: number
  ): number {
    return (
      (fieldArea * 10 * annualWaterConsum * (pumpPressure * 0.746)) /
      (270 * (pumpEfficiency / 100))
    );
  }

  private calcAnnualTotalEnergyByUsa(
    fieldArea: number,
    annualWaterConsum: number,
    pumpPressure: number,
    pumpEfficiency: number
  ): number {
    // field area from square feet to square meter in docs (3.28084) is wrong - should be 10.7639.
    const fixedFieldArea = fieldArea / this.acreToHectare;
    const fixedAnnualWaterConsumption =
      annualWaterConsum / this.InchToMmPerHour;
    const fixedPumpPressure = pumpPressure / this.poundPerSquareInchToMeter;
    return this.calcAnnualTotalEnergyByMetric(
      fixedFieldArea,
      fixedAnnualWaterConsumption,
      fixedPumpPressure,
      pumpEfficiency
    );
  }

  // Unit Value Investment - $/m
  calcUVIByMetric(
    interestValue: number,
    periodOfYear: number,
    annualTotalEnergyCost: number,
    pumpPressure: number
  ): number {
    return (
      this.calcEnergyInvestedAlongPeriod(
        interestValue,
        periodOfYear,
        annualTotalEnergyCost
      ) / pumpPressure
    );
  }

  calcUVIByUsa(
    interestValue: number,
    periodOfYear: number,
    annualTotalEnergyCost: number,
    pumpPressure: number
  ): number {
    return (
      this.calcEnergyInvestedAlongPeriod(
        interestValue,
        periodOfYear,
        annualTotalEnergyCost
      ) /
      (pumpPressure / this.poundPerSquareInchToMeter)
    );
  }

  // characteristics

  getEnergyLabelsConfig(): DeviceLessCalcCharacteristicsLabelConfig[] {
    return this.energyLabelsConfig;
  }

  private energyLabelsConfig: DeviceLessCalcCharacteristicsLabelConfig[] = [
    {
      ObjName: "field_area",
      TextKey: "input_field_area_label",
      unitsKey: "hect",
    },
    {
      ObjName: "annual_water_consumption",
      TextKey: "input_annual_water_consumption_label",
      unitsKey: "mm",
    },
    {
      ObjName: "pump_pressure",
      TextKey: "input_pump_pressure_label",
      unitsKey: "m_pressure",
    },
    {
      ObjName: "pump_efficiency",
      TextKey: "input_pump_efficiency_label",
      unitsText: "(%)",
    },
    {
      ObjName: "cost_of_KW_per_hour",
      TextKey: "input_cost_of_kw_per_hour_label",
      unitsText: "($)",
    },
    {
      ObjName: "interest_value",
      TextKey: "input_interest_value_label",
      unitsText: "(%)",
    },
    {
      ObjName: "period_of_years",
      TextKey: "input_period_of_years_label",
    },
  ];
}
