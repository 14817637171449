import { AbsCalculationProcess } from "../AbsCalculationProcess";
import { Segment } from "../../Segment";
import { PipeSection } from "../../PipeSection";
import { SubmainCalculationType } from "../../../enums/SubmainCalculationType.enum";
import { SlopeSection } from "../../../../calculator/Entities/SlopeSection";
import { CalculationErrors, ECalculationErrors } from "../../../errors_and_warnings/errors";
import { CalculationWarnings, ECalculationWarnings } from "../../../errors_and_warnings/warnings";
import { AbsSubmainCalculationProcess } from "./AbsSubmainCalculationProcess";

export class PipesDiameter extends AbsSubmainCalculationProcess {

    public initSegments(data: any, segments: any, slopes: any, pipes: any, isFlushing: boolean = false) {
        // Add Slope section propeties to Segments:
        this.addSlopeSectionsToSegments(segments, slopes);
        if (isFlushing) {
            this.addPipeSectionsToSegments(segments, pipes);
        }
    }

    public calculate(segments: any, pipes: any, slopes: any, data: any) {
        let isFlushing = data.isFlushingMode;
        if (isFlushing) {
            // Flushing calculation:
            return this.calcTotalPressureLoss(segments, pipes, slopes, data);
        }
        else {
            // Pipes Diameter calculation:
            return this.calcPipeDiametersForAGivenMaxPressureLoss(segments, pipes, slopes, data);
        }
    }
    // ---------------------------------------- OVERRIDE METHODS ----------------------------------------

    protected getCurrPipeSectionInit(calculationType: any, pipes: PipeSection[], pipesIndex: number, segments: any = null) {
        let pipe: any = null;

        if (calculationType == SubmainCalculationType.PIPES_DIAMETERS) {
            pipe = pipes[pipesIndex];
            if (!pipe) {
                return null;
            }
            pipe.EndPressure = segments[segments.length - 1].EndPressure;
        }

        return pipe;
    }

    protected initSegmentPipeSection(data: any) {
        if (data.calculationType == SubmainCalculationType.PIPES_DIAMETERS) {
            // Set curr segment pipe section:
            data.segment.PipeSection = data.pipe;
        }
    }

    protected handleCalculationStops(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false,
            InletPressureUIstop: false
        }
        let { pipes, slopes, segments, calculationType, currPipeSection, systemGeneralPressureLoss, maxPressureLoss, currSegment, isEndPressureCalculation, inletPressureUI, isFlushingMode, index } = data
        let currSegmentInletPressure = currSegment.InletPressure;
        if (calculationType == SubmainCalculationType.PIPES_DIAMETERS) {
            // Pressure stop:
            if (currSegment.InletPressure > Number(currPipeSection.MaxAllowedPressure)) {
                // Restart claculation with Larger pipe diameter:
                calcStopsResponse.isStopIteration = true;
                return calcStopsResponse;
            }

            // Pressure loss stop:
            if (systemGeneralPressureLoss >= maxPressureLoss) {
                // return calcStopsResponse;
                if (systemGeneralPressureLoss > maxPressureLoss * AbsCalculationProcess.MAX_PRESSURE_LOSS_PRECENT) {
                    // Restart calculation with Larger pipe diameter:
                    calcStopsResponse.isStopIteration = true;
                    return calcStopsResponse;
                }
                else {
                    // Proceed calculation with warning:
                    calcStopsResponse.warning = ECalculationWarnings.USER_MAX_PRESSURE_LOSS_DEVIATION_WITH_EXTRA;

                    return calcStopsResponse;
                }
            }
            // Update pipe section:
            currPipeSection.SectionLength += currSegment.Length;
        }
        if (calculationType == SubmainCalculationType.VERIFICATION) {
            if (systemGeneralPressureLoss >= maxPressureLoss) {
                // calcStopsResponse.isStopIteration = true;
                // return calcStopsResponse;// No 2.5 addition
                if (systemGeneralPressureLoss > maxPressureLoss * AbsCalculationProcess.MAX_PRESSURE_LOSS_PRECENT) {
                    // Restart calculation with Larger pipe diameter:
                    calcStopsResponse.isStopIteration = true;
                    return calcStopsResponse;
                }
                else {
                    // Proceed calculation with warning:
                    calcStopsResponse.warning = ECalculationWarnings.USER_MAX_PRESSURE_LOSS_DEVIATION_WITH_EXTRA;

                    return calcStopsResponse;
                }
            }
            // Pressure stop:
            let currentPressure = currSegment.InletPressure;
            if (currentPressure > currPipeSection.MaxAllowedPressure) {
                // Restart calculation with Larger pipe diameter:
                calcStopsResponse.isStopIteration = true;
                return calcStopsResponse;
            }

        }

        return calcStopsResponse;
    }

    protected postCalcActions(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false
        }
        let { calculationType, pipes, maxPressureLoss, segmentsCalcData } = data

        if (calculationType == SubmainCalculationType.PIPES_DIAMETERS) {
            // Calc succeeded, check pipe sections length:
            if (pipes.length == 1 && pipes[0].Id == 0) {
                // curr pipe is the smallest, calculation ended successfully:
                calcStopsResponse.isCalcDone = true;
                return calcStopsResponse;
            }
            let usedPipes = pipes.filter((p) => p.SectionLength > 0);

            if ((usedPipes.length == 1 && usedPipes[0].PressureLoss < maxPressureLoss)) {
                // Calculation ended with 1 pipe with pressure loss < maxPressureLoss,
                // Replace 1 pipe calc with 2 pipes: first pipe is curr pipe - 1 meters as start, second pipe is 1 diameter smaller (submain length - second pipe length)
                segmentsCalcData.type = SubmainCalculationType.VERIFICATION;
                calcStopsResponse.isStopIteration = true;
                return calcStopsResponse;
            }
        }

        return calcStopsResponse;
    }

    // ---------------------------------------- PRAIVATE METHODS ----------------------------------------

    private calcPipeDiametersForAGivenMaxPressureLoss(segments: Segment[], pipes: PipeSection[], slopes: SlopeSection[], calcData: any) {
        let totalPressureLoss = 0;
        let totalMaxPressure: number;
        let totalMinPressure: number;
        let isCalcDone = false;
        let isFlushing = calcData.isFlushingMode;
        let lastFlowRate = this.calcLastLateralFlowRateForCalculation(calcData.blockChars.num_of_laterals, calcData.blockChars.is_rectangular, calcData.blockChars.last_lateral_flow_rate, calcData.blockChars.total_lateral_flow_rate, calcData.blockChars.last_lateral_length, calcData.blockChars.first_lateral_length);
        let endPressure = calcData.blockChars.end_pressure;
        let lastSegment = segments[segments.length - 1];
        let pipesIndex = 0;
        let submainLength = calcData.blockChars.submain_pipe_length;
        let meters = 20;
        let currPipeSection = pipes[pipesIndex];
        let errors = [];
        let warnings = [];
        let systemPL: number;
        let iscalcStopInletPressureUIstop = false

        // Init last segment:
        lastSegment.FlowRate = lastFlowRate;
        lastSegment.AtomicFlowRate = lastFlowRate;
        lastSegment.EndPressure = endPressure;
        lastSegment.PipeSection = currPipeSection;

        // Set segment calculation data:        
        let segmentsCalcData: any = { calcData, pipes, slopes, pipesIndex, segments, lastFlowRate, type: SubmainCalculationType.PIPES_DIAMETERS };
        pipes = pipes.sort((a, b) => a.NominalDiameter - b.NominalDiameter);
        do {
            // Reset CalculationData:
            this.resetSegmentsCalcData(segmentsCalcData);
            // Handle segments calculation:
            let res = this.handleSegmentsCalculation(segmentsCalcData);
            isCalcDone = res.isCalcDone;
            systemPL = res.systemPL;
            iscalcStopInletPressureUIstop = res.isInletPressureUIstop

            if (!isCalcDone) {
                if (segmentsCalcData.type != SubmainCalculationType.VERIFICATION) {
                    segmentsCalcData.pipesIndex++;
                    // Check if calculation ended:
                    if (segmentsCalcData.pipesIndex >= segmentsCalcData.pipes.length) {

                        errors.push(CalculationErrors.get(ECalculationErrors.NO_RESULTS));
                        isCalcDone = true;
                    }
                }
                else {
                    if (segmentsCalcData.pipesIndex == 0) {
                        // Pipe is the smallest from pipes group' return success: 
                        isCalcDone = true;
                    }
                    else {
                        // Take spacing meters from first pipe to second pipe
                        meters += segments[0].Length;
                        let newPipes = this.setPipesToSegments(segments, pipes, segmentsCalcData.pipesIndex, meters, submainLength);
                        segmentsCalcData.pipes = newPipes;
                    }
                }
            }
        } while (!isCalcDone);

        // Get selected pipes:
        pipes = pipes.filter((p) => p.SectionLength > 0);

        // Summerize results:
        warnings = segmentsCalcData.warnings || [];
        if (errors.length == 0) {
            errors = segmentsCalcData.errors || [];
        }
        segments.forEach(element => {
            totalPressureLoss += element.Pressureloss;
        });
        let maxVelocity = 0;

        // Set Total Min/Max Pressure: 
        if (pipes.length > 0) {
            totalMaxPressure = pipes[0].MaxPressure;
            totalMinPressure = pipes[0].MinPressure;
            pipes.forEach(pipe => {
                if (pipe.MaxPressure > totalMaxPressure) {
                    totalMaxPressure = pipe.MaxPressure;
                }
                if (pipe.MinPressure < totalMinPressure) {
                    totalMinPressure = pipe.MinPressure;
                }
                maxVelocity = this.setMaxOrMin(maxVelocity, pipe.MaxVelocity, true);

                pipe.SectionLength = Number(pipe.SectionLength.toFixed(2));
                pipe.PressureLoss = pipe.InletPressure - pipe.EndPressure;
            });
        }
        //claculate submain length:
        let sum = 0;
        segments.map(s => { sum = Math.round((sum + s.Length) * 1e12) / 1e12 });
        submainLength = iscalcStopInletPressureUIstop ? sum : calcData.blockChars.submain_pipe_length;

        return { totalPressureLoss, totalMaxPressure, totalMinPressure, maxVelocity, submainLength, warnings, errors };
    }
}