import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import {
  Einputs_fields,
  Ecalculator,
  EtransaltionGroups, EqueryTypes
} from 'src/app/shared/enum';
import { TextService } from 'src/app/services/text.service';
@Component({
  selector: 'app-advanced-setting',
  templateUrl: './advanced-setting.component.html',
  styleUrls: ['./advanced-setting.component.scss']
})
export class AdvancedSettingComponent implements OnInit {
  toggle_advanced_option = false;
  @Input() selectedFlushingMode;
  @Input() flushingValue: FormControl;
  @Input() selectedCalculationMethod;
  @Input() calculatorIndex;
  @Input() queryIndex
  @Input() pipe_roughness_value: FormControl;
  @Input() flushingToOtherValue: FormControl;
  @Output() changeFlushingMode = new EventEmitter<string>();
  @Output() changeCalculationMethod = new EventEmitter<string>();
  @Input() selectedPipeRoughness;
  @Input() selectedFlushingTo;
  @Output() changePipeRoughnessSelection = new EventEmitter<string>();
  @Output() changeFlushingTo = new EventEmitter<string>();
  @Input() current_section;
  @Input() addSectionForFlushing: any
  @Output() changeAddSectionForFlushingSelection = new EventEmitter<string>();
  @Input() flushing_with_closed_valves: any
  @Output() changeFlushingIsValveClosed = new EventEmitter<string>();
  Einputs_fields = Einputs_fields;
  Ecalculator = Ecalculator;
  EtransaltionGroups = EtransaltionGroups;
  EqueryTypes = EqueryTypes;
  flushingLabelInput = '';
  constructor(
    public dataService: DataService,
    public textService: TextService
  ) { }

  ngOnInit(): void { }
  pipeRoughnessValidation() {
    if (this.selectedPipeRoughness == '2') {
      this.pipe_roughness_value.setValidators([
        Validators.compose([
          Validators.required,
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.min(0),
          Validators.max(155)
        ])
      ]);
      this.pipe_roughness_value.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false
      });
    } else {
      this.pipe_roughness_value.clearValidators();
      this.pipe_roughness_value.setValue('', {
        emitEvent: false
      });
      this.pipe_roughness_value.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false
      });
    }

  }
  flushingToOtherValueValidation() {
    if (this.selectedFlushingTo == 'Other') {
      this.flushingToOtherValue.setValidators([
        Validators.compose([
          Validators.required,
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.min(0),
          Validators.max(150)
        ])
      ]);
      this.flushingToOtherValue.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false
      });
    } else {
      this.flushingToOtherValue.clearValidators();
      this.flushingToOtherValue.setValue('', {
        emitEvent: false
      });
      this.flushingToOtherValue.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false
      });
    }

  }

  flushingInputValidation() {
    this.changeFlushingMode.emit(this.selectedFlushingMode);
    if (this.selectedFlushingMode == 'yes') {
      switch (this.calculatorIndex) {
        case this.Ecalculator.lateral:
          this.flushingLabelInput = this.textService.addMesaureUnitsToLabel(
            this.textService.getText(
              this.EtransaltionGroups.LATERAL,
              'lateral_advanced_options_title_2_velocity'
            ),

            'ms'
          );
          break;
        case this.Ecalculator.submain:
          this.flushingLabelInput = this.textService.addMesaureUnitsToLabel(
            this.textService.getText(
              this.EtransaltionGroups.SUBMAIN,
              'advanced_options_flushing_velocity'
            ),
            'ms'
          );
          break;
        case this.Ecalculator.mainline:
          this.flushing_with_closed_valves = 'yes'
          this.changeFlushingIsValveClosed.emit(this.flushing_with_closed_valves)
          this.flushingLabelInput = this.textService.addMesaureUnitsToLabel(
            this.textService.getText(
              this.EtransaltionGroups.MAINLINE,
              'mainline_advanced_options_flushing_velocity'
            ),
            'ms'
          );

          break;
        default:
          break;
      }

      this.flushingValue.setValidators([
        Validators.compose([
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.required,
          Validators.min(
            this.calculatorIndex === this.Ecalculator.submain
              ? this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.flushing_velocity
                ],
                'min'
              )
              : 0
          ),
          Validators.max(
            this.calculatorIndex === this.Ecalculator.submain
              ? this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.flushing_velocity
                ],
                'max'
              )
              : 6
          )
        ])
      ]);
      this.flushingValue.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false
      });
    } else {
      this.flushingValue.clearValidators();
      this.flushingValue.setValue('', {
        emitEvent: false
      });
      this.flushingValue.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false
      });
      switch (this.calculatorIndex) {
        case Ecalculator.mainline:
          this.addSectionForFlushing = 'no'
          this.changeAddSectionForFlushingSelection.emit(this.addSectionForFlushing)
          this.flushing_with_closed_valves = 'yes'
          this.changeFlushingIsValveClosed.emit(this.flushing_with_closed_valves)
          break;

        default:
          break;
      }
    }
  }

}
