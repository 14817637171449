import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { CognitoApisServiceService } from 'src/app/services/cognito-apis-service.service';
import { EforgotPasswordSteps } from 'src/app/shared/enum';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
@Component({
  selector: 'app-forgot-password-popup',
  templateUrl: './forgot-password-popup.component.html',
  styleUrls: ['./forgot-password-popup.component.scss']
})
export class ForgotPasswordPopupComponent implements OnInit {
  formGroupObject = {};
  emailRegx = /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
  forgotPasswordForm: FormGroup;
  EtransaltionGroups = EtransaltionGroups;
  EforgotPasswordSteps = EforgotPasswordSteps;
  current_step;
  constructor(
    public dataService: DataService,
    public cognitoAWSService: CognitoApisServiceService,
    public textService: TextService
  ) {}

  ngOnInit(): void {
    this.current_step = this.EforgotPasswordSteps.send_email;
    // this.current_step = this.EforgotPasswordSteps.change_passwords;
    this.generateForm();

    this.forgotPasswordForm.controls['repeatPassword'].valueChanges.subscribe(
      val => {
        if (this.forgotPasswordForm.controls['password'].value !== val) {
          this.forgotPasswordForm.controls['repeatPassword'].setErrors({
            notEqual: true
          });
        }
      }
    );
  }

  generateForm() {
    this.formGroupObject['email'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(this.emailRegx)
    ]);
    this.formGroupObject['verify_code'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50)
    ]);
    this.formGroupObject['password'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50)
    ]);
    this.formGroupObject['repeatPassword'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50)
    ]);

    this.forgotPasswordForm = new FormGroup(this.formGroupObject);
  }

  async onSave() {
    if (
      this.forgotPasswordForm.controls.email.valid &&
      this.current_step == 1
    ) {
      let response = await this.cognitoAWSService.sendEmailForgetPassword(
        this.forgotPasswordForm.controls.email.value
      );

      if (response.success) {
        this.current_step = this.EforgotPasswordSteps.change_passwords;
      } else {
        this.dataService.showPasswordPopup = false;
        this.dataService.error_result_msg = response.error.message;
        this.dataService.showErrorPopup = true;
      }
    }
    if (this.current_step == 2 && this.validateSecondStep()) {
      let response = await this.cognitoAWSService.setForgetPassword(
        this.forgotPasswordForm.controls.email.value,
        this.forgotPasswordForm.controls.verify_code.value,
        this.forgotPasswordForm.controls.password.value
      );
      if (response.success) {
        this.dataService.signInUser.next({
          email: this.forgotPasswordForm.controls.email.value,
          password: this.forgotPasswordForm.controls.password.value
        });
        this.dataService.showPasswordPopup = false;
      } else {
        this.dataService.showPasswordPopup = false;
        this.dataService.error_result_msg = response.error.message;
        this.dataService.showErrorPopup = true;
      }
    }
  }

  onCancel() {
    this.dataService.showPasswordPopup = false;
  }

  get controls() {
    return this.forgotPasswordForm.controls;
  }

  checkError(controlName: string) {
    if (this.forgotPasswordForm.controls[controlName].errors) {
      const theErrors = this.forgotPasswordForm.controls[controlName].errors;
      if (theErrors.required) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'field_required'
        );
      }
      if (theErrors.minlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );

        return this.textService.addValueToText(
          text,
          theErrors.minlength.requiredLength
        );
      }
      if (theErrors.maxlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );
        return this.textService.addValueToText(
          text,
          theErrors.maxlength.requiredLength
        );
      }
      if (theErrors.pattern) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'invalid_pattern'
        );
      }
      if (theErrors.notEqual) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'password_validation'
        );
      }
    }
  }

  validateSecondStep() {
    return (
      this.forgotPasswordForm.controls.password.valid &&
      this.forgotPasswordForm.controls.verify_code.valid &&
      this.forgotPasswordForm.controls.repeatPassword.valid
    );
  }
}
