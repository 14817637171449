import { Result } from "../../models/Result";
import { ErrorMsgs } from "../../models/Errors/ErrorMsgs";
import { Handler } from "../../../hydrocalc-code/generics/base.handler";

export abstract class AbsRequestHandler extends Handler {

    public async HandleRequest(reqData: any, handle: Function, buildResult: Function): Promise<Result> {
        let result = new Result();
        try {
            let res = await handle(reqData);
            if (!res) {
                throw new ErrorMsgs('Internal error occured while handling Request');
            }
            result.result = buildResult(res);

            return result;
        } catch (error) {
            return this.buildErrorResult(error);
        }
    }

    //------------------------------------------- RESULT BUILDERS ----------------------------------------------------

    public buildPageDataResult(resultData: any) {
        let result = { page_data: resultData.pageData };

        return result;
    }

    public buildLoginUrlResult(resultData: any) {
        let result = { login_url: resultData.login_url };

        return result;
    }
    protected buildErrorResult(error: any): Result {
        let res = new Result();
        res.success = false;
        res.error = error
        res.status_code = error.StatusCode || 500;

        return res;
    }

    public buildDeleteResult(resultData: any) {
        let result = { is_deleted: resultData.isDeleted };

        return result;
    }

    public buildSuccessResult(resultData: any) {
        let result = { success: resultData.isSucceeded };

        return result;
    }

    public buildInternalDiameterResult(resultData: any) {
        let result = { internal_diameter: resultData.internalDiameter };

        return result;
    }

    public buildInternalDiameterAndRoghness(resultData: any) {
        let result = { internal_diameter: resultData.internalDiameter, roughness: resultData.roughness };

        return result;
    }
}