import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SubmainCalculatorPageComponent } from "./pages/submain-calculator-page/submain-calculator-page.component";
import { LateralCalculatorPageComponent } from "./pages/lateral-calculator-page/lateral-calculator-page.component";
import { CanActivateLoggedUser } from "./shared/CanActivateLoggedUser";
import { SignUpComponent } from "./pages/sign-up/sign-up.component";
import { SignInComponent } from "./pages/sign-in/sign-in.component";
import { CalculatorMainComponent } from "./pages/calculator-main/calculator-main.component";
import { CalcHomeComponent } from "./pages/calculator-main/calc-home/calc-home.component";
import { CalcHelpComponent } from "./pages/calculator-main/calc-help/calc-help.component";
import { CalcSettingsComponent } from "./pages/calculator-main/calc-mobile/calc-settings/calc-settings.component";
import { HelpContactUsComponent } from "./pages/calculator-main/calc-mobile/help-contact-us/help-contact-us.component";
import { HelpCommonQuestionComponent } from "./pages/calculator-main/calc-mobile/help-common-question/help-common-question.component";
import { MainlineCalculatorPageComponent } from "./pages/mainline-calculator-page/mainline-calculator-page.component";
import { ValveCalculatorPageComponent } from "./pages/valve-calculator-page/valve-calculator-page.component";
import { EnergyCalculatorPageComponent } from "./pages/energy-calculator-page/energy-calculator-page.component";
import { IrrigationCalculatorPageComponent } from "./pages/irrigation-calculator-page/irrigation-calculator-page.component";
import { ShiftCalculatorPageComponent } from "./pages/shift-calculator-page/shift-calculator-page.component";
import { ValvesMainPageComponent } from "./pages/valves-main-page/valves-main-page.component";
import { ProductFinderComponent } from "./pages/product-finder/product-finder.component";

const routes: Routes = [
  {
    path: "calc",
    component: CalculatorMainComponent,
    children: [
      {
        path: "home",
        component: CalcHomeComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "lateral",
        component: LateralCalculatorPageComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "submain",
        component: SubmainCalculatorPageComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "mainline",
        component: MainlineCalculatorPageComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "valve",
        component: ValvesMainPageComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "valve/hydraulic-calculator",
        component: ValveCalculatorPageComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "valve/product-finder",
        component: ProductFinderComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "irrigation",
        component: IrrigationCalculatorPageComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "shift",
        component: ShiftCalculatorPageComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "energy",
        component: EnergyCalculatorPageComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "help",
        component: CalcHelpComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "help/contact",
        component: HelpContactUsComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "help/common-question",
        component: HelpCommonQuestionComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "settings",
        component: CalcSettingsComponent,
        canActivate: [CanActivateLoggedUser],
      },
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full",
        canActivate: [CanActivateLoggedUser],
      },
    ],
  },
  { path: "login", component: SignInComponent },
  { path: "signup", component: SignUpComponent },
  { path: "", redirectTo: "login", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
