import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-img-tooltip',
  templateUrl: './img-tooltip.component.html',
  styleUrls: ['./img-tooltip.component.scss']
})
export class ImgTooltipComponent implements OnInit {
  @Input() imgSrc;
  @Input() tooltipText;
  constructor() {}

  ngOnInit(): void {}
}
