import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "./data.service";
import { ErrorService } from "./error.service";
import { HttpService } from "./http.service";
import { HttpHandler } from "./httpHandler";
import { OfflineService } from "./offline.service";

@Injectable({
  providedIn: "root",
})
export class ValveService extends HttpHandler {
  private url = "calculator/valve";
  constructor(
    public router: Router,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService,
    public offlineService: OfflineService
  ) {
    super(_http, httpService, errorService);
  }

  getValvesData(body) {
    const url = this.url + "/valvescharacteristics";
    return this.post(url, body);
  }

  getLimitedPressureLoss(body) {
    const url = this.url + "/limitedpressureloss";
    return this.post(url, body);
  }

  calculate(body) {
    const url = this.url;
    return this.post(url, body);
  }
}
