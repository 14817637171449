import { Component, OnInit } from '@angular/core';
import { MENU_OPTIONS } from 'src/app/shared/types';
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  openMenu = false;
  pageChosen = MENU_OPTIONS.SUBMAIN;
  MENU_OPTIONS = MENU_OPTIONS;
  constructor() { }

  ngOnInit(): void {
  }

  navigateTo(option){
    if(this.openMenu){
      
      switch(option){
        case(this.MENU_OPTIONS.ABOUT):
          this.pageChosen = this.MENU_OPTIONS.ABOUT
          break;
        case(this.MENU_OPTIONS.SUBMAIN):
          this.pageChosen = this.MENU_OPTIONS.SUBMAIN
          break;
        case(this.MENU_OPTIONS.LATERAL):
          this.pageChosen = this.MENU_OPTIONS.LATERAL
          break;
        case(this.MENU_OPTIONS.HOME):
          this.pageChosen = this.MENU_OPTIONS.HOME
          break;
        case(this.MENU_OPTIONS.SAVE):
          this.pageChosen = this.MENU_OPTIONS.SAVE
          break;
        case(this.MENU_OPTIONS.HELP):
          this.pageChosen = this.MENU_OPTIONS.HELP
          break;
        default:
            this.pageChosen = this.MENU_OPTIONS.SUBMAIN;
            break;
      }
    }
    
  }

}


