import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { utils, writeFile } from "xlsx";
import { PdfService } from "src/app/services/pdf.service";
import { TextService } from "src/app/services/text.service";
import { EtransaltionGroups } from "src/app/shared/enum";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-matrix-results",
  templateUrl: "./matrix-results.component.html",
  styleUrls: ["./matrix-results.component.scss"],
})
export class MatrixResultsComponent implements OnInit {
  @ViewChild("pdf_to_export") pdfView: ElementRef;
  @Input() results: any;
  tableColumns: number[];
  keysForSlopes: string[];
  dateDisplay: string = " ";
  pdfCalcInfoDisplayStr: string;
  boldedCalcInfoDisplay: string;
  EtransaltionGroups = EtransaltionGroups;
  lateral_s3_nominal_diameter;
  calculationExtraInfo;
  calculationExtraInfoMap = {
    0: { name: "model", translation_str: "lateral_s1_modle" },
    1: {
      name: "nominalDiameter",
      translation_str: "lateral_s3_nominal_diameter",
      units: "mm",
    },
    2: {
      name: "internalDiameter",
      translation_str: "lateral_s3_internal_diameter",
      units: "mm",
    },
    3: {
      name: "wallThickness",
      translation_str: "lateral_results_wall_thickness",
    },
    4: {
      name: "flowRate",
      translation_str: "lateral_s1_flow_rate",
      units: "lh",
    },
    5: { name: "kd", translation_str: "lateral_results_kd" },
    6: {
      name: "inletPressure",
      translation_str: "lateral_s3_lateral_pressure_inlet",
      units: "m_pressure",
    },
    7: {
      name: "endPressure",
      translation_str: "lateral_s3_lateral_pressure_end",
      units: "m_pressure",
    },
  };
  constructor(public textService: TextService, public pdfService: PdfService, public dataService: DataService) {}

  ngOnInit(): void {
    this.setResultsData();
    this.setPdfDisplays();
  }

  setResultsData() {
    const { calculationInfo, calculationResults } = this.results;
    this.setCalculationExtraInfo(calculationInfo);

    this.keysForSlopes = Object.keys(calculationResults).sort(
      (key1, key2) => Number(key1) - Number(key2)
    );
    this.tableColumns = calculationInfo.spacing;
  }

  setCalculationExtraInfo(calculationInfo) {
    const calculationExtraInfo = [];
    const sortedKeys = Object.keys(this.calculationExtraInfoMap).sort();

    for (let i = 0; i < sortedKeys.length; i++) {
      const key = sortedKeys[i];
      if (calculationInfo[this.calculationExtraInfoMap[key].name]) {
        const titleDisplay = this.textService.getText(
          EtransaltionGroups.LATERAL,
          this.calculationExtraInfoMap[key].translation_str
        );
        const value = calculationInfo[this.calculationExtraInfoMap[key].name];
        const units = this.calculationExtraInfoMap[key].units
          ? this.textService.getUnitsAsText(
              this.calculationExtraInfoMap[key].units
            )
          : "";
        calculationExtraInfo.push({ titleDisplay, value, units });
      }
    }

    this.calculationExtraInfo = calculationExtraInfo;
  }

  buildPDFCalcInfoDisplay() {
    this.pdfCalcInfoDisplayStr = this.calculationExtraInfo
      .slice(0, this.calculationExtraInfo.length - 1)
      .map((info) => `${info.titleDisplay}: ${info.value} ${info.units}`)
      .join(", ");

    this.boldedCalcInfoDisplay = `${
      this.calculationExtraInfo[this.calculationExtraInfo.length - 1]
        .titleDisplay
    }: ${
      this.calculationExtraInfo[this.calculationExtraInfo.length - 1].value
    } ${this.calculationExtraInfo[this.calculationExtraInfo.length - 1].units}`;
  }

  setPdfDisplays() {
    this.buildPDFCalcInfoDisplay();
    this.buildDateStr();
  }

  buildDateStr() {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    this.dateDisplay = `${day}-${months[month]}-${year}`;
  }

  exportToPDF() {
    let content: Element = this.pdfView.nativeElement;
    let title = "results";
    let options = {
      useCORS: true,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        scale: 2,
        width: 595.276,
        backgroundColor: "#f4f7fb",
        scrollX: 0,
        scrollY: 0,
      },
      jsPDF: { orientation: "portrait", unit: "mm", format: "a4" },
    };
    this.pdfService.generatePDF(content, title, options);
  }

  exportToExcel() {
    const content: Element = this.pdfView.nativeElement;
    let worksheet = utils.table_to_sheet(content);
    let workbook = utils.book_new();
    workbook.SheetNames.push("matrix-results");
    workbook.Sheets["matrix-results"] = worksheet;
    writeFile(workbook, "matrix-results.xlsx")
  }
  
  ngAfterViewInit() {
    const element = document.querySelector('#top');
    element.scrollIntoView();
  }
}
