
export class PipeSection {
    // Pipes Raw Properties
    Type: string;
    Class: string;
    NominalDiameter: number;
    InternalDiameter: number;
    Roughness: number;
    MaxAllowedPressure: number;
    MaxAllowedVelocity: number;
    KD: number;

    // Section Properties:
    Id: number;
    SectionLength: number = 0;
    NumOfSegmentsInSection: number;

    // Calculated Properties:
    PressureLoss: number = 0;
    InletPressure: number;
    EndPressure: number;
    MaxVelocity: number = 0;
    MaxFlowRate: number = 0;
    MaxPressure: number;
    MinPressure: number;

    //flow rate from user input for mainline
    accumulatedFlowRate
    atomicFlowRate

    //check for mainline - if it is extra section for flushing
    sectionForFlushing

    maxPressureSegementId: number;
    minPressureSegementId: number;

    //which pipe exceeds pressure/velocity
    max_velocity_warning: boolean = false;
    max_pressure_warning: boolean = false;
}


