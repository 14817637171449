import { AbsCalculationProcess } from "../AbsCalculationProcess";
import { ErrorMsgs } from "../../../../../hydrocalc-code/models/Errors/ErrorMsgs";
import { Segment } from "../../Segment";
import { PipeSection } from "../../PipeSection";
import { SlopeSection } from "../../SlopeSection";
import { MainlineCalculationType } from "../../../../calculator/enums/MainlineCalculationType.enum";
import { range } from "lodash";
import { textChangeRangeIsUnchanged } from "typescript";

export abstract class AbsMainlineCalculationProcess extends AbsCalculationProcess {
    /**
  * calcLastLateralFlowRateForCalculation
  * calc Last lateral Flow Rate for calculation use - for a given block shape, last lateral/total flow rate and Number of laterals
  * 
  */
    public calcLastLateralFlowRateForCalculation(numOfLaterals: number, isReqtangular: boolean, lastLateralFlowRate: number, totalLateralFlowRate: number = null, lastLateralLength: number = null, firstLateralLength: number = null): number {
        let flowRate: number = lastLateralFlowRate; //m^3/h

        return flowRate;
    }


    protected calcTotalPressureLoss(segments: Segment[], pipes: PipeSection[], slopes: SlopeSection[], calcData: any) {

        let endPressure = Number(calcData.blockChars.end_pressure);
        let isCalcDone = false;
        let systemPL: number;
        let isFlushing = calcData.isFlushingMode;
        let lastCalculationSegmentIndex = segments.length - 1;
        let lastFlowRate = this.calcLastLateralFlowRateForCalculation(undefined, undefined, segments[lastCalculationSegmentIndex].PipeSection.accumulatedFlowRate, undefined, undefined, undefined);
        let segment_index_for_desired_inlet_pressure

        // Init last calculation segment:
        segments[lastCalculationSegmentIndex].FlowRate = lastFlowRate;
        segments[lastCalculationSegmentIndex].AtomicFlowRate = lastFlowRate;
        if (!isFlushing) {
            segments[lastCalculationSegmentIndex].EndPressure = endPressure;
        }

        // Set segment calculation data:     
        let segmentsCalcData: any = { calcData, pipes, slopes, segments, lastFlowRate, type: MainlineCalculationType.PRESSURE_LOSS_FOR_KNOWN_PIPES };
        do {
            // Handle segments calculation:
            let res = this.handleSegmentsCalculation(segmentsCalcData);
            isCalcDone = res.isCalcDone;
            systemPL = res.systemPL;
            segment_index_for_desired_inlet_pressure = res.segment_index_for_desired_inlet_pressure

        } while (!isCalcDone);

        let results: any = this.summarizeResults(segments, pipes, systemPL, calcData.maxVelocity, calcData.isFlushingMode, calcData.end_velocity, segment_index_for_desired_inlet_pressure);
        results.totalLength = calcData.blockChars.totalLengthWithFlushing;
        results.errors = segmentsCalcData.errors || [];
        return results;
    }


    /**
     * Gets pressure loss caused by changes in pipe
     * like drippers, connectors, etc. (local friction, stored in database)
     * 
     * @param data 
     */
    protected getLocalHeadLoss(calcData: any, segment: any): number {
        let K = AbsCalculationProcess.K2_LOCAL_LOSS_COEFFICIENT_SUBMAIN;
        let pipeInternalDiameter = segment.PipeSection.InternalDiameter;
        let flowRate = segment.FlowRate;
        let KD = segment.PipeSection.KD;

        return KD * K * Math.pow(flowRate, 2) * Math.pow(pipeInternalDiameter, -4)
    }

    /**
     * Diskin - for Submain calculations
     * @param flowRate - Q
     * @param pipeInternalDiameter - D
     * @param distanceBetweenLaterals - R
     * @returns  
     */
    protected diskin(data: any): number {
        let flowRate = data.segment.FlowRate;
        let pipeInternalDiameter = data.segment.PipeSection.InternalDiameter;
        let segmentLength = data.segment.Length;

        if (pipeInternalDiameter > 40) {
            return AbsCalculationProcess.DISKIN_COEFFICIENT * Math.pow(flowRate, 1.81) * Math.pow(pipeInternalDiameter, -4.81) * segmentLength;
        }
        else {
            return AbsCalculationProcess.DISKIN_SMALL_DIAMETERS_COEFFICIENT * Math.pow(flowRate * 1000, 1.76) * Math.pow(pipeInternalDiameter, -4.76) * segmentLength; // No flow rate conversion
        }
    }


    /**
     * Hazen - for Submain calculations
     * 
     * @param flowRate - Q
     * @param pipeInternalDiameter - D
     * @param distanceBetweenLaterals - R
     * @param roughnessCoefficient - C
     * @returns
     */
    protected hazen(data: any): number {
        let flowRate = data.segment.FlowRate;
        let pipeInternalDiameter = data.segment.PipeSection.InternalDiameter;
        let segmentLength = data.segment.Length;
        let roughnessCoefficient = data.calcData.pipeRoughnessChw || data.segment.PipeSection.Roughness || AbsCalculationProcess.PIPES_ROUGHNESS_DEFAULT_VALUE;

        return AbsCalculationProcess.HAZEN_COEFFICIENT * Math.pow((flowRate / roughnessCoefficient), AbsCalculationProcess.HAZEN_POW1_COEFFICIENT) * Math.pow(pipeInternalDiameter, AbsCalculationProcess.HAZEN_POW2_COEFFICIENT) * segmentLength;
    }

    protected calcPrevSegFlowRate(data: any) {
        let { isReqtangle, lengthSum, calcData, segments, index, currSegment, lastFlowRate } = data;
        let prevSegment = segments[index - 1]
        if (!segments[index - 1].FlowRate || segments[index - 1].FlowRate === 0) {
            segments[index - 1].FlowRate = prevSegment.PipeSection.accumulatedFlowRate;
            segments[index - 1].AtomicFlowRate = prevSegment.PipeSection.atomicFlowRate;
        }

        return segments[index - 1].AtomicFlowRate;
    }
    protected setFlushingSettings(data: any, pipes: any): any {
        let isFlushingWithClosedValves = data.isFlushingWithClosedValves
        let flushingVelocity: any = this.getFlushingVelocityFromData(data);
        let lastOutletFlowRate: Number;
        let lastPipeSectionDiameter = pipes[pipes.length - 1].InternalDiameter;
        if (isFlushingWithClosedValves) {
            lastOutletFlowRate = this.calcFlowRateForFlushing(lastPipeSectionDiameter, flushingVelocity)
        } else {
            //check if all sections have enough flow rate in each section depends on the given velocity
            let flow_rate_gap: Number = 0
            pipes.forEach(pipe => {
                let accumulated_flow_rate: Number = pipe.accumulatedFlowRate
                let flow_rate_according_flushing_velociity = this.calcFlowRateForFlushing(pipe.InternalDiameter, flushingVelocity)
                if (flow_rate_according_flushing_velociity > accumulated_flow_rate) {
                    let gap = Math.round((Number(flow_rate_according_flushing_velociity) - Number(accumulated_flow_rate)) * 1e12) / 1e12
                    flow_rate_gap = Math.round((Number(flow_rate_gap) + Number(gap)) * 1e12) / 1e12

                }
            });
            lastOutletFlowRate = this.calcFlowRateForFlushing(lastPipeSectionDiameter, flushingVelocity);
            lastOutletFlowRate = Math.round((Number(lastOutletFlowRate) + Number(flow_rate_gap) * 1e12) / 1e12)
        }

        data.isFlushingMode = true;
        data.end_velocity = flushingVelocity

        data.flushing_end_flow_rate = lastOutletFlowRate;
        data.calcType = MainlineCalculationType.PRESSURE_LOSS_FOR_KNOWN_PIPES;
        data.flushing_end_pressure = AbsCalculationProcess.ATMOSPHERE_PRESSURE;

        return lastOutletFlowRate
    }
    protected handleNegativeFlushing(flushingResults: any, flushingPipes: any, data: any) {
        let minPressure = 0;
        let diff = 0;
        // Get min pressure:
        flushingPipes.forEach(pipe => {
            if (pipe.MinPressure < minPressure) {
                minPressure = pipe.MinPressure;
            }
        });
        if (minPressure < 0) {
            diff = AbsCalculationProcess.ATMOSPHERE_PRESSURE - minPressure;
            flushingResults.totalMaxPressure += diff;
            flushingResults.totalMinPressure += diff;
            flushingPipes[0].InletPressure += diff;

            //for user's input -  inlet pressure results
            if (flushingResults.result_for_user_inlet_pressure) {
                flushingResults.result_for_user_inlet_pressure.totalMinPressureForUserInletPressureInput += diff;
                flushingResults.result_for_user_inlet_pressure.totalMaxPressureForUserInletPressureInput += diff;
            }
        }
    }

    //Override
    protected calcPipeSumLength(segmentsCalcData) {
        let calcData: any = segmentsCalcData.calcData;
        let segments: Segment[] = segmentsCalcData.segments;
        let length: number;
        //if user choose to claculate flushing the last pipe section belong only for flushing mode
        if (calcData.userIsFlushing) {
            length = calcData.blockChars.totalLength
            for (let index = 0; index < segments.length; index++) {
                let segment = segments[index];
                if (segment.PipeSection.sectionForFlushing) {
                    length = length - segment.Length
                }
            }

        } else {
            length = calcData.blockChars.totalLength
        }
        return length
    }

    //Override
    protected resetSegmentsForFlushing(_segments: any, totalLength: number, data: any, blockChars: any, topographyChars: any, pipeChars: any, isFlushing: boolean): any {
        //create all segements in flushing mode
        let Allsegments = this.createCalculationSegments(data, blockChars, topographyChars, pipeChars, isFlushing)

        const dist = _segments[0].Length;
        let total = 0;
        const l = (_segments.length - 1) * dist;
        let segments = [];
        let segmentsAmount = _segments.length


        for (let index = 0; index < _segments.length; index++) {
            const s = _segments[index];
            total = Math.round((total + s.Length) * 1e12) / 1e12
            delete s.EndPressure
            delete s.InletPressure
            delete s.Pressureloss
            s.AtomicFlowRate = 0;

            delete s.FlowRate
            delete s.Frictionloss
            delete s.LocalHeadloss
            delete s.Topoloss
            delete s.TravelTime
            segments.push(s);
        }
        //add segements that belongs to flushing section
        if (segmentsAmount < Allsegments.length) {
            for (let index = segmentsAmount; index < Allsegments.length; index++) {
                const s = Allsegments[index];
                segments.push(s)
            }
        }


        return segments;
    }

    //Override
    protected resetPipesForFlushing(data: any): any {
        let _pipes = data._pipes
        let lastFlowRate: number = data.lastFlowRate
        let isFlushingWithClosedValves = data.isFlushingWithClosedValves
        if (isFlushingWithClosedValves) {
            // Update flow rate for last section
            _pipes[_pipes.length - 1].accumulatedFlowRate = lastFlowRate
            _pipes[_pipes.length - 1].atomicFlowRate = lastFlowRate

            // Update flow rate for the rest of the sections
            for (let index = _pipes.length - 2; index >= 0; index--) {
                _pipes[index].atomicFlowRate = 0
                _pipes[index].accumulatedFlowRate = _pipes[index + 1].accumulatedFlowRate
            }

        } else {
            // Update flow rate for last section
            _pipes[_pipes.length - 1].accumulatedFlowRate = lastFlowRate + _pipes[_pipes.length - 1].atomicFlowRate
            _pipes[_pipes.length - 1].atomicFlowRate = lastFlowRate + _pipes[_pipes.length - 1].atomicFlowRate

            // Update flow rate for the rest of the sections
            for (let index = _pipes.length - 2; index >= 0; index--) {
                _pipes[index].accumulatedFlowRate = Math.round((_pipes[index].atomicFlowRate + _pipes[index + 1].accumulatedFlowRate) * 1e12) / 1e12
            }
        }




        let pipes: any = _pipes.map((p: any) => {
            delete p.EndPressure
            delete p.InletPressure
            delete p.MinPressure

            p.MaxFlowRate = 0;
            p.MaxPressure = 0;
            p.MaxVelocity = 0;
            p.PressureLoss = 0

            return p;
        })


        return pipes;
    }

    //Override
    protected setPipesData(calculationData: any) {
        let isItFlushingMode = calculationData.isFlushing;
        let isLastSectionForFlushing = calculationData.isLastSectionForFlushing
        let pipesLength = !isItFlushingMode && isLastSectionForFlushing ? calculationData.pipes.length - 2 : calculationData.pipes.length - 1
        // Get selected pipes:
        calculationData.pipes = calculationData.pipes.filter((p) => p.SectionLength > 0);

        // Update pipes:
        calculationData.pipes[0].InletPressure = calculationData.segments[0].InletPressure;
        calculationData.pipes[pipesLength].EndPressure = calculationData.segments[calculationData.segments.length - 1].EndPressure;
        calculationData.pipes[0].MaxFlowRate = calculationData.segments[0].FlowRate;

        for (let index = pipesLength; index >= 0; index--) {
            let pipe = calculationData.pipes[index];
            let r = pipe.InternalDiameter;
            // Set max pipes velocity:
            pipe.MaxVelocity = AbsCalculationProcess.VELOCITY_COEFFICIENT * (pipe.MaxFlowRate / (r * r))
            // Set Inlet/out pressure:
            if (index < pipesLength) {
                pipe.EndPressure = calculationData.pipes[index + 1].InletPressure;

            }
        }

        if (calculationData.shiftedSeg) {
            // Remove shifted segment:
            const shifted = calculationData.shiftedSeg;
            calculationData.pipes[0].SectionLength -= shifted.Length;
        }

    }

    public createCalculationSegments(data: any, blockChars: any, topographyChars: any, pipeChars: any, isFlushing: boolean, oldSegments: any = null) {
        let segments: Segment[] = [];

        //add pipes section acording to real length on topography charcteristics
        this.rebuildPipesCharateristicsAcordingToTopography(pipeChars, topographyChars)

        let totalMainlineLength = this.calcPipeTotalLength(pipeChars);
        blockChars.totalLength = !data.isFlushingMode || (data.isFlushingMode && isFlushing) ? totalMainlineLength : totalMainlineLength - pipeChars[pipeChars.length - 1].section_length;
        blockChars.totalLengthWithFlushing = totalMainlineLength;
        let sum = 0;
        // Create lengths segments array:
        let lengthsArr: number[] = this.createLengthsArr(blockChars.totalLength, 0, data.addSectionForFlushing && data.isFlushingMode, pipeChars, topographyChars, isFlushing);
        if (!lengthsArr) {
            throw new ErrorMsgs('Error occured while calculating result - Pipes sections total length should be at least as pipe real length', null, true)
        }

        // Create First Calculation Segments:
        let seg1 = new Segment(1);
        seg1.Length = lengthsArr[0];
        sum += seg1.Length;
        segments.push(seg1);

        // Create Calculation Segments:
        for (let i = 1; i < lengthsArr.length; i++) {
            let seg = new Segment(i + 1);

            if (i < lengthsArr.length) {
                seg.Length = Number((lengthsArr[i] - lengthsArr[i - 1]).toFixed(2));
                sum += seg.Length;
                sum = Number(sum.toFixed(2))
            }

            segments.push(seg);
        }
        return segments
    }

    protected createLengthsArr(mainlineLength: any, numOfLaterals: number, isLastSectionForFlushing: boolean, pipeChars: any, topographyChars: any, isFlushingMode: boolean): number[] {
        let arr: number[] = [];
        let lengthSet: Set<number> = new Set();
        let len = 0;
        let pipeslen = 0;
        let pipes = pipeChars.pipes ? pipeChars.pipes : pipeChars;


        if (pipes) {
            pipeslen = 0;
            // Insert pipes segments lengths:
            for (let index = 0; index < pipes.length; index++) {

                if (isLastSectionForFlushing) {
                    if (index < pipes.length - 1 && !isFlushingMode) {
                        pipeslen += pipes[index].section_length;
                        lengthSet.add(pipeslen);
                    } else if (isFlushingMode) {
                        pipeslen += pipes[index].section_length;
                        lengthSet.add(pipeslen);
                    }
                } else {
                    pipeslen += pipes[index].section_length;
                    lengthSet.add(pipeslen);
                }
            }
        }

        len = 0;
        // Insert topography segments lengths:
        for (let index = 0; index < topographyChars.length; index++) {
            len += topographyChars[index].real_length;
            if (len <= pipeslen) {
                lengthSet.add(len);
            }
        }

        if (pipeslen < mainlineLength) {
            // pipe sections are less then real pipe length
            return null;
        }

        len = 0;

        arr = Array.from(lengthSet);
        // sort arr:
        arr.sort(function (a, b) { return a - b });

        return arr;
    }

    public createPipeSections(pipeCharsData: any, isLastSectionForFlushing: boolean, segements: Segment[]) {
        let numOfPipeSections = pipeCharsData.length;
        let pipes: PipeSection[] = [];
        let nextSectionflowRate: number = 0
        let accumulatedFlowRate: number = 0

        // Calculate accumulated flow rate for pipes
        for (let index = numOfPipeSections - 1; index >= 0; index--) {
            let pipeData = pipeCharsData[index];

            //accumulated Flow Rate for section
            if (!pipeCharsData[index].accumulated_flow_rate) {
                accumulatedFlowRate = Math.round((accumulatedFlowRate + pipeData.section_flow_rate) * 1e12) / 1e12
                pipeCharsData[index].accumulated_flow_rate = accumulatedFlowRate;
            }

            //atomic Flow Rate for pipe section
            pipeCharsData[index].section_flow_rate = pipeData.section_flow_rate ? pipeData.section_flow_rate : nextSectionflowRate
            nextSectionflowRate = pipeData.section_flow_rate

        }

        for (let index = 0; index < numOfPipeSections; index++) {
            let pipe = new PipeSection();
            pipe.Id = index + 1;
            let pipeData = pipeCharsData[index];
            let rough = pipeData.pipe_roughness || pipeData.roughness || null;
            if (rough) {
                rough = Number(rough) > Number(AbsCalculationProcess.MAX_ROUGHNESS) ? AbsCalculationProcess.MAX_ROUGHNESS : rough;
            }
            pipe.Type = pipeData.pipe_material || pipeData.type;;
            pipe.Class = pipeData.pipe_class || pipeData.class;
            pipe.NominalDiameter = pipeData.nominal_diameter
            pipe.InternalDiameter = pipeData.internal_diameter
            pipe.SectionLength = pipeData.section_length || 0;
            pipe.KD = Number(pipeData.kd_local_friction_factor);
            pipe.Roughness = rough || AbsCalculationProcess.PIPES_ROUGHNESS_DEFAULT_VALUE;
            pipe.MaxPressure = 0;
            pipe.MaxAllowedVelocity = pipeData.max_velocity;
            pipe.MaxAllowedPressure = pipeData.max_allowable_design_pressure ? Number(pipeData.max_allowable_design_pressure) : Number(pipeData.max_allowable_technical_pressure);

            //flow rate for pipe section
            pipe.atomicFlowRate = pipeData.section_flow_rate
            pipe.accumulatedFlowRate = pipeData.accumulated_flow_rate


            //is it an extra section for flushing mode
            pipe.sectionForFlushing = isLastSectionForFlushing && index == numOfPipeSections - 1
            pipes.push(pipe);
        }
        // pipes.sort((a, b) => Number(b.NominalDiameter) - Number(a.NominalDiameter));
        return pipes;
    }
    private getLargestDiameter(pipes: any): number {
        let internal_diameter: number = 0
        pipes.forEach(pipe => {
            if (internal_diameter < Number(pipe.InternalDiameter)) {
                internal_diameter = Number(pipe.InternalDiameter)
            }
        });
        return internal_diameter
    }

    private rebuildPipesCharateristicsAcordingToTopography(pipesChar, topoChars) {
        let topoTotalLength: number = 0
        let pipeTotalLength: number = 0
        let rangePipeSectionArray: RangePipeSection[] = []
        //check if the sum of topoChar length is larger the pipeChars length 
        pipesChar.forEach((pipe, index) => {
            pipeTotalLength = Math.round((pipeTotalLength + pipe.section_length) * 1e12) / 1e12

            //build range pipes section
            let range = new RangePipeSection()
            if (index == 0) {
                range.start = 0
            } else {
                range.start = rangePipeSectionArray[index - 1].ends
            }
            range.ends = pipeTotalLength
            rangePipeSectionArray.push(range)
        });
        topoChars.forEach(slopeSection => {
            topoTotalLength = Math.round((topoTotalLength + slopeSection.real_length) * 1e12) / 1e12
        });

        //if it is - we will add pipe section with the gap between the real length to the length on map - with the relevant pipe
        if (topoTotalLength > pipeTotalLength) {
            let sum: number = 0
            topoChars.forEach(slopeSection => {
                sum = Math.round((sum + slopeSection.length_on_map) * 1e12) / 1e12
                let pipeIndex = rangePipeSectionArray.findIndex(range => sum >= range.start && sum <= range.ends)
                let real_length_addition = Math.round((slopeSection.real_length - slopeSection.length_on_map) * 1e12) / 1e12
                if (real_length_addition > 0) {
                    pipesChar[pipeIndex].section_length = Math.round((real_length_addition + pipesChar[pipeIndex].section_length) * 1e12) / 1e12
                }
            });
        }
        // return pipesChar
    }
}

export class RangePipeSection {
    start: number
    ends: number
}