import { Injectable } from "@angular/core";
import * as html2pdf from "html2pdf.js";
import { DataService } from "./data.service";
@Injectable({
  providedIn: "root",
})
export class PdfService {
  constructor(public dataService: DataService) {}

  public generatePDF(data, title, options = null) {
    this.dataService.showLoader = true;
    setTimeout(() => {
      if (!this.dataService.isItAppleDevice && this.dataService.isItMobile) {
        this.dataService.showLoader = false;
      }
    }, 10000);
    if (!options) {
      options = {
        useCORS: true,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 2,
          backgroundColor: "#f4f7fb",
          scrollX: 0,
          scrollY: 0,
        },
        jsPDF: { orientaion: "portrait" },
      };
    }

    return html2pdf()
      .from(data)
      .set(options)
      .save(title)
      .then((done) => {
        this.dataService.showLoader = false;
      });
  }
}
