import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "../../environments/environment";
import { PopupTypes } from "../models/popuptype";
import { HttpHandler } from "./httpHandler";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { CalculatorSection, ValveDiameters } from "../shared/types";
import { BlockShape } from "../shared/characteristics/block-characteristics.types";
import { OBlockShape } from "../shared/characteristics/block-characteristics.consts";

@Injectable({
  providedIn: "root",
})
export class DataService extends HttpHandler {
  isItMobile;
  isItAppleDevice;
  loginURL;

  calculatorIndex;
  submainLength = "";
  showLoader = false;
  showCalculatingLoader = false
  regions_list = [];
  languages_list = [];
  graph_region;
  selected_region;
  selected_language;
  user_name = "";
  units_list = [
    { name: "Metric", value: 1 },
    { name: "US units", value: 2 },
  ];
  selected_units = this.units_list[0];
  resetSubmainCalculator = new Subject();
  submain_inputs_data = [];
  error_messages_inputs = [];

  //sign-in
  signInUser = new Subject<any>();

  //home-page
  getHomePageData = new Subject<any>();

  //help
  getHelpPageData = new Subject<any>();

  //Popup
  showWarningPopup = false;
  showErrorPopup = false;
  showClearPopup = false;
  showPipePopup = false;
  showDeletePopup = false;
  showDeleteGlobalPopup = false;
  showEditPopup = false;
  showRadioPopup = false;
  showMessagePopup = false;
  showPasswordPopup = false;
  showResetPasswordPopup = false;
  showSettingsPopup = false;
  showDataPopup = false;
  showIlustrationPopup = false;
  showSiteUnderMaintenancePopup = true;
  showGraphPopup = false;
  showConfirmationPopup = false;

  //sign-up
  resendEmailForSignUp = new Subject();
  confirmAcountForSignUp = new Subject<any>();
  errorTxtConfirmAcount = "";

  currentSectionOnCalculatorPage = "question";
  passToSectionOnCalculatorPage = "" as CalculatorSection ;
  error_result_msg = "";
  showAlertMessage = false;
  alert_msg_text = "";
  showAlertInRed = false;
  error_id_msg: number;
  AcceptChangeBetweenSectionOnCalculatorPage = new Subject();
  clearBlockCharacteristcsData = new Subject();
  clearSubmainCharacteristicsData = new Subject();
  deleteData: string[];
  deleteDataCodes: string[];
  editPopupData: any;
  editPopupDataResult = new Subject<any>();
  WhichPartToClearData;

  //snackBar
  snackBarText = "";
  snackBarAcceptText = "";
  snackBarCancelText = "";
  showCancelButtonSnackBar = false;
  initSnackBar = new Subject();
  resetBlockCharacteristics = new Subject();
  clickedOnShape = "Rectangular";

  //Block Characteristcs
  validationForMapLengthSlope = new Subject();
  slopeMapLengthHasChanged = new Subject();
  updateBlockFormValidationByQ = new Subject();
  changeMulitpleToggleButtonChoise = new Subject();
  changeShapeToggleButton = new Subject();
  totalLengthLeftForSlopes = 0;
  totalSlopesLength: Number = 0;
  selectedShape: BlockShape = OBlockShape.rectangular;
  selectedTopography = "Flat";
  submainLayoutChangeToReadonly = "";
  blockFlowRateChangeToReadonly = "";
  reloadSlopesIlustration = new Subject();
  last_flow_rate_full_result: number;
  total_flow_rate_full_result: number;

  //Submain characteristics
  totalRealLength: Number = 0;
  calculateSubmainRealLength = new Subject<any>();
  sectionAchosenPipeType = "";
  sectionAchosenPipeClass = "";
  totalLengthLeftForSections: Number = 0;
  updateSectionToASectionChanges = new Subject();
  InternalDiameterSmallerThenPreviousSection = true;
  reloadSectionsIlustration = new Subject<any>();
  submain_sections_for_mobile_ilustration: any[];
  checkWarningByAllSectiosSubmain = new Subject<any>();

  // Submain results
  reloadIlustrationResult = new Subject<any>();
  reloadGraphResult = new Subject<any>();
  reloadPipes = new Subject<any>();
  reloadResults = new Subject<any>();

  // summary
  showBlockCharcteristics = true;
  showSubmain = true;

  //emitters - step one
  model_options;
  model_options_observable;
  flow_rate_options;
  inline_emitters_list = [];
  online_emitters_list = [];
  chosen_emitter_type = "";
  chosen_model = "";
  chosen_model_object: any;

  //queries - step 2
  initiateQueries = new Subject<any>();

  //Lateral characteristics
  sectionAchosenPipeMaterial = "";
  sectionAchosenPipeWellThickness = "";
  checkSectionsChoises = new Subject<any>();
  totalLateralLength: Number = 0;
  clearLateralCharacteristcsData = new Subject<any>();
  hintMinNumberForOutletPressure = "";
  hintMaxNumberForOutletPressure = "";
  resetLateralCalculator = new Subject<any>();
  updatePressureFormVaidation = new Subject<any>();
  calculateTotalLengthLateral = new Subject<any>();
  spacingMeasureUnit = 'm'

  minValidationForEndletPressure: Number;
  maxValidationForEndletPressure: Number;
  getEndletPressureValidation = new Subject<any>();

  //field elevation
  resetElevation = new Subject<any>();
  total_length_changed = false;

  //Result - calculator lateral
  chosedSectionToChangeTo = "" as CalculatorSection;
  changeToAnotherSectionInCalculator = new Subject<any>();
  exportResultToPdf = new Subject<any>();

  //text-translation
  logic_text_or = "";

  //pipe characteristics - mainline
  totalLengthMainline: number = 0;
  totalLengthLeftForMainlineSections;
  sectionAchosenPipeTypeMainline = "";
  sectionAchosenPipeClassMainline = "";
  calculateTotalLengthMainline = new Subject<any>();
  updateMainlineSectionsForIlustration = new Subject<any>();
  updateClassOptions = new Subject<any>();
  updateNominalOption = new Subject<any>();

  //mainline
  resetMainlieCalculator = new Subject<any>();
  inputs_contents_has_changed = false;

  colors = [
    "#5B19C0",
    "#FFBA00",
    "#FD0000",
    "#fa8e00",
    "#00C7D3",
    "#10ABCE",
    "#F96284",
    "#787FF8",
    "#84C7F6",
    "#7AE1AF",
  ];

  sectionItems: any[] = [
    {
      id: 0,
      letter: "A",
      color: "#5B19C0",
      slope_color: "#7AE1AF",
    },

    {
      id: 1,
      letter: "B",
      color: "#FFBA00",
      slope_color: "#84C7F6",
    },

    {
      id: 2,
      letter: "C",
      color: "#FD0000",
      slope_color: "#787FF8",
    },

    {
      id: 3,
      letter: "D",
      color: "#fa8e00",
      slope_color: "#F96284",
    },

    {
      id: 4,
      letter: "E",
      color: "#00C7D3",
      slope_color: "#10ABCE",
    },

    {
      id: 5,
      letter: "F",
      color: "#10ABCE",
      slope_color: "#00C7D3",
    },

    {
      id: 6,
      letter: "G",
      color: "#F96284",
      slope_color: "#fa8e00",
    },

    {
      id: 7,
      letter: "H",
      color: "#787FF8",
      slope_color: "#FD0000",
    },

    {
      id: 8,
      letter: "I",
      color: "#84C7F6",
      slope_color: "#FFBA00",
    },

    {
      id: 9,
      letter: "J",
      color: "#7AE1AF",
      slope_color: "#5B19C0",
    },
  ];

  // valve
  resetValveCalculator = new Subject<any>();
  changeValveQuestionForm = new Subject<any>();
  restorePreviousDropdownValues = new Subject<any>();
  valveModelOptions: string[] = [];
  valveDiameterOptions: ValveDiameters[];
  valveDiameterMmOptions: string[] = [];
  valveDiameterInchOptions: string[] = [];

  // irrigation
  resetIrrigationCalculator = new Subject<any>();
  irrigationRate: number;

  // shift
  resetShiftCalculator = new Subject<any>();

  // energy
  resetEnergyCalculator = new Subject<any>();

  constructor(
    public router: Router,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService
  ) {
    super(_http, httpService, errorService);
  }

  closePopup(popupType: PopupTypes) {
    switch (popupType) {
      case PopupTypes.warning:
        this.showWarningPopup = false;
        break;
      case PopupTypes.error:
        this.showErrorPopup = false;
        break;
      case PopupTypes.clear:
        this.showClearPopup = false;
        break;
      case PopupTypes.pipe:
        this.showPipePopup = false;
        break;
      case PopupTypes.delete:
        this.showDeletePopup = false;
        break;
      case PopupTypes.edit:
        this.showEditPopup = false;
        break;
      case PopupTypes.radio:
        this.showRadioPopup = false;
        break;
      case PopupTypes.message:
        this.showMessagePopup = false;
        break;
      case PopupTypes.password:
        this.showPasswordPopup = false;
        break;
      case PopupTypes.resetPassword:
        this.showResetPasswordPopup = false;
        break;
      case PopupTypes.settings:
        this.showSettingsPopup = false;
      case PopupTypes.delete_global:
        this.showDeleteGlobalPopup = false;
        break;
      case PopupTypes.show_data:
        this.showDataPopup = false;
        break;
      case PopupTypes.ilustration:
        this.showIlustrationPopup = false;
        break;
      case PopupTypes.graph:
        this.showGraphPopup = false;
        break;
      case PopupTypes.offline_confirmation:
        this.showConfirmationPopup = false;
        break;
      default:
        break;
    }
  }

  composePlaceholder(input) {
    let label = input.field_name;
    if (this.selected_units.value == 1) {
      label = input.metric_units_string_symbol
        ? label + " (" + input.metric_units_string_symbol + ")"
        : label;
    } else {
      label = input.us_units_string_symbol
        ? label + " (" + input.us_units_string_symbol + ")"
        : label;
    }

    return label;
  }

  addUnitsToText(text, input) {
    if (this.selected_units.value == 1) {
      text = input.metric_units_string_symbol
        ? text + " (" + input.metric_units_string_symbol + ")"
        : text;
    } else {
      text = input.us_units_string_symbol
        ? text + " (" + input.us_units_string_symbol + ")"
        : text;
    }

    return text;
  }

  composeMinMaxValidator(input, validator_name) {
    if (validator_name === "min") {
      if (this.selected_units.value === 1) {
        return Number(input.min_value);
      } else {
        return Number(input.min_value_us_units);
      }
    }
    if (validator_name === "max") {
      if (this.selected_units.value === 1) {
        return Number(input.max_value);
      } else {
        return Number(input.max_value_us_units);
      }
    }
  }

  checkIfItsInlineDripper(value) {
    return this.inline_emitters_list.includes(value);
  }
}
