import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { CalculatorService } from '../../../../services/calculator.service';

@Component({
  selector: 'app-help-contact-us',
  templateUrl: './help-contact-us.component.html',
  styleUrls: ['./help-contact-us.component.scss']
})
export class HelpContactUsComponent implements OnInit {
  faqData: any;
  contactTopics: any;
  videos_data;
  constructor(
    private calculatorService: CalculatorService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {
    this.calculatorService.getFaq().subscribe(
      response => {
        // this.faqData = response.result.page_data.QATopics;
        // this.videos_data = response.result.page_data.videos;

        this.contactTopics = response.result.page_data.contactUsTopics;
        // this.dataService.regions_list = response.result.page_data.regions;
      },
      error => {
        console.log(error);
      }
    );
  }
}
