import { Directive, ElementRef, Input, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill';


@Directive({
  selector: '[appOverflowTooltip]'
})
export class OverflowTooltipDirective implements AfterViewInit, OnDestroy {
  @Input() tooltipText: string;
  @Input() tooltipClass: string;
  @Input() scrollableParent: string = '.table-container';
  @Input() offset: number = 60;

  private resizeObserver: ResizeObserver;
  private scrollSubscription: Subscription;
  private tooltipEnabled = false;
  private scrollParentElement: HTMLElement;

  constructor(
    private el: ElementRef,
    private ngbTooltip: NgbTooltip,
    private ngZone: NgZone
  ) { }

  ngAfterViewInit() {
    this.setupTooltipClass();
    this.setupScrollParent();
    this.setupOverflowChecking();
  }

  ngOnDestroy() {
    this.cleanupObservers();
  }

  private setupTooltipClass() {
    this.ngbTooltip.tooltipClass = `overflow-tooltip${this.tooltipClass ? ` ${this.tooltipClass}` : ''}`;
  }

  private setupScrollParent() {
    this.scrollParentElement = this.el.nativeElement.closest(this.scrollableParent);
    if (!this.scrollParentElement) {
      console.warn(`Scrollable parent "${this.scrollableParent}" not found for appOverflowTooltip`);
    }
  }

  private setupOverflowChecking() {
    this.checkOverflow();

    this.resizeObserver = new ResizeObserver(() => {
      this.checkOverflow();
    });

    this.resizeObserver.observe(this.el.nativeElement);

    if (this.scrollParentElement) {
      this.ngZone.runOutsideAngular(() => {
        this.scrollSubscription = fromEvent(this.scrollParentElement, 'scroll')
          .pipe(debounceTime(50))
          .subscribe(() => {
            this.ngZone.run(() => this.checkOverflow());
          });
      });
    }
  }

  private checkOverflow() {
    const element = this.el.nativeElement;
    const hasOverflow = element.scrollWidth > element.clientWidth;

    if (hasOverflow && !this.tooltipEnabled) {
      this.enableTooltip();
    } else if (!hasOverflow && this.tooltipEnabled) {
      this.disableTooltip();
    }

    this.updateTooltipPosition();
  }

  private enableTooltip() {
    this.ngbTooltip.ngbTooltip = this.tooltipText || this.el.nativeElement.textContent;
    this.tooltipEnabled = true;
  }

  private disableTooltip() {
    this.ngbTooltip.ngbTooltip = '';
    this.tooltipEnabled = false;
  }

  private updateTooltipPosition() {
    if (!this.scrollParentElement) return;

    const element = this.el.nativeElement;
    const rect = element.getBoundingClientRect();
    const containerRect = this.scrollParentElement.getBoundingClientRect();
    if (rect.bottom + this.offset > containerRect.bottom) {
      this.ngbTooltip.placement = ['top', 'top-left', 'top-right'];
    } else {
      this.ngbTooltip.placement = ['bottom', 'bottom-left', 'bottom-right'];
    }
  }

  private cleanupObservers() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }
}