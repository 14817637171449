import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { EtransaltionGroups } from "src/app/shared/enum";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {}
  EtransaltionGroups = EtransaltionGroups;
  calculatingText: string;
  ngOnInit(): void {
    this.setCalculatingLoaderText()
  }

  setCalculatingLoaderText() {
    this.calculatingText = this.textService.getText(
      EtransaltionGroups.LAYOUT,
      "calculating_loader_text"
    );
  }
}
