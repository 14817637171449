import { ICalculationProcess } from "../Interfaces/ICalculationProcess";
import { PressureLossForKnownPipe } from "../CalculationProcess/Mainline/PressureLossForKnownPipe";
import { PipesDiameter } from "../CalculationProcess/Mainline/PipesDiameter";
import { MainlineCalculationType } from "../../enums/MainlineCalculationType.enum";

export class MainlineCalculationsFactory {
  static getCalcProcess(
    calcType: MainlineCalculationType
  ): ICalculationProcess {
    let calcProcess: ICalculationProcess = null;
    switch (calcType) {
      case MainlineCalculationType.PRESSURE_LOSS_FOR_KNOWN_PIPES:
        calcProcess = new PressureLossForKnownPipe();

        break;
      case MainlineCalculationType.PIPES_DIAMETERS:
        calcProcess = new PipesDiameter();

        break;
    }

    return calcProcess;
  }
}
