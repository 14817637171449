import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-clear-popup',
  templateUrl: './clear-popup.component.html',
  styleUrls: ['./clear-popup.component.scss'],
})
export class ClearPopupComponent implements OnInit {
  constructor(public dataService: DataService) {}

  ngOnInit(): void {}

  cancel() {
    this.dataService.showClearPopup = false;
  }

  clearData() {
    switch (this.dataService.WhichPartToClearData) {
      case 'block':
        this.dataService.clearBlockCharacteristcsData.next();
        break;
      case 'submain':
        this.dataService.clearSubmainCharacteristicsData.next();

        break;
      case 'lateral':
        this.dataService.clearLateralCharacteristcsData.next();

        break;
      default:
        break;
    }
  }
}
