export const CALCULATORS_INDEXES = {
  LATERAL: 2,
} as const;

export const CALCULATOR_SECTION = {
  EMITTER: "emitter",
  BLOCK: "block",
  QUESTION: "question",
  SUBMAIN: "submain",
  LATERAL: "lateral",
  RESULT: "result",
  PIPE: "pipe",
  ELEVATION: "elevation",
  VALVE: "valve",
  IRRIGATION: "irrigation",
  SHIFT: "shift",
  ENERGY_FIRST: "energyFirstPage",
  ENERGY_SECOND: "energySecondPage",
} as const;
