import { SubmainCalculationType } from "../../enums/SubmainCalculationType.enum";
import { ICalculationProcess } from "../Interfaces/ICalculationProcess";
import { PressureLossForAGivenPipe } from "../CalculationProcess/Submain/PressureLossForAGivenPipe";
import { PipesDiameter } from "../CalculationProcess/Submain/PipesDiameter";
import { PipesMaxLength } from "../CalculationProcess/Submain/PipesMaxLength";

export class SubmainCalculationsFactory{
    static getCalcProcess(calcType: SubmainCalculationType): ICalculationProcess {
        let calcProcess: ICalculationProcess = null;
        switch (calcType) {
            case SubmainCalculationType.PRESSURE_LOSS_FOR_SELECTED_PIPE:
                calcProcess = new PressureLossForAGivenPipe();

                break;
            case SubmainCalculationType.PIPES_DIAMETERS:
                calcProcess = new PipesDiameter();
            
                break;
            case SubmainCalculationType.PIPES_MAX_LENGTH:
                calcProcess = new PipesMaxLength();
        
                break;

        }

        return calcProcess;
    }
}