import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormControl,
  ValidatorFn
} from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { CalculatorService } from 'src/app/services/calculator.service';
import { CognitoApisServiceService } from 'src/app/services/cognito-apis-service.service';
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
@Component({
  selector: 'app-settings-popup',
  templateUrl: './settings-popup.component.html',
  styleUrls: ['./settings-popup.component.scss']
})
export class SettingsPopupComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  generalForm: FormGroup;
  passwordForm: FormGroup;
  languages;
  regions;
  generalFormGroupObject = {};
  passwordFormGroupObject = {};
  selected_region = '';
  show_success_text_password = false;
  show_success_text_general = false;
  constructor(
    public dataService: DataService,
    public calculatorService: CalculatorService,
    private cdr: ChangeDetectorRef,
    public cognitoAWSService: CognitoApisServiceService,
    public httpService: HttpService,
    public router: Router,
    public textService: TextService
  ) { }

  ngOnInit(): void {
    this.generateGeneralForm();
    this.getPopupData();
    this.generatePasswordForm();

    this.onChanges();
  }
  getPopupData() {
    this.dataService.showLoader = true;
    this.calculatorService.getSettingData().subscribe(resp => {
      this.dataService.showLoader = false;

      if (resp.success) {
        this.languages = resp.result.settings.languages;
        let selected_language = this.languages.find(value => {
          return value.row_id === resp.result.settings.user.language.toString();
        });
        this.generalForm.get('language').setValue(selected_language, {
          emitEvent: false
        });
        this.regions = resp.result.settings.regions;
        this.selected_region = this.regions.find(value => {
          return value.region_id == resp.result.settings.user.region;
        });
        // this.dataService.selected_region = this.selected_region;
        this.generalForm.get('region').setValue(this.selected_region, {
          emitEvent: false
        });

        this.generalForm
          .get('receiveUpdates')
          .setValue(resp.result.settings.user.is_receive_updates, {
            emitEvent: false
          });

        // let selected_language = this.languages.filter(value => {
        //   value.row_id == resp.result.settings.user.language;
        // });
        // this.generalForm.controls.language.setValue(selected_language);
        this.cdr.detectChanges();
      }
      if (Number(resp.result.settings.user.units) == 1) {
        this.dataService.selected_units = this.dataService.units_list[0];
      } else {
        this.dataService.selected_units = this.dataService.units_list[1];
      }
    });
  }
  async onGeneralSave() {
    if (
      this.dataService.selected_units != this.generalForm.controls.metric.value
    ) {
      this.dataService.selected_units = this.generalForm.controls.metric.value;
    }
    this.dataService.showLoader = true;
    this.cognitoAWSService.updateUserAttributes(
      this.generalForm.controls.language.value.row_id,
      this.generalForm.controls.region.value.region_id,
      this.dataService.selected_units.value.toString(),
      this.generalForm.controls.receiveUpdates.value ? '1' : '0',
      response => {
        if (response.success) {
          this.dataService.selected_region = this.generalForm.controls.region.value;          
          this.dataService.selected_language = this.generalForm.controls.language.value;          
          this.httpService.updateHeader(
            'Authorization',
            localStorage.getItem('Authorization')
          );
          this.getTexts();
          this.show_success_text_general = true;
        } else {
          this.dataService.showSettingsPopup = false;
          this.dataService.error_result_msg = response.error.message;
          this.dataService.showErrorPopup = true;
        }
      }
    );
  }

  getTexts() {
    this.textService
      .getResourceText(this.dataService.selected_language.row_id)
      .subscribe(res => {
        this.dataService.showLoader = false;
        if (res.success) {
          this.textService.translation_text = res.result.translations;
          localStorage.setItem(
            'resource_txt',
            JSON.stringify(this.textService.translation_text)
          );
          this.textService.setUnitsDictionary();
          if (this.router.url.includes('home')) {
            this.dataService.getHomePageData.next();
          }
          if (this.router.url.includes('submain')) {
            this.dataService.resetSubmainCalculator.next();
          }
          if (this.router.url.includes('lateral')) {
            this.dataService.resetLateralCalculator.next();
          }
          if (this.router.url.includes('valve')) {
            this.dataService.resetValveCalculator.next();
          }
          if (this.router.url.includes('irrigation')) {
            this.dataService.resetIrrigationCalculator.next();
          }
          if (this.router.url.includes('shift')) {
            this.dataService.resetShiftCalculator.next();
          }
          if (this.router.url.includes('energy')) {
            this.dataService.resetEnergyCalculator.next();
          }
          if (this.router.url.includes('help')) {
            this.dataService.getHelpPageData.next();
          }
          if (this.router.url.includes('mainline')) {
            this.dataService.resetMainlieCalculator.next();
          }
          this.dataService.showSettingsPopup = false;
        }
      });
  }

  async onPasswordSave() {
    let response = await this.cognitoAWSService.changePassword(
      this.passwordForm.controls.currentPassword.value,
      this.passwordForm.controls.password.value
    );
    if (response.success) {
      this.dataService.showSettingsPopup = false;
      this.show_success_text_password = true;
    } else {
      this.dataService.showSettingsPopup = false;
      this.dataService.error_result_msg = response.error.message;
      this.dataService.showErrorPopup = true;
    }
  }

  onCancel() {
    this.dataService.showSettingsPopup = false;
  }

  generateGeneralForm() {
    this.generalFormGroupObject['language'] = new FormControl('', [
      Validators.required
    ]);
    this.generalFormGroupObject[
      'region'
    ] = new FormControl(this.selected_region, [Validators.required]);
    this.generalFormGroupObject[
      'metric'
    ] = new FormControl(this.dataService.selected_units, [Validators.required]);
    this.generalFormGroupObject['receiveUpdates'] = new FormControl([]);

    this.generalForm = new FormGroup(this.generalFormGroupObject);
  }

  generatePasswordForm() {
    this.passwordFormGroupObject['currentPassword'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50)
    ]);
    this.passwordFormGroupObject['password'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50)
    ]);
    this.passwordFormGroupObject['repeatPassword'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50)
    ]);
    this.passwordForm = new FormGroup(this.passwordFormGroupObject);
  }

  checkError(controlName: string) {
    if (this.passwordForm.controls[controlName].errors) {
      const theErrors = this.passwordForm.controls[controlName].errors;
      if (theErrors.required) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'field_required'
        );
      }
      if (theErrors.minlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );

        return this.textService.addValueToText(
          text,
          theErrors.minlength.requiredLength
        );
      }
      if (theErrors.maxlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );
        return this.textService.addValueToText(
          text,
          theErrors.maxlength.requiredLength
        );
      }
      if (theErrors.pattern) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'invalid_pattern'
        );
      }
      if (theErrors.notEqual) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'password_validation'
        );
      }
    }
  }
  openForgotPasswordPopup() {
    this.dataService.showSettingsPopup = false;
    this.dataService.showPasswordPopup = true;
  }
  onChanges(): void {
    this.passwordForm.valueChanges.subscribe(value => {
      this.show_success_text_password = false;
    });
    this.generalForm.valueChanges.subscribe(value => {
      this.show_success_text_general = false;
    });

    this.passwordForm.controls['repeatPassword'].valueChanges.subscribe(val => {
      if (this.passwordForm.controls['password'].value !== val) {
        this.passwordForm.controls['repeatPassword'].setErrors({
          notEqual: true
        });
      }
    });
  }
  get generalControls() {
    return this.generalForm.controls;
  }

  get controls() {
    return this.passwordForm.controls;
  }
}
