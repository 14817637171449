import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  HostBinding,
} from '@angular/core';
import { CALCULATOR_SECTION } from 'src/app/shared/consts';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { CalculatorService } from 'src/app/services/calculator.service';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { SubmainService } from 'src/app/services/submain.service';
import { BlockCharacteristicsComponent } from 'src/app/components/calculator-components/block-characteristics/block-characteristics.component';
import { PopupTypes } from 'src/app/models/popuptype';
import {
  Einputs_fields,
  Eunits,
  Ecalculator,
  EtransaltionGroups,
  EsubmainSteps,
  EqueryTypes,
} from 'src/app/shared/enum';
import { TextService } from 'src/app/services/text.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { OfflineService } from 'src/app/services/offline.service';
import { OBlockShape } from 'src/app/shared/characteristics/block-characteristics.consts';
import { CalculatorSection } from 'src/app/shared/types';

@Component({
  selector: 'app-submain-calculator-page',
  templateUrl: './submain-calculator-page.component.html',
  styleUrls: ['./submain-calculator-page.component.scss'],
})
export class SubmainCalculatorPageComponent implements OnInit {
  @HostBinding('attr.style')
  user_id //for analytics events
  CALCULATOR_SECTION = CALCULATOR_SECTION;
  show_section: CalculatorSection = CALCULATOR_SECTION.QUESTION;
  current_section: CalculatorSection = CALCULATOR_SECTION.QUESTION;
  Ecalculator = Ecalculator;
  EtransaltionGroups = EtransaltionGroups;
  QuestionsConfig: any[] = [];
  questionSelectedIndex; //0 -Q1 (pressure loss), 1 - Q2 (pipes diameters), 2-Q3 (max length)
  changeQuery = false;
  isContinueButtonEnabled: boolean = false;
  controlsArray: any[];
  warningPopup = PopupTypes.warning;
  errorPopup = PopupTypes.error;
  clearPopup = PopupTypes.clear;

  //advanced-setting
  selectedCalculationMethod = '1';
  selectedFlushingMode = 'no';
  flushingValue: FormControl;
  selectedPipeRoughness = '1';
  pipe_roughness_value: FormControl;

  Einputs_fields = Einputs_fields;
  Eunits = Eunits;
  EsubmainSteps = EsubmainSteps;
  stickySummary = false;
  toggle_advanced_option = false;
  @ViewChild(BlockCharacteristicsComponent) BlockCharacteristicsComponent;
  sideNavOpen = true;
  mySubscription: any;

  //submain-characteristics
  pipeTypeOptions: any[];
  pipeClassOptions: any[];
  nominalDiametersOptions: any[];
  currentSectionForms: any = [];
  totalSectionLength;

  //block-charcteristics
  blockForm: FormGroup;
  blockFormInitialValue: any;
  currentSlopeForms: any = [];

  //result
  displayedColumns: string[] = [
    'pipe_section',
    'pressure_loss',
    'max_pressure',
    'min_pressure',
    'pipe_length',
    'pipe_diameter',
    'inlet_presure',
    'end_pressure',
    'flow_rate',
    'velocity',
  ];
  tableDisplay = [];
  topResultBody: any[] = [];
  elevationTablePDF = [];
  show_result_button = false

  bodyRequestForCalculate;
  calculator_results;

  str_id_step_text = [
    'calculation_query',
    'submain_s2_block_charac',
    'submain_s3_charac',
  ];

  constructor(
    public router: Router,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    public calculatorService: CalculatorService,
    public dataService: DataService,
    public submainService: SubmainService,
    public textService: TextService,
    public analyticsService: GoogleAnalyticsService,
    public offlineService: OfflineService
  ) {
    this.dataService.AcceptChangeBetweenSectionOnCalculatorPage.subscribe(
      (res) => {
        this.changeBetweenFunctions();
      }
    );
    this.dataService.clearBlockCharacteristcsData.subscribe((res) => {
      this.clearBlock();
    });
    this.dataService.clearSubmainCharacteristicsData.subscribe((res) => {  
      this.currentSectionForms = [];
      this.dataService.sectionAchosenPipeType = '';
      this.dataService.sectionAchosenPipeClass = '';
      this.totalSectionLength = undefined
      this.dataService.totalRealLength = undefined

      this.addSection();
      if (this.dataService.showClearPopup) {  
        this.dataService.reloadSectionsIlustration.next(
          this.currentSectionForms
        );
        this.dataService.showClearPopup = false;
      }
    });
    this.dataService.resetSubmainCalculator.subscribe((res) => {
      this.dataService.selectedShape = OBlockShape.rectangular;
      this.dataService.selectedTopography = 'Flat';
      this.dataService.sectionAchosenPipeType = '';
      this.dataService.sectionAchosenPipeClass = '';
      this.router.navigateByUrl('calc/submain');
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });

    this.dataService.calculateSubmainRealLength.subscribe(res => {
      this.calculateRealLength()
    })
    this.dataService.checkWarningByAllSectiosSubmain.subscribe(res => {
      this.checkLengthWarningForSubmainSection()
    })
  }

  ngOnInit(): void {
    this.resetDisableInput();
    //init service var
    this.dataService.selectedShape = OBlockShape.rectangular;
    this.dataService.selectedTopography = 'Flat';
    this.dataService.sectionAchosenPipeType = ''
    this.dataService.sectionAchosenPipeClass = ''

    //init boolean inputs
    this.dataService.inputs_contents_has_changed = false;
    this.dataService.showAlertMessage = false;
    this.dataService.alert_msg_text = ''


    this.getData();
    this.flushingValue = new FormControl('', {
      validators: [],
      updateOn: 'blur',
    });
    this.initPipeRoughnessInit();
  }

  analyticsEvent() {
    if (localStorage.getItem('submain_view')) {
      let views_count_by_user = Number(localStorage.getItem('submain_view'));
      if (views_count_by_user == 1) {
        this.analyticsService.sendEvent(
          'submain',
          'recurring_user_second_time',
          'recurring_user', this.user_id
        );
      }
      if (views_count_by_user == 4) {
        this.analyticsService.sendEvent(
          'submain',
          'recurring_user_fifth_time',
          'recurring_user', this.user_id
        );
      }
      views_count_by_user++;
      localStorage.setItem('submain_view', views_count_by_user.toString());
    } else {
      localStorage.setItem('submain_view', '1');
    }
  }

  resetDisableInput() {
    this.dataService.submainLayoutChangeToReadonly = '';
    this.dataService.blockFlowRateChangeToReadonly = '';
  }

  initPipeRoughnessInit() {
    this.pipe_roughness_value = new FormControl('', {
      validators: [],
      updateOn: 'blur',
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  initializeQuestionsConfig() {
    this.QuestionsConfig = [
      {
        title: this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_s1_pressure_loss'
        ),
        subTitle: this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_calcualtion_Q_pressure_subtitle'
        ),
        placeholder: '',
        hint: '',
        isSelected: false,
        isSimple: true,
        control: new FormControl('', {
          validators: [Validators.requiredTrue],
          updateOn: 'blur',
        }),
      },
      {
        title: this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_s1_diameters'
        ),
        subTitle: this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_s1_according_pressure_loss'
        ),
        placeholder: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_s1_max_pressure_loss'
          ),
          'm_pressure'
        ),

        hint: '',

        isSelected: false,
        isSimple: false,
        control: new FormControl('', {
          validators: [
            Validators.required,
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.min(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.pressure_loss
                ],
                'min'
              )
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.pressure_loss
                ],
                'max'
              )
            ),
          ],
          updateOn: 'blur',
        }),
      },
      {
        title: this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_s1_max_length'
        ),
        subTitle: this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_s1_according_pressure_loss'
        ),
        placeholder: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_s1_max_pressure_loss'
          ),
          'm_pressure'
        ),
        hint: '',
        isSelected: false,
        isSimple: false,
        control: new FormControl('', {
          validators: [
            Validators.required,
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.min(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.pressure_loss
                ],
                'min'
              )
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.pressure_loss
                ],
                'max'
              )
            ),
          ],
          updateOn: 'blur',
        }),
      },
    ];
  }

  initializeForm() {
    this.selectedCalculationMethod = String(
      this.dataService.submain_inputs_data[
        this.Einputs_fields.calculation_method
      ].default_value
    );
    this.controlsArray = [
      new FormControl(false, Validators.requiredTrue),
      new FormControl(false, Validators.required),
      new FormControl(false, Validators.required),
    ];
  }

  initializeBlockForm() {
    this.blockFormInitialValue = {
      pipeLength: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.submain_pipe_length
                ],
                'min'
              )
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.submain_pipe_length
                ],
                'max'
              )
            ),
          ],
          updateOn: 'blur',
        },
      ],
      lateralsDist: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.distance_between_laterals
                ],
                'min'
              )
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.distance_between_laterals
                ],
                'max'
              )
            ),
          ],
          updateOn: 'blur',
        },
      ],
      numOfLaterals: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.num_of_laterals
                ],
                'min'
              )
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.num_of_laterals
                ],
                'max'
              )
            ),
          ],
          updateOn: 'blur',
        },
      ],
      inletPress: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.inlet_pressure
                ],
                'min'
              )
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.inlet_pressure
                ],
                'max'
              )
            ),
          ],
          updateOn: 'blur',
        },
      ],
      endletPress: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.end_pressure
                ],
                'min'
              )
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.end_pressure
                ],
                'max'
              )
            ),
          ],
          updateOn: 'blur',
        },
      ],
      lastLatFlowRate: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.last_lateral_flow_rate
                ],
                'min'
              )
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.last_lateral_flow_rate
                ],
                'max'
              )
            ),
          ],
          updateOn: 'blur',
        },
      ],
      totalLatFlowRate: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.block_flow_rate
                ],
                'min'
              )
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.block_flow_rate
                ],
                'max'
              )
            ),
          ],
          updateOn: 'blur',
        },
      ],
      firstLatRate: ['', { updateOn: 'blur' }],
      lastLatRate: ['', { updateOn: 'blur' }],
    }
    this.blockForm = this.fb.group(this.blockFormInitialValue);
  }

  updateSelectedQuestion(event, index) {
    for (let i = 0; i < this.QuestionsConfig.length; i++) {
      this.QuestionsConfig[i].control.reset();
      if (event == false && index == i) {
        this.changeQuery = true;
        this.QuestionsConfig[i].isSelected = true;
        this.questionSelectedIndex = i;
      } else {
        this.QuestionsConfig[i].isSelected = false;
      }
      this.QuestionsConfig[0].control.setValue(
        this.QuestionsConfig[0].isSelected
      );
      this.dataService.inputs_contents_has_changed = true
    }
    this.clearSectionLengthAndHandleLengthMessage()
    this.updateSubmainSectionByQchoise()
    //Reset Elevation
    if (this.dataService.selectedTopography != 'Flat') {
      this.currentSlopeForms = [];
      this.dataService.changeMulitpleToggleButtonChoise.next('Flat');
    }
   
  }

  changeBetweenFunctions() {
    this.current_section = this.dataService.passToSectionOnCalculatorPage;
    this.show_section = this.dataService.passToSectionOnCalculatorPage;
    this.dataService.showWarningPopup = false;
  }

  updateSubmainSectionByQchoise() {
    if (this.changeQuery) {
      this.currentSectionForms = [];
      const isUpdateInputsContentsHasChanged = true
      this.addSection(isUpdateInputsContentsHasChanged);
      this.dataService.updateBlockFormValidationByQ.next(this.questionSelectedIndex)
    }
  }
  
  async getData() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = await this.calculatorService.getCalculatorPageDataSubmainOffline()
      this.setResponsePageData({ result: response, success: response.page_data.submain_charactaristics_data.types ? true : false })
    } else {
      this.calculatorService
        .getCalculatorPageDataSubmain()
        .subscribe(
          (resp) => {
            this.dataService.showLoader = false;
            this.setResponsePageData(resp)
          }
        );
    }
  }
  setResponsePageData(resp) {
    if (resp.success) {
      this.user_id = resp.result.page_data.submain_charactaristics_data.user.id
      this.analyticsEvent();
      this.dataService.user_name =
        resp.result.page_data.submain_charactaristics_data.user
          .first_name +
        ' ' +
        resp.result.page_data.submain_charactaristics_data.user.last_name;
      this.pipeTypeOptions =
        resp.result.page_data.submain_charactaristics_data.types;
      this.pipeClassOptions =
        resp.result.page_data.submain_charactaristics_data.classes;
      this.nominalDiametersOptions =
        resp.result.page_data.submain_charactaristics_data.nominal_diameters;
      this.dataService.regions_list =
        resp.result.page_data.submain_charactaristics_data.regions;
      this.dataService.selected_units = this.dataService.units_list.find(
        (value) => {
          return (
            value.value ===
            Number(
              resp.result.page_data.submain_charactaristics_data.user
                .units
            )
          );
        }
      );
      this.dataService.selected_region =
        this.dataService.regions_list.find((value) => {
          return (
            resp.result.page_data.submain_charactaristics_data.user
              .region === value.region_id
          );
        });
      this.dataService.submain_inputs_data =
        resp.result.page_data.submain_charactaristics_data.calculation_inputs;
      this.dataService.languages_list =
        resp.result.page_data.submain_charactaristics_data.languages;
      this.dataService.selected_language =
        this.dataService.languages_list.find((value) => {
          return (
            value.row_id ===
            resp.result.page_data.submain_charactaristics_data.user.language.toString()
          );
        });
      this.getTexts();
      this.initializeBlockForm();
      this.initializeQuestionsConfig();
    }
  }


  getTexts() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = this.textService
        .getResourceTextOffline()
      this.setTextResponse({ result: response, success: response ? true : false })
      this.dataService.showLoader = false;
    } else {
      this.textService
        .getResourceText(this.dataService.selected_language.row_id)
        .subscribe(res => {
          this.dataService.showLoader = false;
          this.setTextResponse(res)
        });
    }
  }
  setTextResponse(res) {
    if (res.success) {
      this.textService.translation_text = res.result.translations ? res.result.translations : res.result;
      localStorage.setItem(
        'resource_txt',
        JSON.stringify(this.textService.translation_text)
      );
      this.textService.setUnitsDictionary();
      this.setResultText();
      this.dataService.logic_text_or = this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_or_1'
      );
      this.textService.initialErrorMessages(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s2_values_range'
      );
      this.initializeForm();
    }
  }

  setResultText() {
    this.tableDisplay = [
      {
        display: this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_results_pipe_sections'
        ),
        name: 'pipe_section',
        key: '',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_pipe_sections_pressure_loss'
          ),
          'm_pressure'
        ),
        name: 'pressure_loss',
        key: 'PressureLoss',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_sections_max_pressure'
          ),
          'm_pressure'
        ),
        name: 'max_pressure',
        key: 'MaxPressure',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_sections_min_pressure'
          ),
          'm_pressure'
        ),
        name: 'min_pressure',
        key: 'MinPressure',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_pipe_sections_length'
          ),
          'm'
        ),
        name: 'pipe_length',
        key: 'SectionLength',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_pipe_sections_diameter'
          ),
          'mm'
        ),
        name: 'pipe_diameter',
        key: 'NominalDiameter',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_pipe_sections_inlet'
          ),
          'm_pressure'
        ),
        name: 'inlet_presure',
        key: 'InletPressure',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_pipe_sections_end'
          ),
          'm_pressure'
        ),
        name: 'end_pressure',
        key: 'EndPressure',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_pipe_sections_max_flow_rate'
          ),
          'm3h'
        ),
        name: 'flow_rate',
        key: 'MaxFlowRate',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_pipe_sections_max_velocity'
          ),
          'ms'
        ),
        name: 'velocity',
        key: 'MaxVelocity',
      },
    ];

    this.elevationTablePDF = [
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.SUBMAIN,
            'submain_summary_length_on_map'
          ),
          'm'
        ),
        key: 'mapLength',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.SUBMAIN,
            'submain_summary_hight_dif_meters'
          ),
          'm'
        ),
        key: 'heightDiffM',
      },
      {
        display: this.textService.getText(
          EtransaltionGroups.SUBMAIN,
          'submain_summary_hight_dif_precent'
        ),
        key: 'heightDiffP',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.SUBMAIN,
            'submain_summary_real_length'
          ),
          'm'
        ),
        key: 'realLength',
      },
    ];

    this.topResultBody = [
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_total_length'
          ),
          'm'
        ),
        key: 'submain_length',
      },
      {
        name: this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_results_pipe_type'
        ),
        key: 'submain_pipe_type',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_inlet_pressure'
          ),
          'm_pressure'
        ),
        key: 'inlet_pressure',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_max_flow_rate'
          ),
          'm3h'
        ),
        key: 'inlet_flow_rate',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_pressure_loss'
          ),
          'm_pressure'
        ),
        key: 'submain_pressure_loss',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_max_pressure'
          ),
          'm_pressure'
        ),
        key: 'max_pressure',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_end_pressure'
          ),
          'm_pressure'
        ),
        key: 'end_pressure',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_max_velocity'
          ),
          'ms'
        ),
        key: 'max_velocity',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_min_pressure'
          ),
          'm_pressure'
        ),

        key: 'min_pressure',
      },
      {
        name: this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_results_num_laterals'
        ),
        key: 'num_of_laterals',
      },
    ];
  }

  addSlope() {
    let slopeForm: FormGroup = this.fb.group({
      mapLength: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.submain_pipe_length
                ],
                'min'
              )
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.submain_pipe_length
                ],
                'max'
              )
            ),
          ],
          updateOn: 'blur',
        },
      ],
      heightDiffM: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
          ],
          updateOn: 'blur',
        },
      ],
      heightDiffP: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.heigth_diff_precent
                ],
                'min'
              )
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.dataService.submain_inputs_data[
                this.Einputs_fields.heigth_diff_precent
                ],
                'max'
              )
            ),
          ],
          updateOn: 'blur',
        },
      ],
      realLength: [
        '',
        {
          validators: [Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?')],
          updateOn: 'blur',
        },
      ],
    });
    this.currentSlopeForms.push(slopeForm);
  }

  deleteSlope(index) {
    this.currentSlopeForms.splice(index, 1);
  }

  deleteAllSlopes() {
    this.currentSlopeForms.splice(0, this.currentSlopeForms.length);
    this.dataService.reloadSlopesIlustration.next();
  }

  isSlopesFormValid() {
    let bool = true;
    if (this.currentSlopeForms.length > 0) {
      this.currentSlopeForms.forEach((element) => {
        bool = bool && element.valid;
      });
    }
    if (this.dataService.selectedTopography === 'Multiple Elevations') {
      bool =
        bool &&
        Number(this.dataService.totalSlopesLength) ===
        Number(this.blockForm.value.pipeLength);
    }
    return bool;
  }

  addSection(isUpdateInputsContentsHasChanged = false) {
    let sectionForm: FormGroup;
    sectionForm = this.fb.group({
      pipeType: ['', { validators: [Validators.required], updateOn: 'blur' }],
      nominalDiameter: [
        '',
        { validators: [Validators.required], updateOn: 'blur' },
      ],
      pipeClass: ['', { validators: [Validators.required], updateOn: 'blur' }],
      pipeInternalDiameter: [
        '',
        { validators: [Validators.required], updateOn: 'blur' },
      ],
      pipeSectionLength: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(0.1),
          ],
          updateOn: 'blur',
        },
      ],
    });

    if (this.questionSelectedIndex === 1) {
      sectionForm.controls['nominalDiameter'].clearValidators();
      sectionForm.controls['nominalDiameter'].updateValueAndValidity();
      sectionForm.controls['pipeInternalDiameter'].clearValidators();
      sectionForm.controls['pipeInternalDiameter'].updateValueAndValidity();
      sectionForm.controls['pipeSectionLength'].clearValidators();
      sectionForm.controls['pipeSectionLength'].updateValueAndValidity();
    }
    if (this.questionSelectedIndex === 2) {
      sectionForm.controls['pipeSectionLength'].clearValidators();
      sectionForm.controls['pipeSectionLength'].updateValueAndValidity();
    }

    this.currentSectionForms.push(sectionForm);

    // if function being called from updateSelectedQuestion then update 
    if(isUpdateInputsContentsHasChanged){
      this.dataService.inputs_contents_has_changed = true
    }
  }

  deleteSection(index) {
    if (this.currentSectionForms.length > 1) {
      this.currentSectionForms.splice(index, 1);
    } else {
      this.currentSectionForms[0].patchValue(
        {
          pipeType: '',
          nominalDiameter: '',
          pipeClass: '',
          pipeInternalDiameter: '',
          pipeSectionLength: this.dataService.totalRealLength,
        },
        { emitEvent: false }
      );
    }
    this.dataService.reloadSectionsIlustration.next();
    this.dataService.inputs_contents_has_changed = true
    this.checkLengthWarningForSubmainSection()
  }

  isSectionsFormValid() {
    let bool = true;
    this.totalSectionLength = 0;
    this.currentSectionForms.forEach((element) => {
      bool = bool && element.valid;
      this.totalSectionLength =
        Number(this.totalSectionLength) +
        Number(element.value.pipeSectionLength);
    });
    return (
      bool &&
      (this.totalSectionLength >= this.dataService.totalRealLength ||
        this.questionSelectedIndex != 0) &&
      this.flushingValue.valid
    );
  }

  checkLengthWarningForSubmainSection() {
    this.totalSectionLength = 0; //RESET
    this.currentSectionForms.forEach((element) => {
      this.totalSectionLength =
        Number(this.totalSectionLength) +
        Number(element.value.pipeSectionLength);
    });
    if (
      this.totalSectionLength < this.dataService.totalRealLength &&
      this.questionSelectedIndex == 0
    ) {
      this.dataService.alert_msg_text = this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s3_length_not_accumulated'
      );
      this.dataService.showAlertMessage = true;
      this.dataService.showAlertInRed = true
    } else if (this.totalSectionLength > this.dataService.totalRealLength) {
      this.dataService.alert_msg_text = this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_s3_length_accumulated_length_exceeds'
      );
      this.dataService.showAlertMessage = true;
      this.dataService.showAlertInRed = true
    } else {
      this.dataService.alert_msg_text = ''
      this.dataService.showAlertMessage = false;
      this.dataService.showAlertInRed = false
    }
  }
  clearQuestion() {
    if (this.questionSelectedIndex) {
      this.QuestionsConfig[this.questionSelectedIndex].isSelected = false;
      this.QuestionsConfig[this.questionSelectedIndex].control.reset();
    }
    this.questionSelectedIndex = null;
  }

  clearBlock() {
    this.blockForm.reset();
    this.currentSlopeForms = [];
    this.dataService.changeMulitpleToggleButtonChoise.next('Flat');
    this.dataService.showClearPopup = false;
  }

  clearSubmain() {
    if (this.currentSectionForms.length > 1) {
      this.currentSectionForms.splice(1, this.currentSectionForms.length - 1);
    }
    this.currentSectionForms[0].patchValue(
      {
        pipeType: '',
        pipeClass: '',
        nominalDiameter: '',
        pipeInternalDiameter: '',
        pipeSectionLength: '',
      },
      { emitEvent: false }
    );
  }

  calculateRealLength() {
    this.dataService.totalRealLength = 0;
    if (
      this.currentSlopeForms.length > 0 &&
      this.BlockCharacteristicsComponent.selectedTopography === 'Fixed Slope' &&
      (this.currentSlopeForms[0].value.heightDiffP > 15 ||
        this.currentSlopeForms[0].value.heightDiffP < -15)
    ) {
      this.currentSlopeForms.forEach((slope) => {
        this.dataService.totalRealLength =
          Number(this.dataService.totalRealLength) +
          Number(slope.value.realLength);
      });
      if (this.questionSelectedIndex === EqueryTypes.pressure_loss) {
        this.dataService.alert_msg_text = this.textService.getText(EtransaltionGroups.SUBMAIN, "submain_s3_real_length_warn") + " " + this.dataService.totalRealLength
        this.dataService.showAlertMessage = true;
        this.dataService.showAlertInRed = true
      }
    } else {
      this.dataService.totalRealLength = Number(
        this.blockForm.value.pipeLength
      );
    }
  }

  async calculate() {
    this.analyticsService.sendEvent(
      'submain',
      'submain_calculate',
      'calculate_button', this.user_id
    );
    if (this.currentSectionForms.length > 3) {
      this.analyticsService.sendEvent(
        'submain',
        'more_than_3_pipe_sections',
        this.currentSectionForms.length, this.user_id
      );
    }
    this.createBodyForCalculateRequest();
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = await this.submainService.calculateOffline(this.bodyRequestForCalculate)
      this.dataService.showLoader = false;
      this.setCalculationResponse(response)

    } else {
      this.submainService.calculate(this.bodyRequestForCalculate).subscribe((resp) => {
        this.dataService.showLoader = false;
        this.setCalculationResponse(resp)
      },
        (err) => {
          this.dataService.showLoader = false;
          if (err.message) {
            this.dataService.error_result_msg = err.message;
          }
          if (err.ErrorToClient) {
            this.dataService.error_result_msg = err.ErrorToClient;
          }

          this.dataService.showErrorPopup = true;
          if (this.dataService.isItMobile) {
            this.BackStep();
          }
        }
      );

    }
  }

  setCalculationResponse(resp) {
    this.show_result_button = true;
    this.dataService.inputs_contents_has_changed = false
    if (resp.success) {
      setTimeout(() => {
        const element = document.querySelector('#scroll')
        element.scrollIntoView();
      });
      let first_time_calculate = this.calculator_results ? false : true;
      this.calculator_results = undefined;
      this.calculator_results = resp.result;
      setTimeout(() => {
        this.dataService.reloadIlustrationResult.next();
        this.dataService.reloadGraphResult.next();
      });
      this.dataService.reloadPipes.next();
      if (!first_time_calculate) {
        this.dataService.reloadResults.next();
      }
    }
  }

  createBodyForCalculateRequest() {
    this.bodyRequestForCalculate = {};
    this.bodyRequestForCalculate.calculation_type =
      this.questionSelectedIndex + 1;
    this.bodyRequestForCalculate.flushing_velocity = Number(
      this.flushingValue.value
    );
    this.bodyRequestForCalculate.is_flushing =
      this.selectedFlushingMode == 'yes';
    if (this.questionSelectedIndex != 0) {
      this.bodyRequestForCalculate.max_allowed_pressure_loss = Number(
        this.QuestionsConfig[this.questionSelectedIndex].control.value
      );
    }

    this.bodyRequestForCalculate.friction_loss_formula = Number(
      this.selectedCalculationMethod
    );
    if (
      this.selectedCalculationMethod == '2' &&
      this.selectedPipeRoughness == '2'
    ) {
      this.bodyRequestForCalculate.pipe_roughness_chw = Number(
        Number(this.pipe_roughness_value.value)
      );
    }

    //block-characteristcs
    this.bodyRequestForCalculate.block_characteristics = {
      is_rectangular: this.dataService.selectedShape === 'Rectangular',
      first_lateral_length: Number(this.blockForm.get('firstLatRate').value),
      last_lateral_length: Number(this.blockForm.get('lastLatRate').value),
      submain_pipe_length: Number(this.blockForm.get('pipeLength').value),
      distance_between_laterals: Number(
        this.blockForm.get('lateralsDist').value
      ),
      num_of_laterals: Number(this.blockForm.get('numOfLaterals').value),
      inlet_pressure: Number(this.blockForm.get('inletPress').value),
      end_pressure: Number(this.blockForm.get('endletPress').value),
      last_lateral_flow_rate: this.dataService.last_flow_rate_full_result
        ? Number(this.dataService.last_flow_rate_full_result)
        : Number(this.blockForm.get('lastLatFlowRate').value),
      total_lateral_flow_rate: this.dataService.total_flow_rate_full_result
        ? Number(this.dataService.total_flow_rate_full_result)
        : Number(this.blockForm.get('totalLatFlowRate').value),
    };

    //topography_characteristics
    let slopes = [];
    this.bodyRequestForCalculate.topography_characteristics = slopes;
    if (this.currentSlopeForms.length === 0) {
      slopes.push({
        length_on_map: Number(this.blockForm.get('pipeLength').value),
        height_diff_meters: 0,
        heigth_diff_precent: 0,
        real_length: Number(this.blockForm.get('pipeLength').value),
      });
    } else {
      this.currentSlopeForms.forEach((slope) => {
        slopes.push({
          length_on_map: Number(slope.get('mapLength').value),
          height_diff_meters: Number(slope.get('heightDiffM').value),
          heigth_diff_precent: Number(slope.get('heightDiffP').value),
          real_length: Number(slope.get('realLength').value),
        });
      });
    }

    //pipe_characteristics
    let sections = [];
    this.currentSectionForms.forEach((section) => {
      let sectionJsonData = {
        type: section.get('pipeType').value,
        class: section.get('pipeClass').value,
      };
      if (this.questionSelectedIndex != 1) {
        sectionJsonData['nominal_diameter'] = Number(
          Number(section.get('nominalDiameter').value.value)
        );
        sectionJsonData['internal_diameter'] = Number(
          section.get('pipeInternalDiameter').value
        );
      }
      if (this.questionSelectedIndex == 0) {
        sectionJsonData['segment_length'] = Number(
          section.get('pipeSectionLength').value
        );
      }
      sections.push(sectionJsonData);
    });
    this.bodyRequestForCalculate.pipe_characteristics = sections;
  }

  changeFlushingMode(value) {
    this.selectedFlushingMode = value;
    this.dataService.inputs_contents_has_changed = true
  }

  changeCalculationMethod(value) {
    this.selectedCalculationMethod = value;
    this.dataService.inputs_contents_has_changed = true
  }

  BackStep() {
    switch (this.show_section) {
      case this.CALCULATOR_SECTION.BLOCK:
        this.current_section = this.CALCULATOR_SECTION.QUESTION;
        this.show_section = this.CALCULATOR_SECTION.QUESTION;
        break;
      case this.CALCULATOR_SECTION.SUBMAIN:
        this.current_section = this.CALCULATOR_SECTION.BLOCK;
        this.show_section = this.CALCULATOR_SECTION.BLOCK;
        break;
      case this.CALCULATOR_SECTION.RESULT:
        this.current_section = this.CALCULATOR_SECTION.SUBMAIN;
        this.show_section = this.CALCULATOR_SECTION.SUBMAIN;
        this.analyticsService.sendEvent(
          'submain',
          'step_back_after_calculation',
          this.CALCULATOR_SECTION.SUBMAIN, this.user_id
        );
        break;
      default:
        this.current_section = this.CALCULATOR_SECTION.QUESTION;
        this.show_section = this.CALCULATOR_SECTION.QUESTION;
        break;
    }
  }

  nextStepMobile() {
    switch (this.show_section) {
      case this.CALCULATOR_SECTION.BLOCK:
        this.current_section = this.CALCULATOR_SECTION.SUBMAIN;
        this.show_section = this.CALCULATOR_SECTION.SUBMAIN;
        this.calculateRealLength();
        this.dataService.showSubmain = true;
        break;
      case this.CALCULATOR_SECTION.SUBMAIN:
        this.calculate();
        this.current_section = this.CALCULATOR_SECTION.RESULT;
        this.show_section = this.CALCULATOR_SECTION.RESULT;
        break;
      case this.CALCULATOR_SECTION.QUESTION:
        this.current_section = 'block';
        this.show_section = 'block';
        this.updateSubmainSectionByQchoise();
        break;
      default:
        break;
    }
  }

  validationByStep() {
    switch (this.show_section) {
      case this.CALCULATOR_SECTION.BLOCK:
        return !(
          this.blockForm.valid &&
          (this.dataService.selectedTopography === 'Flat' ||
            this.isSlopesFormValid())
        );

      case this.CALCULATOR_SECTION.SUBMAIN:
        return !this.isSectionsFormValid();

      case this.CALCULATOR_SECTION.QUESTION:
        return (
          !this.QuestionsConfig[0]?.control.valid &&
          !this.QuestionsConfig[1]?.control.valid &&
          !this.QuestionsConfig[2]?.control.valid
        );

      default:
        break;
    }
  }

  changePipeRoughnessSelection(value) {
    this.selectedPipeRoughness = value;
    this.dataService.inputs_contents_has_changed = true
  }

  scrollTopResult() {
    const element = document.querySelector('#scroll');
    element.scrollIntoView();
  }

  onClickCloseAlert() {
    this.dataService.showAlertMessage = false;
    this.dataService.inputs_contents_has_changed = false;
    this.dataService.alert_msg_text = "";
  }

  clearSectionLengthAndHandleLengthMessage(){
   this.clearSectionLength()
   this.checkLengthWarningForSubmainSection()
  }


  clearSectionLength(){
    this.totalSectionLength = undefined
    this.dataService.totalRealLength = undefined
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  
}
