import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-tutorial-card',
  templateUrl: './video-tutorial-card.component.html',
  styleUrls: ['./video-tutorial-card.component.scss']
})
export class VideoTutorialCardComponent implements OnInit {
  @Input() video_data: any;
  constructor(protected sanitizer: DomSanitizer) { }

  ngOnInit(): void { }

  createURL(url): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
}
