import { SlopeSection } from "./SlopeSection";
import { PipeSection } from "./PipeSection";
import { EmitterSection } from "./EmitterSection";
//
export class Segment {
    Id: number;
    Length: number;

    SlopeSection: SlopeSection;
    PipeSection: PipeSection;
    EmitterSection: EmitterSection;

    FlowRate: number;
    TravelTime: number = 0;
    AtomicFlowRate: number = 0;
    Pressureloss: number;
    InletPressure: number;
    EndPressure: number;

    Frictionloss: number;
    LocalHeadloss: number;
    Topoloss: number;

    isPipeUpdated: boolean = false;


    constructor(id: number) {
        this.Id = id;
    }
}