import { Segment } from "../Segment";
import { PipeSection } from "../PipeSection";
import { LateralCalculationType } from "../../enums/LateralCalculationType.enum";
import { ErrorMsgs } from "../../../../hydrocalc-code/models/Errors/ErrorMsgs";
import { SlopeSection } from "../SlopeSection";
import { CalculationErrors, ECalculationErrors } from "../../errors_and_warnings/errors";
import { EmitterSection } from "../EmitterSection";

export class BaseMaxLengthCaculationProcess {

    public createCalculationSegments(data: any, blockChars: any, topographyChars: any, pipeChars: any, isFlushing: boolean, oldSegments: any = null) {
        let segments: Segment[] = [];
        let lateralDistance = blockChars.distance_between_laterals;

        // Create Segments:
        if (isFlushing) {
            // Reset old Segments:
            segments = this.resetCalculationSegments(oldSegments);
        }
        else {
            // Create calculation first segment:
            let seg = new Segment(1);
            seg.Length = lateralDistance;
            segments.push(seg);
        }

        return segments;
    }

    public setTopoChars(data: any, _slopes: any) {
        if (_slopes && _slopes.length > 0) {
            data.topographyChars = [];

            _slopes.forEach(slope => {
                let topoChars = {
                    height_diff_meters: slope.HeightDiffInMeters,
                    heigth_diff_precent: slope.HeightDiffPrecent,
                    length_on_map: slope.SectionLengthOnMap,
                    real_length: slope.SectionRealLength
                }

                data.topographyChars.push(topoChars);
            });
        }
    }

    public addSlopeSectionsToSegments(segments: Segment[], slopes: SlopeSection[]) {
        segments[0].SlopeSection = slopes[0];
    }

    public addPipeSectionsToSegments(segments: Segment[], pipes: PipeSection[]) {
        segments[0].PipeSection = pipes[0];
    }

    public addEmitterSectionsToSegments(segments: Segment[], emitters: EmitterSection[]) {
        segments[0].EmitterSection = emitters[0];
    }

    // ---------------------------------------- PRAIVATE METHODS ----------------------------------------
    private resetCalculationSegments(oldSegments: any): any {
        oldSegments.forEach(segment => {
            segment.AtomicFlowRate = null;
            segment.FlowRate = null;
            segment.EndPressure = null;
            segment.InletPressure = null;
            segment.Pressureloss = null;
            segment.SlopeSection = null;
            segment.PipeSection = null;

            segment.EmitterSection = null;
            segment.TravelTime = null;
        });

        return oldSegments;
    }
}