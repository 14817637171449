import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import {
  Ecalculator,
  EqueryTypes,
  EemitterType,
  EtransaltionGroups
} from 'src/app/shared/enum';
import { Validators } from '@angular/forms';
import { LateralService } from 'src/app/services/lateral.service';
import { TextService } from 'src/app/services/text.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { OfflineService } from 'src/app/services/offline.service';
import{ MATRIX_SPACING_VALUES_METRIC, MATRIX_SPACING_VALUES_US, SPACING_MATRIX_VALIDATION_MAX, SPACING_MATRIX_VALIDATION_MIN } from '../../../shared/characteristics/lateral-characteristics.data'
import { MatrixSpacingValueOption } from 'src/app/shared/characteristics/lateral-characteristics.types';

@Component({
  selector: 'app-lateral-characteristics',
  templateUrl: './lateral-characteristics.component.html',
  styleUrls: ['./lateral-characteristics.component.scss']
})
export class LateralCharacteristicsComponent implements OnInit {
  EemitterType = EemitterType;
  Ecalculator = Ecalculator;
  EqueryTypes = EqueryTypes;
  EtransaltionGroups = EtransaltionGroups;

  @Input() queryIndex;
  @Input() currentLateralSectionsForms;
  @Input() pressureForm;
  @Output() addNewSection = new EventEmitter<string>();
  @Output() deleteSectionFromCurrentSectionForms = new EventEmitter<string>();
  @Input() pipeMaterialOptions;
  @Input() pipeWallThicknessOptions;
  @Input() nominalDiametersOptions;
  @Input() emitterForm;
  @Input() user_id //for analytics events
  @Input() userInputMatrixPlaceholder: string
  @Input() matrixSpacingLimit: number

  flow_rate_options = [];
  error_msg_pressure = '';
  whichPressureInputDisable
  matrixSpacingValueOptions: MatrixSpacingValueOption[] = []
  userInputMatrixSpacingPlaceholder: string
  matrixValidationMinValue = SPACING_MATRIX_VALIDATION_MIN
  matrixValidationMaxValue = SPACING_MATRIX_VALIDATION_MAX
  spacingSubtitle: string
  maxSectionsLength: number = 4;

  constructor(
    public dataService: DataService,
    public lateralService: LateralService,
    public textService: TextService,
    public analyticsService: GoogleAnalyticsService,
    public offlineService: OfflineService
  ) {
    this.dataService.updatePressureFormVaidation.subscribe(controlName => {    
      this.whichPressureInputDisable = controlName
      this.setValidators(controlName)
    })
  }
  ngAfterViewInit(): void {
    if (this.dataService.isItMobile) {
      window.scrollTo(0, 0);
    }
  }
  ngOnInit(): void {
    if(this.queryIndex === this.EqueryTypes.matrix){
      this.initMatrixSpacingValueOptions()
      this.setUserInputMatrixSpacingPlaceholder()
      this.buildMatrixSpacingSelectionSubtitle()
    }
    this.onChangesEmitterAndPressure();
    if (
      this.dataService.checkIfItsInlineDripper(
        this.dataService.chosen_emitter_type
      )
    ) {
      this.initEmitterForm();
      this.maxSectionsLength = 3;
      this.getDropdownsForInlineEmitters();
    } else {
      this.dataService.getEndletPressureValidation.next();
      if (!this.currentLateralSectionsForms[0].valid) {
        this.getDropdownsForOnlineEmitters();
      }
      if (this.dataService.isItMobile) {
        this.maxSectionsLength = 3;
      } else {
        this.maxSectionsLength = 4;
      }
    }
  }

  initMatrixSpacingValueOptions() {
    const values =
      this.dataService.selected_units === this.dataService.units_list[1]
        ? MATRIX_SPACING_VALUES_US
        : MATRIX_SPACING_VALUES_METRIC;
    values.forEach((value) =>
      this.matrixSpacingValueOptions.push({ value, isSelected: false })
    );
  }

  onClickSpacingOption(option: MatrixSpacingValueOption) {
    if (
      (this.emitterControls.spacing_matrix.value.length ===
        this.matrixSpacingLimit ||
        (this.emitterControls.spacing_matrix.value.length ===
          this.matrixSpacingLimit - 1 &&
          this.emitterControls.user_matrix_spacing_input.value)) &&
      !option.isSelected
    ) {
      return;
    }

    option.isSelected = !option.isSelected;
    this.addOrRemoveValueToMatrixSpacingControl(option.value);
  }

  buildMatrixSpacingSelectionSubtitle(){
    this.spacingSubtitle = this.textService.getText(
      EtransaltionGroups.LATERAL,
      "lateral_matrix_spacing_subtitle"
    ).replace('{{}}',this.matrixSpacingLimit) 
  }
  
  addOrRemoveValueToMatrixSpacingControl(value: number) {
    const index = this.emitterControls.spacing_matrix.value.indexOf(value);
    let values = [...this.emitterControls.spacing_matrix.value];
    if (index === -1) {
      this.emitterControls.spacing_matrix.setValue([...values, value]);
    } else {
      this.emitterControls.spacing_matrix.setValue(
        values.filter((_value) => _value !== value)
      );
    } 
    this.dataService.inputs_contents_has_changed = true
  }

  setUserInputMatrixSpacingPlaceholder() {
    this.userInputMatrixSpacingPlaceholder = this.userInputMatrixPlaceholder
  }

  initEmitterForm() {
    this.emitterForm.controls.flow_rate.setValidators([
      Validators.compose([Validators.required])
    ]);
    this.emitterForm.controls.flow_rate.updateValueAndValidity({
      emitEvent: false
    });

    this.updateEmitterFormValidatorsAccordingToQuery()
  }
  setValidators(controlName) {
    let text = this.textService.getValidationMinMax(
      EtransaltionGroups.SUBMAIN,
      'submain_s2_values_range',
      this.dataService.minValidationForEndletPressure,
      this.dataService.maxValidationForEndletPressure
    );

    this.error_msg_pressure = this.textService.addMesaureUnitsToLabel(
      text,
      'm_pressure'
    );
    if (controlName === 'inlet_pressure' || controlName === '') {
      this.pressureForm.controls['inlet_pressure'].setValidators([
        Validators.compose([
          Validators.required,
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.min(
            Number(this.dataService.minValidationForEndletPressure)
          ),
          Validators.max(
            Number(this.dataService.maxValidationForEndletPressure)
          )
        ])
      ]);
      this.pressureForm.controls['inlet_pressure'].updateValueAndValidity({

        emitEvent: false
      });
      if (controlName === 'inlet_pressure') {
        this.pressureForm.controls['end_pressure'].clearValidators();
        this.pressureForm.controls['end_pressure'].updateValueAndValidity({

          emitEvent: false
        });
      }
    }

    if (controlName === 'end_pressure' || controlName === '') {
      this.pressureForm.controls['end_pressure'].setValidators([
        Validators.compose([
          Validators.required,
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.min(
            Number(this.dataService.minValidationForEndletPressure)
          ),
          Validators.max(
            Number(this.dataService.maxValidationForEndletPressure)
          )
        ])
      ]);
      this.pressureForm.controls['end_pressure'].updateValueAndValidity({

        emitEvent: false
      });
      if (controlName === 'end_pressure') {
        this.pressureForm.controls['inlet_pressure'].clearValidators();
        this.pressureForm.controls['inlet_pressure'].updateValueAndValidity({

          emitEvent: false
        });
      }
    }
  }

  updateEmitterFormValidatorsAccordingToQuery() {
    const { chosen_emitter_type, selected_units } = this.dataService;
    if (selected_units.value === 2 && (chosen_emitter_type === 'Driplines' || chosen_emitter_type === 'Online Drippers')) {
      this.dataService.spacingMeasureUnit = 'm2'

      this.emitterForm.controls.spacing.setValidators([
        Validators.compose([
          Validators.required,
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.min(0.01),
          Validators.max(100),
        ])
      ]);
    } else {
      this.dataService.spacingMeasureUnit = 'm'
    }
    if (this.queryIndex === this.EqueryTypes.matrix) {
      this.emitterControls.spacing.clearValidators();
      this.emitterControls.spacing.updateValueAndValidity();
    } else if (this.queryIndex !== this.EqueryTypes.matrix) {
      if(!this.dataService.isItMobile && this.emitterControls.user_matrix_spacing_input){
        this.emitterControls.user_matrix_spacing_input.clearValidators();
        this.emitterControls.user_matrix_spacing_input.updateValueAndValidity();
      }
        if(!this.dataService.isItMobile && this.emitterControls.spacing_matrix){
        this.emitterControls.spacing_matrix.clearValidators();
        this.emitterControls.spacing_matrix.updateValueAndValidity();
      }
      this.composeValidatorsForSpacing()
    }
  }

  composeValidatorsForSpacing() {
    this.emitterForm.controls.spacing.setValidators([
      Validators.compose([
        Validators.required,
        Validators.pattern("[-+]?[0-9]+(.[0-9][0-9]?)?"),
        Validators.min(0.001),
        Validators.max(100),
      ]),
    ]);
    this.emitterForm.controls.spacing.updateValueAndValidity({
      emitEvent: false,
    });
  }

  deleteSection(index) {
    this.deleteSectionFromCurrentSectionForms.emit(index);
  }
  sectionHasChangedUpdateNextSection() {
    this.currentLateralSectionsForms.forEach((element, index) => {
      if (index > 0 && this.queryIndex != this.EqueryTypes.maximum_length) {
        this.dataService.updateSectionToASectionChanges.next();
      }
    });
  }
  onChangesEmitterAndPressure() {
    this.emitterControls.spacing.valueChanges.subscribe(() => {
      this.dataService.inputs_contents_has_changed = true
    })

    this.emitterControls.flow_rate.valueChanges.subscribe((value: number) => {
      // if (
      //   this.queryIndex === this.EqueryTypes.matrix &&
      //   this.emitterControls.spacing_matrix.errors
      // ) {
      //   return
      // }
      this.onFlowRateChange(value);
    });

    if(this.queryIndex === this.EqueryTypes.matrix){
      this.emitterControls.spacing_matrix.valueChanges.subscribe(() => {
        // if (!this.emitterControls.flow_rate.errors) {
        //    this.onFlowRateOrMatrixSpacingUpdate(
        //      this.emitterControls.flow_rate.value
        //   );
        //   this.dataService.inputs_contents_has_changed = true
        // }
        this.dataService.inputs_contents_has_changed = true
      });
    }

    this.pressureForm.controls.inlet_pressure.valueChanges.subscribe(value => {
      this.dataService.inputs_contents_has_changed = true
      if (value && value != '' && this.pressureControls.inlet_pressure.valid) {
        this.whichPressureInputDisable = 'end_pressure'
        this.pressureControls.end_pressure.clearValidators();
        this.pressureControls.end_pressure.reset('', { onlySelf: true, emitEvent: false });
        this.pressureControls.end_pressure.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      } else {
        this.whichPressureInputDisable = ''
        this.pressureControls.end_pressure.setValidators([
          Validators.compose([
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              Number(this.dataService.minValidationForEndletPressure)
            ),
            Validators.max(
              Number(this.dataService.maxValidationForEndletPressure)
            )
          ]),
        ])
        this.pressureControls.end_pressure.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      }
      setTimeout(() => {
        if (
          this.pressureForm.controls.inlet_pressure.hasError('max') ||
          this.pressureForm.controls.inlet_pressure.hasError('min')
        ) {
          this.analyticsService.sendEvent(
            'lateral',
            'inlet_pressure_out_of_range',
            'validation_error', this.user_id
          );
        }
      });
    });
    this.pressureForm.controls.end_pressure.valueChanges.subscribe(value => {
      this.dataService.inputs_contents_has_changed = true
      if (value && value != '' && this.pressureControls.end_pressure.valid) {
        this.whichPressureInputDisable = 'inlet_pressure'
        this.pressureControls.inlet_pressure.clearValidators();
        this.pressureControls.inlet_pressure.reset('', { onlySelf: true, emitEvent: false });
        this.pressureControls.inlet_pressure.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      } else {
        this.whichPressureInputDisable = ''
        this.pressureControls.inlet_pressure.setValidators([
          Validators.compose([
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              Number(this.dataService.minValidationForEndletPressure)
            ),
            Validators.max(
              Number(this.dataService.maxValidationForEndletPressure)
            )
          ]),
        ])
        this.pressureControls.inlet_pressure.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      }
      setTimeout(() => {
        if (
          this.pressureForm.controls.end_pressure.hasError('max') ||
          this.pressureForm.controls.end_pressure.hasError('min')
        ) {
          this.analyticsService.sendEvent(
            'lateral',
            'end_pressure_out_of_range',
            'validation_error', this.user_id
          );
        }
      });
    });
  }

onFlowRateOrMatrixSpacingUpdate(value:number){  
  console.log('change');
  
  this.dataService.inputs_contents_has_changed = true
  // setTimeout(() => {
  //   if (
  //     value != null &&
  //     this.dataService.checkIfItsInlineDripper(
  //       this.dataService.chosen_emitter_type
  //     )
  //   ) {
  //     this.dataService.getEndletPressureValidation.next();
  //   }
  // });
};

onFlowRateChange(value){
  this.dataService.inputs_contents_has_changed = true
  setTimeout(() => {
    if (
      value != null &&
      this.dataService.checkIfItsInlineDripper(
        this.dataService.chosen_emitter_type
      )
    ) {
      this.dataService.getEndletPressureValidation.next();
    }
  });
}



  async getDropdownsForInlineEmitters() {
    if (this.offlineService.isItOfflineMode) {
      let response = await this.lateralService.getFlowRateOptionsForEmitterInlineEmittersOffline(this.dataService.chosen_emitter_type,
        this.dataService.chosen_model, '', '', '')
      this.dataService.showLoader = false
      this.setDropdownsForInlineEmitters(response)
    } else {
      this.lateralService
        .getFlowRateOptionsForEmitterInlineEmitters(
          this.dataService.chosen_emitter_type,
          this.dataService.chosen_model,
          '',
          '',
          ''
        )
        .subscribe(resp => {
          this.setDropdownsForInlineEmitters(resp)
        });
    }

  }
  setDropdownsForInlineEmitters(resp) {
    if (resp.success) {
      this.pipeWallThicknessOptions =
        resp.result.lateral_chars.wall_thickess;
      this.nominalDiametersOptions =
        resp.result.lateral_chars.nominal_diameters;
    }
  }
  async getFlowRateDropdownForInlineEmitters() {
    if (this.isLateralSectionsValid()) {
      let nominal_diameters_array = [];
      let well_thickness_array = [];
      this.dataService.showLoader = true;
      this.currentLateralSectionsForms.forEach(section => {
        nominal_diameters_array.push(section.controls.nominal_diameter.value.value);
        well_thickness_array.push(section.controls.wall_thickness.value);
      });
      if (this.offlineService.isItOfflineMode) {
        let response = await this.lateralService.getFlowRateOptionsForEmitterInlineEmittersOffline(
          this.dataService.chosen_emitter_type,
          this.dataService.chosen_model,
          '',
          nominal_diameters_array,
          well_thickness_array
        )
        this.dataService.showLoader = false
        this.setFlowRateDropdownForInlineEmittersResponse(response)
      } else {
        this.lateralService
          .getFlowRateOptionsForEmitterInlineEmitters(
            this.dataService.chosen_emitter_type,
            this.dataService.chosen_model,
            '',
            nominal_diameters_array,
            well_thickness_array
          )
          .subscribe(resp => {
            this.dataService.showLoader = false;
            this.setFlowRateDropdownForInlineEmittersResponse(resp)
          });
      }
    }
  }
  setFlowRateDropdownForInlineEmittersResponse(resp) {
    if (resp.success) {
      this.flow_rate_options = resp.result.lateral_chars.flow_rates;
      if (
        !this.flow_rate_options.includes(
          this.emitterControls.flow_rate.value
        )
      ) {
        this.emitterControls.flow_rate.reset();
      }

      //get pressure form validation
      this.dataService.getEndletPressureValidation.next();
    }
  }
  async getDropdownsForOnlineEmitters() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = await this.lateralService.getFlowRateOptionsForEmitterOnlineEmittersOffline('',
        '',
        '',
        this.EemitterType[this.dataService.chosen_emitter_type])
      this.dataService.showLoader = false;
      this.setDropdownsForOnlineEmittersResponse(response)
    } else {
      this.lateralService
        .getFlowRateOptionsForEmitterOnlineEmitters(
          '',
          '',
          '',
          this.EemitterType[this.dataService.chosen_emitter_type]
        )
        .subscribe(response => {
          this.dataService.showLoader = false;
          this.setDropdownsForOnlineEmittersResponse(response)
        });
    }
  }
  setDropdownsForOnlineEmittersResponse(response) {
    if (response.success) {
      this.pipeMaterialOptions =
        response.result.pipes_section_charactaristics.types;
      this.pipeWallThicknessOptions =
        response.result.pipes_section_charactaristics.classes;
      this.nominalDiametersOptions =
        response.result.pipes_section_charactaristics.nominal_diameters;
    }
  }
  isLateralSectionsValid() {
    let boolean = true;
    this.currentLateralSectionsForms.forEach(form => {
      boolean = boolean && form.valid;
    });
    return boolean;
  }

  get pressureControls() {
    return this.pressureForm.controls;
  }
  get emitterControls() {
    return this.emitterForm.controls;
  }
}
