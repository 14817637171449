import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { CalculatorService } from '../../../../services/calculator.service';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';

@Component({
  selector: 'app-help-common-question',
  templateUrl: './help-common-question.component.html',
  styleUrls: ['./help-common-question.component.scss']
})
export class HelpCommonQuestionComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  faqData: any;
  contactTopics: any;
  videos_data;
  constructor(
    private calculatorService: CalculatorService,
    public dataService: DataService,
    public textService: TextService
  ) {}

  ngOnInit(): void {
    this.dataService.showLoader = true;
    this.calculatorService.getFaq().subscribe(
      response => {
        this.dataService.showLoader = false;
        this.faqData = response.result.page_data.QATopics;
        this.videos_data = response.result.page_data.videos;

        // this.contactTopics = response.result.page_data.contactUsTopics;
        this.dataService.regions_list = response.result.page_data.regions;
      },
      error => {
        console.log(error);
      }
    );
  }
}
