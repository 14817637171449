import { AbsCalculationProcess } from "../AbsCalculationProcess";
import { Segment } from "../../Segment";
import { PipeSection } from "../../PipeSection";
import { SubmainCalculationType } from "../../../enums/SubmainCalculationType.enum";
import { ErrorMsgs } from "../../../../../hydrocalc-code/models/Errors/ErrorMsgs";
import { SlopeSection } from "../../SlopeSection";
import { CalculationErrors, ECalculationErrors } from "../../../errors_and_warnings/errors";
import { AbsSubmainCalculationProcess } from "./AbsSubmainCalculationProcess";
import { BaseMaxLengthCaculationProcess } from "../BaseMaxLengthCaculationProcess";
import { ECalculationWarnings } from "../../../errors_and_warnings/warnings";

export class PipesMaxLength extends AbsSubmainCalculationProcess {

    baseMaxLengthCaculationProcess: BaseMaxLengthCaculationProcess = new BaseMaxLengthCaculationProcess();

    public initSegments(data: any, segments: any, slopes: any, pipes: any, isFlushing: boolean = false) {
        if (isFlushing) {
            // Init all segments:
            // Add Slope section propeties to Segments:
            super.addSlopeSectionsToSegments(segments, slopes);
            // Add Pipe section propeties to Segments:
            super.addPipeSectionsToSegments(segments, pipes);
        }
        else {
            // Init 1 segment:
            // Add Slope section propeties to Segments:
            this.addSlopeSectionsToSegments(segments, slopes);
            // Add Pipe section propeties to Segments:
            this.addPipeSectionsToSegments(segments, pipes);
        }

    }

    public calculate(segments: any, pipes: any, slopes: any, data: any) {
        let isFlushing = data.isFlushingMode;
        if (isFlushing) {
            // Flushing calculation:
            return this.calcTotalPressureLoss(segments, pipes, slopes, data);
        }
        else {
            //Pipes max length calculation:
            return this.calcPipesMaxLength(segments, pipes, slopes, data);
        }
    }

    public createCalculationSegments(data: any, blockChars: any, topographyChars: any, pipeChars: any, isFlushing: boolean, oldSegments: any = null) {
        return this.baseMaxLengthCaculationProcess.createCalculationSegments(data, blockChars, topographyChars, pipeChars, isFlushing, oldSegments);
    }

    protected setTopoChars(data: any, _slopes: any) {
        this.baseMaxLengthCaculationProcess.setTopoChars(data, _slopes);
    }

    protected addSlopeSectionsToSegments(segments: Segment[], slopes: SlopeSection[]) {
        this.baseMaxLengthCaculationProcess.addSlopeSectionsToSegments(segments, slopes);
    }

    protected addPipeSectionsToSegments(segments: Segment[], pipes: PipeSection[]) {
        this.baseMaxLengthCaculationProcess.addPipeSectionsToSegments(segments, pipes);
    }

    protected handleCalculationStops(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false
        }
        let totalLength: Number = data.totalLengthForCurrentSegments + data.spacing; //total submain length with current segment 
        let { pipes, slopes, calculationType, currPipeSection, pressureLossSum, segPerssureLoss, systemGeneralPressureLoss, maxPressureLoss, currSegment, segments, index, isFlushingMode, isEndPressureCalculation, inletPressureUI } = data
        let currSegmentInletPressure = currSegment.InletPressure;
        if (calculationType == SubmainCalculationType.PIPES_MAX_LENGTH) {
            // Pressure stop:
            if (currSegment.InletPressure > Number(currPipeSection.MaxAllowedPressure)) {
                calcStopsResponse.warning = ECalculationWarnings.PIPE_MAX_ALLOWED_PRESSURE;
            }

            // Pressure loss stop or max length for submain stop or Inlet pressure UI stop:
            if (systemGeneralPressureLoss >= maxPressureLoss || (Number(AbsCalculationProcess.MAX_LENGTH_SUBMAIN) < totalLength && index == 0)
                // || this.checkUiInletPressureStop(isFlushingMode, isEndPressureCalculation, inletPressureUI, currSegmentInletPressure)
            ) {
                // End calculation with 1 segment shorter:
                let shiftedSeg = segments.shift();
                if (segments.length <= 0) {
                    // No Results
                    calcStopsResponse.error = ECalculationErrors.NO_RESULTS;
                }
                else {
                    // Set slopes and pipes sections data:
                    this.setSectionsAggregatedData({ pipes, slopes, segments, shiftedSeg });
                }
                // End Calculation
                calcStopsResponse.isCalcDone = true;
                calcStopsResponse.isStopIteration = true;
                return calcStopsResponse;
            }
        }
        else if (calculationType == SubmainCalculationType.PRESSURE_LOSS_FOR_SELECTED_PIPE) {
            // Flushing calculation:
            return {};
        }

        return calcStopsResponse;
    }

    protected postCalcActions(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false
        }
        let { calculationType } = data;

        if (calculationType == SubmainCalculationType.PIPES_MAX_LENGTH) {
            calcStopsResponse.isStopIteration = true;
        }
        else if (calculationType == SubmainCalculationType.PRESSURE_LOSS_FOR_SELECTED_PIPE) {
            // Flushing calculation:
            return {};
        }

        return calcStopsResponse;
    }

    // ---------------------------------------- PRAIVATE METHODS ----------------------------------------

    private calcPipesMaxLength(segments: Segment[], pipes: PipeSection[], slopes: SlopeSection[], calcData: any) {
        let lastFlowRate = this.calcLastLateralFlowRateForCalculation(calcData.blockChars.num_of_laterals, calcData.blockChars.is_rectangular, calcData.blockChars.last_lateral_flow_rate, calcData.blockChars.total_lateral_flow_rate, calcData.blockChars.last_lateral_length, calcData.blockChars.first_lateral_length);
        let isCalcDone = false;
        let systemPL: number;
        let segment_index_for_desired_inlet_pressure: number;

        // Set segment calculation data:        
        let segmentsCalcData: any = { calcData, pipes, slopes, segments, lastFlowRate, type: SubmainCalculationType.PIPES_MAX_LENGTH };
        do {
            if (segmentsCalcData.segments.length == 334) {
                segments
            }
            // Init CalculationData:
            this.resetSegmentsCalcData(segmentsCalcData);

            // set sections length:
            pipes[0].SectionLength = segments.length * calcData.blockChars.distance_between_laterals;
            pipes[0].SectionLength = Number(pipes[0].SectionLength.toFixed(2));
            slopes[0].SectionLengthOnMap = segments.length * calcData.blockChars.distance_between_laterals;
            slopes[0].SectionLengthOnMap = Number(slopes[0].SectionLengthOnMap.toFixed(2));
            slopes[0].SectionRealLength = slopes[0].SectionLengthOnMap;
            slopes[0].SectionRealLength = Number(slopes[0].SectionRealLength.toFixed(2));

            // Handle segments calculation:
            let res = this.handleSegmentsCalculation(segmentsCalcData);
            isCalcDone = res.isCalcDone;
            systemPL = res.systemPL;
            segment_index_for_desired_inlet_pressure = res.segment_index_for_desired_inlet_pressure
            console.log(segments.length)
            if (!isCalcDone) {
                // Add Segment:
                let seg = new Segment(1);
                seg.Length = calcData.blockChars.distance_between_laterals;
                seg.PipeSection = pipes[0];
                seg.SlopeSection = slopes[0];
                segments.unshift(seg);
            }
        } while (!isCalcDone);

        let results: any = {};

        let err = segmentsCalcData.errors || [];
        if (err.length > 0) {
            results.errors = err;
            return results;
        }

        results = this.summarizeResults(segments, pipes, systemPL, calcData.maxVelocity, calcData.isFlushingMode, calcData.end_velocity, segment_index_for_desired_inlet_pressure);
        results.totalPressureLoss = pipes[0].PressureLoss;
        results.endPressure = pipes[pipes.length - 1].EndPressure;
        results.totalLength = Number(pipes[0].SectionLength.toFixed(2));
        // results.numOfLaterals = Math.floor(results.totalLength / calcData.blockChars.distance_between_laterals);  
        results.numOfLaterals = Number(segments.length.toFixed(2));
        let diffMeters = results.totalLength * (slopes[0].HeightDiffPrecent / 100);

        slopes[0].HeightDiffInMeters = Number(diffMeters.toFixed(2));
        return results;
    }

    private checkUiInletPressureStop(isFlushingMode, isEndPressureCalculation, inletPressureUI, currSegmentInletPressure) {
        return (!isFlushingMode && !isEndPressureCalculation && inletPressureUI && currSegmentInletPressure > inletPressureUI)
    }
}