import { Injectable } from "@angular/core";
import { DeviceLessCalcCharacteristicsLabelConfig } from "../shared/characteristics/calc-labels.types";

@Injectable({
  providedIn: "root",
})
export class IrrigationService {
  private FeetToMeter: number = 3.28084;
  private GallonToLiterPerHour: number = 0.2642;
  private InchToMmPerHour: number = 0.0393701;

  constructor() {}

  calcIrrigation(
    emitterFlowRate: number,
    linesPerRow: number,
    distBetweenEmitters: number,
    distBetweenRows: number,
    units: number
  ): number {
    const result =
      units === 1
        ? this.calcIrrigationByMetric(
            emitterFlowRate,
            linesPerRow,
            distBetweenEmitters,
            distBetweenRows
          )
        : this.calcIrrigationByUsa(
            emitterFlowRate,
            linesPerRow,
            distBetweenEmitters,
            distBetweenRows
          );
    return result;
  }

  // Return Irrigation Rate(mm/h)
  private calcIrrigationByMetric(
    emitterFlowRate: number,
    linesPerRow: number,
    distBetweenEmitters: number,
    distBetweenRows: number
  ): number {
    return parseFloat(
      (
        (emitterFlowRate * linesPerRow) /
        (distBetweenEmitters * distBetweenRows)
      ).toFixed(2)
    );
  }

  // Return Irrigation Rate(inch/h)
  private calcIrrigationByUsa(
    emitterFlowRate: number,
    linesPerRow: number,
    distBetweenEmitters: number,
    distBetweenRows: number
  ): number {
    return parseFloat(
      (
        ((emitterFlowRate / this.GallonToLiterPerHour) *
          linesPerRow *
          this.InchToMmPerHour) /
        ((distBetweenEmitters / this.FeetToMeter) *
          (distBetweenRows / this.FeetToMeter))
      ).toFixed(2)
    );
  }

  getIrrigationLabelsConfig(): DeviceLessCalcCharacteristicsLabelConfig[] {
    return this.irrigationLabelsConfig;
  }

  private irrigationLabelsConfig: DeviceLessCalcCharacteristicsLabelConfig[] = [
    {
      ObjName: "emitter_flow_rate",
      TextKey: "input_flow_rate_label",
      unitsKey: "lh",
    },
    {
      ObjName: "dist_between_emitters",
      TextKey: "input_distance_between_emitters_label",
      unitsKey: "m",
    },
    {
      ObjName: "dist_between_rows",
      TextKey: "input_distance_between_rows_label",
      unitsKey: "m",
    },
    {
      ObjName: "irrigation_lines_per_rows",
      TextKey: "input_irrigation_lines_per_row_label",
    },
  ];
}
