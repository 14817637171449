import { AbsCalculationProcess } from "../AbsCalculationProcess";
import { Segment } from "../../Segment";
import { PipeSection } from "../../PipeSection";
import { SlopeSection } from "../../../../calculator/Entities/SlopeSection";
import { CalculationErrors, ECalculationErrors } from "../../../errors_and_warnings/errors";
import { CalculationWarnings, ECalculationWarnings } from "../../../errors_and_warnings/warnings";
import { AbsMainlineCalculationProcess } from "./AbsMainlineCalculationProcess";
import { MainlineCalculationType } from "../../../../calculator/enums/MainlineCalculationType.enum";
import * as _ from "lodash"
export class PipesDiameter extends AbsMainlineCalculationProcess {

    public initSegments(data: any, segments: any, slopes: any, pipes: any, isFlushing: boolean = false) {
        // Add Slope section propeties to Segments:
        this.addSlopeSectionsToSegments(segments, slopes);

        // Add Pipe section propeties to Segments:
        this.addPipeSectionsToSegments(segments, pipes);

    }

    protected createPipeSectionsForEachSegment(segments: Segment[], pipes: PipeSection[]) {
        let meters = 0;
        let pipes_by_segment: PipeSection[] = []
        let segment: Segment
        for (let index = 0; index < segments.length; index++) {
            segment = segments[index]
            meters += segment.Length;
            meters = Number(meters.toFixed(2));

            //deep copy
            let pipeSection: PipeSection = JSON.parse(JSON.stringify(this.getPipeSection(meters, pipes)));;
            //update id and length like the segment
            pipeSection.Id = segment.Id
            pipeSection.SectionLength = segment.Length
            pipes_by_segment.push(pipeSection)
        }
        return pipes_by_segment
    }

    public calculate(segments: any, pipes: any, slopes: any, data: any) {
        let isFlushing = data.isFlushingMode;
        if (isFlushing) {
            // Flushing calculation:
            return this.calcTotalPressureLoss(segments, pipes, slopes, data);
        }
        else {
            // Pipes Diameter calculation:
            return this.calcPipeDiametersForAGivenMaxPressureLoss(segments, pipes, slopes, data);
        }
    }
    // ---------------------------------------- OVERRIDE METHODS ----------------------------------------
    public createPipeSections(pipeCharsData: any, isLastSectionForFlushing: boolean, segments: Segment[]) {
        let numOfPipeSections = pipeCharsData.length;
        // let numOfPipeSections = segments.length > pipeCharsData.length ? segments.length : pipeCharsData.length;
        let pipes: PipeSection[] = [];
        let nextSectionflowRate: number = 0
        let accumulatedFlowRate: number = 0

        // Calculate accumulated flow rate for pipes
        for (let index = numOfPipeSections - 1; index >= 0; index--) {
            let pipeData = pipeCharsData[index];
            //accumulated Flow Rate for section
            accumulatedFlowRate = Math.round((accumulatedFlowRate + pipeData.section_flow_rate) * 1e12) / 1e12
            pipeCharsData[index].accumulated_flow_rate = accumulatedFlowRate;

            //atomic Flow Rate for pipe section
            pipeCharsData[index].section_flow_rate = pipeData.section_flow_rate ? pipeData.section_flow_rate : nextSectionflowRate
            nextSectionflowRate = pipeData.section_flow_rate

        }

        for (let index = 0; index < numOfPipeSections; index++) {
            let pipe = new PipeSection();
            pipe.Id = index + 1;
            let pipeData = pipeCharsData[index];
            let rough = pipeData.pipe_roughness || pipeData.roughness || null;
            if (rough) {
                rough = Number(rough) > Number(AbsCalculationProcess.MAX_ROUGHNESS) ? AbsCalculationProcess.MAX_ROUGHNESS : rough;
            }
            pipe.Type = pipeData.pipe_material || pipeData.type;;
            pipe.Class = pipeData.pipe_class || pipeData.class;
            pipe.NominalDiameter = pipeData.nominal_diameter
            pipe.InternalDiameter = pipeData.internal_diameter
            pipe.SectionLength = pipeData.section_length || 0;
            pipe.KD = Number(pipeData.kd_local_friction_factor);
            pipe.Roughness = rough || AbsCalculationProcess.PIPES_ROUGHNESS_DEFAULT_VALUE;
            pipe.MaxPressure = 0;
            pipe.MaxAllowedVelocity = pipeData.max_velocity;
            pipe.MaxAllowedPressure = pipeData.max_allowable_design_pressure ? Number(pipeData.max_allowable_design_pressure) : Number(pipeData.max_allowable_technical_pressure);

            //flow rate for pipe section
            pipe.atomicFlowRate = pipeData.section_flow_rate
            pipe.accumulatedFlowRate = pipeData.accumulated_flow_rate


            //is it an extra section for flushing mode
            pipe.sectionForFlushing = isLastSectionForFlushing && index == numOfPipeSections - 1
            pipes.push(pipe);
        }
        pipes.sort((a, b) => Number(b.NominalDiameter) - Number(a.NominalDiameter));

        //in case of multiple elevation - more segements than pipes section - we will add new pipes (duplicate) for the segments
        if (segments.length > pipes.length) {
            pipes = this.createPipeSectionsForEachSegment(segments, pipes)
        }
        return pipes;
    }
    protected getCurrPipeSectionInit(calculationType: any, pipes: PipeSection[], pipesIndex: number, segments: any = null) {
        return null;
    }

    protected initSegmentPipeSection(data: any) {
        let isFlushingMode = data.segmentsCalcData.calcData.isFlushingMode
        let currentSegment = data.segment;
        let NumberOfSegment = data.segmentsCalcData.segments.length
        let saveNominalDiameterFromPrevCalculateSegment: boolean = false
        if (!currentSegment.isPipeUpdated && !isFlushingMode) {
            const maxVelocityAllowed = data.segmentsCalcData.calcData.maxVelocityAllowed

            //caluclate minimal internal
            let minNominalDiamterCurrSegment = this.calcMinInternalDiameter(currentSegment.FlowRate, maxVelocityAllowed)
            let NominalDiameterNextSegment = currentSegment.Id < NumberOfSegment ? data.segmentsCalcData.segments[currentSegment.Id].PipeSection.NominalDiameter : null
            //if the previous calculate segment is bigger then the min calcuted nominal we will choose the minimal as the prev calculated segment nominal diamter
            const minNominalDiamter = NominalDiameterNextSegment && minNominalDiamterCurrSegment < NominalDiameterNextSegment ? NominalDiameterNextSegment : minNominalDiamterCurrSegment
            saveNominalDiameterFromPrevCalculateSegment = NominalDiameterNextSegment && minNominalDiamterCurrSegment < NominalDiameterNextSegment

            //calculate minimal allowed pressure
            const minAllowedPressure = currentSegment.EndPressure

            //get minimal pipe to start with him
            const pipe = this.getPipeForCurrentSegment(data.segmentsCalcData, minNominalDiamter, minAllowedPressure, currentSegment, saveNominalDiameterFromPrevCalculateSegment);
            if (pipe) {
                data.segment.PipeSection = pipe
            } else {
                // End calculation with error:
                this.addError(data.segmentsCalcData.errors, ECalculationErrors.NO_RESULTS);

            }
        }

    }

    protected getPipeForCurrentSegment(calcData: any, minInternalDiamter: number, minAllowedPressure: any, currSegment: Segment, saveInternalDiameter: boolean = false) {
        const dictioanry_pipes = calcData.dictioanry_pipes
        const pipe_nominal_diameter_list = calcData.pipe_nominal_diameter_list
        let chosen_pipe: any;
        let internal_diameter_index
        let choosen_internal_diameter
        let segmentPipe = currSegment.PipeSection

        //choose internal diameter index
        internal_diameter_index = pipe_nominal_diameter_list.findIndex(internal_diameter => saveInternalDiameter ? internal_diameter == minInternalDiamter : Number(internal_diameter) > minInternalDiamter)
        if (internal_diameter_index < 0) { //in case there isnt a bigger internal diamter
            return undefined
        }
        //in case there is no matching pipe with minmal class and diamter - we will increase the internal diameter to the next one in the list
        for (internal_diameter_index; internal_diameter_index < pipe_nominal_diameter_list.length - 1; internal_diameter_index++) {
            choosen_internal_diameter = pipe_nominal_diameter_list[internal_diameter_index]

            //get all pipes with chosen internal diameter 
            let potential_pipes_by_internal_diameter = dictioanry_pipes[choosen_internal_diameter]

            //choose minimal class in internal diameter pipes array
            chosen_pipe = potential_pipes_by_internal_diameter.find(pipe => {
                return Number(pipe.max_allowable_design_pressure) > Number(minAllowedPressure)
            })
            if (chosen_pipe) {
                break;
            }
        }
        if (!chosen_pipe) {
            return undefined
        }

        //update segment pipe
        segmentPipe.Class = chosen_pipe.pipe_class || chosen_pipe.class;
        segmentPipe.NominalDiameter = chosen_pipe.nominal_diameter
        segmentPipe.InternalDiameter = chosen_pipe.internal_diameter
        segmentPipe.KD = Number(chosen_pipe.kd_local_friction_factor);
        segmentPipe.MaxAllowedVelocity = chosen_pipe.max_velocity;
        segmentPipe.MaxAllowedPressure = chosen_pipe.max_allowable_design_pressure ? Number(chosen_pipe.max_allowable_design_pressure) : Number(chosen_pipe.max_allowable_technical_pressure);

        return segmentPipe
    }

    protected handleCalculationStops(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            update_current_segment: false,
            isStopIteration: false,
            isCalcDone: false,
            segmentIndex: data.index

        }
        let { systemGeneralPressureLoss, currSegment,
            calculationType, maxPressureLoss } = data
        let currPipeSection = currSegment.PipeSection

        if (calculationType == MainlineCalculationType.PIPES_DIAMETERS) {
            // Pressure stop:
            if (currSegment.InletPressure > Number(currPipeSection.MaxAllowedPressure)) {
                // Restart claculation with Larger class:
                calcStopsResponse.update_current_segment = true;
                calcStopsResponse.isStopIteration = true
                return calcStopsResponse;
            }

            // Pressure loss stop:
            if (systemGeneralPressureLoss >= maxPressureLoss) {
                // Restart calculation with Larger pipe diameter:
                calcStopsResponse.update_current_segment = false;
                calcStopsResponse.isStopIteration = true
                return calcStopsResponse;
            }

        } else {
            // Flushing calculation:
            return {};
        }
        return calcStopsResponse;
    }

    protected postCalcActions(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false
        }
        let { calculationType, pipes, maxPressureLoss, segmentsCalcData } = data

        if (calculationType == MainlineCalculationType.PIPES_DIAMETERS) {
            // Calc succeeded, check pipe sections length:
            if (pipes.length == 1 && pipes[0].Id == 0) {
                // curr pipe is the smallest, calculation ended successfully:
                calcStopsResponse.isCalcDone = true;
                return calcStopsResponse;
            }
            let usedPipes = pipes.filter((p) => p.SectionLength > 0);

            if ((usedPipes.length == 1 && usedPipes[0].PressureLoss < maxPressureLoss)) {
                // Calculation ended with 1 pipe with pressure loss < maxPressureLoss,
                // Replace 1 pipe calc with 2 pipes: first pipe is curr pipe - 1 meters as start, second pipe is 1 diameter smaller (submain length - second pipe length)
                segmentsCalcData.type = MainlineCalculationType.VERIFICATION;
                calcStopsResponse.isStopIteration = true;
                return calcStopsResponse;
            }
        }

        return calcStopsResponse;
    }

    // ---------------------------------------- PRAIVATE METHODS ----------------------------------------
    private calcPipeDiametersForAGivenMaxPressureLoss(segments: Segment[], pipes: PipeSection[], slopes: SlopeSection[], calcData: any) {
        let totalPressureLoss = 0;
        let totalMaxPressure: number;
        let totalMinPressure: number;
        let isCalcDone = false;
        let update_current_segment = false
        let isStopIteration = false
        let isFlushing = calcData.isFlushingMode;
        let lastCalculationSegmentIndex = segments.length - 1;
        let lastFlowRate = this.calcLastLateralFlowRateForCalculation(undefined, undefined, segments[lastCalculationSegmentIndex].PipeSection.accumulatedFlowRate, undefined, undefined, undefined);
        let endPressure = calcData.blockChars.end_pressure;
        let lastSegment = segments[segments.length - 1];
        let pipesIndex = 0;
        let errors = [];
        let warnings = [];
        let systemPL: number;
        let pipe_nominal_diameter_list
        let potential_pipes = calcData.all_pipes_by_user_type



        //get unique list of pipes nominal diameters
        pipe_nominal_diameter_list = potential_pipes.map(item => item.nominal_diameter)
            .filter((value, index, self) => self.indexOf(value) === index);
        //sort nominal diameters from smallest to biggest
        pipe_nominal_diameter_list = pipe_nominal_diameter_list.sort((a, b) => a.nominal_diameter - b.nominal_diameter);

        //create dictionary of potential pipes by nominal diameter
        const pipesGroupedByNominalDiamter = _.groupBy(potential_pipes, "nominal_diameter")
        for (let key in pipesGroupedByNominalDiamter) {
            const pipesArr = pipesGroupedByNominalDiamter[key]
            pipesGroupedByNominalDiamter[key] = pipesArr.sort(function (a, b) {
                return Number(a.max_allowable_design_pressure) - Number(b.max_allowable_design_pressure) || a.nominal_diameter - b.nominal_diameter;
            });
        }

        // Init last segment:
        lastSegment.FlowRate = lastFlowRate;
        lastSegment.AtomicFlowRate = lastFlowRate;
        lastSegment.EndPressure = endPressure;

        // Set segment calculation data:        
        let segmentsCalcData: any = { calcData, pipes, slopes, pipesIndex, segments, lastFlowRate, type: MainlineCalculationType.PIPES_DIAMETERS, dictioanry_pipes: pipesGroupedByNominalDiamter, pipe_nominal_diameter_list };
        pipes = pipes.sort((a, b) => a.NominalDiameter - b.NominalDiameter);
        do {
            // Reset CalculationData:
            this.resetSegmentsCalcData(segmentsCalcData);
            // Handle segments calculation:
            let res = this.handleSegmentsCalculation(segmentsCalcData);
            isCalcDone = res.isCalcDone;
            update_current_segment = res.update_current_segment
            systemPL = res.systemPL;
            isStopIteration = res.isStopIteration
            let segmentIndex = res.segmentIndex
            if (!isCalcDone) {
                if (isStopIteration) {
                    let largerClassOnly = false
                    if (update_current_segment) {
                        //update pipe of last calculated segment
                        largerClassOnly = update_current_segment
                        let result = this.updateSegmentPipe(segmentsCalcData, segmentIndex, largerClassOnly)
                        if (result == ECalculationErrors.NO_RESULTS) {
                            // No Results
                            errors.push(CalculationErrors.get(ECalculationErrors.NO_RESULTS));
                            isCalcDone = true;
                        }
                    } else {
                        //update pipe of last segment
                        // for (let index = segmentsCalcData.segments.length - 1; index >= segmentIndex; index--) {
                        largerClassOnly = update_current_segment
                        let result = this.updateSegmentPipe(segmentsCalcData, segmentIndex, largerClassOnly)
                        if (result == ECalculationErrors.NO_RESULTS) {
                            // No Results
                            errors.push(CalculationErrors.get(ECalculationErrors.NO_RESULTS));
                            isCalcDone = true;
                        } else {
                            // if (index > segmentIndex) {
                            //     let currentSegment = segmentsCalcData.segments[index]
                            //     let prevSegment = segmentsCalcData.segments[index - 1]
                            //     //if nominal diameter of the current segment is smaller or equal to 
                            //     //the nominal diameter of the previous segment - no need to extract 
                            //     //the diameter of the previous segment
                            //     if (currentSegment.PipeSection.NominalDiameter <= prevSegment.PipeSection.NominalDiameter) {
                            //         break;
                            //     }
                            // }
                        }

                        // }

                    }
                } else {
                    // Check if calculation ended:
                    if (segmentIndex === 0) {
                        // errors.push(CalculationErrors.get(ECalculationErrors.NO_RESULTS));
                        isCalcDone = true;
                    }
                }


            }
        } while (!isCalcDone);

        // Summerize results:
        warnings = segmentsCalcData.warnings || [];
        if (errors.length == 0) {
            errors = segmentsCalcData.errors || [];
        }
        segments.forEach(element => {
            totalPressureLoss += element.Pressureloss;
        });
        let maxVelocity = 0;

        // Set Total Min/Max Pressure: 
        if (pipes.length > 0) {
            totalMaxPressure = pipes[0].MaxPressure;
            totalMinPressure = pipes[0].MinPressure;
            pipes.forEach(pipe => {
                if (pipe.MaxPressure > totalMaxPressure) {
                    totalMaxPressure = pipe.MaxPressure;
                }
                if (pipe.MinPressure < totalMinPressure) {
                    totalMinPressure = pipe.MinPressure;
                }
                maxVelocity = this.setMaxOrMin(maxVelocity, pipe.MaxVelocity, true);

                pipe.SectionLength = Number(pipe.SectionLength.toFixed(2));
                pipe.PressureLoss = pipe.InletPressure - pipe.EndPressure;
            });
        }

        return { totalPressureLoss, totalMaxPressure, totalMinPressure, maxVelocity, length: calcData.blockChars.totalLength, warnings, errors };
    }
    protected updateSegmentPipe(calcData, segmentIndex: number, largerClassOnly: boolean) {
        let segment: Segment
        let minNominalDiamter: number
        let minAllowedPressure: any
        let saveNominalDiameter: boolean = false

        for (let index = segmentIndex; index >= 0; index--) {
            segment = calcData.segments[index]
            if (largerClassOnly) {
                minNominalDiamter = segment.PipeSection.NominalDiameter
                saveNominalDiameter = true
                minAllowedPressure = segment.PipeSection.MaxAllowedPressure
            } else {
                minNominalDiamter = segment.PipeSection.NominalDiameter
                minAllowedPressure = segment.EndPressure
            }
            let pipe = this.getPipeForCurrentSegment(calcData, minNominalDiamter, minAllowedPressure, segment, saveNominalDiameter)

            if (pipe) {
                calcData.segments[index].PipeSection = pipe
                segmentIndex = index;
                break;
            }
            if ((!pipe && index == 0) || !pipe && Number(segment.InletPressure) > Number(segment.PipeSection.MaxAllowedPressure)) {
                return ECalculationErrors.NO_RESULTS

            }
        }

        for (let index = calcData.segments.length - 1; index >= segmentIndex; index--) {
            calcData.segments[segmentIndex].isPipeUpdated = true
        }
    }

    protected calcMinInternalDiameter(accumulated_flow_rate: number, velocity: number) {
        return Math.sqrt((accumulated_flow_rate * AbsCalculationProcess.VELOCITY_COEFFICIENT) / velocity);
    }
}