import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import {
  Ecalculator,
  Einputs_fields,
  EtransaltionGroups,
  Eunits,
} from "src/app/shared/enum";
import { Validators } from "@angular/forms";
import { TextService } from "src/app/services/text.service";
import { UnitsConverterService } from "src/app/services/units-converter.service";
@Component({
  selector: "block-question",
  templateUrl: "./block-question.component.html",
  styleUrls: ["./block-question.component.scss"],
})
export class BlockQuestionComponentNew implements OnInit {
  @Input() questionData: any;
  @Input() calculatorIndex;
  Einputs_fields = Einputs_fields;
  EtransaltionGroups = EtransaltionGroups;
  UnitsConverterService = UnitsConverterService;
  Eunits = Eunits;
  showFvEuTooltip = false;
  @Output() emitSelected: EventEmitter<boolean> = new EventEmitter();

  Ecalculator = Ecalculator;

  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {}
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.dataService.isItMobile) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    } else {
    }
  }

  setValidators(inputName) {
    if (inputName == "control") {
      this.questionData.control.setValidators([
        Validators.compose([
          Validators.required,
          Validators.pattern("[-+]?[0-9]+(.[0-9][0-9]?)?"),
          Validators.max(50),
        ]),
      ]);
      this.questionData.control.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
      this.questionData.control_2.clearValidators();
      this.questionData.control_2.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    } else {
      this.questionData.control_2.setValidators([
        Validators.compose([
          Validators.required,
          Validators.pattern("[-+]?[0-9]+(.[0-9][0-9]?)?"),
          Validators.min(50),
        ]),
      ]);
      this.questionData.control_2.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
      this.questionData.control.clearValidators();
      this.questionData.control.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
  }
}
