import { Component, OnInit, Input } from '@angular/core';
import * as c3 from 'c3';
import { DataService } from 'src/app/services/data.service';
import { EtransaltionGroups, Ecalculator, Eunits } from 'src/app/shared/enum';
import { TextService } from 'src/app/services/text.service';
@Component({
  selector: 'app-ilustration-sections',
  templateUrl: './ilustration-sections.component.html',
  styleUrls: ['./ilustration-sections.component.scss'],
})
export class IlustrationSectionsComponent implements OnInit {
  Ecalculator = Ecalculator
  EtransaltionGroups = EtransaltionGroups;
  Eunits = Eunits
  @Input() currentSectionForms: any = [];
  @Input() calculatorIndex
  axis_text_right
  axis_text_left
  yArray: [string, ...c3.Primitive[]] = ['data1', 1];
  xArray: [string, ...c3.Primitive[]] = ['x', 0];
  regions = [];
  graphMaxSize: number;
  chart;

  //key-name
  section_length_key
  nominal_diameter_section_key
  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {
    this.dataService.reloadSectionsIlustration.subscribe((res) => {
      if (this.dataService.showClearPopup) {
        if (this.calculatorIndex === Ecalculator.submain) {
          this.currentSectionForms = res;
          this.currentSectionForms[0].controls.pipeSectionLength.setValue(
            this.dataService.totalRealLength,
            { emitEvent: false }
          );
        }
      }
      this.currentSectionForms
      this.updateIlustration();

    });
  }

  ngOnInit(): void {
    this.getTexts()
    this.getVariablesKeysByCalculator()
  }

  ngAfterViewInit() {
    this.initialzeRegionsOnGraph();
    this.initialzeIlustration();
  }

  initialzeIlustration() {
    let data = {
      xs: {
        data1: 'x',
      },
      columns: [this.xArray, this.yArray],
    };
    this.chart = c3.generate({
      bindto: '#sections-ilustration',
      size: {
        height: 75,
      },
      axis: {
        x: {
          max: this.graphMaxSize,
          tick: {
            outer: false,
            rotate: -0.1,
            multiline: false,
          },
        },
      },
      interaction: {
        //dont show tooltip on data points
        enabled: false,
      },
      legend: {
        //dont show MIKRA
        show: false,
      },
      data: data,
      regions: this.regions,
      point: {
        show: false,
      },
    });
  }

  initialzeRegionsOnGraph() {
    let xDistance = 0;
    this.xArray = ['x', 0];
    this.yArray = ['data1', 1];
    this.regions = [];
    this.currentSectionForms.forEach((section, index) => {
      let section_length = Number(section.value[this.section_length_key]) ? Number(section.value[this.section_length_key]) : 0
      let nominal_diameter = section.value[this.nominal_diameter_section_key] ? section.value[this.nominal_diameter_section_key].label : ''
      this.regions.push({
        start: xDistance,
        end: Number(xDistance) + section_length,
        label: this.dataService.isItMobile
          ? nominal_diameter
          : nominal_diameter == '' ? `(${this.dataService.sectionItems[index].letter})`
            : `(${this.dataService.sectionItems[index].letter}) ${nominal_diameter}${Number(this.dataService.selected_units.value) === Eunits.metric ? 'mm' : 'inch'}`,
        class: 'fill-' + index,
        multiline: true,
        opacity: 0.2,
      });
      xDistance = Number(xDistance) + section_length;
      this.yArray.push(1);
      this.xArray.push(xDistance);
    });
  }

  updateIlustration() {
    this.getTexts()
    this.getVariablesKeysByCalculator()
    this.initialzeRegionsOnGraph();
    this.chart.destroy();
    this.initialzeIlustration();
  }

  onResize(event) {
    this.updateIlustration();
  }

  getTexts() {
    switch (this.calculatorIndex) {
      case this.Ecalculator.submain:
        this.axis_text_right = this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            "submain_s2_elevation_graph"
          ),
          "m_pressure"
        )
        break;
      case this.Ecalculator.mainline:
        this.axis_text_right = this.textService.getText(
          this.EtransaltionGroups.MAINLINE,
          "mainline_ilustration_x_length"
        )
        this.axis_text_left = this.textService.getText(
          this.EtransaltionGroups.MAINLINE,
          "mainline_ilustration_x_water_source"
        )
        break;

      default:
        break;
    }
  }
  getVariablesKeysByCalculator() {
    switch (this.calculatorIndex) {
      case this.Ecalculator.submain:
        this.graphMaxSize = Number(this.dataService.submainLength);
        this.section_length_key = "pipeSectionLength"
        this.nominal_diameter_section_key = 'nominalDiameter'
        break;
      case this.Ecalculator.mainline:
        this.graphMaxSize = Number(this.dataService.totalLengthMainline);
        this.section_length_key = 'length'
        this.nominal_diameter_section_key = "nominal_diameter"
        break;
      default:
        break;
    }
  }
}
