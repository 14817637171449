import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { CognitoApisServiceService } from 'src/app/services/cognito-apis-service.service';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss']
})
export class MessagePopupComponent implements OnInit {
  message: any;
  @Input() signUpFunctionality = false;
  EtransaltionGroups = EtransaltionGroups;
  userConfirmCode: FormControl;
  constructor(
    public dataService: DataService,
    public router: Router,
    public cognitoAWSService: CognitoApisServiceService,
    public textService: TextService
  ) {}

  ngOnInit(): void {
    this.message = this.dataService.editPopupData;
    this.userConfirmCode = new FormControl(null, [
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(6)
    ]);

    this.userConfirmCode.valueChanges.subscribe(res => {
      this.dataService.errorTxtConfirmAcount = '';
    });
  }

  cancel() {
    this.dataService.showMessagePopup = false;
  }
  goToSignIn() {
    this.router.navigateByUrl('login');
    this.dataService.showMessagePopup = false;
  }
  checkError() {
    if (this.userConfirmCode.errors) {
      const theErrors = this.userConfirmCode.errors;
      if (theErrors.required) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'field_required'
        );
      }
      if (theErrors.minlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );

        return this.textService.addValueToText(
          text,
          theErrors.minlength.requiredLength
        );
      }
      if (theErrors.maxlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );
        return this.textService.addValueToText(
          text,
          theErrors.maxlength.requiredLength
        );
      }
    }
  }
}
