
export class EmitterSection{
    // Pipes Raw Properties
    WallThickness: number;
    NominalDiameter: number;
    InternalDiameter: number;
    Roughness: number;
    MaxAllowedPressure: number;
    MinAllowedPressure: number;
    MaxTechnicalPressure: number;
    MinTechnicalPressure: number;
    MaxAllowedVelocity: number;
    KD: number;
    CV: number;
    XEmitterExponent: number;
    KEmitterConstant: number;
    K2EmitterConstant: number;
    IsEmitterPC: boolean;
    
    // Section Properties:
    Id: number;
    Type: string;
    Model: string;
    SectionLength: number = 0;
    NumOfSegmentsInSection: number;

    // Calculated Properties:
    PressureLoss: number = 0;
    InletPressure: number;
    EndPressure: number;
    MaxVelocity: number = 0;
    MaxFlowRate: number = 0;
    MaxPressure: number;
    MinPressure: number;
}