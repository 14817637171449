import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { CalculatorService } from 'src/app/services/calculator.service';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-help-contact',
  templateUrl: './help-contact.component.html',
  styleUrls: ['./help-contact.component.scss']
})
export class HelpContactComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  fileDataMedia: File = null;
  imageSrc;
  @Input() contactTopics: any;
  contactForm: FormGroup;
  formGroupObject = {};

  show_success_text = false;
  show_other_input = false;

  constructor(
    public textService: TextService,
    public calculatorService: CalculatorService,
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.textService.setTextSource();

    this.generateForm();
    this.onChanges();
  }
  onChanges(): void {
    this.contactForm.valueChanges.subscribe(value => {
      this.show_success_text = false;
    });
    this.contactForm.controls.topic.valueChanges.subscribe(value => {
      if (value.english == 'Other') {
        this.show_other_input = true;
        this.contactForm.controls.topic_other_text.setValidators([
          Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9/)&("+\'_.-\\s]*$')
          ])
        ]);
        this.contactForm.controls.topic_other_text.updateValueAndValidity({
          emitEvent: false
        });
      } else {
        this.show_other_input = false;
        this.contactForm.controls.topic_other_text.clearValidators();
        this.contactForm.controls.topic_other_text.setValue('');
        this.contactForm.controls.topic_other_text.updateValueAndValidity({
          emitEvent: false
        });
      }
    });
  }

  submit() {
    if (this.contactForm.valid) {
      const formData = new FormData();
      if (this.fileDataMedia) formData.append('file', this.fileDataMedia);
      formData.append(
        'topic',
        this.contactForm.controls.topic.value.english == 'Other'
          ? this.contactForm.controls.topic_other_text
          : this.contactForm.controls.topic.value.english
      );
      formData.append(
        'description',
        this.contactForm.controls.description.value
      );
      this.calculatorService.sendContactUsForm(formData).subscribe(resp => {
        this.show_success_text = resp.success;
        if (this.show_success_text) {
          this.contactForm.reset();
          this.contactForm.controls.description.setValue('');
          this.contactForm.controls.description.updateValueAndValidity({
            emitEvent: false
          });
          this.myInputVariable.nativeElement.value = '';
          this.fileDataMedia = null;
          this.imageSrc = null;
          this.show_success_text = true
        }
      });
    }
  }

  generateForm() {
    this.formGroupObject['topic'] = new FormControl(null, [
      Validators.required
    ]);
    this.formGroupObject['topic_other_text'] = new FormControl('', []);
    this.formGroupObject['description'] = new FormControl('', [
      Validators.required,
      Validators.maxLength(500)
    ]);

    this.contactForm = new FormGroup(this.formGroupObject);
  }
  get controls() {
    return this.contactForm.controls;
  }

  checkError(controlName: string) {
    if (this.contactForm.controls[controlName].errors) {
      const theErrors = this.contactForm.controls[controlName].errors;
      if (theErrors.required) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'field_required'
        );
      }
      if (theErrors.minlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );

        return this.textService.addValueToText(
          text,
          theErrors.minlength.requiredLength
        );
      }
      if (theErrors.maxlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );
        return this.textService.addValueToText(
          text,
          theErrors.maxlength.requiredLength
        );
      }
      if (theErrors.pattern) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'invalid_pattern'
        );
      }
      if (theErrors.notEqual) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'password_validation'
        );
      }
    }
  }

  fileProgressMedia(fileInput: any) {
    //this.fileData = <File>fileInput.target.files[0];
    this.fileDataMedia = <File>fileInput[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput[0]);
    reader.onload = () => {
      this.imageSrc = reader.result as string;
    };
  }
}
