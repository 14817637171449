import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { PopupTypes } from "src/app/models/popuptype";
import { CognitoApisServiceService } from "src/app/services/cognito-apis-service.service";
import { Router } from "@angular/router";
import { TextService } from "src/app/services/text.service";
import { EtransaltionGroups } from "src/app/shared/enum";
import { OfflineService } from "src/app/services/offline.service";
@Component({
  selector: "app-calc-header",
  templateUrl: "./calc-header.component.html",
  styleUrls: ["./calc-header.component.scss"],
})
export class CalcHeaderComponent implements OnInit {
  settingsPopup = PopupTypes.settings;
  passwordPopup = PopupTypes.password;
  resetPasswordPopup = PopupTypes.resetPassword;
  errorPopup = PopupTypes.error;
  siteUnderMaintenancePopup = PopupTypes.error;
  EtransaltionGroups = EtransaltionGroups;

  constructor(
    public dataService: DataService,
    public router: Router,
    public cognitoAWSService: CognitoApisServiceService,
    public textService: TextService,
    public offlineService: OfflineService
  ) {}

  ngOnInit(): void {
    this.textService.setTextSource();
  }

  openSettings() {
    this.dataService.showSettingsPopup = true;
  }
  async updatePagesData() {
    this.cognitoAWSService.updateUserRegion(
      this.dataService.selected_region.region_id,
      (response) => {
        if (response.success) {
          if (this.router.url.includes("home")) {
            this.dataService.getHomePageData.next();
          } else if (this.router.url.includes("submain")) {
            this.dataService.resetSubmainCalculator.next();
          } else if (this.router.url.includes("lateral")) {
            this.dataService.resetLateralCalculator.next();
          } else if (this.router.url.includes("mainline")) {
            this.dataService.resetMainlieCalculator.next();
          } else if (this.router.url.includes("valve")) {
            this.dataService.resetValveCalculator.next();
          } else if (this.router.url.includes("irrigation")) {
            this.dataService.resetIrrigationCalculator.next();
          } else if (this.router.url.includes("shift")) {
            this.dataService.resetShiftCalculator.next();
          } else if (this.router.url.includes("energy")) {
            this.dataService.resetEnergyCalculator.next();
          }
        } else {
          this.dataService.error_result_msg = response.error.message;
          this.dataService.showErrorPopup = true;
        }
      }
    );
  }

  upadateLanguageUser() {
    this.cognitoAWSService.updateUserLanguage(
      this.dataService.selected_language.row_id,
      (response) => {
        if (response.success) {
          this.getTexts();
        } else {
          this.dataService.error_result_msg = response.error.message;
          this.dataService.showErrorPopup = true;
        }
      }
    );
  }
  getTexts() {
    if (this.offlineService.isItOfflineMode) {
      let response = this.textService.getResourceTextOffline();
      this.setTextResponse({
        result: response,
        success: response ? true : false,
      });
      this.dataService.showLoader = false;
    } else {
      this.textService
        .getResourceText(this.dataService.selected_language.row_id)
        .subscribe((res) => {
          this.dataService.showLoader = false;
          this.setTextResponse(res);
        });
    }
  }
  setTextResponse(res) {
    if (res.success) {
      this.textService.translation_text = res.result.translations;
      localStorage.setItem(
        "resource_txt",
        JSON.stringify(this.textService.translation_text)
      );
      this.textService.setUnitsDictionary();
      if (this.router.url.includes("home")) {
        this.dataService.getHomePageData.next();
      }
      if (this.router.url.includes("submain")) {
        this.dataService.resetSubmainCalculator.next();
      }
      if (this.router.url.includes("lateral")) {
        this.dataService.resetLateralCalculator.next();
      }
      if (this.router.url.includes("help")) {
        this.dataService.getHelpPageData.next();
      }
      if (this.router.url.includes("mainline")) {
        this.dataService.resetMainlieCalculator.next();
      }
      if (this.router.url.includes("valve")) {
        this.dataService.resetValveCalculator.next();
      }
      if (this.router.url.includes("irrigation")) {
        this.dataService.resetIrrigationCalculator.next();
      }
      if (this.router.url.includes("shift")) {
        this.dataService.resetShiftCalculator.next();
      }
      if (this.router.url.includes("energy")) {
        this.dataService.resetEnergyCalculator.next();
      }
      this.dataService.showSettingsPopup = false;
    }
  }
  async signOut() {
    let response = await this.cognitoAWSService.signOut();
    if (response.success) {
      localStorage.clear();
      this.router.navigateByUrl("login");
    } else {
      this.dataService.error_result_msg = response.error.message;
      this.dataService.showErrorPopup = true;
    }
  }
}
