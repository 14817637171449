import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import * as c3 from 'c3';
import { DataService } from 'src/app/services/data.service';
import { Ecalculator, EtransaltionGroups } from 'src/app/shared/enum';
import { TextService } from 'src/app/services/text.service';
import { Canvg } from 'canvg';
@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})
export class GraphComponent implements OnInit, AfterViewInit {
  EtransaltionGroups = EtransaltionGroups;
  @Input() graphID;
  @Input() submainLength = 200;
  @Input() graphData: any;
  @Input() pipes: any[];
  @Input() oneYAxis = true;
  @Input() calculatorIndex;
  @Input() isItPopup = false
  isItHorizontalOnXline = false;
  yArray: [string, ...c3.Primitive[]] = ['data1'];
  xArray: [string, ...c3.Primitive[]] = ['x'];
  y2Array: [string, ...c3.Primitive[]] = ['data2'];
  x2Array: [string, ...c3.Primitive[]] = ['x2'];
  regions: any[] = [];
  chart;
  zoom = 1;
  zoom_factor = 1.1;
  min = 0;
  src;
  convertedSvgToPng;
  maximumPressureOnYAxis: number = 0
  minimumPressureOnYAxis: number
  minimumElevation: number

  dashedLinesX: any[] = [];
  dashedLinesY: any[] = [];
  tempDataObject = {};
  tempDashedObject = {};
  tempColors = {};
  flow_rate_label;
  working_pressure_label;
  accumulated_length_label;
  elevation_label;

  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {
    this.dataService.reloadGraphResult.subscribe((res) => {
      setTimeout(() => {
        this.initMembers();
        this.initialzePointsOnGraph();
        this.initialzeRegionsColor();
        this.initialzeGraph();
      });
    });
  }

  ngOnInit(): void {
    this.flow_rate_label = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.calculatorIndex == 1
          ? EtransaltionGroups.SUBMAIN
          : EtransaltionGroups.LATERAL,
        this.calculatorIndex == 1
          ? 'submain_results_graph2_y_axis'
          : 'lateral_results_graph_2_flow_rate'
      ),
      [Ecalculator.submain, Ecalculator.mainline].includes(this.calculatorIndex) ? 'm3h' : 'lh'
    );

    this.working_pressure_label = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.calculatorIndex == 1
          ? EtransaltionGroups.SUBMAIN
          : EtransaltionGroups.LATERAL,
        this.calculatorIndex == 1
          ? 'submain_results_graph1_y_axis_1'
          : 'lateral_results_graph_1_working_pressure'
      ),
      'm_pressure'
    );

    this.accumulated_length_label = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.calculatorIndex == 1
          ? EtransaltionGroups.SUBMAIN
          : EtransaltionGroups.LATERAL,
        this.calculatorIndex == 1
          ? 'submain_results_graph1_x_axis'
          : 'lateral_results_graph_1_accu_length'
      ),
      'm_pressure'
    );
    this.elevation_label = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.calculatorIndex == 1
          ? EtransaltionGroups.SUBMAIN
          : EtransaltionGroups.LATERAL,
        this.calculatorIndex == 1
          ? 'submain_results_graph1_y_axis_2'
          : 'lateral_results_graph_1_elevation'
      ),
      'm'
    );
    this.initialzePointsOnGraph();
    this.initialzeRegionsColor();
  }

  ngAfterViewInit() {
    this.initialzeGraph();
  }

  initialzeRegionsColor() {
    let start: Number = 0;
    this.pipes.forEach((element, index) => {
      this.regions.push({
        start: start,
        end: Number(element.SectionLength) + Number(start),
        class: 'fill-' + index,
      });
      start = Number(element.SectionLength) + Number(start);
    });

    this.dataService.graph_region = this.regions;
  }

  initialzePointsOnGraph() {
    if (this.oneYAxis) {
      this.graphData.flow_rate.points_on_graph.flow_rate.forEach((element) => {
        this.xArray.push(element.x);
        this.yArray.push(element.y);
      });
    } else {
      this.isItHorizontalOnXline = true;
      let prev_y_slope: number
      this.minimumElevation = Number(this.graphData.slopes_and_pressure.points_on_graph.slopes[0].y)
      this.graphData.slopes_and_pressure.points_on_graph.slopes.forEach(
        (element) => {
          this.x2Array.push(element.x);
          this.y2Array.push(element.y);
          if (prev_y_slope != undefined && Number(element.y) == prev_y_slope && this.minimumElevation == element.y) {
            this.isItHorizontalOnXline = false;
          }
          if (Number(this.minimumElevation) > Number(element.y)) {
            this.minimumElevation = Number(element.y)
          }
          prev_y_slope = element.y
        }
      );
      if (!this.isItHorizontalOnXline) {
        document.documentElement.style.setProperty('--opacity', '0');
      } else {
        document.documentElement.style.setProperty('--opacity', '1');
      }
      this.graphData.slopes_and_pressure.points_on_graph.pressure.forEach(
        (element) => {
          this.xArray.push(element.x);
          this.yArray.push(element.y);
        }
      );
    }
  }

  calculateY(m: number, b: number, X: number) {
    return m * X + b;
  }

  createDashedLines() {
    let dashedLinesX: any[] = [];
    let dashedLinesY: any[] = [];
    let tempDataObject = {};
    let tempDashedObject = {};
    let tempColors = {};
    this.regions.forEach((region, regionIndex) => {
      dashedLinesX.push([`d1${regionIndex}`, region.start, region.end]);

      if (this.oneYAxis) {
      }
      else {
        if (Number(this.maximumPressureOnYAxis) < Number(this.pipes[regionIndex]['MaxAllowedPressure'])) {
          this.maximumPressureOnYAxis = Number(this.pipes[regionIndex]['MaxAllowedPressure'])
        }
        if (Number(this.minimumPressureOnYAxis) > Number(this.pipes[regionIndex]['MaxAllowedPressure']) || !this.minimumPressureOnYAxis) {
          this.minimumPressureOnYAxis = Number(this.pipes[regionIndex]['MaxAllowedPressure'])
        }
        dashedLinesY.push([
          `dashed1${regionIndex}`,
          Number(this.pipes[regionIndex]['MaxAllowedPressure']),
          Number(this.pipes[regionIndex]['MaxAllowedPressure']),
        ]);
      }

      tempDashedObject['dashed1' + String(regionIndex)] = [
        { start: region.start, end: region.end, style: 'dashed' },
      ];
      tempDataObject['dashed1' + String(regionIndex)] =
        'd1' + String(regionIndex);
      tempColors['dashed1' + String(regionIndex)] = '#333333';

    });

    this.dashedLinesX = dashedLinesX;
    this.dashedLinesY = dashedLinesY;
    this.tempDataObject = tempDataObject;
    this.tempDashedObject = tempDashedObject;
    this.tempColors = tempColors;
  }

  initialzeGraph() {
    this.createDashedLines();
    if (Number(this.maximumPressureOnYAxis) < Number(this.graphData.slopes_and_pressure.max_working_pressure)) {
      this.maximumPressureOnYAxis = Number(this.graphData.slopes_and_pressure.max_working_pressure)
    }
    if (Number(this.minimumPressureOnYAxis) > Number(this.graphData.slopes_and_pressure.min_working_pressure)) {
      this.minimumPressureOnYAxis = Number(this.graphData.slopes_and_pressure.min_working_pressure)
    }

    this.chart = c3.generate({
      bindto: '#' + this.graphID,
      size: this.dataService.isItMobile
        ? { height: 250, width: window.innerWidth * 0.8 }
        : { height: this.isItPopup ? 500 : 250 },
      interaction: {
        //dont show tooltip on data points
        enabled: false,
      },
      padding: {
        top: 3,
      },
      legend: {
        //dont show MIKRA
        show: false,
      },
      data: {
        xs: {
          data1: 'x',
          data2: 'x2',
          ...this.tempDataObject,
        },
        columns: [
          this.yArray,
          this.xArray,
          this.y2Array,
          this.x2Array,
          ...this.dashedLinesX,
          ...this.dashedLinesY,
        ],
        types: {
          // data2: 'area-step'
        },
        regions: this.tempDashedObject,
        colors: this.tempColors,
        axes: {
          data1: 'y',
          data2: !this.oneYAxis ? 'y2' : '',
        },
      },
      zoom: {
        enabled: this.dataService.isItMobile ? false : true,
        rescale: true,
        type: 'drag',
        onzoom: function (domain) {
          console.log('zoom', domain);
        },
      },
      regions: this.regions,
      point: {
        show: false,
      },
      area: {
        zerobased: false,
      },
      line: {
        step: {
          type: 'step-after',
        },
      },
      axis: {
        x: {
          max: this.submainLength,
          tick: {
            count: this.dataService.isItMobile ? 5 : 10,
            outer: false,
            culling: false,
            rotate: -0.1,
            multiline: false,
            format: function (x) {
              return Number(x).toFixed(0);
            },
          },
        },
        y: {
          max: !this.oneYAxis ? Number(this.maximumPressureOnYAxis) + 5 : '',
          min: !this.oneYAxis ? Number(this.minimumPressureOnYAxis) - 5 : 0,
          padding: { top: 5, bottom: 0 },
          label: this.oneYAxis
            ? {}
            : {
              text: ' ',
              position: 'outer-middle',
            },
        },
        y2: {
          min: this.dataService.selectedTopography == 'Flat' ? 0 : Number(this.minimumElevation),
          padding: { top: 5, bottom: 0 },
          show: !this.oneYAxis,
          inner: true,
        },
      },
    });
  }

  zoomIn() {
    if (this.zoom < 5) this.zoom *= this.zoom_factor;
    this.chart.zoom([this.min, this.chart.data()[0].values.length / this.zoom]);
  }

  ngOnDestroy() {
    this.convertedSvgToPng && this.convertedSvgToPng.stop();
  }

  initMembers() {
    this.isItHorizontalOnXline = false;
    this.yArray = ['data1'];
    this.xArray = ['x'];
    this.y2Array = ['data2'];
    this.x2Array = ['x2'];
    this.regions = [];
    this.chart = undefined;
    this.zoom = 1;
    this.zoom_factor = 1.1;
    this.min = 0;
  }
}
