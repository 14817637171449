import { MarketingContentDAL } from "../DAL/MarketingContentDAL";
import { EMarketingContentMediaType } from "../enums/MarketingContentMediaType.enum";
import { EPublicity } from "../enums/Publicity.enum";
import { EPublicityStatus } from "../enums/PublicityStatus.enum";
let AWS = require('aws-sdk');

export class MarketingContentService {
    public static readonly LOCAL_STORAGE_CONTENT_TABLE_NAME = "HomePage_Content";
    marketingContentDAL: MarketingContentDAL = new MarketingContentDAL();

    public async getContent(serviceData: any): Promise<any> {
        let filters = serviceData.filters;
        let isOffline = serviceData.isOffline || false
        if (isOffline) {
            let content = JSON.parse(localStorage.getItem(MarketingContentService.LOCAL_STORAGE_CONTENT_TABLE_NAME))
            return content
        }
        let content = await this.marketingContentDAL.getContent(filters);
        let gs = Number(content.global_publicity_status);
        if (!serviceData.isAdmin && filters.publicity == EPublicity.GLOBAL && gs != EPublicityStatus.PUBLISH) {
            // Global content is HIDE
            content = null;
        }
        if (filters.isItForOffline) {
            let content_only_texts = {
                title: content.title,
                subtitle: content.subtitle,
                body: content.body
            }
            content = content_only_texts
        }
        return content;
    }

    public async updateGlobalContent(serviceData: any): Promise<any> {
        let updateContentData = serviceData.contentData;
        let region = MarketingContentDAL.GLOBAL_REGION_STR;
        let file = serviceData.file;
        let type = updateContentData.mediaType;
        if (file) {
            // Upload file to S3:
            let contentUrl = await this.updloadMarketingContent(region,'',file, type);
            updateContentData.mediaUrl = contentUrl;
        }

        // Update content data in db 
        let isUpdated = await this.marketingContentDAL.updateGlobalContent(updateContentData);
        return isUpdated;
    }

    private async updloadMarketingContent(region: string, language:string, file: any, type: any) {
        let s3 = new AWS.S3();
        let folder = this.marketingContentDAL.getEnvName();
        let fileName = file.originalname;
        let fileExtention = fileName.split('.');
        fileExtention = fileExtention[fileExtention.length - 1];
        if (!fileExtention || fileName.split('.').length == 1) {
            // Default extentions:
            fileExtention = type == EMarketingContentMediaType.IMAGE ? '.png' : '.mp4';
        }
        else {
            fileExtention = '.' + fileExtention;
        }
        let key = `${folder}/marketing_content/`
        if (region == MarketingContentDAL.GLOBAL_REGION_STR) {
            key += 'global_content'
        }
        else {
            key += 'local_content_' + region+'_'+language;
        }
        // Add file extention:
        key += fileExtention;

        var params = {
            ACL: 'public-read',
            Bucket: 'hydrocalc-bucket',
            Body: file.buffer,
            Key: key,
        };


        let res = await s3.upload(params).promise();
        let contentUrl = res.Location;
        return contentUrl;
    }

    public async updateLocalContent(serviceData: any): Promise<any> {
        let region = serviceData.regionId;
        let language = serviceData.languageId;
        let file = serviceData.file;
        let type = serviceData.contentData.mediaType;
        if (file) {
            // Upload file to S3:
            let contentUrl = await this.updloadMarketingContent(region, language.toString(), file, type);
            serviceData.contentData.mediaUrl = contentUrl;
        }

        let updateContentData = { contentData: serviceData.contentData, regionId: region, languageId: language };

        let isUpdated = await this.marketingContentDAL.updateLocalContent(updateContentData);
        return isUpdated;
    }

    public async setGlobalContentPublicityStatus(serviceData: any): Promise<any> {
        let globalPublicityStatus = serviceData.globalPublicityStatus;

        let isUpdated = await this.marketingContentDAL.setGlobalContentPublicityStatus(globalPublicityStatus);
        return isUpdated;
    }
}

