import { AbsDAL } from "./Base/AbsDAL";
let AWS = require('aws-sdk');

export class CalculationInputsDAL extends AbsDAL {
  public static readonly CALCULATION_INPUTS_TABLE_NAME = "CalculationInputs";
  public static readonly GLOBAL_REGION_STR = "GLOBAL";
  public static readonly LOCAL_STORAGE_INPUTS_TABLE_NAME = "submain_calculation_inputs";

  /**
   * Gets Calculation inputs by filters - region or global
   */
  public async getCalculationInputs(data: any) {
    let filters = data.filters
    let isOffline = data.isOffline
    let res: any;
    if (isOffline) {
      res = JSON.parse(localStorage.getItem(CalculationInputsDAL.LOCAL_STORAGE_INPUTS_TABLE_NAME))
    } else {
      let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
      let table = this.getTableName(CalculationInputsDAL.CALCULATION_INPUTS_TABLE_NAME);
      let region = filters.region ? filters.region.toString() : CalculationInputsDAL.GLOBAL_REGION_STR;

      // Get all Regions params:
      let params = {
        TableName: table,
        KeyConditionExpression: 'region_id = :hkey',
        ExpressionAttributeValues: {
          ':hkey': region,
        }
      };
      res = await docClient.query(params).promise();
    }
    let inputs = this.mapInputs(res.Items ? res.Items : res);

    return inputs;
  }

  private mapInputs(inputs: any) {
    let ret = inputs.map((input) => {
      let inp = input;
      inp.default_value = input.default_value != -1 ? input.default_value : null;
      inp.min_value = input.min_value != -1 ? input.min_value : null;
      inp.max_value = input.max_value != -1 ? input.max_value : null;

      return inp;
    });

    return ret.sort((a, b) => Number(a.input_id) - Number(b.input_id));
  }

  /**
   * Updates Calculation Input 
   */
  public async updateCalculationInput(inputData: any, region: any = null) {
    try {
      let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
      let table = this.getTableName(CalculationInputsDAL.CALCULATION_INPUTS_TABLE_NAME);
      let inputId = inputData.inputId.toString();
      region = region ? region.toString() : CalculationInputsDAL.GLOBAL_REGION_STR;


      // Get all Regions params:
      let params = {
        TableName: table,
        Key: {
          'region_id': region,
          'row_id': inputId
        },
        UpdateExpression: 'set min_value = :val1, max_value = :val2, default_value = :val3',
        ExpressionAttributeValues: {
          ":val1": inputData.minValue || -1,
          ":val2": inputData.maxValue || -1,
          ":val3": inputData.defaulValue || -1,
        },
        ReturnValues: 'ALL_NEW'
      };

      let res = await docClient.update(params).promise();

      return res.Attributes;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Reads Calculation Input 
   */
  public async getCalculationInput(inputId: any, region: any = null) {
    let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
    let table = this.getTableName(CalculationInputsDAL.CALCULATION_INPUTS_TABLE_NAME);
    region = region ? region.toString() : CalculationInputsDAL.GLOBAL_REGION_STR;
    // Get all Regions params:
    let params = {
      TableName: table,
      KeyConditionExpression: 'region_id = :hkey and row_id = :skey',
      ExpressionAttributeValues: {
        ':hkey': region,
        ':skey': inputId.toString()
      }
    };
    let res = await docClient.query(params).promise();
    let inputs = this.mapInputs(res.Items);

    return inputs[0];
  }
  public createCalculationInput(inputData: any) {
    throw new Error("Method not implemented.");
  }

  // --------------------------------------------------- PRIVATE METHODS ----------------------------------

  // Insert all calculation inputs to dynamodb - development use
  private insertAllCalcInputs() {
    let itemsJson = [];
    let items = [];

    itemsJson.forEach(item => {
      let it = {
        PutRequest: {
          Item: {
            "region_id": { "S": item.region ? item.region.$oid : "GLOBAL" },
            "row_id": { "S": item._id.$oid.toString() },
            "field_name": { "S": item.field_name.toString() || "" },
            "metric_units_string_symbol": { "S": item.metric_units_string_symbol || "" },
            "us_units_string_symbol": { "S": item.us_units_string_symbol || "" },
            "min_value": { "N": item.min_value ? item.min_value.toString() : "-1" },
            "max_value": { "N": item.max_value ? item.max_value.toString() : "-1" },
            "default_value": { "N": item.default_value ? item.default_value.toString() : "-1" },
            "conversion_type": { "N": item.conversion_type ? item.conversion_type.toString() : "-1" }
          }
        }
      }

      items.push(it);
    });
    return items
  }
}