import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
// import { PopupTypes } from '../models/popuptype';
import { HttpHandler } from './httpHandler';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpService } from './http.service';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { DataService } from './data.service';
import { OfflineService } from './offline.service';
import { HomePageHandler } from "../../hydrocalc-code/handlers/homePage.handler";
import { SubmainCalculatorHandler } from '../../hydrocalc-code/handlers/Calculators/SubmainCalculator.handler';
import { LateralCalculatorHandler } from '../../hydrocalc-code/handlers/Calculators/LateralCalculatorHandler';
import { MainlineCalculatorHandler } from 'src/hydrocalc-code/handlers/Calculators/MainlineCalculator.handler';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService extends HttpHandler {
  private url = 'calculator';
  private deviceLessPageDataEndPoint = 'device-less-page-data'
  constructor(
    public router: Router,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService,
    public offlineService: OfflineService
  ) {
    super(_http, httpService, errorService);
  }

  getCalculatorPageDataSubmain() {
    const url = this.url + '/1';

    return this.get(url);
  }

  getHomePage() {
    const url = `home`;
    return this.get(url);
  }

  getFaq() {
    const url = `help`;

    return this.get(url);
  }

  getSettingData() {
    const url = `home/settings`;
    return this.get(url);
  }

  getCalculatorPageDataLateral() {
    const url = this.url + '/2';
    return this.get(url);
  }

  sendContactUsForm(body) {
    const url = `help/contact`;
    return this.post(url, body);
  }

  getCalculatorPageDataMainlie() {
    const url = this.url + '/3';

    return this.get(url);
  }

  getCalculatorPageDataValve() {
    const url = this.url + '/4';

    return this.get(url);
  }

  getProductFinderData(body = {}) {
    const url = this.url + '/valve/product-finder';

    return this.post(url, body);
  }

  getCalculatorPageDataDeviceLessCalcs() {
    const url = `${this.url}/${this.deviceLessPageDataEndPoint}`

    return this.get(url);
  }

  getExternalToolsData() {
    return ['NetSpeX', 'FilterConfig']
  }

  //-------------------------------------------- OFFLINE METHODS ----------------------------------------------


  async getHomePageOffline() {
    let user: any = JSON.parse(localStorage.getItem('user'));
    let region = user.region;
    let isOffline = this.offlineService.isItOfflineMode
    let homePageHandler = new HomePageHandler()

    let result = await homePageHandler.getHomePageData({ user, region, isOffline })
    let response = homePageHandler.buildPageDataResult(result)
    return { result: response }
  }
  async getCalculatorPageDataSubmainOffline() {
    let user: any = JSON.parse(localStorage.getItem('user'));
    let submainCalculatorHandler = new SubmainCalculatorHandler()
    let region = user.region;
    let isOffline = this.offlineService.isItOfflineMode
    let result = await submainCalculatorHandler.getCalculatorPageData({ user, isOffline })
    let response = submainCalculatorHandler.buildPageDataResult(result)
    return response
  }
  async getCalculatorPageDataLateralOffline() {
    let user: any = JSON.parse(localStorage.getItem('user'));
    let lateralCalculatorHandler = new LateralCalculatorHandler()
    let isOffline = this.offlineService.isItOfflineMode
    let result = await lateralCalculatorHandler.getCalculatorPageData({ user, isOffline })
    let response = lateralCalculatorHandler.buildPageDataResult(result)
    return response
  }
  async getCalculatorPageDataMainlieOffline() {
    let user: any = JSON.parse(localStorage.getItem('user'));
    let mainlineCalculatorHandler = new MainlineCalculatorHandler()
    let isOffline = this.offlineService.isItOfflineMode
    let result = await mainlineCalculatorHandler.getCalculatorPageData({ user, isOffline })
    let response = mainlineCalculatorHandler.buildPageDataResult(result)
    return response
  }

  async getCalculatorPageDataValveOffline() {

  }
}
