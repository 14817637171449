import { ICalculationProcess } from "../Interfaces/ICalculationProcess";
import { CalculatorTypes } from "../../../../hydrocalc-code/enums/calculatorTypes.enum";
import { Calculator } from "./base.calculator";
import { CalculationErrors, ECalculationErrors } from "../../errors_and_warnings/errors";
import { EUnits } from "../../../../hydrocalc-code/enums/Units.enum";
import { MainlineCalculationsFactory } from "../Factories/MainlineCalculationsFactory";
export class MainlineCalculator extends Calculator {
    /**
     * calculate
     * calculate - start mainline calculation process
     * 
     */
    public calculate(data: any) {
        let calculationProcess: ICalculationProcess = MainlineCalculationsFactory.getCalcProcess(data.calcType);
        if (!calculationProcess) {
            return null;
        }
        data.calculator = CalculatorTypes.MAINLINE;
        let results = calculationProcess.start(data);

        return results;
    }

    // -------------------------------------------- Pre calculations --------------------------------------------

    /**
     * calcMainlineEndPressure
     * calc mainline end pressure - for a given Inlet pressure
     * 
     */
    public calcEndPressure(calculationData: any) {
        let endPressue: number;
        let a = 0.01;
        let result: any;
        let fix = 0;
        let firstSegmentInletPressure;
        let toCalcAgain = false;
        let inletPressureUI = calculationData.inletPressureUI;

        // Set initial end Pressure to 1 and Calc first lateral inlet pressure:
        calculationData.blockChars.end_pressure = 1;
        calculationData.isEndPressureCalculation = true;
        do {
            calculationData.blockChars.end_pressure = Math.round((calculationData.blockChars.end_pressure + fix) * 1e12) / 1e12
            result = this.calculate(calculationData);
            if (!result || !result.calcResults || !result.calcResults.calculationResults || result.calcResults.calculationResults.errors.length > 0) {
                throw new Error('Error - Error occured while calculating Mainline End Pressure');
            }
            firstSegmentInletPressure = result.calcResults.segments[0].InletPressure;
            // firstSegmentInletPressure = result.calcResults.calculationResults.totalPressureLoss

            if (!firstSegmentInletPressure) {
                // Calculation falied - get first set segment inlet:
                let flag = 0;
                for (let index = 0; index < result.calcResults.segments.length && !flag; index++) {
                    const seg = result.calcResults.segments[index];
                    if (seg.AtomicFlowRate != 0) {
                        // last set segment:
                        firstSegmentInletPressure = index > result.calcResults.segments.length - 1 ? result.calcResults.segments[index + 1].InletPressure : seg.InletPressure;
                        flag = 1;
                    }
                }
            }
            firstSegmentInletPressure = Number(firstSegmentInletPressure.toFixed(2));
            fix = Math.round((inletPressureUI - firstSegmentInletPressure) * 1e12) / 1e12

            toCalcAgain = this.isCalcAgain(firstSegmentInletPressure, inletPressureUI, a);
        } while (toCalcAgain);

        calculationData.isEndPressureCalculation = false;
        return { inletPressure: inletPressureUI, endPressure: calculationData.blockChars.end_pressure };
    }


    // -------------------------------------------- Private Methods --------------------------------------------

    private isCalcAgain(a1: number, a2: number, a: number) {
        let isCalcAgain = a2 - a > a1 || (a2 * 1.01) < a1;
        return isCalcAgain;
    }
}
