import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { PopupTypes } from 'src/app/models/popuptype';
import { CognitoApisServiceService } from 'src/app/services/cognito-apis-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
import { Router } from '@angular/router';
import { of } from 'rxjs';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  messagePopup = PopupTypes.message;
  errorPopup = PopupTypes.error;
  selected_language;
  languages = [];
  countries;
  countries_observable
  chosen_country
  signUpForm: FormGroup;
  formGroupObject = {};
  emailRegx = /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

  constructor(
    public authService: AuthService,
    public dataService: DataService,
    public cognitoAWSService: CognitoApisServiceService,
    public textService: TextService,
    public router: Router
  ) {
    this.dataService.resendEmailForSignUp.subscribe((res) => {
      this.resendEmail();
    });
    this.dataService.confirmAcountForSignUp.subscribe((code) => {
      this.confirmEmail(code);
    });
  }

  ngOnInit(): void {
    this.getDataPage();
    this.generateForm();
    this.onChange();
  }
  getDataPage() {
    this.dataService.showLoader = true;
    this.authService.getSignUpPageData().subscribe((response) => {
      if (response.success) {
        this.countries = response.result.page_data.countries;
        this.countries_observable = of(this.countries)
        this.languages = response.result.page_data.languages;
        this.selected_language = this.languages[0];
        this.getTexts();
      }
    });
  }
  getTexts() {
    this.dataService.showLoader = true;
    this.textService
      .getResourceText(this.selected_language.row_id)
      .subscribe((res) => {
        this.dataService.showLoader = false;
        if (res.success) {
          this.textService.translation_text = res.result.translations;
          localStorage.setItem(
            'resource_txt',
            JSON.stringify(this.textService.translation_text)
          );
          this.textService.setUnitsDictionary();
        }
      });
  }
  onChange() {
    this.signUpForm.controls.region.valueChanges.subscribe(value => {
      if (value != null) {
        let objectValue = this.countries.filter(country => {
          return country.country_name === value
        })[0]
        this.chosen_country = objectValue
        setTimeout(() => {
          if (
            !this.countries.includes(
              this.chosen_country
            )
          ) {
            this.chosen_country = null
          }
        });
      }
    })
    this.signUpForm.controls['repeatPassword'].valueChanges.subscribe((val) => {
      if (this.signUpForm.controls['password'].value !== val) {
        this.signUpForm.controls['repeatPassword'].setErrors({
          notEqual: true,
        });
      }
    });
  }
  async submit() {
    if (this.signUpForm.valid) {
      let response = await this.cognitoAWSService.signUp(
        this.signUpForm.controls.lastName.value,
        this.signUpForm.controls.firstName.value,
        this.signUpForm.controls.password.value,
        this.signUpForm.controls.email.value,
        this.signUpForm.controls.units.value.value,
        this.chosen_country.region_id,
        this.chosen_country.country_name,
        this.signUpForm.controls.companyPosition.value
          ? this.signUpForm.controls.companyPosition.value
          : '',
        this.signUpForm.controls.companyName.value
          ? this.signUpForm.controls.companyName.value
          : '',
        this.signUpForm.controls.receiveUpdates.value ? '1' : '0',
        this.signUpForm.controls.termsAndConditions.value ? '1' : '0',
        this.selected_language.row_id
      );

      if (response.success) {
        // this.authService.sendUserData(response.user).subscribe(res => {

        let body = {
          first_name: this.signUpForm.controls.firstName.value,
          last_name: this.signUpForm.controls.lastName.value,
          email: this.signUpForm.controls.email.value,
          region: this.chosen_country.country_name,
          company: this.signUpForm.controls.companyName.value,
          position: this.signUpForm.controls.companyPosition.value,
          is_receive_updates: this.signUpForm.controls.receiveUpdates.value,
        };
        this.dataService.showLoader = true;
        this.authService.sendUserData(body).subscribe(
          (resp) => {
            this.dataService.showLoader = false;
            if (resp.success) {
              this.dataService.editPopupData = {
                messageTitle: this.textService.getText(
                  this.EtransaltionGroups.SIGNUP,
                  'confirm_account'
                ),
                messageTextUpper: `A verification code was sent to your email ${this.signUpForm.controls['email'].value}`,
                messageTextLower:'Check your email and enter the code.'
              };
              this.dataService.showMessagePopup = true;
            }
          },
          (error) => {
            this.dataService.showLoader = false;
            console.log(error);
          }
        );
        // });
      } else {
        this.dataService.error_result_msg = response.error.message;
        this.dataService.showErrorPopup = true;
      }
    }
  }

  generateForm() {
    this.formGroupObject['firstName'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern('^([^0-9]*)$'),
    ]);
    this.formGroupObject['lastName'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern('^([^0-9]*)$'),
    ]);
    this.formGroupObject['email'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(this.emailRegx),
    ]);
    this.formGroupObject['region'] = new FormControl(null, [
      Validators.required, this.isCountryExist()
    ]);
    this.formGroupObject[
      'units'
    ] = new FormControl(this.dataService.units_list[0], [Validators.required]);
    this.formGroupObject['companyName'] = new FormControl(null, [
      Validators.maxLength(50),
    ]);
    this.formGroupObject['companyPosition'] = new FormControl(null, [
      Validators.maxLength(50),
    ]);
    this.formGroupObject['password'] = new FormControl(null, [
      Validators.required,
      Validators.pattern(/^(?=\D*\d)(?=[^A-Z]*[A-Z]).{8,50}$/)
    ]);
    this.formGroupObject['repeatPassword'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50),
      Validators.minLength(8),
    ]);
    this.formGroupObject['termsAndConditions'] = new FormControl(false, [
      Validators.requiredTrue,
    ]);
    this.formGroupObject['receiveUpdates'] = new FormControl(null, []);

    this.signUpForm = new FormGroup(this.formGroupObject);
  }

  resendEmail() {
    this.cognitoAWSService.resendConfirmationCode(
      this.signUpForm.controls.email.value
    );
  }

  async confirmEmail(code) {
    let response = await this.cognitoAWSService.confirmUserCognito(
      this.signUpForm.controls.email.value,
      code
    );

    if (response.success) {
   
      this.dataService.showMessagePopup = false;
      this.router.navigate(['/login'], { queryParams: { email: this.signUpForm.controls.email.value, isFirstSignup: true } });
    } else {

      this.dataService.errorTxtConfirmAcount = response.error.message;
    }
  }

  get controls() {
    return this.signUpForm.controls;
  }

  checkError(controlName: string) {
    if (this.signUpForm.controls[controlName].errors) {
      const theErrors = this.signUpForm.controls[controlName].errors;
      if (theErrors.required || (!this.chosen_country && controlName === 'region')) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'field_required'
        );
      }
      if (theErrors.minlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );

        return this.textService.addValueToText(
          text,
          theErrors.minlength.requiredLength
        );
      }
      if (theErrors.maxlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );
        return this.textService.addValueToText(
          text,
          theErrors.maxlength.requiredLength
        );
      }
      if (theErrors.pattern) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'invalid_pattern'
        );
      }
      if (theErrors.notEqual) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'password_validation'
        );
      }
    }
  }

  public isCountryExist(): ValidatorFn {
    return (control: AbstractControl): {} | null => {
      //set country and value as an object
      let country_name = String(control.value)
      let objectValue = this.countries ? this.countries.filter(country => {
        return country.country_name === country_name
      })[0] : null

      if (objectValue) {
        return true;
      } else {
        return of(false);
      }
    }
  }
}
