import { ErrorMsgs } from "../../../../../hydrocalc-code/models/Errors/ErrorMsgs";
import { Segment } from "../../../../calculator/Entities/Segment";
import { PipeSection } from "../../PipeSection";
import { AbsCalculationProcess } from "../AbsCalculationProcess";
import { AbsMainlineCalculationProcess } from "./AbsMainlineCalculationProcess";

export class PressureLossForKnownPipe extends AbsMainlineCalculationProcess {


    public initSegments(data: any, segments: any, slopes: any, pipes: any, isFlushing: boolean = false) {
        // Add Slope section propeties to Segments:
        this.addSlopeSectionsToSegments(segments, slopes);
        // Add Pipe section propeties to Segments:
        this.addPipeSectionsToSegments(segments, pipes);
    }

    public calculate(segments: any, pipes: any, slopes: any, data: any) {
        return this.calcTotalPressureLoss(segments, pipes, slopes, data);
    }

    // Override
    protected getCurrPipeSectionInit(calculationType: any, pipes: PipeSection[], pipesIndex: number, segments: any = null) {
        return null;
    }

}