import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TextService } from "src/app/services/text.service";
import { DataService } from "src/app/services/data.service";
import { PdfService } from "src/app/services/pdf.service";
import { OfflineService } from "src/app/services/offline.service";
import { EtransaltionGroups } from 'src/app/shared/enum';
import { CalculatorService } from 'src/app/services/calculator.service';
import { NavigationEnd, Router } from '@angular/router';
import { utils, writeFile } from "xlsx";

@Component({
  selector: 'app-product-finder',
  templateUrl: './product-finder.component.html',
  styleUrls: ['./product-finder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductFinderComponent implements OnInit {
  @ViewChild("pdf_to_export") pdfView: ElementRef;
  EtransaltionGroups = EtransaltionGroups;
  tableButtons = []
  tableColumns = [];
  searchInputs = [];
  valves = [];
  descriptionGuideLink: string = "";
  dateDisplay: string = "";
  isExportingEnabled: boolean = false;
  mySubscription: any;
  user_id;

  constructor(
    public textService: TextService,
    public dataService: DataService,
    public calculatorService: CalculatorService,
    public offlineService: OfflineService,
    public pdfService: PdfService,
    public router: Router
  ) {
    this.dataService.resetValveCalculator.subscribe((res) => {
      this.router.navigateByUrl("calc/valve/product-finder");
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit(): void {
    this.getPageData();
    this.setTableButtons();
    this.setTableColumns();
    this.buildDateStr();
  }

  private async getPageData() {
    this.dataService.showLoader = true
    this.calculatorService.getProductFinderData().subscribe((resp) => {
      this.dataService.showLoader = false;
      if (resp.success) {
        this.setResponsePageData(resp);
      }
    },
      (error) => {
        this.dataService.showLoader = false;
        if (error.message) {
          this.dataService.error_result_msg = error.message;
        }
        if (error.ErrorToClient) {
          this.dataService.error_result_msg = error.ErrorToClient;
        }
        this.dataService.showErrorPopup = true;
      }
    );
    this.getValveCalculatorData();
  };

  private setResponsePageData(resp) {
    this.valves = resp.result.valves || [];
    this.isExportingEnabled = this.valves.length > 0 && this.searchInputs.some((input) => input.searchTerm);
    this.descriptionGuideLink = resp.result.descriptionGuide || "";
  }

  private async getValveCalculatorData() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
    } else {
      this.calculatorService.getCalculatorPageDataValve().subscribe((resp) => {
        this.dataService.showLoader = false;
        this.setResponseValveCalculatorPageData(resp);
      });
    }
    this.getValves();
  }

  private setResponseValveCalculatorPageData(resp) {
    if (resp.success) {
      this.user_id = resp.result.page_data.user.id;
      // this.analyticsEvent();
      this.dataService.user_name =
        resp.result.page_data.user.first_name +
        " " +
        resp.result.page_data.user.last_name;

      this.dataService.regions_list = resp.result.page_data.regions;
      this.dataService.selected_units = this.dataService.units_list.find(
        (value) => {
          return value.value === Number(resp.result.page_data.user.units);
        }
      );
      this.dataService.selected_region = this.dataService.regions_list.find(
        (value) => {
          return resp.result.page_data.user.region === value.region_id;
        }
      );
      this.dataService.languages_list = resp.result.page_data.languages;
      this.dataService.selected_language = this.dataService.languages_list.find(
        (value) => {
          return (
            value.row_id === resp.result.page_data.user.language.toString()
          );
        }
      );
      this.getTexts();
    }
  }

  getTexts() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = this.textService.getResourceTextOffline();
      this.setTextResponse({
        result: response,
        success: response ? true : false,
      });
      this.dataService.showLoader = false;
    } else {
      this.textService
        .getResourceText(this.dataService.selected_language.row_id)
        .subscribe((res) => {
          this.dataService.showLoader = false;
          this.setTextResponse(res);
        });
    }
  }

  setTextResponse(res) {
    if (res.success) {
      this.textService.translation_text = res.result.translations
        ? res.result.translations
        : res.result;
      localStorage.setItem(
        "resource_txt",
        JSON.stringify(this.textService.translation_text)
      );
      this.textService.setUnitsDictionary();

      this.dataService.logic_text_or = this.textService
        .getText(this.EtransaltionGroups.SUBMAIN, "submain_or_1")
        .toString();
    }
  }

  private setTableButtons() {
    this.tableButtons = [
      {
        text: "Reset Search",
        click: this.resetSearch.bind(this),
        isShowOnMobile: true
      },
      {
        text: this.textService.getText(EtransaltionGroups.VALVE, "valve_results_export_pdf"),
        click: this.exportToPDF.bind(this),
        isShowOnMobile: true
      },
      {
        text: this.textService.getText(EtransaltionGroups.VALVE, "product_finder_export_excel"),
        click: this.exportToExcel.bind(this),
        isShowOnMobile: false
      }
    ];
  }

  private setTableColumns() {
    this.tableColumns = [
      {
        display: "Material Description",
        key: "material_description",
        searchable: true,
        isPreciseSearch: false
      },
      { display: "Model", key: "valve_model", searchable: true, isPreciseSearch: false },
      { display: "Diameter (mm)", key: "diameter_mm", searchable: true, isPreciseSearch: true },
      { display: "Diameter (inch)", key: "diameter_inch", searchable: true, isPreciseSearch: true },
      { display: "Material", key: "valve_material", searchable: true, isPreciseSearch: false },
      { display: "Connection Type", key: "connection_type", searchable: true, isPreciseSearch: false },
      { display: "Function", key: "common_function", searchable: true, isPreciseSearch: false },
      { display: "Family", key: "valve_family", searchable: true, isPreciseSearch: false },
    ];

    this.searchInputs = [
      { inputName: "materialDescription", searchTerm: "", type: "text" },
      { inputName: "model", searchTerm: "", type: "text" },
      { inputName: "diameterMm", searchTerm: "", type: "text" },
      { inputName: "diameterInch", searchTerm: "", type: "text" },
      { inputName: "material", searchTerm: "", type: "text" },
      { inputName: "connectionType", searchTerm: "", type: "text" },
      { inputName: "commonFunction", searchTerm: "", type: "text" },
      { inputName: "family", searchTerm: "", type: "text" },
    ];
  }

  private buildDateStr() {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    this.dateDisplay = `${day}-${months[month]}-${year}`;
  }


  private getSearchFilters() {
    return this.searchInputs.reduce((acc, input) => {
      if (input.searchTerm) {
        acc[input.inputName] = input.searchTerm.replace(/''|["“”]/g, '"');
      }
      return acc;
    }, {});
  }

  private getValves(filters = {}) {
    this.calculatorService.getProductFinderData({ filters }).subscribe((resp) => {
      this.dataService.showLoader = false;
      if (resp.success) {
        this.setResponsePageData(resp);
      }
    },
      (error) => {
        this.dataService.showLoader = false;
        if (error.message) {
          this.dataService.error_result_msg = error.message;
        }
        if (error.ErrorToClient) {
          this.dataService.error_result_msg = error.ErrorToClient;
        }
        this.dataService.showErrorPopup = true;
      }
    );
  }

  exportToExcel() {
    const content: Element = this.pdfView.nativeElement;
    let worksheet = utils.table_to_sheet(content);
    let workbook = utils.book_new();
    workbook.SheetNames.push("product-finder");
    workbook.Sheets["product-finder"] = worksheet;
    writeFile(workbook, "product-finder.xlsx")
  }

  exportToPDF() {
    let content: HTMLElement = this.pdfView.nativeElement;
    let title = "results";
    let options = {
      useCORS: true,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        scale: 2,
        backgroundColor: "#f4f7fb",
        scrollX: 0,
        scrollY: 0,
      },
      jsPDF: { orientation: "landscape", unit: "mm", format: "a4" },
      pagebreak: {
        avoid: '.pdf-table-row',
      },
    };

    // Clone the content and inject the styles
    let clone = content.cloneNode(true) as HTMLElement;
    const style = document.createElement('style');
    style.innerHTML = `
    .pdf-results {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: white;
    }
    .pdf-header {
      background-color: var(--netafim-blue);
      height: 4rem;
      max-height: 4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      width: 100%;
    }
    .pdf-header .left-side {
      display: flex;
      margin-left: 24px;
      align-items: center;
    }
    .pdf-table-icon {
      height: 24px;
      max-height: 100%;
    }
    .pdf-table-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #fff;
      padding-left: 12px;
    }
    .pdf-header .right-side {
      margin-right: 24px;
      display: flex;
      align-items: center;
    }
    .hydrocalc-symbol {
      width: 105px;
    }
    .pdf-results-inner-container {
      padding: 18px 12px 0;
    }
    .pdf-date {
      color: --netafim-dark-blue;
      font-family: Roboto;
      font-weight: 400;
      line-height: 28px;
      font-size: 16px;
      padding: 5.3px 0 22px 0px;
    }
    .pdf-table-container {
      max-width: 100%;
      overflow: auto;
      border-radius: 10px;
      background-color: #fff;
      padding: 12px 16px;
      box-shadow: 0px 0px 8px 0px rgba(186, 186, 186, 0.66);
    }
    .product-finder-pdf-table {
      width: 100%;
      height: 100%;
      table-layout: fixed;
      overflow: auto;
      border-collapse: collapse;
    }
    .pdf-table-head {
      display: table-header-group;
      width: 100%;
      background: white;
    }
    .pdf-table-body {
      display: table-row-group;
      width: 100%;
    }
    .pdf-table-body .pdf-table-row {
      background: #f0f8ff;
      border-bottom: 8px solid white;
    }
    .pdf-table-body .pdf-table-row:last-child:after {
      border-bottom: none;
    }
    .pdf-table-row {
      width: 100%;
      display: table-row;
    }
    .table-head-cell, .table-data-cell {
      display: table-cell;
      text-overflow: ellipsis;
      white-space: wrap;
      text-align: start;
      min-width: 104px;
      padding-right: 16px;
      max-width: 180px;
      word-wrap: break-word;
    }
    .table-head-cell:last-of-type, .table-data-cell:last-of-type {
      padding-right: 0;
    }
    .table-head-cell {
      padding-bottom: 12px;
    }
    .table-head-cell .table-head-wrapper {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
    .table-head-cell .table-head-wrapper .table-head-cell-text {
      display: flex;
      justify-content: center;
      align-self: flex-start;
      font-size: 16px;
      font-weight: 400;
      color: var(--netafim-black);
      text-align: start;
      text-wrap: wrap;
      height: 20px;
      flex: 1;
      display: flex;
      align-items: center;
    }
    .table-data-cell {
      padding: 9px 0 9px 16px;
    }
    .table-data-cell .table-cell-text {
      display: block;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: wrap;
      font-size: 12px;
      font-weight: 300;
      line-height: 14.06px;
      text-align: left;
      color: #282828;
    }
    .empty-data {
        font-size: 24px;
        font-weight: 300;
        white-space: nowrap;
      }
    .pdf-results .copyright {
      color: #282828;
      font-size: 12px;
      font-family: Roboto, sans-serif;
      margin-top: 12px;
    }`
    clone.appendChild(style);

    this.pdfService.generatePDF(clone, title, options);
  }

  resetSearch() {
    this.searchInputs.forEach((input) => {
      input.searchTerm = "";
    });
    this.isExportingEnabled = false;
    this.search();
  }

  search() {
    this.dataService.showLoader = true;
    const filters = this.getSearchFilters();
    this.getValves(filters);
  }


}
