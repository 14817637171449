import { AbsDAL } from "./Base/AbsDAL";
import { ELanguages } from "../enums/Languages.enum";
let AWS = require('aws-sdk');

export class TranslationsDAL extends AbsDAL {
    public static readonly TRANSLATIONS_TABLE_NAME = "Translations";
    public static readonly LOCAL_STORAGE_TRANSLATIONS_TABLE_NAME = "resource_txt"

    /**
     * Gets Translations By Language
     * 
     */
    public async getTranslationsByLanguage(languageId: number, isOffline: boolean = false) {
        if (isOffline) {
            let translation_data = JSON.parse(localStorage.getItem(TranslationsDAL.LOCAL_STORAGE_TRANSLATIONS_TABLE_NAME))
            return translation_data;
        }
        let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
        let table = TranslationsDAL.TRANSLATIONS_TABLE_NAME;

        // Get Translations By Language params:
        let params = {
            TableName: table,
            KeyConditionExpression: 'language_id = :hkey',
            ExpressionAttributeValues: {
                ':hkey': languageId
            }
        };
        let res = await docClient.query(params).promise();

        return res.Items;
    }

    public async addNewTranslationToLanguage(transData: any) {
        let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
        let table = TranslationsDAL.TRANSLATIONS_TABLE_NAME;

        var params = {
            TableName: table,
            Item: {
                "group_id": transData.groupID,
                "is_inline_param": transData.isInline,
                "language_id": transData.language,
                "translation_id": transData.id,
                "translation_id_str": transData.idStr,
                "value": transData.val
            }
        };

        await docClient.put(params).promise();

        return true;
    }

    /**
     * Gets all empty translations:
     * 
     */
    public async getEmptyTranslations() {
        let trans = [];
        let languages = [
            ELanguages.ENGLISH,
            ELanguages.FRENCH,
            ELanguages.GERMAN,
            ELanguages.ITALIAN,
            ELanguages.DUTCH,
            ELanguages.KOREAN,
            ELanguages.PORTUGUESE,
            ELanguages.SPANISH,
            ELanguages.TURKISH,
            ELanguages.CHINESE,
        ];

        await Promise.all(languages.map(async (language: any) => {


            let lanTranslations = await this.getTranslationsByLanguage(language);
            // get empty translations:
            lanTranslations = lanTranslations.filter((t => t.value == " "));

            trans = trans.concat(lanTranslations);
        }));

        return trans;
    }
}