import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';

@Component({
  selector: 'app-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss']
})
export class FooterMobileComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  constructor(public dataService: DataService,
    public textService: TextService) { }

  ngOnInit(): void {

  }
}
