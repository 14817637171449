import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnitsConverterService {

  constructor() { }
  public static metersToPSI(meters: number, isUsUntis: boolean): number {
    if (meters != null) {
      let psi = isUsUntis ? (meters * 1.4219).toFixed(2) : meters

      return Number(psi);
    }
  }

  public static metersPerSecondToFtPerSecond(val: number, isUsUntis: boolean): number {
    if (val != null) {
      let converted = isUsUntis ? (val * 3.2808).toFixed(2) : val

      return Number(converted);
    }
  }

  public static metersToFt(val: number, isUsUntis: boolean): number {
    if (val != null) {
      let converted = isUsUntis ? (val * 3.2808).toFixed(2) : val

      return Number(converted);
    }
  }

  public static mmToInch(val: number, isUsUntis: boolean): number {
    if (val != null) {
      let converted = isUsUntis ? (val * 0.03937).toFixed(2) : val

      return Number(converted)
    }
  }

  public static cubicToGalon(val: number, isUsUntis: boolean): number {
    if (val != null) {
      let converted = isUsUntis ? (val * 4.402868).toFixed(2) : val

      return Number(converted)
    }
  }

  public static literToGalon(val: number, isUsUntis: boolean): number {
    // Liter to gallon per minute
    if (val != null) {
      let converted = isUsUntis ? (val * 0.0044).toFixed(2) : val

      return Number(converted);
    }
  }
}
