import { Injectable } from "@angular/core";
import { threadId } from "worker_threads";
import { DataService } from "./data.service";
import { HttpHandler } from "./httpHandler";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { EtransaltionGroups } from "src/app/shared/enum";
import { OfflineService } from "./offline.service";
@Injectable({
  providedIn: "root",
})
export class TextService extends HttpHandler {
  EtransaltionGroups = EtransaltionGroups;
  measureDictionary = {
    m: ["(m)", "(ft)"],
    m2: ["(m)", "(inch)"],
    m_pressure: ["(m)", "(psi)"],
    mm: ["(mm)", "(inch)"],
    lh: ["(l/h)", "(gpm)"],
    m3h: ["(m³/h)", "(gpm)"],
    ms: ["(m/s)", "(ft/s)"],
    square_meter: ["(m^2)", "(Feet^2)"],
    mmh: ["(mm/h)", "(inch/h)"],
    mmd: ["(mm/day)", "(inch/day)"],
    hect: ["(Hectare)", "(Acre)"]
  };
  translation_text;

  constructor(
    public router: Router,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService,
    public offlineService: OfflineService
  ) {
    super(_http, httpService, errorService);
  }

  setTextSource() {
    if (localStorage.getItem("resource_txt")) {
      this.translation_text = JSON.parse(localStorage.getItem("resource_txt"));
    }
  }

  getResourceText(language_id) {
    const url = `translations/${language_id}`;
    return this.get(url);
  }
  getResourceTextOffline() {
    return JSON.parse(localStorage.getItem("resource_txt"));
  }

  addMesaureUnitsToLabel(text, metric_units) {
    return (
      text +
      " " +
      this.measureDictionary[metric_units][
      this.dataService.selected_units.value - 1
      ]
    );
  }

  getUnitsAsText(metric_units) {
    return this.measureDictionary[metric_units][
      this.dataService.selected_units.value - 1
    ];
  }

  getText(group_id, translation_id_str) {
    if (this.translation_text) {
      let text = this.translation_text.find((value) => {
        return (
          value.group_id == group_id &&
          translation_id_str == value.translation_id_str
        );
      });
      return text.value;
    } else {
      return "";
    }
  }

  addValueToText(resource, value) {
    if (resource.includes("{")) {
      let name_of_data = resource.substring(
        resource.lastIndexOf("{"),
        resource.lastIndexOf("}") + 1
      );
      let finaltext = resource.replace(name_of_data, value);
      return finaltext;
    } else {
      return resource;
    }
  }

  setUnitsDictionary() {
    this.measureDictionary = {
      m: [
        this.getText(this.EtransaltionGroups.UNITS, "meters"),
        this.getText(this.EtransaltionGroups.UNITS, "us_feet"),
      ],
      m2: [
        this.getText(this.EtransaltionGroups.UNITS, "meters"),
        this.getText(this.EtransaltionGroups.UNITS, "us_inch"),
      ],
      m_pressure: [
        this.getText(this.EtransaltionGroups.UNITS, "meters"),
        this.getText(this.EtransaltionGroups.UNITS, "us_psi"),
      ],
      mm: [
        this.getText(this.EtransaltionGroups.UNITS, "milimeter"),
        this.getText(this.EtransaltionGroups.UNITS, "us_inch"),
      ],
      lh: [
        this.getText(this.EtransaltionGroups.UNITS, "liters_per_hour"),
        this.getText(this.EtransaltionGroups.UNITS, "us_gph"),
      ],
      m3h: [
        this.getText(this.EtransaltionGroups.UNITS, "cubic_meters_per_hour"),
        this.getText(this.EtransaltionGroups.UNITS, "us_gpm"),
      ],
      ms: [
        this.getText(this.EtransaltionGroups.UNITS, "meter_per_second"),
        this.getText(this.EtransaltionGroups.UNITS, "us_fts"),
      ],
      square_meter: [
        this.getText(this.EtransaltionGroups.UNITS, "square_meter"),
        this.getText(this.EtransaltionGroups.UNITS, "square_feet"),
      ],
      mmh: [
        this.getText(this.EtransaltionGroups.UNITS, "millimeters_per_hour"),
        this.getText(this.EtransaltionGroups.UNITS, "inch_per_hour"),
      ],
      mmd: [
        this.getText(this.EtransaltionGroups.UNITS, "millimeters_per_day"),
        this.getText(this.EtransaltionGroups.UNITS, "inch_per_day"),
      ],
      hect: [
        this.getText(this.EtransaltionGroups.UNITS, "hectare"),
        this.getText(this.EtransaltionGroups.UNITS, "acre"),
      ]
    };
    this.dataService.units_list[0].name = this.getText(
      this.EtransaltionGroups.SETTIGNS,
      "settings_metric"
    );
    this.dataService.units_list[1].name = this.getText(
      this.EtransaltionGroups.SETTIGNS,
      "settings_us_units"
    );
  }

  initialErrorMessages(group_id, translation_id_str) {
    //init error messaged input
    this.dataService.error_messages_inputs = [];

    this.dataService.submain_inputs_data.forEach((element, index) => {
      let errorMsg = this.getText(group_id, translation_id_str); // Enter value between ({min}) to ({max})
      // let errorMsg = 'Enter value between ({min}) to ({max})';
      if (index != 1 && index != 12) {
        if (this.dataService.selected_units.value === 1) {
          errorMsg = errorMsg.replace("{min}", element.min_value);
          errorMsg = errorMsg.replace("{max}", element.max_value);

          // if (element.metric_units_string_symbol) {
          //   errorMsg = errorMsg + element.metric_units_string_symbol;
          // }
        } else {
          errorMsg = errorMsg.replace("{min}", element.min_value_us_units);
          errorMsg = errorMsg.replace("{max}", element.max_value_us_units);

          // if (element.us_units_string_symbol) {
          //   errorMsg = errorMsg + ' ' + element.us_units_string_symbol;
          // }
        }
      }
      this.dataService.error_messages_inputs.push(errorMsg);
    });
  }

  getValidationMinMax(group_id, translation_id_str, min_value, max_value) {
    let errorMsg = this.getText(group_id, translation_id_str); // Enter value between ({min}) to ({max})
    errorMsg = errorMsg.replace("{min}", min_value);
    errorMsg = errorMsg.replace("{max}", max_value);
    return errorMsg;
  }
}
