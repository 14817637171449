import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-mulitple-toggle-button',
  templateUrl: './mulitple-toggle-button.component.html',
  styleUrls: ['./mulitple-toggle-button.component.scss']
})
export class MulitpleToggleButtonComponent implements OnInit {
  constructor(public dataService: DataService) {
    this.dataService.changeMulitpleToggleButtonChoise.subscribe(option => {
      if (this.options.includes(option)) this.change(option);
    });
  }
  @Input() options = [];
  @Input() options_text = [];
  @Input() chosen;
  @Output() change_choice = new EventEmitter<string>();
  @Input() isItDiseable: boolean = false

  ngOnInit(): void { }

  change(option) {
    this.chosen = option;
    this.change_choice.emit(option);
  }
}
