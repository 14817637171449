import { Injectable, OnDestroy } from '@angular/core';
import { HttpHandler } from './httpHandler';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpService } from './http.service';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataService } from './data.service';
import { OfflineService } from './offline.service';
import { LateralCalculatorHandler } from '../../hydrocalc-code/handlers/Calculators/LateralCalculatorHandler';

@Injectable({
  providedIn: 'root',
})
export class LateralService extends HttpHandler {
  private url = 'calculator/lateral';
  lateralCalculatorHandler = new LateralCalculatorHandler()
  constructor(
    public router: Router,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService,
    public offlineService: OfflineService
  ) {
    super(_http, httpService, errorService);
  }

  getModelOptions(emitter_type) {
    const url = this.url + '/models';
    let params = new HttpParams({
      fromObject: {
        type: emitter_type,
      },
    });

    return this.get(url, params);
  }

  getFlowRateOptionsForEmitterSection(emitter_type, model) {
    const url = this.url + '/flowrates';
    let params = new HttpParams({
      fromObject: {
        type: emitter_type,
        model,
      },
    });

    return this.get(url, params);
  }

  getFlowRateOptionsForEmitterInlineEmitters(
    emitter_type,
    model,
    flow_rate,
    nominal_diameters,
    wall_thickness
  ) {
    const url = this.url + '/charactaristics/inline';
    let body = {
      type: emitter_type,
      model,
      flow_rate,
      nominal_diameters,
      wall_thickness,
    };

    return this.post(url, body);
  }

  getFlowRateOptionsForEmitterOnlineEmitters(
    pipe_material,
    class_,
    nominal_diameter,
    emitter_type
  ) {
    const url = this.url + '/charactaristics/online';
    let params = new HttpParams({
      fromObject: {
        type: pipe_material,
        nominal_diameter,
        class: class_,
        emitter_type,
      },
    });

    return this.get(url, params);
  }

  getInternalDiameter(
    is_inline_emitter,
    type,
    model,
    flow_rate,
    wall_thickness,
    pipe_material,
    nominal_diameter
  ) {
    const url = this.url + '/pipediameter';
    let body = {
      is_inline_emitter,
      type,
      pipe_material,
      model,
      flow_rate,
      nominal_diameter,
    };
    if (is_inline_emitter) {
      body['wall_thickness'] = wall_thickness;
    } else {
      body['pipe_class'] = wall_thickness;
    }

    return this.post(url, body);
  }

  calculate(body) {
    const url = this.url;
    return this.post(url, body);
  }

  getLastEmitterPressure(body) {
    let url = 'calculator/lateral/emitter';
    return this.post(url, body);
  }


  //---------------------------- OFFLINE METHODS -----------------------------------
  //Offline getModelOptions
  async getModelOptionsOffline(emitter_type) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    //build request body for handler
    let request = {
      user,
      isOffline: this.offlineService.isItOfflineMode || false,
      emitterType: emitter_type
    }
    let result = await this.lateralCalculatorHandler.getEmitterModelsByType(request)
    let response = this.lateralCalculatorHandler.buildEmitterModelsResult(result)
    return { result: response, success: response.models ? true : false }
  }
  //Offline getFlowRateOptionsForEmitterSection
  async getFlowRateOptionsForEmitterSectionOffline(emitter_type, model) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    let request = {
      user,
      isOffline: this.offlineService.isItOfflineMode || false,
      emitterType: emitter_type,
      model
    }
    let result = await this.lateralCalculatorHandler.getEmitterFlowRates(request)
    let response = this.lateralCalculatorHandler.buildEmitterFlowRatesResult(result)
    return { result: response, success: response.flow_rates ? true : false }
  }

  //Offline getFlowRateOptionsForEmitterInlineEmitters
  async getFlowRateOptionsForEmitterInlineEmittersOffline(
    emitter_type,
    model,
    flow_rate,
    nominal_diameters,
    wall_thickness
  ) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    let request = {
      user,
      emitterType: emitter_type,
      model,
      lateralCharacs: {
        flowRate: flow_rate,
        nominalDiameters: nominal_diameters,
        wallThickness: wall_thickness,
        pClass: undefined,
        pipeMaterial: undefined,
        isOffline: this.offlineService.isItOfflineMode || false,
      }
    }
    let result = await this.lateralCalculatorHandler.getLateralCharactaristicsForInlineEmitters(request)
    let response = this.lateralCalculatorHandler.buildLateralCharasteristicsResult(result)
    return { result: response, success: response.lateral_chars ? true : false }
  }

  async getFlowRateOptionsForEmitterOnlineEmittersOffline(
    pipe_material,
    class_,
    nominal_diameter,
    emitter_type
  ) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    let request = {
      user,
      emitterType: emitter_type,
      sectionCharac: {
        diameter: nominal_diameter,
        class: class_,
        type: pipe_material,
        isOffline: this.offlineService.isItOfflineMode || false,
      },

    }
    let result = await this.lateralCalculatorHandler.getSectionCharactaristicsForOnlineEmitters(request)
    let response = this.lateralCalculatorHandler.buildPipesSectionCharactaristicsResult(result)
    return { result: response, success: response.pipes_section_charactaristics ? true : false }
  }

  async getInternalDiameterOffline(
    is_inline_emitter,
    type,
    model,
    flow_rate,
    wall_thickness,
    pipe_material,
    nominal_diameter
  ) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    //build request body for handler
    let request = {
      user,
      filters: {
        isInlineEmitter: is_inline_emitter,
        type, model,
        flowRate: flow_rate,
        wallThickness: is_inline_emitter ? wall_thickness : undefined,
        pipeMaterial: pipe_material,
        pipeClass: !is_inline_emitter ? wall_thickness : undefined,
        nominalDiameter: nominal_diameter,
        isOffline: this.offlineService.isItOfflineMode || false
      }
    }
    let result = await this.lateralCalculatorHandler.getLateralInternalDiameter(request)
    return { result: this.lateralCalculatorHandler.buildInternalDiameterResult(result), success: result.internalDiameter ? true : false }

  }
  async calculateOffline(body) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    //build request body for handler
    let request = {
      calculationData: {
        calcType: body.calculation_type,
        frictionFormula: body.friction_loss_formula,
        pipeRoughnessChw: body.pipe_roughness_chw,
        isFlushingMode: body.is_flushing || false,
        isAtmospherePressure: body.is_atmosphere_pressure || false,
        isCollectorPressure: body.is_collector_pressure || false,
        flushingPressure: body.pressure_for_flushing ? Number(body.pressure_for_flushing.toString()) : null,
        flushingVelocity: body.flushing_velocity ? Number(body.flushing_velocity.toString()) : null,
        maxAllowedPressureLoss: body.max_allowed_pressure_loss,
        maxAllowedFlowVariation: body.max_allowed_flow_variation,
        maxAllowedEmissionUniformity: body.max_allowed_emission_uniformity,
        emitterChars: body.emitter_characteristics,
        blockChars: body.block_characteristics,
        topographyChars: body.topography_characteristics,
        pipeChars: body.lateral_characteristics,
        isOffline: this.offlineService.isItOfflineMode || false
      },
      user
    };
    let result = await this.lateralCalculatorHandler.calculate(request)
    let response = this.lateralCalculatorHandler.buildCalculationResult(result)
    return { result: response, success: response.calculation_success }
  }
  //getLastEmitterPressure offline
  async getLastEmitterPressureOffline(body) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    let request = {
      user,
      filters: {
        isInlineEmitter: body.is_inline_emitter,
        type: body.type,
        model: body.model,
        flowRate: Number(body.flow_rate),
        wallThickness: body.wall_thickness ? body.wall_thickness : null, //only from driplines
        nominalDiameter: body.nominal_diameter ? Number(body.nominal_diameter) : null,//only from driplines
        isOffline: this.offlineService.isItOfflineMode || false,
      },

    }
    let result = await this.lateralCalculatorHandler.getLateralEmitterPressures(request)
    let response = this.lateralCalculatorHandler.buildEmitterPressuresResult(result)
    return { result: response, success: response.last_emitter_pressures ? true : false }


  }
}
