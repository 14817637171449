import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { CalculatorService } from 'src/app/services/calculator.service';
import { DataService } from 'src/app/services/data.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { MainlineService } from 'src/app/services/mainline.service';
import { OfflineService } from 'src/app/services/offline.service';
import { TextService } from 'src/app/services/text.service';
import { UnitsConverterService } from 'src/app/services/units-converter.service';
import { Eunits, Ecalculator, EmainlineSteps, EtransaltionGroups, EqueryTypes } from 'src/app/shared/enum';
import { CALCULATOR_SECTION } from 'src/app/shared/consts';
import { CalculatorSection } from 'src/app/shared/types';

@Component({
  selector: 'app-mainline-calculator-page',
  templateUrl: './mainline-calculator-page.component.html',
  styleUrls: ['./mainline-calculator-page.component.scss']
})
export class MainlineCalculatorPageComponent implements OnInit {
  //general
  user_id //for analytics events
  CALCULATOR_SECTION = CALCULATOR_SECTION
  EmainlineSteps = EmainlineSteps;
  Ecalculator = Ecalculator
  EtransaltionGroups = EtransaltionGroups
  EqueryTypes = EqueryTypes
  Eunits = Eunits
  mySubscription: any;
  show_section_mobile: CalculatorSection = CALCULATOR_SECTION.QUESTION
  str_id_step_text = [
    'mainline_calculation_Q_title',
    'mainline_pipe_charcteristics_title',
    'mainline_field_elevation_title',
  ];

  //advanced-setting
  selectedCalculationMethod: any = '1';
  selectedFlushingMode: any = 'no';
  flushingValue: FormControl;
  selectedPipeRoughness: any = '1';
  pipe_roughness_value: FormControl;
  addSectionForFlushing: any = 'no';
  flushing_with_closed_valves: any = 'yes'

  //claculatopn questions 
  QuestionsConfig: any[] = [];
  questionSelectedIndex; //0 -Q1 (pressure loss), 1 - Q2 (pipes diameters)

  //pipe characteristics
  currentMainlineSectionsForms: any[] = [];
  mainlineSectionsForIlustrations: any[] = []; //with flushing section
  pressureForm: FormGroup;
  FlushingSectionForm: FormGroup
  pipeTypeOptions: any = []
  pipeClassOptions = []
  nominalDiametersOptions = []

  //elevation section
  currentSlopeForms: any[] = [];

  //result
  show_result_button = false
  bodyRequestForCalculate;
  displayedColumns: string[] = []
  tableDisplay = [];
  elevationTablePDF = [];
  calculator_results: any;

  setResultText() {
    this.tableDisplay = [
      {
        display: this.textService.getText(
          EtransaltionGroups.MAINLINE,
          'mainline_results_section_label'
        ),
        name: 'pipe_section',
        key: '',
      },
      {
        display: this.textService.getText(
          EtransaltionGroups.MAINLINE,
          'mainline_results_material_type_label'
        ),
        name: 'material_type',
        key: 'Type',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.MAINLINE,
            'mainline_results_nominal_diameter_label'
          ),
          'mm'
        ),
        name: 'nominal_diameter',
        key: 'NominalDiameter',
      },
      {
        display: this.textService.getText(
          EtransaltionGroups.MAINLINE,
          'mainline_results_class_label'
        ),
        name: 'pipe_class',
        key: 'Class',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.MAINLINE,
            'mainline_results_length_label'
          ),
          'm'
        ),
        name: 'length',
        key: 'SectionLength',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_pipe_sections_pressure_loss'
          ),
          'm_pressure'
        ),
        name: 'pressure_loss',
        key: 'PressureLoss',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.MAINLINE,
            'mainline_results_inlet_pressure_label'
          ),
          'm_pressure'
        ),
        name: 'inlet_pressure',
        key: 'InletPressure',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.MAINLINE,
            'mainline_results_end_pressure_label'
          ),
          'm_pressure'
        ),
        name: 'end_pressure',
        key: 'EndPressure',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.MAINLINE,
            'mainline_results_max_pressure_label'
          ),
          'm_pressure'
        ),
        name: 'max_pressure',
        key: 'MaxPressure',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.MAINLINE,
            'mainline_results_min_pressure_label'
          ),
          'm_pressure'
        ),
        name: 'min_pressure',
        key: 'MinPressure',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.MAINLINE,
            'mainline_results_flow_rate_label'
          ),
          'm3h'
        ),
        name: 'flow_rate',
        key: 'AtomicFlowRate',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.MAINLINE,
            'mainline_results_accumulated_flow_rate_label'
          ),
          'm3h'
        ),
        name: 'accumulated_flow_rate',
        key: 'MaxFlowRate',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_results_pipe_sections_max_velocity'
          ),
          'ms'
        ),
        name: 'velocity',
        key: 'MaxVelocity',
      },
    ];
    this.displayedColumns = [
      'pipe_section',
      'material_type',
      'nominal_diameter',
      'pipe_class',
      'length',
      'pressure_loss',
      'inlet_pressure',
      'end_pressure',
      'max_pressure',
      'min_pressure',
      'flow_rate',
      'accumulated_flow_rate',
      'velocity'
    ];
    if (this.dataService.selectedTopography === 'Flat') {
      this.tableDisplay.splice(8, 2)
      this.displayedColumns.splice(8, 2)
    }
    this.elevationTablePDF = [
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.SUBMAIN,
            'submain_summary_length_on_map'
          ),
          'm'
        ),
        key: 'mapLength',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.SUBMAIN,
            'submain_summary_hight_dif_meters'
          ),
          'm'
        ),
        key: 'heightDiffM',
      },
      {
        display: this.textService.getText(
          EtransaltionGroups.SUBMAIN,
          'submain_summary_hight_dif_precent'
        ),
        key: 'heightDiffP',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.SUBMAIN,
            'submain_summary_real_length'
          ),
          'm'
        ),
        key: 'realLength',
      },
    ];
  }

  constructor(public dataService: DataService, private fb: FormBuilder,
    public calculatorService: CalculatorService, public textService: TextService,
    public router: Router, public mainlineService: MainlineService,
    public untisConvertorService: UnitsConverterService, public analyticsService: GoogleAnalyticsService,
    public offlineService: OfflineService) {
    //calcluate mainline length
    this.dataService.calculateTotalLengthMainline.subscribe(val => {
      this.CalculateMainlineLength()
    })
    this.dataService.updateMainlineSectionsForIlustration.subscribe(val => {
      this.updateMainlineSectionsForIlustration()
    })
    this.dataService.resetMainlieCalculator.subscribe((res) => {
      this.dataService.selectedTopography = 'Flat';
      this.router.navigateByUrl('calc/mainline');
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit(): void {
    //init boolean inputs
    this.dataService.inputs_contents_has_changed = false;
    this.dataService.showAlertMessage = false;
    this.dataService.alert_msg_text = ''
    this.dataService.showAlertInRed = true

    //get page data
    this.getPageData();

    //initialize questions config
    this.initializeQuestionsConfig()

    //initialize pipe charcteristics
    this.initPressureForm();
    this.currentMainlineSectionsForms = [];
    this.addPipeSection();
    this.dataService.sectionAchosenPipeClassMainline = ''
    this.dataService.sectionAchosenPipeTypeMainline = ''

    //initialize elevation
    this.initializeElevetaion()


  }
  async getPageData() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = await this.calculatorService.getCalculatorPageDataMainlieOffline()
      this.dataService.showLoader = false
      this.setResponsePageData({ result: response, success: response.page_data ? true : false })
    } else {
    }
    this.calculatorService.getCalculatorPageDataMainlie().subscribe((resp) => {
      this.dataService.showLoader = false;
      this.setResponsePageData(resp)
    });

  }

  setResponsePageData(resp) {
    if (resp.success) {
      //analyticsEvents

      this.user_id = resp.result.page_data.user.id
      this.analyticsEvent()
      this.dataService.user_name =
        resp.result.page_data.user.first_name +
        ' ' +
        resp.result.page_data.user.last_name;
      this.dataService.regions_list =
        resp.result.page_data.regions;

      this.dataService.selected_region = this.dataService.regions_list.find(
        (value) => {
          return (
            resp.result.page_data.user.region ===
            value.region_id
          );
        }
      );
      this.dataService.selected_units = this.dataService.units_list.find(
        (value) => {
          return (
            value.value ===
            Number(resp.result.page_data.user.units)
          );
        }
      );
      this.dataService.languages_list =
        resp.result.page_data.languages;
      this.dataService.selected_language =
        this.dataService.languages_list.find((value) => {
          return (
            value.row_id ===
            resp.result.page_data.user.language.toString()
          );
        });
      this.pipeTypeOptions = resp.result.page_data.mainline_charactaristics_data.types
      this.pipeClassOptions = resp.result.page_data.mainline_charactaristics_data.classes
      this.nominalDiametersOptions = resp.result.page_data.mainline_charactaristics_data.nominal_diameters
      this.getTexts();
      //initialize form controls for flushing and pipe roghuness
      this.initAdvancedOptions()

      //initialize questions config
      this.initializeQuestionsConfig()
    }
  }

  analyticsEvent() {
    if (localStorage.getItem('lateral_view')) {
      let views_count_by_user = Number(localStorage.getItem('lateral_view'));
      if (views_count_by_user == 1) {
        this.analyticsService.sendEvent(
          'mainline',
          'recurring_user_second_time',
          'recurring_user', this.user_id
        );
      }
      if (views_count_by_user == 4) {
        this.analyticsService.sendEvent(
          'mainline',
          'recurring_user_fifth_time',
          'recurring_user', this.user_id
        );
      }
      views_count_by_user++;
      localStorage.setItem('mainline_view', views_count_by_user.toString());
    } else {
      localStorage.setItem('mainline_view', '1');
    }
  }
  getTexts() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = this.textService
        .getResourceTextOffline()
      this.setTextResponse({ result: response, success: response ? true : false })
      this.dataService.showLoader = false;
    } else {
      this.textService
        .getResourceText(this.dataService.selected_language.row_id)
        .subscribe((res) => {
          this.dataService.showLoader = false;
          this.setTextResponse(res)
        });
    }
  }
  setTextResponse(res) {
    if (res.success) {
      this.textService.translation_text = res.result.translations ? res.result.translations : res.result;
      localStorage.setItem(
        'resource_txt',
        JSON.stringify(this.textService.translation_text)
      );
      this.textService.setUnitsDictionary();

      this.dataService.logic_text_or = this.textService
        .getText(this.EtransaltionGroups.SUBMAIN, 'submain_or_1')
        .toString();
    }
  }
  initializeElevetaion() {
    this.dataService.selectedTopography = 'Flat'
    this.dataService.total_length_changed = false
  }

  initAdvancedOptions() {
    this.flushingValue = new FormControl('', {
      validators: [],
      updateOn: 'blur',
    });
    this.pipe_roughness_value = new FormControl('', {
      validators: [],
      updateOn: 'blur',
    });
  }
  initializeQuestionsConfig() {
    this.QuestionsConfig = [
      {
        title: this.textService.getText(
          EtransaltionGroups.MAINLINE,
          'mainline_calcualtion_Q_pressure'
        ),
        subTitle: this.textService.getText(
          EtransaltionGroups.MAINLINE,
          'mainline_calcualtion_Q_pressure_subtitle'
        ),
        placeholder: '',
        hint: '',
        isSelected: false,
        isSimple: true,
        control: new FormControl('', {
          validators: [Validators.requiredTrue],
          updateOn: 'blur',
        }),
      }
      , {
        title: this.textService.getText(
          EtransaltionGroups.MAINLINE,
          'mainline_calcualtion_Q_diameters_title'
        ),
        subTitle: this.textService.getText(
          EtransaltionGroups.MAINLINE,
          'mainline_calcualtion_Q_diameters_subtitle'
        ),
        isSelected: false,
        isSimple: false,
        control: new FormControl('', {
          validators: [
            Validators.required,
            Validators.min(UnitsConverterService.metersPerSecondToFtPerSecond(0.1, this.dataService.selected_units.value == this.Eunits.us_untis)),
            Validators.max(UnitsConverterService.metersPerSecondToFtPerSecond(4, this.dataService.selected_units.value == this.Eunits.us_untis)),
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          ],
          updateOn: 'blur',
        }),
        placeholder:
          this.textService.addMesaureUnitsToLabel(
            this.textService.getText(
              EtransaltionGroups.MAINLINE,
              'mainline_calcualtion_max_allowed_velocity_label'
            ),
            'ms'
          ),
        control_2: new FormControl('', {
          validators: [
            Validators.required,
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.min(UnitsConverterService.metersToPSI(0.1, this.dataService.selected_units.value == this.Eunits.us_untis)),
            Validators.max(UnitsConverterService.metersToPSI(30, this.dataService.selected_units.value == this.Eunits.us_untis)),
          ],
          updateOn: 'blur',
        }),
        placeholder_2: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.MAINLINE,
            'mainline_calcualtion_max_allowed_pressure_label'
          ),
          'm'
        ),
        hide: true //hide query pipes diameter for maninline
      }

    ];
  }
  updateSelectedQuestion(event, index) {
    for (let i = 0; i < this.QuestionsConfig.length; i++) {
      this.QuestionsConfig[i].control.reset();
      if (this.QuestionsConfig[i].control_2) {
        this.QuestionsConfig[i].control_2.reset();
      }
      if (event == false && index == i) {
        // this.changeQuery = true;
        this.QuestionsConfig[i].isSelected = true;
        this.questionSelectedIndex = i;
      } else {
        this.QuestionsConfig[i].isSelected = false;
      }
      this.QuestionsConfig[0].control.setValue(
        this.QuestionsConfig[0].isSelected
      );
      this.dataService.inputs_contents_has_changed = true
    }
    this.updateMainlineSectionsData()
  }

  changeFlushingMode(value) {
    this.selectedFlushingMode = value;
    this.dataService.inputs_contents_has_changed = true
  }

  changeCalculationMethod(value) {
    this.selectedCalculationMethod = value;
    this.dataService.inputs_contents_has_changed = true
  }

  changePipeRoughnessSelection(value) {
    this.selectedPipeRoughness = value;
    this.dataService.inputs_contents_has_changed = true
  }
  changeAddSectionForFlushingSelection(value) {
    this.addSectionForFlushing = value;
    if (this.addSectionForFlushing == 'yes') {
      this.initFlushingSectionForm()
    } else {
      this.FlushingSectionForm = undefined;
      this.CalculateMainlineLength()
    }
    this.dataService.inputs_contents_has_changed = true
  }
  changeFlushingIsValveClosed(value) {
    this.flushing_with_closed_valves = value;
    this.dataService.inputs_contents_has_changed = true
  }
  initPressureForm() {
    this.pressureForm = this.fb.group({
      end_pressure: [
        '',
        {
          validators: [Validators.compose([
            Validators.required,
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.min(UnitsConverterService.metersToPSI(1, this.dataService.selected_units.value == this.Eunits.us_untis)),
            Validators.max(UnitsConverterService.metersToPSI(1000, this.dataService.selected_units.value == this.Eunits.us_untis))
          ])],
          updateOn: 'blur',
        },
      ],
      inlet_pressure: [
        '',
        {
          validators: [Validators.compose([
            Validators.required,
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.min(UnitsConverterService.metersToPSI(1, this.dataService.selected_units.value == this.Eunits.us_untis)),
            Validators.max(UnitsConverterService.metersToPSI(1000, this.dataService.selected_units.value == this.Eunits.us_untis))
          ])],
          updateOn: 'blur',
        },
      ],
    });
  }
  initFlushingSectionForm() {
    this.FlushingSectionForm = this.fb.group({
      pipe_material: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      nominal_diameter: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      pipe_class: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      internal_diameter: [
        '',
        {
          validators: [
            Validators.required,
            Validators.pattern(
              '[-+]?[0-9]+(.[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]?)?'
            ),

            Validators.min(UnitsConverterService.mmToInch(0.05, this.dataService.selected_units.value == this.Eunits.us_untis)),
            Validators.max(UnitsConverterService.mmToInch(100, this.dataService.selected_units.value == this.Eunits.us_untis)),
          ],
          updateOn: 'blur',
        },
      ],
      roughness: [
        '',
        {
          validators: [
            Validators.required,
            Validators.pattern(
              '[-+]?[0-9]+(.[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]?)?'
            ),

            Validators.min(0.05),
            Validators.max(155),
          ],
          updateOn: 'blur',
        },
      ],
      length: [
        '',
        {
          validators: [
            Validators.required,
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.min(UnitsConverterService.metersToFt(0.05, this.dataService.selected_units.value == this.Eunits.us_untis)),
            Validators.max(UnitsConverterService.metersToFt(1000, this.dataService.selected_units.value == this.Eunits.us_untis)),
          ],
          updateOn: 'blur',
        },
      ],
      section_flow_rate: [
        0,
        {
          validators: [],
          updateOn: 'blur',
        },
      ],
    })
    this.updateMainlineSectionsForIlustration()
  }
  addPipeSection() {
    let section = this.fb.group({
      pipe_material: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      nominal_diameter: [
        '',
        {
          validators: this.questionSelectedIndex == EqueryTypes.pipes_diameter ? [] : [Validators.required],
          updateOn: 'blur',
        },
      ],
      pipe_class: [
        '',
        {
          validators: this.questionSelectedIndex == EqueryTypes.pipes_diameter ? [] : [Validators.required],
          updateOn: 'blur',
        },
      ],
      internal_diameter: [
        '',
        {
          validators: this.questionSelectedIndex == EqueryTypes.pipes_diameter ? [] : [
            Validators.required,
            Validators.pattern(
              '[-+]?[0-9]+(.[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]?)?'
            ),
            Validators.min(UnitsConverterService.mmToInch(0.05, this.dataService.selected_units.value == this.Eunits.us_untis)),
            Validators.max(UnitsConverterService.mmToInch(100, this.dataService.selected_units.value == this.Eunits.us_untis)),
          ],
          updateOn: 'blur',
        },
      ],
      roughness: [
        '',
        {
          validators: this.questionSelectedIndex == EqueryTypes.pipes_diameter ? [] : [
            Validators.required,
            Validators.pattern(
              '[-+]?[0-9]+(.[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]?)?'
            ),

            Validators.min(0.05),
            Validators.max(155),
          ],
          updateOn: 'blur',
        },
      ],
      length: [
        '',
        {
          validators: [
            Validators.required,
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.min(UnitsConverterService.metersToFt(0.1, this.dataService.selected_units.value == this.Eunits.us_untis)),


          ],
          updateOn: 'blur',
        },
      ],
      section_flow_rate: [
        '',
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
              Validators.min(UnitsConverterService.cubicToGalon(0, this.dataService.selected_units.value == this.Eunits.us_untis)),
              Validators.max(UnitsConverterService.cubicToGalon(100000, this.dataService.selected_units.value == this.Eunits.us_untis)),
            ])
          ],
          updateOn: 'blur',
        },
      ],
    })
    this.currentMainlineSectionsForms.push(section)
    this.dataService.inputs_contents_has_changed = true
  }
  deleteSection(index) {
    if (this.currentMainlineSectionsForms.length > 1) {
      this.currentMainlineSectionsForms.splice(index, 1);
    } else {
      this.currentMainlineSectionsForms[0].patchValue(
        {
          pipe_material: '',
          nominal_diameter: '',
          pipe_class: '',
          internal_diameter: '',
          roughness: '',
          length: '',
          section_flow_rate: ''
        },
        { emitEvent: false }

      );

    }
    this.CalculateMainlineLength()
    this.updateMainlineSectionsForIlustration()
    this.dataService.inputs_contents_has_changed = true
  }

  CalculateMainlineLength() {
    this.dataService.totalLengthMainline = 0
    this.currentMainlineSectionsForms.forEach(section => {
      this.dataService.totalLengthMainline = Number(this.dataService.totalLengthMainline) + Number(section.controls.length.value)
    })
    if (this.FlushingSectionForm && this.FlushingSectionForm.controls.length.value) {
      this.dataService.totalLengthMainline = Number(this.dataService.totalLengthMainline) + Number(this.FlushingSectionForm.controls.length.value)
    }
    this.updateMainlineSectionsForIlustration()
    this.eleveationUpdateLength()
  }
  updateMainlineSectionsForIlustration() {
    if (this.FlushingSectionForm) {
      this.mainlineSectionsForIlustrations = this.currentMainlineSectionsForms.concat(this.FlushingSectionForm)
    } else {
      this.mainlineSectionsForIlustrations = this.currentMainlineSectionsForms
    }
    setTimeout(() => {
      this.dataService.reloadSectionsIlustration.next();
    });
  }
  isPipeSectionsValid() {
    let boolean = true;
    this.currentMainlineSectionsForms.forEach((form) => {
      boolean = boolean && form.valid;
    });
    if (this.FlushingSectionForm) {
      boolean = boolean && this.FlushingSectionForm.valid
    }

    return boolean;
  }
  addSlope() {
    let slopeForm: FormGroup = this.fb.group({
      mapLength: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(UnitsConverterService.metersToFt(0, this.dataService.selected_units.value == this.Eunits.us_untis)),
            Validators.max(UnitsConverterService.metersToFt(1000, this.dataService.selected_units.value == this.Eunits.us_untis)),
          ],
          updateOn: 'blur',
        },
      ],
      heightDiffM: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
          ],
          updateOn: 'blur',
        },
      ],
      heightDiffP: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(-100),
            Validators.max(100),
          ],
          updateOn: 'blur',
        },
      ],
      realLength: [
        '',
        {
          validators: [Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?')],
          updateOn: 'blur',
        },
      ],
    });
    this.currentSlopeForms.push(slopeForm);
    this.dataService.inputs_contents_has_changed = true
  }
  deleteSlope(index) {
    this.currentSlopeForms.splice(index, 1);
    this.dataService.inputs_contents_has_changed = true
  }
  deleteAllSlopes() {
    this.currentSlopeForms.splice(0, this.currentSlopeForms.length);
    this.dataService.reloadSlopesIlustration.next();
    this.dataService.inputs_contents_has_changed = true
  }
  isSlopesFormValid() {
    let bool = true;
    this.currentSlopeForms.forEach((element) => {
      bool = bool && element.valid;
    });
    if (this.dataService.selectedTopography === 'Multiple Elevations') {
      bool =
        bool &&
        Number(this.dataService.totalSlopesLength) ===
        Number(this.dataService.totalLengthMainline);
    }
    return bool;
  }
  clearFieldElevation() {
    this.currentSlopeForms = [];
    this.dataService.selectedTopography = 'Flat';
    this.dataService.changeMulitpleToggleButtonChoise.next('Flat');
    this.dataService.showClearPopup = false;
  }
  eleveationUpdateLength() {
    if (this.dataService.selectedTopography === 'Fixed Slope') {
      this.currentSlopeForms[0].controls.mapLength.setValue(this.dataService.totalLengthMainline, { emitEvent: true })
    }
    this.dataService.reloadSlopesIlustration.next();
    setTimeout(() => {
      this.dataService.validationForMapLengthSlope.next();
    }, 0);
  }

  async calculate() {
    this.analyticsService.sendEvent(
      'mainline',
      'mainline_calculate',
      'calculate_button', this.user_id
    );
    //initialize result text
    this.setResultText()

    this.createBodyForCalculateRequest();
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = await this.mainlineService.calculateOffline(this.bodyRequestForCalculate)
      this.dataService.showLoader = false;
      this.setCalculateResponse(response)
    } else {
      this.mainlineService.calculate(this.bodyRequestForCalculate).subscribe(resp => {
        this.dataService.showLoader = false;
        this.setCalculateResponse(resp)
      },
        (err) => {
          this.dataService.showLoader = false;
          if (err.message) {
            this.dataService.error_result_msg = err.message;
          }
          if (err.ErrorToClient) {
            this.dataService.error_result_msg = err.ErrorToClient;
          }
          this.dataService.showErrorPopup = true;
        })
    }
  }

  setCalculateResponse(resp) {
    this.show_result_button = true;
    this.dataService.inputs_contents_has_changed = false
    if (resp.success) {
      const element = document.querySelector('#scroll');
      element.scrollIntoView();
      let first_time_calculate = this.calculator_results ? false : true;
      this.calculator_results = undefined;
      this.calculator_results = resp.result;
      setTimeout(() => {
        this.dataService.reloadIlustrationResult.next();
        this.dataService.reloadGraphResult.next();
      });

      this.dataService.reloadPipes.next();
      if (!first_time_calculate) {
        this.dataService.reloadResults.next();
      }
    }
  }
  createBodyForCalculateRequest() {
    this.bodyRequestForCalculate = {};
    this.bodyRequestForCalculate.calculation_type =
      this.questionSelectedIndex + 1;
    this.bodyRequestForCalculate.flushing_velocity = Number(
      this.flushingValue.value
    );
    this.bodyRequestForCalculate.is_flushing =
      this.selectedFlushingMode == 'yes';
    if (this.bodyRequestForCalculate.is_flushing) {
      this.bodyRequestForCalculate.addition_section_for_flushing = this.addSectionForFlushing == 'yes'
      this.bodyRequestForCalculate.is_flushing_with_closed_valves = this.flushing_with_closed_valves == 'yes'
    }

    this.bodyRequestForCalculate.friction_loss_formula = Number(
      this.selectedCalculationMethod
    );

    //query conditions
    if (this.questionSelectedIndex === EqueryTypes.pipes_diameter) {
      this.bodyRequestForCalculate.max_allowed_pressure_loss = Number(this.QuestionsConfig[this.questionSelectedIndex].control_2.value)
      this.bodyRequestForCalculate.max_allowed_velocity = Number(this.QuestionsConfig[this.questionSelectedIndex].control.value)
    }

    //block-characteristcs
    this.bodyRequestForCalculate.block_characteristics = {
      inlet_pressure: Number(this.pressureForm.controls.inlet_pressure.value),
      end_pressure: Number(this.pressureForm.controls.end_pressure.value)
    };

    //topography_characteristics
    let slopes = [];
    this.bodyRequestForCalculate.topography_characteristics = slopes;
    if (this.currentSlopeForms.length === 0) {
      slopes.push({
        length_on_map: Number(this.dataService.totalLengthMainline),
        height_diff_meters: 0,
        heigth_diff_precent: 0,
        real_length: Number(this.dataService.totalLengthMainline),
      });
    } else {
      this.currentSlopeForms.forEach((slope) => {
        slopes.push({
          length_on_map: Number(slope.get('mapLength').value),
          height_diff_meters: Number(slope.get('heightDiffM').value),
          heigth_diff_precent: Number(slope.get('heightDiffP').value),
          real_length: Number(slope.get('realLength').value),
        });
      });
    }

    //pipe_characteristics
    let sections = [];
    this.mainlineSectionsForIlustrations.forEach((section) => {
      let sectionJsonData = {
        type: section.controls.pipe_material.value,
        pipe_class: section.controls.pipe_class.value,
        nominal_diameter: Number(section.controls.nominal_diameter.value?.value),
        internal_diameter: Number(section.controls.internal_diameter.value),
        roughness: Number(section.controls.roughness.value),
        section_length: Number(section.controls.length.value),
        section_flow_rate: section.controls.section_flow_rate.value ? Number(section.controls.section_flow_rate.value) : 0
      };
      sections.push(sectionJsonData);
    });
    this.bodyRequestForCalculate.pipe_characteristics = sections;
  }

  scrollTopResult() {
    const element = document.querySelector('#scroll');
    element.scrollIntoView();
  }

  updateMainlineSectionsData() {
    if (this.currentMainlineSectionsForms) {
      switch (this.questionSelectedIndex) {
        case EqueryTypes.pressure_loss:
          this.currentMainlineSectionsForms.forEach(section => {
            section.controls.pipe_class.setValidators([
              Validators.compose([
                Validators.required,
              ])]);
            section.controls.nominal_diameter.setValidators([
              Validators.compose([
                Validators.required,
              ])]);
            section.controls.internal_diameter.setValidators([
              Validators.compose([
                Validators.required,
                Validators.pattern(
                  '[-+]?[0-9]+(.[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]?)?'
                ),
                Validators.min(UnitsConverterService.mmToInch(0.05, this.dataService.selected_units.value == this.Eunits.us_untis)),
                Validators.max(UnitsConverterService.mmToInch(100, this.dataService.selected_units.value == this.Eunits.us_untis)),
              ])]);
            section.controls.roughness.setValidators([
              Validators.compose([
                Validators.required,
                Validators.pattern(
                  '[-+]?[0-9]+(.[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]?)?'
                ),
                Validators.min(0.05),
                Validators.max(155),
              ])]);
            section.controls.pipe_class.reset()
            section.controls.nominal_diameter.reset()
            section.controls.internal_diameter.reset()
            section.controls.roughness.reset()
            section.controls.nominal_diameter.updateValueAndValidity({
              emitEvent: false,
            });
            section.controls.internal_diameter.updateValueAndValidity({
              emitEvent: false,
            });
            section.controls.roughness.updateValueAndValidity({
              emitEvent: false,
            });
            section.controls.pipe_class.updateValueAndValidity({
              emitEvent: false,
            });
            this.dataService.updateClassOptions.next()
          })
          break;
        case EqueryTypes.pipes_diameter:
          //no flushing section for pipes diameter
          this.addSectionForFlushing = 'no'
          this.FlushingSectionForm = undefined;
          this.CalculateMainlineLength()
          this.dataService.alert_msg_text = this.textService.getText(this.EtransaltionGroups.MAINLINE, 'mainline_warning_flushing_section_pipes_diameter')
          this.dataService.showAlertMessage = true;
          this.dataService.showAlertInRed = false


          this.currentMainlineSectionsForms.forEach(section => {
            section.controls.pipe_class.clearValidators()
            section.controls.nominal_diameter.clearValidators()
            section.controls.internal_diameter.clearValidators()
            section.controls.roughness.clearValidators()
            section.controls.pipe_class.reset()
            section.controls.nominal_diameter.reset()
            section.controls.internal_diameter.reset()
            section.controls.roughness.reset()
            section.controls.nominal_diameter.updateValueAndValidity({
              emitEvent: false,
            });
            section.controls.internal_diameter.updateValueAndValidity({
              emitEvent: false,
            });
            section.controls.roughness.updateValueAndValidity({
              emitEvent: false,
            });
            section.controls.pipe_class.updateValueAndValidity({
              emitEvent: false,
            });

          })
          break;
        default:
          break;
      }

    }
  }

  nextStepMobile() {
    switch (this.show_section_mobile) {
      case this.CALCULATOR_SECTION.QUESTION:
        this.show_section_mobile = this.CALCULATOR_SECTION.PIPE;
        this.scrollToTop()
        break;
      case this.CALCULATOR_SECTION.PIPE:
        this.show_section_mobile = this.CALCULATOR_SECTION.ELEVATION;
        this.scrollToTop()
        break;
      case this.CALCULATOR_SECTION.ELEVATION:
        this.show_section_mobile = this.CALCULATOR_SECTION.RESULT;
        this.calculate();
        break;
      default:
        break;
    }
  }

  validationByStep() {
    switch (this.show_section_mobile) {
      case this.CALCULATOR_SECTION.QUESTION:
        return (
          (!this.QuestionsConfig[1].control.valid ||
            !this.QuestionsConfig[1]?.control_2?.valid) &&
          !this.QuestionsConfig[0].control.valid
        );
        break;
      case this.CALCULATOR_SECTION.PIPE:
        return !this.isPipeSectionsValid() || !this.pressureForm.valid
        break;
      case this.CALCULATOR_SECTION.ELEVATION:
        return !(
          this.dataService.selectedTopography === 'Flat' ||
          this.isSlopesFormValid()
        ) || (this.flushingValue && !this.flushingValue?.valid && this.selectedFlushingMode == 'yes')
        break;
      default:
        break;
    }
  }

  backStep() {
    switch (this.show_section_mobile) {
      case this.CALCULATOR_SECTION.PIPE:
        this.show_section_mobile = this.CALCULATOR_SECTION.QUESTION;
        this.scrollToTop()
        break;
      case this.CALCULATOR_SECTION.ELEVATION:
        this.show_section_mobile = this.CALCULATOR_SECTION.PIPE;
        this.scrollToTop()
        break;
      case this.CALCULATOR_SECTION.RESULT:
        this.show_section_mobile = this.CALCULATOR_SECTION.ELEVATION;
        this.scrollToTop()
        break;
      default:
        this.show_section_mobile = this.CALCULATOR_SECTION.QUESTION;
        break;
    }
  }

  scrollToTop() {
    setTimeout(() => {
      const element_elevation = document.querySelector('#scroll_top');
      element_elevation.scrollIntoView({ behavior: "smooth" });
    });
  }
}
