import { PipeSection } from "../../PipeSection";
import { AbsLateralCalculationProcess } from "./AbsLateralCalculationProcess";
import { Segment } from "../../Segment";
import { SlopeSection } from "../../../../calculator/Entities/SlopeSection";
import { LateralCalculationType } from "../../../enums/LateralCalculationType.enum";
import { ECalculationWarnings } from "../../../errors_and_warnings/warnings";
import { ECalculationErrors } from "../../../errors_and_warnings/errors";
import { AbsCalculationProcess } from "../AbsCalculationProcess";

export class PressureLossForAGivenLateral extends AbsLateralCalculationProcess {


    public initSegments(data: any, segments: any, slopes: any, pipes: any, isFlushing: boolean = false) {
        let emitters = data.emitters;
        // Add Slope section propeties to Segments:
        this.addSlopeSectionsToSegments(segments, slopes);
        // Add Pipe section propeties to Segments:
        this.addPipeSectionsToSegments(segments, pipes);
        // Add Emitter section propeties to Segments:
        this.addEmitterSectionsToSegments(segments, emitters);
    }

    public calculate(segments: any, pipes: any, slopes: any, data: any) {
        return this.calcPressureLossForAGivenLateral(segments, pipes, slopes, data);
    }

    protected handleCalculationStops(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false,
            InletPressureUIstop: false
        }
        let { pipes, slopes, calculationType, currSegment, segments, lengthSum,
            isEndPressureCalculation, inletPressureUI, isFlushingMode, index } = data;

        if (calculationType == LateralCalculationType.PRESSURE_LOSS_FOR_SELECTED_LATERAL) {
            // Design/Technical pressures:
            let currSegmentInletPressure = currSegment.InletPressure;
            let currEmitter = currSegment.EmitterSection;


            // Pressures:   
            if (currSegmentInletPressure > currEmitter.MaxAllowedPressure) {
                if (currSegmentInletPressure > currEmitter.MaxTechnicalPressure) {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_TECHNICAL_PRESSURE_WARNING;
                }
                else {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_DESIGN_PRESSURE_WARNING;
                }
            }
            // Minimum pressures:
            if (currSegmentInletPressure < currEmitter.MinAllowedPressure) {
                if (currSegmentInletPressure < currEmitter.MinTechnicalPressure) {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_TECHNICAL_PRESSURE_WARNING;
                }
                else {
                    calcStopsResponse.warning = ECalculationWarnings.EMITTER_DESIGN_PRESSURE_WARNING;
                }
            }
        }

        if (this.isSegmentIsOnLateral(lengthSum, data.spacing)) {
            this.setSegmentTravelTime(currSegment, data.spacing);
        }

        return calcStopsResponse;
    }

    protected postCalcActions(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false
        }
        let { calculationType, segmentsCalcData } = data;
        let minFlowRate = segmentsCalcData.minFlowRate;
        let maxFlowRate = segmentsCalcData.maxFlowRate;

        // Calc Flow variation:
        let flowVariation = this.calcFlowVariation(minFlowRate, maxFlowRate);
        segmentsCalcData.calcData.flowVariation = flowVariation;
        return calcStopsResponse;
    }

    // Override
    protected getCurrPipeSectionInit(calculationType: any, pipes: PipeSection[], pipesIndex: number, segments: any = null) {
        return null;
    }


    // ---------------------------------------- PRAIVATE METHODS ----------------------------------------


}