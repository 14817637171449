import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { Ecalculator, EtransaltionGroups } from "src/app/shared/enum";
import { ShiftCharacteristicsLabels } from "src/app/shared/characteristics/calc-labels.types";

@Component({
  selector: "app-shift-characteristics",
  templateUrl: "./shift-characteristics.component.html",
  styleUrls: ["./shift-characteristics.component.scss"],
})
export class ShiftCharacteristicsComponent implements OnInit {
  @Input() shiftForm: FormGroup;
  @Input() currentStep: number;
  @Input() labels: ShiftCharacteristicsLabels;
  @Input() user_id: string;

  Ecalculator = Ecalculator;
  EtransaltionGroups = EtransaltionGroups;

  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {}

  get controls() {
    return this.shiftForm.controls;
  }

  ngOnInit(): void {}
}
