import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { PopupTypes } from '../models/popuptype';
import { HttpHandler } from './httpHandler';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfflineService extends HttpHandler {
  isItOfflineMode: boolean = false;
  turnHydroToOfflineMode = new Subject<any>();
  private url = 'calculator';

  constructor(
    public router: Router,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService
  ) {
    super(_http, httpService, errorService);
  }

  getAllDBforCalculators() {
    const url = this.url + '/offline/getAllCalculatorsData'

    return this.get(url);
  }

}
