import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import {
  CalculatorCard,
  ExternalToolCard,
} from "src/app/shared/home-page/home-page.interfaces";
@Component({
  selector: "app-section-card",
  templateUrl: "./section-card.component.html",
  styleUrls: ["./section-card.component.scss"],
})
export class SectionCardComponent implements OnInit {
  @Input() card: CalculatorCard & ExternalToolCard;

  constructor(private router: Router) {}

  navigateToUrl() {
    if (!this.card.isDisabled) {
      this.card.calcName
        ? this.router.navigateByUrl(`calc/${this.card.calcName}`)
        : window.open(this.card.link, "_blank");
    }
  }

  ngOnInit(): void {}
}
