import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
import { TextService } from 'src/app/services/text.service';
@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  @Input() left_option;
  @Input() right_option;
  @Input() left_option_text = '';
  @Input() right_option_text = '';
  @Input() chosen;
  @Output() change_choice = new EventEmitter<string>();
  @Input() showSnackBar;
  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {
    this.dataService.changeShapeToggleButton.subscribe(option => {
      this.change(option);
    });
    this.dataService.resetBlockCharacteristics.subscribe(option => {
      this.changeAfterAcceptOnSnackBar();
    });
  }

  ngOnInit(): void {
  }

  change(chosen_button) {
    if (chosen_button === 'Trapezoid' || chosen_button === 'Rectangular') {
      this.dataService.clickedOnShape = chosen_button;
      if (this.showSnackBar) {
        this.dataService.snackBarText = this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_s2_block_shape_snackbar'
        );
        this.dataService.snackBarAcceptText = this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_s2_block_shape_snackbar_accept'
        );
        this.dataService.snackBarCancelText = this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_s2_block_shape_snackbar_cancel'
        );
        this.dataService.showCancelButtonSnackBar = true;
        this.dataService.initSnackBar.next();
      } else {
        this.changeAfterAcceptOnSnackBar();
      }
    } else {
      this.chosen = chosen_button;
      this.change_choice.emit(chosen_button);
    }
  }

  changeAfterAcceptOnSnackBar() {
    this.chosen = this.dataService.clickedOnShape;
    this.change_choice.emit(this.dataService.clickedOnShape);
  }
}
