import { SubmainCalculationsFactory } from "../Factories/SubmainCalculationsFactory";
import { ICalculationProcess } from "../Interfaces/ICalculationProcess";
import { CalculatorTypes } from "../../../../hydrocalc-code/enums/calculatorTypes.enum";
import { Calculator } from "./base.calculator";
import { CalculationErrors, ECalculationErrors } from "../../errors_and_warnings/errors";
import { EUnits } from "../../../../hydrocalc-code/enums/Units.enum";
export class SubmainCalculator extends Calculator {
    /**
     * calculate
     * calculate - start submain calculation process
     * 
     */
    public calculate(data: any) {
        let calculationProcess: ICalculationProcess = SubmainCalculationsFactory.getCalcProcess(data.calcType);
        if (!calculationProcess) {
            return null;
        }
        data.calculator = CalculatorTypes.SUBMAIN;
        let results = calculationProcess.start(data);

        return results;
    }

    // -------------------------------------------- Pre calculations --------------------------------------------

    /**
     * calcSubmainEndPressure
     * calc submain end pressure - for a given Inlet pressure
     * 
     */
    public calcEndPressure(calculationData: any) {
        let endPressue: number;
        let a = 0.01;
        let result: any;
        let fix = 0;
        let firstSegmentInletPressure;
        let toCalcAgain = false;
        let inletPressureUI = calculationData.inletPressureUI;

        // Set initial end Pressure to 1 and Calc first lateral inlet pressure:
        calculationData.blockChars.end_pressure = 1;
        calculationData.isEndPressureCalculation = true;
        do {
            calculationData.blockChars.end_pressure += fix;
            result = this.calculate(calculationData);
            if (!result || !result.calcResults || !result.calcResults.calculationResults) {
                throw new Error('Error - Error occured while calculating Submain End Pressure');
            }
            firstSegmentInletPressure = result.calcResults.segments[0].InletPressure;
            // firstSegmentInletPressure = result.calcResults.calculationResults.totalPressureLoss

            if (!firstSegmentInletPressure) {
                // Calculation falied - get first set segment inlet:
                let flag = 0;
                for (let index = 0; index < result.calcResults.segments.length && !flag; index++) {
                    const seg = result.calcResults.segments[index];
                    if (seg.AtomicFlowRate != 0) {
                        // last set segment:
                        firstSegmentInletPressure = index > result.calcResults.segments.length - 1 ? result.calcResults.segments[index + 1].InletPressure : seg.InletPressure;
                        flag = 1;
                    }
                }
            }
            firstSegmentInletPressure = Number(firstSegmentInletPressure.toFixed(2));
            fix = inletPressureUI - firstSegmentInletPressure;
            // if (fix < 0) { //to delete
            //     return { inletPressureCalcFailure: true }
            // }
            toCalcAgain = this.isCalcAgain(firstSegmentInletPressure, inletPressureUI, a);
        } while (toCalcAgain);

        calculationData.isEndPressureCalculation = false;
        return { inletPressure: inletPressureUI, endPressure: calculationData.blockChars.end_pressure };
    }

    /**
     * calcBlockFlowRate
     * calc Block Flow Rate - for a given block shape, last lateral/total flow rate and Number of laterals
     * 
     */
    public calcBlockFlowRate(data: any): number {
        let { isReqtangular, lastLateralFlowRate, totalLateralFlowRate, numOfLaterals, firstLateralLength, lastLateralLength, units } = data;

        let flowRate: number;
        if (!numOfLaterals || (!lastLateralFlowRate && !totalLateralFlowRate)) {
            // Error - Invalid params received
            return null;
        }

        if (lastLateralFlowRate) {
            flowRate = this.calcTotalFlowRateForUI(numOfLaterals, isReqtangular, lastLateralFlowRate, firstLateralLength, lastLateralLength);
        }
        else if (totalLateralFlowRate) {
            flowRate = this.calcLastLateralFlowRateForUI(numOfLaterals, isReqtangular, totalLateralFlowRate, firstLateralLength, lastLateralLength, units);
        }

        return flowRate;
    }

    // -------------------------------------------- Private Methods --------------------------------------------
    /**
     * calcLateralXFlowRateForCalculation
     * calc the X'th lateral Flow Rate for calculation use
     * 
     */
    private calcTrapezLateralFlowRateForCalculation(firstLateralLength, lastLateralLength, lastLateralFlowRate, lateralNumber, numOfLaterals): number {
        // Calc first Lateral Atomic Flow Rate
        let firstLateralAtomicFlowRate = (firstLateralLength * lastLateralFlowRate) / lastLateralLength;
        // Calc diff between lateral lengths:
        let d = (lastLateralFlowRate - firstLateralAtomicFlowRate) / (numOfLaterals - 1);
        // Calc atomic flow rate:
        let atomicFlowRate: number = firstLateralAtomicFlowRate + (lateralNumber - 1) * d;
        return atomicFlowRate;
    }

    /** 
     * calcTotalFlowRateForUI
     * calc Total Flow Rate - for a given block shape, last lateral flow rate and Number of laterals
     * 
     */
    private calcTotalFlowRateForUI(numOfLaterals: number, isReqtangular: boolean, lastLateralFlowRate: number, firstLateralLength: number = 0, lastLateralLength: number = 0): number {
        let flowRate: number;

        if (isReqtangular) {
            // Block shape is Rectangle:
            flowRate = (lastLateralFlowRate * numOfLaterals) / 1000;
        }
        else {
            // Block shape is Trapez:
            let sum = 0;
            for (let index = numOfLaterals; index > 0; index--) {
                let atomicFlowRate = this.calcTrapezLateralFlowRateForCalculation(firstLateralLength, lastLateralLength, lastLateralFlowRate / 1000, index, numOfLaterals)
                sum += atomicFlowRate;
            }
            flowRate = sum;
        }

        return flowRate;
    }

    /**
     * calcLastLateralFlowRateForUI
     * calc Last Flow Rate - for a given block shape, Total lateral flow rate and Number of laterals
     * 
     */
    private calcLastLateralFlowRateForUI(numOfLaterals: number, isReqtangular: boolean, totalLateralFlowRate: number, firstLateralLength: number = 0, lastLateralLength: number = 0, units): number {
        let flowRate: number;

        if (isReqtangular) {
            // Block shape is Rectangle:
            flowRate = (totalLateralFlowRate * 1000) / numOfLaterals;
        }
        else {
            // Block shape is Trapez:
            let SL = ((firstLateralLength + lastLateralLength) / 2) * numOfLaterals;
            flowRate = ((totalLateralFlowRate * lastLateralLength) / SL) * 1000;
        }

        return units == EUnits.US_UNITS ? flowRate : Number(flowRate.toFixed(2));
    }

    private isCalcAgain(a1: number, a2: number, a: number) {
        let isCalcAgain = a2 - a > a1;
        return isCalcAgain;
    }
}
