export const environment = {
  production: false,
  url: 'https://hydrocalc-test-software.netafim.com',
  auth_header_key: '',
  auth_header_value: '',
  prod: true,
  cognito_userPoolId: 'eu-west-1_LRa8GbcHA',
  cognito_userPoolWebClientId: '6sft5vao7177kai6aspdqmi01e',
  isOffline: true
};
