import { CalculatorTypes } from "../../enums/calculatorTypes.enum";
import { SubmainCalculator } from "../Calculators/submainCalculator";
import { LateralCalculator } from "../Calculators/lateralCalculator";
import { MainlineCalculator } from "../Calculators/MainlineCalculator";
import { ValveCalculator } from "../Calculators/valveCalculator";

export class CalculatorFactory {

    public static get(calculatorType: any): any {
        let calculator: any;
        switch (calculatorType) {
            case CalculatorTypes.SUBMAIN:
                calculator = new SubmainCalculator();
                break;

            case CalculatorTypes.LATERAL:
                calculator = new LateralCalculator();
                break;
            case CalculatorTypes.MAINLINE:
                calculator = new MainlineCalculator();
                break;
                case CalculatorTypes.VALVE:
                calculator = new ValveCalculator();
                break;

            default:
                throw new Error(`Unknown calculator ${calculatorType}`);
                break
        }

        return calculator;
    }
}