import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-reset-password-popup',
  templateUrl: './reset-password-popup.component.html',
  styleUrls: ['./reset-password-popup.component.scss']
})
export class ResetPasswordPopupComponent implements OnInit {
  resetPasswordForm: FormGroup;
  formGroupObject = {};

  constructor(public dataService: DataService) {}

  ngOnInit(): void {
    this.generateForm();

    this.resetPasswordForm.controls['repeatPassword'].valueChanges.subscribe(
      val => {
        if (this.resetPasswordForm.controls['password'].value !== val) {
          this.resetPasswordForm.controls['repeatPassword'].setErrors({
            notEqual: true
          });
        }
      }
    );
  }

  generateForm() {
    this.formGroupObject['password'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50)
    ]);
    this.formGroupObject['repeatPassword'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50)
    ]);

    this.resetPasswordForm = new FormGroup(this.formGroupObject);
  }

  get controls() {
    return this.resetPasswordForm.controls;
  }

  checkError(controlName: string) {
    if (this.resetPasswordForm.controls[controlName].errors) {
      const theErrors = this.resetPasswordForm.controls[controlName].errors;
      if (theErrors.required) {
        return `This field is required.`;
      }
      if (theErrors.minlength) {
        return `Expecting ${theErrors.minlength.requiredLength} characters.`;
      }
      if (theErrors.maxlength) {
        return `Expecting ${theErrors.minlength.requiredLength} characters.`;
      }
      if (theErrors.pattern) {
        return `The pattern you entered is invalid.`;
      }
      if (theErrors.notEqual) {
        return `The passwords do not match`;
      }
    }
  }

  onSave() {
    this.dataService.showPasswordPopup = false;
  }
}
