import { AbsCalculationProcess } from "../AbsCalculationProcess";
import { Segment } from "../../Segment";
import { PipeSection } from "../../PipeSection";
import { SubmainCalculationType } from "../../../enums/SubmainCalculationType.enum";
import { SlopeSection } from "../../../../calculator/Entities/SlopeSection";
import { AbsSubmainCalculationProcess } from "./AbsSubmainCalculationProcess";
import { ECalculationErrors } from "../../../errors_and_warnings/errors";

export class PressureLossForAGivenPipe extends AbsSubmainCalculationProcess {


    public initSegments(data: any, segments: any, slopes: any, pipes: any, isFlushing: boolean = false) {
        // Add Slope section propeties to Segments:
        this.addSlopeSectionsToSegments(segments, slopes);
        // Add Pipe section propeties to Segments:
        this.addPipeSectionsToSegments(segments, pipes);
    }

    public calculate(segments: any, pipes: any, slopes: any, data: any) {
        return this.calcTotalPressureLoss(segments, pipes, slopes, data);
    }
    // Override
    protected getCurrPipeSectionInit(calculationType: any, pipes: PipeSection[], pipesIndex: number, segments: any = null) {
        return null;
    }
    protected handleCalculationStops(data: any): any {
        let calcStopsResponse = {
            error: null,
            warning: null,
            isStopIteration: false,
            isCalcDone: false,
            InletPressureUIstop: false
        }
        let { pipes, slopes, calculationType, currSegment, segments, lengthSum,
            isEndPressureCalculation, inletPressureUI, isFlushingMode, index } = data;
        if (calculationType == SubmainCalculationType.PRESSURE_LOSS_FOR_SELECTED_PIPE) {
            let currSegmentInletPressure = currSegment.InletPressure;
        }

        return calcStopsResponse;
    }



}