import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment'; import { OfflineService } from './offline.service';
declare var gtag: any;
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object, public offlineService: OfflineService) { }

  sendPageView(path, name) {

    if (isPlatformBrowser(this.platformId) && !this.offlineService.isItOfflineMode) {
      gtag('config', 'G-B6Z3K6PM4S', { page_path: path });
      // gtag('config', 'GTM-NK985NP', { page_path: path });
    }
  }
  sendEvent(eventCategory, eventAction, value, user_id, eventLabel?) {
    if (isPlatformBrowser(this.platformId) && environment.production && !this.offlineService.isItOfflineMode) {
      gtag('event', eventAction, {
        user_id,
        event_category: eventCategory,
        event_label: eventLabel ? eventLabel : value,
        value: eventLabel ? value : ''
      });
    }
  }
}
