import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MulitpleToggleButtonComponent } from 'src/app/common/mulitple-toggle-button/mulitple-toggle-button.component';
import { TextService } from 'src/app/services/text.service';
import {
  Ecalculator,
  EtransaltionGroups,
  EqueryTypes,
} from 'src/app/shared/enum';
import { MatrixSlopeValueOption } from 'src/app/shared/elevation/elevation.types';
import { ELEVATION_MATRIX_VALIDATION_MAX, ELEVATION_MATRIX_VALIDATION_MIN, MATRIX_SLOPE_VALUES } from 'src/app/shared/elevation/elevation.data';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CALCULATORS_INDEXES } from 'src/app/shared/consts';

@Component({
  selector: 'app-elevation-section',
  templateUrl: './elevation-section.component.html',
  styleUrls: ['./elevation-section.component.scss']
})
export class ElevationSectionComponent implements OnInit {

  constructor(
    public dataService: DataService,
    private cdr: ChangeDetectorRef,
    public textService: TextService,
    private fb: FormBuilder
  ) { }
  EqueryTypes = EqueryTypes;
  EtransaltionGroups = EtransaltionGroups;
  Ecalculator = Ecalculator;
  selectedTopography = 'Flat';

  @Input() currentSlopeForms: any = [];
  @Input() queryIndex; //0 - Q1, 1 - Q2, 2-Q3
  @Input() calculatorIndex;
  @Input() user_id //for analytics events
  @Input() userInputMatrixPlaceholder: string
  @Input() matrixSlopeForm: FormGroup
  @Input() matrixSlopesLimit: number

  @Output() addNewSlope = new EventEmitter<string>();
  @Output() deleteSlopeFromCurrentSlopeForms = new EventEmitter<string>();
  @Output() deleteAllSlopes = new EventEmitter<string>();

  @ViewChild(MulitpleToggleButtonComponent) MulitpleToggleButtonComponent;
  slopeItems: any[];
  matrixSlopeValueOptions: MatrixSlopeValueOption[] = []
  userInputMatrixSlopePlaceholder: string
  matrixValidationMinValue = ELEVATION_MATRIX_VALIDATION_MIN
  matrixValidationMaxValue = ELEVATION_MATRIX_VALIDATION_MAX
  slopesSubtitle: string

  ngOnInit(): void {    
    if(this.calculatorIndex === CALCULATORS_INDEXES.LATERAL && this.queryIndex === this.EqueryTypes.matrix){
      this.initMatrixSlopeValueOptions()
      this.setUserInputMatrixSlopePlaceholder()
      this.buildMatrixSlopesSelectionSubtitle()
      return
    }
    this.changeTopography('Flat')
    this.slopeItems = this.dataService.sectionItems;
    if (
      'Fixed Slope' === this.dataService.selectedTopography &&
      this.queryIndex != EqueryTypes.maximum_length
    ) {
      let form = this.currentSlopeForms[0];
      switch (this.calculatorIndex) {
        case Ecalculator.lateral:
          form.get('mapLength').setValue(this.dataService.totalLateralLength);
          break;
        case Ecalculator.mainline:
          form.get('mapLength').setValue(this.dataService.totalLengthMainline);
          break;
        default:
          break;
      }
      this.dataService.reloadSlopesIlustration.next();
    }
  }

  initMatrixSlopeValueOptions() {
    this.matrixSlopeValueOptions = MATRIX_SLOPE_VALUES.map((value) => ({
      value,
      isSelected: false,
    }));
  }

  buildMatrixSlopesSelectionSubtitle(){
    this.slopesSubtitle = this.textService.getText(
      EtransaltionGroups.LATERAL,
      "lateral_matrix_slopes_subtitle"
    ).replace('{{}}',this.matrixSlopesLimit) 
  }

  setUserInputMatrixSlopePlaceholder() {
    this.userInputMatrixSlopePlaceholder = this.userInputMatrixPlaceholder
  }

  onClickSlopeOption(option: MatrixSlopeValueOption) {
    if (
      (this.matrixSlopeForm.controls.slopes_matrix.value.length ===
        this.matrixSlopesLimit ||
        (this.matrixSlopeForm.controls.slopes_matrix.value.length ===
          this.matrixSlopesLimit - 1 &&
          this.matrixSlopeForm.controls.user_matrix_slope_input.value)) &&
      !option.isSelected
    ) {
      return;
    }

    option.isSelected = !option.isSelected;
    this.addOrRemoveValueToMatrixSlopesControl(option.value);
  }

  addOrRemoveValueToMatrixSlopesControl(value: number){
    const index = this.matrixSlopeForm.controls.slopes_matrix.value.indexOf(value);
    let values = [...this.matrixSlopeForm.controls.slopes_matrix.value];
    if (index === -1) {
      this.matrixSlopeForm.controls.slopes_matrix.setValue([...values, value]);
    } else {
      this.matrixSlopeForm.controls.slopes_matrix.setValue(
        values.filter((_value) => _value !== value)
      );
    } 
    this.dataService.inputs_contents_has_changed = true
  }

  ngAfterViewInit(): void {
    if(!(this.calculatorIndex === CALCULATORS_INDEXES.LATERAL && this.queryIndex === this.EqueryTypes.matrix)){
      this.selectedTopography = this.dataService.selectedTopography;
      this.MulitpleToggleButtonComponent.chosen = this.dataService.selectedTopography;
      this.cdr.detectChanges();
    }
  }

  changeTopography(option) {
    this.dataService.selectedTopography = option;
    this.selectedTopography = option;
    this.deleteAllSlopes.emit();
    if (option === 'Fixed Slope' || option === 'Multiple Elevations') {
      this.addSlope();
      this.updateSlopeForm();
      this.cdr.detectChanges();
    }
  }

  addSlope() {
    this.addNewSlope.emit();
  }

  updateSlopeForm() {
    if (
      (this.selectedTopography === 'Fixed Slope' ||
        this.selectedTopography === 'Multiple Elevations') &&
      this.currentSlopeForms.length > 0
    ) {
      let form = this.currentSlopeForms[0];
      switch (this.calculatorIndex) {
        case Ecalculator.lateral:
          form.get('mapLength').setValue(this.dataService.totalLateralLength);
          break;
        case Ecalculator.mainline:
          form.get('mapLength').setValue(this.dataService.totalLengthMainline);
          break;
        default:
          break;
      }
    }
  }

  calculateTotalLengthLeftForSlopes(slopeIndex) {
    this.dataService.totalSlopesLength = 0;
    switch (this.calculatorIndex) {
      case Ecalculator.lateral:
        this.dataService.totalLengthLeftForSlopes = Number(
          this.dataService.totalLateralLength
        );
        break;
      case Ecalculator.mainline:
        this.dataService.totalLengthLeftForSlopes = Number(
          this.dataService.totalLengthMainline
        );
        break;
      default:
        break;
    }

    this.currentSlopeForms.forEach((slope, index) => {
      this.dataService.totalSlopesLength =
        Number(this.dataService.totalSlopesLength) +
        Number(slope.get('mapLength').value);
      if (index < slopeIndex) {
        this.dataService.totalLengthLeftForSlopes =
          this.dataService.totalLengthLeftForSlopes -
          slope.get('mapLength').value;
      }
    });
  }

  deleteSlope(index) {
    this.deleteSlopeFromCurrentSlopeForms.emit(index);
    if (this.currentSlopeForms.length === 0) {
      this.MulitpleToggleButtonComponent.change('Flat');
    }
    if (this.currentSlopeForms.length > 0) {
      setTimeout(() => {
        this.dataService.validationForMapLengthSlope.next();
      }, 0);
    }
  }
}
