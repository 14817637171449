import { AbsCalculationProcess } from "../AbsCalculationProcess";
import { ErrorMsgs } from "../../../../../hydrocalc-code/models/Errors/ErrorMsgs";
import { SubmainCalculationType } from "../../../enums/SubmainCalculationType.enum";
import { Segment } from "../../../Entities/Segment";
import { PipeSection } from "../../../Entities/PipeSection";
import { SlopeSection } from "../../../Entities/SlopeSection";

export abstract class AbsSubmainCalculationProcess extends AbsCalculationProcess {
    /**
      * calcLastLateralFlowRateForCalculation
      * calc Last lateral Flow Rate for calculation use - for a given block shape, last lateral/total flow rate and Number of laterals
      * 
      */
    public calcLastLateralFlowRateForCalculation(numOfLaterals: number, isReqtangular: boolean, lastLateralFlowRate: number, totalLateralFlowRate: number = null, lastLateralLength: number = null, firstLateralLength: number = null): number {
        let flowRate: number;

        if (isReqtangular) {
            // Block shape is Rectangle:
            if (lastLateralFlowRate) {
                // User entered last lateral flow rate, convert it to [m^3/h]:
                flowRate = (lastLateralFlowRate) / 1000;
            }
            else {
                // User entered total flow rate, calc last lateral:
                flowRate = (totalLateralFlowRate) / numOfLaterals;
            }
        }
        else {
            // Block shape is Trapez:
            if (lastLateralFlowRate) {
                // User entered last lateral flow rate, convert it to [m^3/h]:
                flowRate = (lastLateralFlowRate) / 1000;
            }
            else {
                // User entered total flow rate, calc last lateral:
                let lastLateralRelativeLength = lastLateralLength / (Math.abs((firstLateralLength - lastLateralLength)) / 2);
                flowRate = (totalLateralFlowRate / numOfLaterals) * lastLateralRelativeLength;
            }
        }

        return flowRate;
    }

    protected calcTotalPressureLoss(segments: Segment[], pipes: PipeSection[], slopes: SlopeSection[], calcData: any) {

        let lastFlowRate = this.calcLastLateralFlowRateForCalculation(calcData.blockChars.num_of_laterals, calcData.blockChars.is_rectangular, calcData.blockChars.last_lateral_flow_rate, calcData.blockChars.total_lateral_flow_rate, calcData.blockChars.last_lateral_length, calcData.blockChars.first_lateral_length);
        let endPressure = Number(calcData.blockChars.end_pressure);
        let isCalcDone = false;
        let systemPL: number;
        let isFlushing = calcData.isFlushingMode;
        let lastCalculationSegmentIndex = isFlushing ? segments.length - 2 : segments.length - 1;
        let segment_index_for_desired_inlet_pressure
        let iscalcStopInletPressureUIstop = false

        // Init last calculation segment:
        segments[lastCalculationSegmentIndex].FlowRate = lastFlowRate;
        segments[lastCalculationSegmentIndex].AtomicFlowRate = lastFlowRate;
        if (!isFlushing) {
            segments[lastCalculationSegmentIndex].EndPressure = endPressure;
        }
        else {
            segments[lastCalculationSegmentIndex].FlowRate += calcData.flushing_end_flow_rate;
        }

        // Set segment calculation data:     
        let segmentsCalcData: any = { calcData, pipes, slopes, segments, lastFlowRate, type: SubmainCalculationType.PRESSURE_LOSS_FOR_SELECTED_PIPE };
        do {
            // Handle segments calculation:
            let res = this.handleSegmentsCalculation(segmentsCalcData);
            isCalcDone = res.isCalcDone;
            systemPL = res.systemPL;
            segment_index_for_desired_inlet_pressure = res.segment_index_for_desired_inlet_pressure
            iscalcStopInletPressureUIstop = res.isInletPressureUIstop

        } while (!isCalcDone);

        let results: any = this.summarizeResults(segments, pipes, systemPL, calcData.maxVelocity, calcData.isFlushingMode, calcData.end_velocity, segment_index_for_desired_inlet_pressure);
        // results.totalLength = calcData.blockChars.submain_pipe_length;
        let sum = 0;
        segments.map(s => { sum = Math.round((sum + s.Length) * 1e12) / 1e12 });
        results.totalLength = iscalcStopInletPressureUIstop ? sum : calcData.blockChars.submain_pipe_length;
        results.errors = segmentsCalcData.errors || [];
        return results;
    }

    /**
     * Gets pressure loss caused by changes in pipe
     * like drippers, connectors, etc. (local friction, stored in database)
     * 
     * @param data 
     */
    protected getLocalHeadLoss(calcData: any, segment: any): number {
        let K = AbsCalculationProcess.K2_LOCAL_LOSS_COEFFICIENT_SUBMAIN;
        let pipeInternalDiameter = segment.PipeSection.InternalDiameter;
        let flowRate = segment.FlowRate;
        let KD = segment.PipeSection.KD;

        return KD * K * Math.pow(flowRate, 2) * Math.pow(pipeInternalDiameter, -4)
    }

    /**
     * Diskin - for Submain calculations
     * @param flowRate - Q
     * @param pipeInternalDiameter - D
     * @param distanceBetweenLaterals - R
     * @returns  
     */
    protected diskin(data: any): number {
        let flowRate = data.segment.FlowRate;
        let pipeInternalDiameter = data.segment.PipeSection.InternalDiameter;
        let segmentLength = data.segment.Length;

        return AbsCalculationProcess.DISKIN_COEFFICIENT * Math.pow(flowRate, 1.81) * Math.pow(pipeInternalDiameter, -4.81) * segmentLength;
    }


    /**
     * Hazen - for Submain calculations
     * 
     * @param flowRate - Q
     * @param pipeInternalDiameter - D
     * @param distanceBetweenLaterals - R
     * @param roughnessCoefficient - C
     * @returns
     */
    protected hazen(data: any): number {
        let flowRate = data.segment.FlowRate;
        let pipeInternalDiameter = data.segment.PipeSection.InternalDiameter;
        let segmentLength = data.segment.Length;
        let roughnessCoefficient = data.calcData.pipeRoughnessChw || data.segment.PipeSection.Roughness || AbsCalculationProcess.PIPES_ROUGHNESS_DEFAULT_VALUE;

        return AbsCalculationProcess.HAZEN_COEFFICIENT * Math.pow((flowRate / roughnessCoefficient), AbsCalculationProcess.HAZEN_POW1_COEFFICIENT) * Math.pow(pipeInternalDiameter, AbsCalculationProcess.HAZEN_POW2_COEFFICIENT) * segmentLength;
    }

    protected calcPrevSegFlowRate(data: any) {
        let { isReqtangle, lengthSum, calcData, segments, index, currSegment, lastFlowRate } = data;

        if (isReqtangle) {
            if (this.isSegmentIsOnLateral(lengthSum, calcData.blockChars.distance_between_laterals)) {
                // Lateral segment, add last lateral flow rate:
                if (!segments[index - 1].FlowRate || segments[index - 1].FlowRate === 0) {
                    segments[index - 1].FlowRate = currSegment.FlowRate + lastFlowRate;
                    segments[index - 1].AtomicFlowRate = lastFlowRate;
                }
            }
            else {
                if (!segments[index - 1].FlowRate || segments[index - 1].FlowRate === 0) {
                    segments[index - 1].FlowRate = currSegment.FlowRate;
                    segments[index - 1].AtomicFlowRate = lastFlowRate;
                }
            }
        }
        else {
            // Calc trapez seg X Atomic Flow Rate:
            let numOfLaterals = calcData.blockChars.num_of_laterals != null ? calcData.blockChars.num_of_laterals : segments.length;
            let lateralNum = this.getSegmentLateralNum(lengthSum, calcData.blockChars.distance_between_laterals, numOfLaterals);
            if (lateralNum < 0) {
                throw new ErrorMsgs('Error occured while calculating result - Invalid params', null, true)
            }
            let segXAtomicFlowRate = this.calcTrapezLateralFlowRateForCalculation(calcData.blockChars.first_lateral_length, calcData.blockChars.last_lateral_length, lastFlowRate, lateralNum, calcData.blockChars.num_of_laterals);
            if (!segments[index - 1].AtomicFlowRate || segments[index - 1].AtomicFlowRate === 0) {
                segments[index - 1].AtomicFlowRate = segXAtomicFlowRate;

                if (this.isSegmentIsOnLateral(lengthSum, calcData.blockChars.distance_between_laterals)) {
                    // Lateral segment, add last lateral flow rate:
                    segments[index - 1].FlowRate = segXAtomicFlowRate + currSegment.FlowRate;
                }
                else {
                    segments[index - 1].FlowRate = currSegment.FlowRate
                }
            }
        }

        return segments[index - 1].AtomicFlowRate;
    }

    protected setFlushingSettings(data: any, pipes: any): any {
        let lastOutletFlowRate = super.setFlushingSettings(data, pipes);

        data.flushing_end_flow_rate = lastOutletFlowRate;
        data.calcType = SubmainCalculationType.PRESSURE_LOSS_FOR_SELECTED_PIPE;
        data.flushing_end_pressure = AbsCalculationProcess.ATMOSPHERE_PRESSURE;

        return true
    }

    protected handleNegativeFlushing(flushingResults: any, flushingPipes: any, data: any) {
        let minPressure = 0;
        let diff = 0;
        // Get min pressure:
        flushingPipes.forEach(pipe => {
            if (pipe.MinPressure < minPressure) {
                minPressure = pipe.MinPressure;
            }
        });
        if (minPressure < 0) {
            diff = AbsCalculationProcess.ATMOSPHERE_PRESSURE - minPressure;
            flushingResults.totalMaxPressure += diff;
            flushingResults.totalMinPressure += diff;
            flushingPipes[0].InletPressure += diff;

            //for user's input -  inlet pressure results
            flushingResults.result_for_user_inlet_pressure.totalMaxPressureForUserInletPressureInput += diff;
            flushingResults.result_for_user_inlet_pressure.totalMinPressureForUserInletPressureInput += diff;
        }
    }
}