import { AbsDAL } from "./Base/AbsDAL";
import { EUnits } from "../enums/Units.enum";
import { EPipeStatus } from "../enums/PipeStatus.enum";
import { EmitterTypes } from "../enums/Lateral/emitterTypes.enum";


let AWS = require('aws-sdk');

export class EmittersDAL extends AbsDAL {
    public static readonly ONLINE_EMITTERS_TABLE_NAME = "OnlineEmitters";
    public static readonly INLINE_EMITTERS_TABLE_NAME = "InlineEmitters";
    public static readonly LOCAL_STORAGE_ONLINE_EMITTERS_TABLE_NAME = "onlineEmitters";
    public static readonly LOCAL_STORAGE_INLINE_EMITTERS_TABLE_NAME = "inlineEmitters";

    /**
     * Gets Emitter by keys
     */
    public async getEmitter(region: string, isInlineEmitter: any, units: any, type: any, model: any, nominalDiameter: any, pClass: any, flowRate: any, isRequestFromCalculatorAndUsUnits: boolean, isOffline: boolean) {
        let filters = { region, type, model, flowRate, nominalDiameter, pClass, wallThickness: pClass, isExactKeysMatch: true, units, isRequestFromCalculatorAndUsUnits, isOffline }
        let emitters = isInlineEmitter ? await this.getInlineEmitters(filters) : await this.getOnlineEmitters(filters);
        // publicity status filter:
        let filtered = emitters.filter((em) => em.local_status == EPipeStatus.PUBLISH);

        if (emitters.length <= 0) {
            return null;
        }
        let emitter = this.mapToEmitter(filtered[0]);
        return emitter;
    }


    /**
     * Gets Emitter types
     */
    public async getEmitterTypes(region: any, isOffline: boolean) {
        let allEmitters = await this.getRegionEmitters(region, { isOffline });
        let isGlobal = region.toString() === EmittersDAL.GLOBAL_REGION_STR;
        let filtered = allEmitters;
        // publicity status filter:
        filtered = isGlobal ? filtered.filter((em) => em.global_status == EPipeStatus.PUBLISH) : filtered.filter((em) => em.local_status == EPipeStatus.PUBLISH);

        // Get uniqe types:
        let unique = new Set(allEmitters.map(emitter => emitter.emitter_type));
        let arr = Array.from(unique);
        arr = arr.sort((a, b) => (a > b) ? -1 : ((b > a) ? 1 : 0));
        let last = arr.pop();
        let first = arr.shift();
        let third = arr.pop();
        arr.unshift(last);
        arr.push(first);
        arr.push(third);
        return arr;
    }

    /**
     * Get Emitter Models By Type
     */
    public async getEmitterModelsByType(emitterType: any, region: any, isOffline: boolean) {
        let allEmitters = await this.getRegionEmitters(region, { emitterType, isOffline });
        let isGlobal = region.toString() === EmittersDAL.GLOBAL_REGION_STR;
        // // publicity status filter:
        let filtered = isGlobal ? allEmitters.filter((em) => em.global_status == EPipeStatus.PUBLISH) : allEmitters.filter((em) => em.local_status == EPipeStatus.PUBLISH);

        // Get uniqe models:
        let arr = filtered.map(emitter => { return { model: emitter.model, modelLabel: emitter.model, isPC: emitter.is_emitter_pc } })
        arr.forEach((emiter: any) => {
            let isPC = emiter.isPC;
            if (isPC) {
                isPC
            }
            let isPCSTR = isPC ? ' (PC)' : ' (non-PC)'
            emiter.modelLabel += isPCSTR
        });
        let unique = new Set(arr.map((elem => elem.modelLabel)));
        let uniqueArr = Array.from(unique).sort();
        let emittersModels = [];

        uniqueArr.forEach((element: any) => {
            let em = arr.filter((elem => elem.modelLabel == element));
            emittersModels.push({ model: em[0].model, modelLabel: element, isPC: em[0].isPC })
        });
        let pcs = emittersModels.filter(a => a.isPC)
        pcs = pcs.sort((a, b) => (a.model > b.model) ? 1 : ((b.model > a.model) ? -1 : 0));
        let NONpcs = emittersModels.filter(a => !a.isPC)
        NONpcs = NONpcs.sort((a, b) => (a.model > b.model) ? 1 : ((b.model > a.model) ? -1 : 0));
        NONpcs.forEach(non => {
            pcs.push(non)
        });
        return pcs
    }

    /**
     * Get Emitter Flow rates By Type and model
     */
    public async getAllEmittersFlowRates(emitterType: any, model: any, region: any, units: any, isOffline: boolean) {
        let allEmitters = await this.getRegionEmitters(region, { emitterType, model, isOffline });
        let isGlobal = region.toString() === EmittersDAL.GLOBAL_REGION_STR;
        // publicity status filter:
        let filtered = isGlobal ? allEmitters.filter((em) => em.global_status == EPipeStatus.PUBLISH) : allEmitters.filter((em) => em.local_status == EPipeStatus.PUBLISH);

        // Get uniqe flow rates:
        let unique = units == EUnits.METRIC ? new Set(filtered.map(emitter => ({ flow_rate: Number(emitter.flow_rate), flow_rate_label: emitter.flow_rate_label }))) : new Set(filtered.map(emitter => ({ flow_rate: Number(emitter.flow_rate_US), flow_rate_label: emitter.flow_rate_label })));
        let arr = Array.from(unique);
        arr = arr.sort((a, b) => a.flow_rate - b.flow_rate)
        return arr;
    }

    /**
     * Get Inline Emitters nominal diameters By filters
     */
    public async getInlineEmittersNominalDiameters(filters: any) {
        let { region, units, emitterType, model, flowRate, isOffline, wallThickness } = filters;
        region = region ? region.toString() : EmittersDAL.GLOBAL_REGION_STR;
        let isGlobal = region.toString() === EmittersDAL.GLOBAL_REGION_STR;
        // Get Inline emitters:
        let inlineEmitters = await this.getRegionInlineEmitters(emitterType, region, model, flowRate, null, null, true, isOffline);
        inlineEmitters = inlineEmitters.Items || [];
        // Filter emitters:
        let filtered = inlineEmitters;
        // publicity status filter:
        filtered = isGlobal ? filtered.filter((em) => em.global_status == EPipeStatus.PUBLISH) : filtered.filter((em) => em.local_status == EPipeStatus.PUBLISH);

        if (wallThickness) {
            // wall thickness:
            filtered = filtered.filter((em) => em.wall_thickness == wallThickness);
        }
        // Get uniqe nominal diameters:
        let unique = units == EUnits.METRIC ? new Set(filtered.map(emitter => emitter.nominal_diameter)) : new Set(filtered.map(emitter => emitter.nominal_diameter_US));

        let diameters = Array.from(unique).sort();

        //add label and value to every nominal diameter - show Integers (label)
        let diameters_list = diameters.map(diameter => ({ label: Number(diameter).toFixed(units == EUnits.METRIC ? 0 : 3), value: diameter }))
        return diameters_list
    }

    /**
     * Get Inline Emitters wall thickness By filters
     */
    public async getInlineEmittersWallThickness(filters: any) {
        let { region, units, emitterType, model, flowRate, diameters, isOffline } = filters;
        region = region ? region.toString() : EmittersDAL.GLOBAL_REGION_STR;
        let isGlobal = region.toString() === EmittersDAL.GLOBAL_REGION_STR;
        // Get Inline emitters:
        let inlineEmitters = await this.getRegionInlineEmitters(emitterType, region, model, flowRate, null, null, true, isOffline);
        inlineEmitters = inlineEmitters.Items || [];
        // Filter emitters:
        let filtered = inlineEmitters;
        // publicity status filter:
        filtered = isGlobal ? filtered.filter((em) => em.global_status == EPipeStatus.PUBLISH) : filtered.filter((em) => em.local_status == EPipeStatus.PUBLISH);

        // Type:
        filtered = filtered.filter((em) => em.emitter_type == emitterType);
        // Model:
        filtered = filtered.filter((em) => em.model == model);
        if (flowRate) {
            // Flow rate:
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.flow_rate == flowRate) : filtered.filter((em) => em.flow_rate_US == flowRate);
        }
        if (diameters) {
            // Nominal diameters:
            filtered = units == EUnits.METRIC ? filtered.filter((em) => diameters.includes(em.nominal_diameter)) : filtered.filter((em) => diameters.includes(em.nominal_diameter_US));
        }

        // Get uniqe wall thickness:
        let unique = new Set(filtered.map(emitter => emitter.wall_thickness));

        return Array.from(unique).sort();
    }

    public async getInlineEmittersFlowRates(filters: any) {
        let { region, units, emitterType, model, diameters, wallThickness, isOffline } = filters;
        region = region ? region.toString() : EmittersDAL.GLOBAL_REGION_STR;
        let isGlobal = region.toString() === EmittersDAL.GLOBAL_REGION_STR;
        // Get Inline emitters:
        let inlineEmitters = await this.getRegionInlineEmitters(emitterType, region, model, null, null, null, true, isOffline);
        inlineEmitters = inlineEmitters.Items || [];
        // Filter emitters:
        let filtered = inlineEmitters;
        // publicity status filter:
        filtered = isGlobal ? filtered.filter((em) => em.global_status == EPipeStatus.PUBLISH) : filtered.filter((em) => em.local_status == EPipeStatus.PUBLISH);

        if (diameters) {
            // Nominal diameters:
            filtered = units == EUnits.METRIC ? filtered.filter((em) => diameters.includes(em.nominal_diameter)) : filtered.filter((em) => diameters.includes(em.nominal_diameter_US));
        }
        if (wallThickness) {
            // Wall thickness:
            filtered = filtered.filter((em) => wallThickness.includes(em.wall_thickness));
        }
        // Get uniqe flow rates:
        let unique = units == EUnits.METRIC ? new Set(filtered.map(emitter => emitter.flow_rate)) : new Set(filtered.map(emitter => emitter.flow_rate_US));

        return Array.from(unique).sort();
    }

    public async getLateralInternalDiameter(filters: any, units: any) {
        let region = filters.region;
        let emitterType = filters.type;
        let model = filters.model;
        let flowRate = filters.flowRate;
        let wallThickness = filters.wallThickness;
        let diameter = filters.nominalDiameter;
        let isOffline = filters.isOffline
        let isGlobal = region.toString() === EmittersDAL.GLOBAL_REGION_STR;
        let inlineEmitters = await this.getRegionInlineEmitters(emitterType, region, model, flowRate, diameter, wallThickness, true, isOffline);
        inlineEmitters = inlineEmitters.Items || [];

        // Filter emitters:
        let filtered = inlineEmitters;
        // publicity status filter:
        filtered = isGlobal ? filtered.filter((em) => em.global_status == EPipeStatus.PUBLISH) : filtered.filter((em) => em.local_status == EPipeStatus.PUBLISH);
        if (diameter) {
            // Nominal diameters:
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.nominal_diameter == diameter) : filtered.filter((em) => em.nominal_diameter_US == diameter);
        }
        if (wallThickness) {
            // Wall thickness:
            filtered = filtered.filter((em) => wallThickness == em.wall_thickness);
        }

        let internalDiameter;

        if (filtered.length <= 0) {
            internalDiameter = null;
        }
        else {
            internalDiameter = units == EUnits.METRIC ? filtered[0].internal_diameter : filtered[0].internal_diameter_US;
        }

        return internalDiameter;
    }

    public async getOnlineEmitters(filters: any) {

        let { region, units, model, flowRate, isRequestFromCalculatorAndUsUnits, isOffline } = filters;
        region = region ? region.toString() : EmittersDAL.GLOBAL_REGION_STR;
        let emitterType = filters.type;
        let isExactKeysMatch = filters.isExactKeysMatch || false;

        // Get Online emitters:
        let onlineEmitters = await this.getRegionOnlineEmitters(emitterType, region, model, flowRate, isExactKeysMatch, isOffline, isRequestFromCalculatorAndUsUnits);
        onlineEmitters = onlineEmitters.Items || [];
        if (onlineEmitters.length == 0) {
            return onlineEmitters;
        }
        // Filter emitters:
        let filtered = onlineEmitters;

        // Description:
        if (filters.isPc || filters.isPc == false) {
            filtered = filtered.filter((em) => em.is_emitter_pc.toString().includes(filters.isPc.toString()));
        }
        // Material code:
        if (filters.materialCode) {
            if (filters.isExactMaterialCode) {
                filtered = filtered.filter((em) => {
                    return em.material_code == filters.materialCode
                });
            }
            else {
                filtered = filtered.filter((em) => {
                    return em.material_code.includes(filters.materialCode)
                });
            }
        }
        // Description:
        if (filters.description) {
            filtered = filtered.filter((em) => em.material_description.includes(filters.description));
        }
        // Model:
        if ((!isExactKeysMatch || isRequestFromCalculatorAndUsUnits) && filters.model) {
            // filtered = filtered.filter((em) => em.model.includes(filters.model));
            let model_upper_case = filters.model.toUpperCase()
            filtered = filtered.filter((em) => em.model_upper_case.includes(model_upper_case));
        }
        // Flow rate:
        if ((!isExactKeysMatch || isRequestFromCalculatorAndUsUnits) && filters.flowRate) {
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.flow_rate.toString().includes(filters.flowRate)) : filtered.filter((em) => em.flow_rate_US.toString().includes(filters.flowRate));
        }

        if (filters.isBoth) {
            filtered = filtered.filter((em) => em.region_id == filters.region.toString())
            filters.region = null;
        }
        if (filters.isOnlyLocals) {
            filtered = filtered.filter((em) => em.region_id == filters.region.toString())
            filtered = filtered.filter((em) => em.global_emitter_ref == EmittersDAL.EMPTY_DB_FIELD || em.global_emitter_ref == EmittersDAL.EMPTY_DB_FIELD)
        }
        if (filters.isOnlyGlobal) {
            filtered = filtered.filter((em) => em.region_id == filters.region.toString())
            filtered = filtered.filter((em) => em.global_emitter_ref != EmittersDAL.EMPTY_DB_FIELD)
        }
        if (filters.globalStatus) {
            filtered = filtered.filter((em) => em.global_status == filters.globalStatus)
        }
        if (filters.localStatus) {
            filtered = filtered.filter((em) => em.local_status == filters.localStatus)
        }

        // cv:
        if (filters.cv) {
            filtered = filtered.filter((em) => em.cv.toString().includes(filters.cv));
        }
        // k Emitter Constant:
        if (filters.kEmitterConstant) {
            filtered = filtered.filter((em) => em.k_emitter_constant.toString().includes(filters.kEmitterConstant));
        }
        // x Emitter Exponent:
        if (filters.xEmitterExponent) {
            filtered = filtered.filter((em) => em.x_exponent.toString().includes(filters.xEmitterExponent));
        }
        // min Design Pressure:
        if (filters.minDesignPressure) {
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.min_design_pressure.toString().includes(filters.minDesignPressure)) : filtered.filter((em) => em.min_design_pressure_US.toString().includes(filters.minDesignPressure));
        }
        // max Design Pressure:
        if (filters.maxDesignPressure) {
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.max_design_pressure.toString().includes(filters.maxDesignPressure)) : filtered.filter((em) => em.max_design_pressure_US.toString().includes(filters.maxDesignPressure));
        }
        // min Tecnical Pressure:
        if (filters.minTechnicalPressure) {
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.min_technical_pressure.toString().includes(filters.minTechnicalPressure)) : filtered.filter((em) => em.min_technical_pressure_US.toString().includes(filters.minTechnicalPressure));
        }
        // max Tecnical Pressure:
        if (filters.maxTechnicalPressure) {
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.max_technical_pressure.toString().includes(filters.maxTechnicalPressure)) : filtered.filter((em) => em.max_technical_pressure_US.toString().includes(filters.maxTechnicalPressure));
        }
        // Date
        if (filters.date) {
            let filterdate = new Date(filters.date.getTime())
            let filterdateAdded = new Date(filters.date.getTime())
            filterdateAdded.setDate(filterdateAdded.getDate() + 1);

            filtered = filtered.filter((emitter) => {
                let cat = new Date(emitter.created_at);
                return filterdate < cat && cat < filterdateAdded
            })
        }
        let emitters = Array.from(filtered).sort();

        return this.mapEmitters(emitters);
    }

    public async getInlineEmitters(filters: any) {
        let { region, units, model, wallThickness, flowRate, isRequestFromCalculatorAndUsUnits, isOffline } = filters;
        region = region ? region.toString() : EmittersDAL.GLOBAL_REGION_STR;
        let emitterType = filters.type;
        let nominalDiameter = filters.nominalDiameter;
        let isExactKeysMatch = filters.isExactKeysMatch || false;

        if (filters.diameter) {
            filters.nominalDiameter = filters.diameter;
            nominalDiameter = filters.diameter;
        }

        // Get Inline emitters:
        let inlineEmitters = await this.getRegionInlineEmitters(emitterType, region, model, flowRate, nominalDiameter, wallThickness, isExactKeysMatch, isOffline, isRequestFromCalculatorAndUsUnits);
        inlineEmitters = inlineEmitters.Items || [];
        if (inlineEmitters.length == 0) {
            return inlineEmitters;
        }
        // Filter emitters:
        let filtered = inlineEmitters;


        // //update usa emitters
        // filtered = filtered.filter((em) => {
        //     return em.emitter_keys.toString().includes(' ')
        // })
        // let result = []
        // filtered.forEach(async emitter => {
        //     let emitter1 = await this.updateInlineEmitter({
        //         "type": "Driplines",
        //         "model": emitter.model,
        //         "flowRate": Number(emitter.flow_rate),
        //         "nominalDiameter": Number(emitter.nominal_diameter),
        //         "internalDiameter": Number(emitter.internal_diameter),
        //         "wallThickness": emitter.wall_thickness,
        //         "kd": emitter.kd_local_friction_factor,
        //         "roughness": emitter.roughness,
        //         "materialCode": emitter.material_code,
        //         "isPc": emitter.is_emitter_pc
        //         , "emitterDescription": emitter.material_description
        //         , "cv": emitter.cv,
        //         "kEmitterConstant": emitter.k_emitter_constant,
        //         "k2EmitterConstant": emitter.k2,
        //         "xEmitterExponent": emitter.x_exponent,
        //         "minDesignPressure": Number(emitter.min_design_pressure),
        //         "maxDesignPressure": Number(emitter.max_design_pressure),
        //         "minTechnicalPressure": Number(emitter.min_technical_pressure),
        //         "maxTechnicalPressure": Number(emitter.max_technical_pressure),
        //         "status": emitter.local_status
        //         , "units": 1
        //         , "isInlineEmitter": true
        //         , "emitterId": emitter.row_id
        //         , "region": emitter.region_id,
        //         "flowRateUS": emitter.flow_rate_US,
        //         "minDesignPressureUS": emitter.min_design_pressure_US,
        //         "maxDesignPressureUS": emitter.max_design_pressure_US,
        //         "minTechnicalPressureUS": emitter.min_technical_pressure_US,
        //         "maxTechnicalPressureUS": emitter.max_technical_pressure_US,
        //         "nominalDiameterUS": emitter.nominal_diameter_US,
        //         "internalDiameterUS": emitter.internal_diameter_US,
        //         "emitterKeys": `Driplines?${emitter.model.replace(/\s/g, '')}?${emitter.flow_rate}?${emitter.nominal_diameter}?${emitter.wall_thickness.toString().replace(/\s/g, '')}`
        //     })
        //     result.push(emitter1)
        // });

        // Material code:
        if (filters.materialCode) {
            if (filters.isExactMaterialCode) {
                filtered = filtered.filter((em) => {
                    return em.material_code == filters.materialCode
                });
            }
            else {
                filtered = filtered.filter((em) => {
                    return em.material_code.includes(filters.materialCode)
                });
            }
        }

        // Model:
        if ((!isExactKeysMatch || isRequestFromCalculatorAndUsUnits) && filters.model) {
            // filtered = filtered.filter((em) => em.model.includes(filters.model));
            let model_upper_case = filters.model.toUpperCase()
            filtered = filtered.filter((em) => em.model_upper_case.includes(model_upper_case));
        }

        // Flow rate:
        if ((!isExactKeysMatch || isRequestFromCalculatorAndUsUnits) && filters.flowRate) {
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.flow_rate.toString().includes(filters.flowRate)) : filtered.filter((em) => em.flow_rate_US.toString().includes(filters.flowRate));
        }
        if ((!isExactKeysMatch || isRequestFromCalculatorAndUsUnits) && filters.nominalDiameter) {
            // Nominal diameter:
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.nominal_diameter.toString().includes(filters.nominalDiameter.toString())) : filtered.filter((em) => em.nominal_diameter_US.toString().includes(filters.nominalDiameter.toString()));
        }
        if ((!isExactKeysMatch || isRequestFromCalculatorAndUsUnits) && filters.wallThickness) {
            // Wall thickness
            filtered = filtered.filter((em) => em.wall_thickness.toString().includes(filters.wallThickness));
        }
        if (filters.internalDiameter) {
            //Internal diameter
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.internal_diameter.toString().includes(filters.internalDiameter)) : filtered.filter((em) => em.internal_diameter_US.toString().includes(filters.internalDiameter));
        }
        if (filters.roughness) {
            // Roughness
            filtered = filtered.filter((em) => em.roughness.toString().includes(filters.roughness));
        }
        if (filters.kd) {
            // KD
            filtered = filtered.filter((em) => em.kd_local_friction_factor.toString().includes(filters.kd));
        }
        if (filters.isPc || filters.isPc === false) {
            filtered = filtered.filter((em) => em.is_emitter_pc == filters.isPc)
        }
        if (filters.isBoth) {
            filtered = filtered.filter((em) => em.region_id == filters.region.toString())
            filters.region = null;
        }
        if (filters.isOnlyLocals) {
            filtered = filtered.filter((em) => em.region_id == filters.region.toString())
            filtered = filtered.filter((em) => em.global_emitter_ref == EmittersDAL.EMPTY_DB_FIELD || em.global_emitter_ref == EmittersDAL.EMPTY_DB_FIELD)
        }
        if (filters.isOnlyGlobal) {
            filtered = filtered.filter((em) => em.region_id == filters.region.toString())
            filtered = filtered.filter((em) => em.global_emitter_ref != EmittersDAL.EMPTY_DB_FIELD)
        }
        if (filters.globalStatus) {
            filtered = filtered.filter((em) => em.global_status == filters.globalStatus)
        }
        if (filters.localStatus) {
            filtered = filtered.filter((em) => em.local_status == filters.localStatus)
        }

        // cv:
        if (filters.cv) {
            filtered = filtered.filter((em) => em.cv.toString().includes(filters.cv));
        }
        // k Emitter Constant:
        if (filters.kEmitterConstant) {
            filtered = filtered.filter((em) => em.k_emitter_constant.toString().includes(filters.kEmitterConstant));
        }
        // x Emitter Exponent:
        if (filters.xEmitterExponent) {
            filtered = filtered.filter((em) => em.x_exponent.toString().includes(filters.xEmitterExponent));
        }
        // min Design Pressure:
        if (filters.minDesignPressure) {
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.min_design_pressure.toString().includes(filters.minDesignPressure)) : filtered.filter((em) => em.min_design_pressure_US.toString().includes(filters.minDesignPressure));
        }
        // max Design Pressure:
        if (filters.maxDesignPressure) {
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.max_design_pressure.toString().includes(filters.maxDesignPressure)) : filtered.filter((em) => em.max_design_pressure_US.toString().includes(filters.maxDesignPressure));
        }
        // min Tecnical Pressure:
        if (filters.minTechnicalPressure) {
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.min_technical_pressure.toString().includes(filters.minTechnicalPressure)) : filtered.filter((em) => em.min_technical_pressure_US.toString().includes(filters.minTechnicalPressure));
        }
        // max Tecnical Pressure:
        if (filters.maxTechnicalPressure) {
            filtered = units == EUnits.METRIC ? filtered.filter((em) => em.max_technical_pressure.toString().includes(filters.maxTechnicalPressure)) : filtered.filter((em) => em.max_technical_pressure_US.toString().includes(filters.maxTechnicalPressure));
        }
        // Description:
        if (filters.description) {
            filtered = filtered.filter((em) => em.material_description.includes(filters.description));
        }

        // Date
        if (filters.date) {
            let filterdate = new Date(filters.date.getTime())
            let filterdateAdded = new Date(filters.date.getTime())
            filterdateAdded.setDate(filterdateAdded.getDate() + 1);

            filtered = filtered.filter((emitter) => {
                let cat = new Date(emitter.created_at);
                return filterdate < cat && cat < filterdateAdded
            })
        }
        let emitters = Array.from(filtered);

        return this.mapEmitters(emitters);
    }

    public mapToEmitter(emitter: any) {
        if (!emitter) {
            return null;
        }
        let isEmitterPc = emitter.is_emitter_pc == true || emitter.is_emitter_pc == "1" || emitter.is_emitter_pc == "true";
        emitter.flow_rate = Number(Number(emitter.flow_rate).toFixed(4));
        emitter.flow_rate_US = Number(Number(emitter.flow_rate_US).toFixed(4));
        emitter.nominal_diameter = emitter.nominal_diameter ? Number(Number(emitter.nominal_diameter).toFixed(4)) : null;
        emitter.nominal_diameter_US = emitter.nominal_diameter_US ? Number(Number(emitter.nominal_diameter_US).toFixed(4)) : null;
        emitter.internal_diameter = emitter.internal_diameter ? Number(Number(emitter.internal_diameter).toFixed(4)) : null;
        emitter.internal_diameter_US = emitter.internal_diameter_US ? Number(Number(emitter.internal_diameter_US).toFixed(4)) : null;

        emitter.min_technical_pressure = Number(Number(emitter.min_technical_pressure).toFixed(4));
        emitter.max_technical_pressure = Number(Number(emitter.max_technical_pressure).toFixed(4));
        emitter.min_technical_pressure_US = Number(Number(emitter.min_technical_pressure_US).toFixed(4));
        emitter.max_technical_pressure_US = Number(Number(emitter.max_technical_pressure_US).toFixed(4));

        emitter.min_design_pressure = Number(Number(emitter.min_design_pressure).toFixed(4));
        emitter.max_design_pressure = Number(Number(emitter.max_design_pressure).toFixed(4));
        emitter.min_design_pressure_US = Number(Number(emitter.min_design_pressure_US).toFixed(4));
        emitter.max_design_pressure_US = Number(Number(emitter.max_design_pressure_US).toFixed(4));

        emitter.is_emitter_pc = isEmitterPc;
        emitter.global_emitter_ref = emitter.global_emitter_ref != -1 ? emitter.global_emitter_ref : null;
        let date = emitter.created_at.split('T')[0];
        emitter.created_at = date;

        return emitter;
    }

    // ----------------------------------------------------------- PRIVATE METHODS -----------------------------------------------------------

    // Get all emitters in region
    private async getRegionEmitters(region: any, filters: any) {
        let emitterType = filters.emitterType || null;
        let isInlineEmitter = emitterType ? emitterType == EmitterTypes[EmitterTypes.Driplines] : null;
        let model = filters.model || null;
        let isOffline = filters.isOffline

        region = region ? region.toString() : EmittersDAL.GLOBAL_REGION_STR;
        let tasks = [];
        if (!emitterType) {
            tasks = [
                // Get Inline emitters:
                this.getRegionInlineEmitters(emitterType, region, model, null, null, null, false, isOffline),
                // Get Online emitters:
                this.getRegionOnlineEmitters(emitterType, region, model, null, false, isOffline)
            ]
        }
        else if (isInlineEmitter) {
            tasks = [
                // Get Inline emitters:
                this.getRegionInlineEmitters(emitterType, region, model, null, null, null, true, isOffline),
            ]
        }
        else {
            tasks = [
                // Get Online emitters:
                this.getRegionOnlineEmitters(emitterType, region, model, null, true, isOffline)
            ]
        }


        let res = await Promise.all(tasks);
        let inlineEmitters = (!emitterType || isInlineEmitter) ? res[0].Items : [];
        let onlineEmitters = [];
        if (!emitterType) {
            onlineEmitters = res[1].Items;
        }
        else if (!isInlineEmitter) {
            onlineEmitters = res[0].Items;
        }

        let emitters = [];

        inlineEmitters.forEach(em => {
            emitters.push(em);
        });
        onlineEmitters.forEach(em => {
            emitters.push(em);
        });

        return emitters;
    }

    // Get inline emitters in region
    private async getRegionInlineEmitters(emitterType: string, region: any, model: string, flowRate: string, nominalDiameter: string, wallThickness: string, isExactKeysMatch: boolean, isOffline: boolean, isRequestFromCalculatorAndUsUnits?: boolean) {
        region = region ? region.toString() : EmittersDAL.GLOBAL_REGION_STR;

        let keys: any = emitterType || "";
        let KeyConditionExpression = ""
        if (isExactKeysMatch) {
            if (model) {
                // add model to keys query string:
                keys += "?" + model.replace(/\s/g, '') + "?";
            }
            if (flowRate) {
                // add flowRate to keys query string:
                let flow: any = flowRate.toString().split('.');
                keys += flowRate + "?";

                if (nominalDiameter) {
                    // add nominalDiameter to keys query string:
                    keys += nominalDiameter + "?";

                    if (wallThickness) {
                        // add wallThickness to keys query string:
                        keys += wallThickness.toString().replace(/\s/g, '');
                    }
                }
            }

        }

        if (isOffline) {
            let data_table = JSON.parse(localStorage.getItem(EmittersDAL.LOCAL_STORAGE_INLINE_EMITTERS_TABLE_NAME))
            if ((isExactKeysMatch || emitterType != null) && !isRequestFromCalculatorAndUsUnits) {
                let filtered_emitters = data_table.filter((em) => (em.emitter_keys).startsWith(keys))
                return { Items: filtered_emitters };
            }
            return { Items: data_table };
        }

        let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
        let inlineEmittersTable = this.getTableName(EmittersDAL.INLINE_EMITTERS_TABLE_NAME);

        let inlineEmittersParams: any = {
            TableName: inlineEmittersTable,
            KeyConditionExpression: 'region_id = :hkey',
            IndexName: 'emitter_keys',
            ExpressionAttributeValues: {
                ':hkey': region
            }
        };
        if ((isExactKeysMatch || emitterType != null) && !isRequestFromCalculatorAndUsUnits) {
            inlineEmittersParams.KeyConditionExpression += ' and begins_with(emitter_keys, :skey)';
            inlineEmittersParams.ExpressionAttributeValues[':skey'] = keys;
        }


        let inlineEmitters = await docClient.query(inlineEmittersParams).promise();
        let LastEvaluatedKey = inlineEmitters.LastEvaluatedKey

        if (LastEvaluatedKey) {
            do {
                inlineEmittersParams.ExclusiveStartKey = LastEvaluatedKey;
                let response = await docClient.query(inlineEmittersParams).promise();
                inlineEmitters.Count = response.Count + inlineEmitters.Count
                inlineEmitters.ScannedCount = inlineEmitters.ScannedCount + response.ScannedCount
                let items = inlineEmitters.Items.concat(response.Items)
                inlineEmitters.Items = items
                LastEvaluatedKey = response.LastEvaluatedKey
            } while (LastEvaluatedKey)
        }

        return inlineEmitters;
    }

    // Get online emitters in region
    private async getRegionOnlineEmitters(emitterType: string, region: any, model: string, flowRate: string, isExactKeysMatch: boolean, isOffline: boolean, isRequestFromCalculatorAndUsUnits?: boolean) {
        region = region ? region.toString() : EmittersDAL.GLOBAL_REGION_STR;

        let keys = emitterType || "";
        if (isExactKeysMatch) {
            if (model) {
                // add model to keys query string:
                keys += "?" + model.replace(/\s/g, '') + "?";
            }
            if (flowRate) {
                // add flowRate to keys query string:
                let flow: any = flowRate.toString().split('.');
                keys += flowRate;
            }
        }

        if (isOffline) {
            let data_table = JSON.parse(localStorage.getItem(EmittersDAL.LOCAL_STORAGE_ONLINE_EMITTERS_TABLE_NAME))
            if ((isExactKeysMatch || emitterType != null) && !isRequestFromCalculatorAndUsUnits) {
                let filtered_emitters = data_table.filter((em) => (em.emitter_keys).startsWith(keys))
                return { Items: filtered_emitters };
            }
            return { Items: data_table };
        }


        let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
        let onlineEmittersTable = this.getTableName(EmittersDAL.ONLINE_EMITTERS_TABLE_NAME);
        let onlineEmittersParams = {
            TableName: onlineEmittersTable,
            KeyConditionExpression: 'region_id = :hkey',
            IndexName: 'emitter_keys',
            ExpressionAttributeValues: {
                ':hkey': region
            }
        };
        if ((isExactKeysMatch || emitterType != null) && !isRequestFromCalculatorAndUsUnits) {
            onlineEmittersParams.KeyConditionExpression += ' and begins_with(emitter_keys, :skey)';
            onlineEmittersParams.ExpressionAttributeValues[':skey'] = keys;
        }

        let onlineEmitters = await docClient.query(onlineEmittersParams).promise();
        if (isExactKeysMatch && flowRate && !isRequestFromCalculatorAndUsUnits) {
            onlineEmitters.Items = onlineEmitters.Items.filter((emit => emit.flow_rate == flowRate))
        }
        return onlineEmitters;
    }

    private mapEmitters(emitters: any) {
        let _this = this;
        let emittersRet = emitters.map((em) => this.mapToEmitter(em));
        let sorted = emittersRet.sort(function (a, b) {

            let adate: any = new Date(b.created_at);
            let bdate: any = new Date(a.created_at);
            let materialSort = _this.secodSort(b.model, a.model);
            return adate - bdate || materialSort;
        });

        return sorted;
    }



}