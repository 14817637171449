import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { EtransaltionGroups, Ecalculator } from "src/app/shared/enum";
import { TextService } from "src/app/services/text.service";
var $;

@Component({
  selector: "app-pdf",
  templateUrl: "./pdf.component.html",
  styleUrls: ["./pdf.component.scss"],
})
export class PdfComponent implements OnInit, AfterViewInit {
  EtransaltionGroups = EtransaltionGroups;
  Ecalculator = Ecalculator;
  @Input() topResultBody: any[];
  @Input() columnsDisplayNames;
  @Input() displayedColumns: string[];
  @Input() results;
  @Input() title;
  @Input() calculatorIndex;
  @Input() flushingResultBody;
  @Input() flushingResultGivenInletPressure;
  @Input() queryIndex;
  @Input() lateral_text;
  @Input() model_text;
  @Input() pipes_diameter_text;
  @Input() elevationData; // add
  @Input() columnsDisplayNamesElevation; //add
  pipes;
  flushing_subtitle_text;
  columnsDisplayForPdf

  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {
    this.dataService.reloadPipes.subscribe((res) => {
      setTimeout(() => {
        this.initProperties();
        this.setFlushingResultText();
      });
    });
  }

  ngOnInit(): void {
    this.initProperties();
    this.setFlushingResultText();
  }

  setFlushingResultText() {
    switch (this.calculatorIndex) {
      case Ecalculator.submain:
        this.flushing_subtitle_text = this.textService.getText(
          EtransaltionGroups.SUBMAIN,
          "submain_results_flushing_subtitle"
        );
        break;
      case Ecalculator.lateral:
        this.flushing_subtitle_text = this.textService.getText(
          EtransaltionGroups.LATERAL,
          "lateral_results_flushing_subtitle"
        );
        break;
      case Ecalculator.mainline:
        this.flushing_subtitle_text = this.textService.getText(
          this.EtransaltionGroups.MAINLINE,
          "mainline_results_flushing_subtitle"
        );
        break;
      default:
        break;
    }
  }

  initProperties() {
    this.pipes = this.results.calculation_extra_details.pipes;
    if (this.queryIndex === 3) {
      document.documentElement.style.setProperty(
        "--color_for_second_result_title",
        "var(--netafim-blue)"
      );
      document.documentElement.style.setProperty(
        "--color_for_second_result_value",
        "var(--netafim-blue)"
      );
      document.documentElement.style.setProperty(
        "--background_for_second_result",
        "#eff7ff"
      );
    } else {
      document.documentElement.style.setProperty(
        "--color_for_second_result_title",
        "#6e6e6e"
      );
      document.documentElement.style.setProperty(
        "--color_for_second_result_value",
        "#2d2d2d"
      );
      document.documentElement.style.setProperty(
        "--background_for_second_result",
        "#ffffff"
      );
    }
  }

  ngAfterViewInit() {}
}
