import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { OfflineService } from "src/app/services/offline.service";
import { TextService } from "src/app/services/text.service";
import { EtransaltionGroups } from "src/app/shared/enum";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-confirmation-popup",
  templateUrl: "./confirmation-popup.component.html",
  styleUrls: ["./confirmation-popup.component.scss"],
})
export class ConfirmationPopupComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  constructor(
    public textService: TextService,
    public dataService: DataService,
    public offlineService: OfflineService
  ) {}
  ngOnInit(): void {
    this.textService.setTextSource();
  }
  cancel() {
    this.dataService.showConfirmationPopup = false;
    this.offlineService.isItOfflineMode = false;
  }

  accept() {
    this.offlineService.turnHydroToOfflineMode.next();
    this.dataService.showConfirmationPopup = false;
  }
}
