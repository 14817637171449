import { Component, OnInit, Input, HostListener } from '@angular/core';
import { BasePopup } from 'src/app/models/popuptype';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-general-popup',
  templateUrl: './general-popup.component.html',
  styleUrls: ['./general-popup.component.scss']
})
export class GeneralPopupComponent extends BasePopup implements OnInit {
  @Input() popup_type: number = this.PopupTypes.warning;
  @Input() allowClose: boolean = true;
  @Input() signUpFunctionality = false;
  constructor(public dataService: DataService) {
    super();
  }

  ngOnInit(): void {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.dataService.closePopup(this.popup_type);
  }
}
