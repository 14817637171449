export enum ELanguages {
    NONE = 0,
    ENGLISH = 1,
    SPANISH,
    GERMAN,
    CHINESE,
    TURKISH,
    ITALIAN,
    PORTUGUESE,
    FRENCH,
    DUTCH,
    KOREAN,
}