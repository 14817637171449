import { ValveCalculationsFactory } from "../Factories/ValveCalculationsFactory";
import { ICalculationProcess } from "../Interfaces/ICalculationProcess";

export class ValveCalculator {
  public calculate(calculationData: any) {
    const calculationProcess: ICalculationProcess =
      ValveCalculationsFactory.getCalcProcess(calculationData.calcType);
    if (!calculationProcess) {
      return null;
    }
    const results = calculationProcess.start(calculationData);
    return results;
  }
}
