import { CalculationInputsDAL } from "../DAL/CalculationInputsDAL";
import { UnitsConverter } from "../UnitsConverter/UnitsConverter";

export class CalculationInputsService {
    calculationInputsDAL: CalculationInputsDAL = new CalculationInputsDAL();

    public async getCalculationInputs(serviceData: any): Promise<any> {
        let filters = serviceData.filters;
        let isOffline = serviceData.isOffline

        let inputs = await this.calculationInputsDAL.getCalculationInputs({ filters, isOffline });
        // Deep copy:
        let bothInputs: any = JSON.parse(JSON.stringify(inputs));
        let convertedInputs = UnitsConverter.convertCalculationInputsToUSUnits(inputs);

        for (let i = 0; i < bothInputs.length; i++) {
            const mInpuT = bothInputs[i];
            mInpuT.min_value_us_units = convertedInputs[i].min_value || null;
            mInpuT.max_value_us_units = convertedInputs[i].max_value || null;
            mInpuT.default_value_us_units = convertedInputs[i].default_value || null;
        }
        return bothInputs;
    }


    public async createCalculationInput(serviceData: any): Promise<any> {
        let inputData = serviceData.inputData;

        let input = await this.calculationInputsDAL.createCalculationInput(inputData);
        return input;
    }

    public async updateCalculationInput(serviceData: any): Promise<any> {
        let inputData = serviceData.inputData;
        let region = serviceData.region;

        let input = await this.calculationInputsDAL.updateCalculationInput(inputData, region);
        return input;
    }

    public async getCalculationInput(serviceData: any): Promise<any> {
        let inputId = serviceData.inputId;
        let region = serviceData.region;
        let input: any = await this.calculationInputsDAL.getCalculationInput(inputId, region);
        let bothInputs: any = JSON.parse(JSON.stringify(input));
        let convertedInputs = UnitsConverter.convertCalculationInputsToUSUnits([input]);

        bothInputs.min_value_us_units = convertedInputs[0].min_value || null;
        bothInputs.max_value_us_units = convertedInputs[0].max_value || null;
        bothInputs.default_value_us_units = convertedInputs[0].default_value || null;

        return bothInputs;
    }
}