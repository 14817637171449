import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { DataService } from './services/data.service';
import { OfflineService } from './services/offline.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    this.offlineService.isItOfflineMode = localStorage.getItem('isOffline') === 'true'
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      this.analyticsService.sendPageView(evt.urlAfterRedirects, evt.url);
    });
  }

  title = 'HydroCalcNetafim-Web';

  constructor(

    public router: Router,
    public dataService: DataService,
    public analyticsService: GoogleAnalyticsService,
    public offlineService: OfflineService

  ) {
    this.dataService.isItAppleDevice = (/iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()));

    this.dataService.isItMobile = window.innerWidth < 600;

  }

}
