import { AbsDAL } from "./Base/AbsDAL";
import { EPipeStatus } from "../enums/PipeStatus.enum";
import { ObjectId } from "mongodb";
import { EUnits } from "../enums/Units.enum";
import { RegionsDAL } from "./RegionsDAL";
let AWS = require('aws-sdk');

export class PipesDAL extends AbsDAL {
    public static readonly PIPES_TABLE_NAME = "Pipes";
    public static readonly LOCAL_STORAGE_PIPES_TABLE_NAME = "pipes";

    /**
     * Gets Pipe types/materials
     * 
     */
    public async getPipeTypes(region: any, pClass: any = null, pNominalDiameter: any = null, units: any, pipeRelevants: any, isOffline: boolean) {
        let isExactMatch = true;
        let allPipes = await this.getRegionPipes(region, null, isOffline, isExactMatch);
        let filtered = allPipes;

        //filter by local status = on
        filtered = filtered.filter((pipe) => pipe.local_status == EPipeStatus.PUBLISH)

        if (pClass || pNominalDiameter) {
            if (pClass) {
                filtered = filtered.filter((pipe) => pipe.pipe_class == pClass)
            }
            if (pNominalDiameter) {
                if (units == EUnits.US_UNITS) {
                    filtered = filtered.filter((pipe) => pipe.nominal_diameter_US == pNominalDiameter)
                }
                else {
                    filtered = filtered.filter((pipe) => pipe.nominal_diameter == pNominalDiameter)
                }
            }

        }
        if (pipeRelevants) {
            if (pipeRelevants.isReleventForMicroSprinklers) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_micro_sprinklers == true);
            }
            if (pipeRelevants.isReleventForOnlineDrippers) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_online_drippers == true);
            }
            if (pipeRelevants.isReleventForSprinklers) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_sprinklers == true);
            }
            if (pipeRelevants.isReleventForMainline) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_mainline == true);
            }
            if (pipeRelevants.isReleventForSubmain) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_submain == true);
            }

        }
        // Get unique types:
        let unique = new Set(filtered.map(pipe => pipe.pipe_material));

        return Array.from(unique).sort();
    }


    /**
     * Gets Pipe classes
     * 
     */
    public async getPipeClasses(region: any, ptype: any = null, pNominalDiameter: any = null, units, pipeRelevants, isOffline) {
        let allPipes = await this.getRegionPipes(region, null, isOffline);
        let filtered = allPipes;

        //filter by local status = on
        filtered = filtered.filter((pipe) => pipe.local_status == EPipeStatus.PUBLISH)

        if (ptype || pNominalDiameter) {
            if (ptype) {
                filtered = filtered.filter((pipe) => pipe.pipe_material == ptype)
            }
            if (pNominalDiameter) {
                if (units == EUnits.US_UNITS) {
                    filtered = filtered.filter((pipe) => pipe.nominal_diameter_US == pNominalDiameter)
                }
                else {
                    filtered = filtered.filter((pipe) => pipe.nominal_diameter == pNominalDiameter)
                }
            }
        }
        if (pipeRelevants) {
            if (pipeRelevants.isReleventForMicroSprinklers) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_micro_sprinklers == true);
            }
            if (pipeRelevants.isReleventForOnlineDrippers) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_online_drippers == true);
            }
            if (pipeRelevants.isReleventForSprinklers) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_sprinklers == true);
            }
            if (pipeRelevants.isReleventForMainline) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_mainline == true);
            }
            if (pipeRelevants.isReleventForSubmain) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_submain == true);
            }
        }
        // Get uniqe classes:
        let unique = new Set(filtered.map(pipe => pipe.pipe_class));
        let arr = Array.from(unique).sort((a: any, b: any) => a - b);

        return arr;
    }

    /**
     * Gets Pipe nominal diameters
     * 
     */
    public async getPipeNominalDiameters(region: any, ptype: any = null, pClass: any = {}, units: any, pipeRelevants, previous_section_nominal_diameter_selcection: number = null, isOffline: boolean) {
        let isExactMatch = true;
        let allPipes = await this.getRegionPipes(region, pClass, isOffline, isExactMatch);
        let filtered = allPipes;

        //filter by local status = on
        filtered = filtered.filter((pipe) => pipe.local_status == EPipeStatus.PUBLISH)

        if (ptype || pClass) {
            if (ptype) {
                filtered = filtered.filter((pipe) => pipe.pipe_material == ptype)
            }
            if (pClass) {
                filtered = filtered.filter((pipe) => pipe.pipe_class == pClass)
            }
        }
        if (pipeRelevants) {
            if (pipeRelevants.isReleventForMicroSprinklers) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_micro_sprinklers == true);
            }
            if (pipeRelevants.isReleventForOnlineDrippers) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_online_drippers == true);
            }
            if (pipeRelevants.isReleventForSprinklers) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_sprinklers == true);
            }
            if (pipeRelevants.isReleventForMainline) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_mainline == true);
            }
            if (pipeRelevants.isReleventForSubmain) {
                filtered = filtered.filter((pipe) => pipe.is_relevant_for_submain == true);
            }
        }

        // Get uniqe diameters:
        let nominalDiameters: any;

        if (units == EUnits.US_UNITS) {
            nominalDiameters = filtered.map(pipe => pipe.nominal_diameter_US.toString());
        }
        else {
            nominalDiameters = filtered.map(pipe => pipe.nominal_diameter.toString());
        }
        let unique = new Set(nominalDiameters);
        let diameters = Array.from(unique).sort((a: any, b: any) => a - b);

        //return more relevant diameters
        if (previous_section_nominal_diameter_selcection) {
            let diameter_index = diameters.findIndex(nominalDiameter => Number(nominalDiameter) > previous_section_nominal_diameter_selcection)
            if (diameter_index != -1 && diameter_index + 1 < diameters.length) {
                diameters.splice(diameter_index + 1)
            }
        }
        //add label and value to every nominal diameter - show Integers (label)
        let diameters_list = diameters.map(diameter => ({ label: units == EUnits.METRIC ? Math.floor(Number(diameter)) : Number(diameter).toFixed(3), value: diameter }))
        return diameters_list;
    }

    /**
     * Gets Pipe Internal Diameter And Roughness
     * 
     */
    public async getPipeInternalDiameterAndRoghness(region: any, type: any, pClass: any, diameter: any, units: any, isOffline: boolean) {
        let isExactMatch = true;
        let allPipes = await this.getRegionPipes(region, pClass, isOffline, isExactMatch);

        //filter by local status = on
        allPipes = allPipes.filter((pipe) => pipe.local_status == EPipeStatus.PUBLISH)

        let pipe = units == EUnits.METRIC ? allPipes.filter((pipe) => pipe.pipe_class == pClass && pipe.nominal_diameter == diameter && pipe.pipe_material == type) : allPipes.filter((pipe) => pipe.pipe_class == pClass && pipe.nominal_diameter_US == diameter && pipe.pipe_material == type);
        // Get uniqe diameters:
        let internalDiameter: any = units == EUnits.METRIC ? pipe[0].internal_diameter : pipe[0].internal_diameter_US;
        let roughness: number = Number(pipe[0].pipe_roughness)

        return { internalDiameter, roughness };
    }

    /**
     * Gets Single Pipe details
     * 
     */
    public async getPipe(type: any, pClass: any, diameter: any, isGlobalPipe: boolean, region: any = PipesDAL.GLOBAL_REGION_STR, isRequestFromCalculatorAndUsUnits: any, isOffline: boolean) {
        let isExactMatch = true;

        let allPipes = await this.getRegionPipes(region, pClass, isOffline, isExactMatch);
        let filtered = allPipes;

        filtered = filtered.filter((pipe) => pipe.pipe_material == type)
        filtered = filtered.filter((pipe) => {
            if (isRequestFromCalculatorAndUsUnits) {
                return pipe.nominal_diameter_US == diameter.toString()
            }
            return pipe.nominal_diameter == diameter.toString()

        })
        if (isGlobalPipe) {
            filtered = filtered.filter((pipe) => pipe.is_global_pipe == isGlobalPipe)
        }

        return filtered[0];
    }

    /**
     * Gets Pipes
     * 
     */
    public async getPipes(filters: any, pagination: any = null, isItCalculator: boolean, isOffline: boolean) {
        try {
            let pClass = filters.pClass || filters.class;
            let pType = filters.type
            let isExactMatch = true;
            let allPipes = await this.getRegionPipes(filters.region, pClass, isOffline, isExactMatch, pType);
            let filtered = allPipes;
            let pipeId = filters.pipeId ? filters.pipeId.toString() : null;
            let type = filters.type;
            let nominalDiameter = filters.diameter || filters.nominalDiameter;

            if (isItCalculator) {
                //filter by local status = on
                filtered = filtered.filter((pipe) => pipe.local_status == EPipeStatus.PUBLISH)
            }

            if (pipeId) {
                filtered = filtered.filter((pipe) => pipe.row_id == pipeId)
            }
            if (type) {
                filtered = filtered.filter((pipe) => pipe.pipe_material == type)
            }
            if (pClass) {
                filtered = filtered.filter((pipe) => pipe.pipe_class == pClass.toString())
            }

            if (nominalDiameter) {
                if (filters.units == EUnits.US_UNITS) {
                    filtered = filtered.filter((pipe) => pipe.nominal_diameter_US == nominalDiameter.toString())
                }
                else {
                    // Units == Metric
                    filtered = filtered.filter((pipe) => pipe.nominal_diameter == nominalDiameter.toString())
                }
            }
            if (filters.internalDiameter) {
                if (filters.units == EUnits.US_UNITS) {
                    filtered = filtered.filter((pipe) => pipe.internal_diameter_US == filters.internalDiameter.toString())
                }
                else {
                    // Units == Metric
                    filtered = filtered.filter((pipe) => pipe.internal_diameter == filters.internalDiameter.toString())
                }
            }
            if (filters.maxDesignPressure) {
                if (filters.units == EUnits.US_UNITS) {
                    filtered = filtered.filter((pipe) => pipe.max_allowable_design_pressure_US == filters.maxDesignPressure.toString())
                }
                else {
                    // Units == Metric
                    filtered = filtered.filter((pipe) => pipe.max_allowable_design_pressure == filters.maxDesignPressure.toString())
                }
            }
            if (filters.maxVelocity) {
                if (filters.units == EUnits.US_UNITS) {
                    filtered = filtered.filter((pipe) => pipe.max_velocity_US == filters.maxVelocity.toString())
                }
                else {
                    // Units == Metric
                    filtered = filtered.filter((pipe) => pipe.max_velocity == filters.maxVelocity.toString())
                }
            }


            if (filters.isGlobalPipe || filters.isGlobalPipe == false) {
                filtered = filtered.filter((pipe) => pipe.is_global_pipe == filters.isGlobalPipe)
            }
            if (filters.roughness) {
                filtered = filtered.filter((pipe) => pipe.pipe_roughness == filters.roughness.toString())
            }
            if (filters.KD || filters.KD == 0) {
                filtered = filtered.filter((pipe) => pipe.kd_local_friction_factor == filters.KD.toString())
            }
            if (filters.materialCode) {
                filtered = filtered.filter((pipe) => pipe.material_code == filters.materialCode)
            }
            if (filters.isBoth) {
                filtered = filtered.filter((pipe) => pipe.region_id == filters.region.toString())
                filters.region = null;
            }
            if (filters.isOnlyLocals) {
                filtered = filtered.filter((pipe) => pipe.region_id == filters.region.toString())
                filtered = filtered.filter((pipe) => pipe.global_pipe_ref == PipesDAL.EMPTY_DB_FIELD)
            }
            if (filters.isOnlyGlobal) {
                filtered = filtered.filter((pipe) => pipe.region_id == filters.region.toString())
                filtered = filtered.filter((pipe) => pipe.global_pipe_ref != PipesDAL.EMPTY_DB_FIELD)
            }
            if (filters.region) {
                let reg = filters.region ? filters.region.toString() : filters.region_id;
                filtered = filtered.filter((pipe) => pipe.region_id == reg)
            }
            if (filters.globalStatus) {
                filtered = filtered.filter((pipe) => pipe.global_status == filters.globalStatus)
            }
            if (filters.localStatus) {
                filtered = filtered.filter((pipe) => pipe.local_status == filters.localStatus)
            }
            if (filters.description) {
                filtered = filtered.filter((pipe) => pipe.material_description == filters.description.toUpperCase())
            }
            if (filters.globalPipeRef) {
                filtered = filtered.filter((pipe) => pipe.global_pipe_ref == filters.globalPipeRef.toString())
            }

            filters = this.mapPipes(filtered);

            let pipes = {
                pipes: filtered,
                total_results: filtered.length
            }
            return pipes
        } catch (error) {
            throw error;
        }
    }

    private mapPipes(filtered: any): any {
        let _this = this;
        filtered.forEach(pipe => {
            // spilt date:
            let date = pipe.created_at.split('T')[0];
            pipe.created_at = date;
        });
        let sorted = filtered.sort(function (a, b) {

            let adate: any = new Date(b.created_at);
            let bdate: any = new Date(a.created_at);
            let materialSort = _this.secodSort(a.pipe_material, b.pipe_material);
            return adate - bdate || materialSort;
        });

        return sorted;
    }

    // Get all pipes in region
    public async getRegionPipes(region: any, pipeClass: any, isOffline: boolean, isExactMatch: boolean = false, pipeType: any = null) {
        if (isOffline) {
            let data_table = JSON.parse(localStorage.getItem(PipesDAL.LOCAL_STORAGE_PIPES_TABLE_NAME))
            return data_table;
        }
        let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
        let table = this.getTableName(PipesDAL.PIPES_TABLE_NAME);
        region = region ? region.toString() : PipesDAL.GLOBAL_REGION_STR;

        // Get all Regions params:
        let params;

        if (pipeClass && isExactMatch) {
            params = {
                TableName: table,
                IndexName: 'pipe_class',
                KeyConditionExpression: 'pipe_class = :key2 and region_id = :key1',
                ExpressionAttributeValues: {
                    ':key2': pipeClass.toString(),
                    ':key1': region.toString()
                }
            };
        }
        else if (pipeType && isExactMatch) {
            params = {
                TableName: table,
                IndexName: 'pipe_material_upper_case',
                KeyConditionExpression: 'pipe_material_upper_case = :key2 and region_id = :key1',
                ExpressionAttributeValues: {
                    ':key2': pipeType.toString().toUpperCase(),
                    ':key1': region.toString()
                }
            };
        }
        else {
            params = {
                TableName: table,
                KeyConditionExpression: 'region_id = :hkey',
                ExpressionAttributeValues: {
                    ':hkey': region,
                }
            };
        }
        let res = await docClient.query(params).promise();

        return res.Items;
    }
}