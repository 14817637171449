import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import {
  Ecalculator,
  EtransaltionGroups,
  EIrrigationSteps,
} from "src/app/shared/enum";
import { IrrigationCharacteristicsLabels } from "src/app/shared/characteristics/calc-labels.types";

@Component({
  selector: "app-irrigation-characteristics",
  templateUrl: "./irrigation-characteristics.component.html",
  styleUrls: ["./irrigation-characteristics.component.scss"],
})
export class IrrigationCharacteristicsComponent implements OnInit {
  @Input() irrigationForm: FormGroup;
  @Input() currentStep: number;
  @Input() labels: IrrigationCharacteristicsLabels;
  @Input() user_id: string;

  Ecalculator = Ecalculator;
  EtransaltionGroups = EtransaltionGroups;

  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {}

  get controls() {
    return this.irrigationForm.controls;
  }

  ngOnInit(): void {}
}
