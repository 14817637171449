import { CardData } from "./home-page.interfaces";
import { ExternalToolCardDataMap } from "./home-page.types";

export const calcsCardsData: CardData[] = [
  {
    calcName: "lateral",
    isDisabled: false,
    titleKey: "home_lateral",
    textKey: "home_lateral_text",
  },
  {
    calcName: "submain",
    isDisabled: false,
    titleKey: "home_submain",
    textKey: "home_submain_text",
  },

  {
    calcName: "mainline",
    isDisabled: false,
    titleKey: "home_main",
    textKey: "home_mainline_text",
  },
  {
    calcName: "valve",
    isDisabled: false,
    titleKey: "home_valve",
    textKey: "home_valve_text",
  },
  {
    calcName: "irrigation",
    isDisabled: false,
    titleKey: "home_irrigation",
    textKey: "home_irrigation_text",
  },
  {
    calcName: "shift",
    isDisabled: false,
    titleKey: "home_shift",
    textKey: "home_shift_text",
  },
  {
    calcName: "energy",
    isDisabled: false,
    titleKey: "home_energy",
    textKey: "home_energy_text",
  },
  {
    calcName: "flushing-manifold",
    isDisabled: true,
    titleKey: "home_unit_converter",
    textKey: "home_unit_converter_text",
  },
];

export const externalToolCardDataMap: ExternalToolCardDataMap = {
  NetSpeX: {
    iconName: "netspex",
    textKey: "home_netspex_text",
    link: "https://netspex.netafim.com/#/NetSpeX/pages/home",
  },
  FilterConfig: {
    iconName: "filter-config",
    textKey: "home_filter_config_text",
    link: "https://filterconfig.netafim.com/",
  },
  ProductConfig: {
    iconName: "product-config",
    textKey: "home_product_config_text",
    link: "https://productconfig.netafim.com/",
  },
  DosingConfig: {
    iconName: "dosing-config",
    textKey: "home_dosing_config_text",
    link: "https://dosingconfig.netafim.com/",
  },
  "App Store": {
    iconName: "app-store",
    textKey: "home_app_store_text",
    link: "https://play.google.com/store/apps/dev?id=6506748078502506562&hl=en&pli=1",
  },
};
