import { ValveCalculationType } from "../../enums/ValveCalculationType.enum";
import { PressureLossForValve } from "../CalculationProcess/Valve/PressureLossForValve";
import { ValveDiameter } from "../CalculationProcess/Valve/ValveDiameter";
import { ICalculationProcess } from "../Interfaces/ICalculationProcess";

export class ValveCalculationsFactory {
  static getCalcProcess(calcType: ValveCalculationType): ICalculationProcess {
    let calcProcess: ICalculationProcess = null;
    switch (calcType) {
      case ValveCalculationType.PRESSURE_LOSS:
        calcProcess = new PressureLossForValve();

        break;
      case ValveCalculationType.VALVE_DIAMETERS:
        calcProcess = new ValveDiameter();

        break;
    }

    return calcProcess;
  }
}
