import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent implements OnInit {
  @Input() changeFrom = 'Submain Characteristics';
  @Input() changeTo = 'Calculation Of';
  constructor(public router: Router, public dataService: DataService) {}

  ngOnInit(): void {}

  deleteDataAndChangeCalculatorSection() {
    if (this.router.url.includes('submain')) {
      this.dataService.AcceptChangeBetweenSectionOnCalculatorPage.next();
    } else if (this.router.url.includes('lateral')) {
      this.dataService.changeToAnotherSectionInCalculator.next();
    }
  }

  cancel() {
    this.dataService.showWarningPopup = false;
  }
}
