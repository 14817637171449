import { Component, OnInit, ViewChild } from "@angular/core";
import { CalculatorService } from "../../../services/calculator.service";
import { DataService } from "../../../services/data.service";
import { Router, NavigationEnd } from "@angular/router";
import { CognitoApisServiceService } from "src/app/services/cognito-apis-service.service";
import { TextService } from "src/app/services/text.service";
import { EtransaltionGroups } from "src/app/shared/enum";
import { OfflineService } from "src/app/services/offline.service";
import {
  CalculatorCard,
  ExternalTool,
  ExternalToolCard,
} from "src/app/shared/home-page/home-page.interfaces";
import {
  calcsCardsData,
  externalToolCardDataMap,
} from "src/app/shared/home-page/home-page.data";
import { ExternalToolName } from "src/app/shared/home-page/home-page.types";

@Component({
  selector: "app-calc-home",
  templateUrl: "./calc-home.component.html",
  styleUrls: ["./calc-home.component.scss"],
})
export class CalcHomeComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  region: string = "5f6dd4ebf977870c98e97926";
  selectedRegionIndex: number;
  content: any;
  user;
  externalTools: ExternalTool[];
  calcsCards: CalculatorCard[]; // calculators cards
  externalToolsCards: ExternalToolCard[]; // cards for digital tools set by admin
  productsAndSolutionsLink: string;
  readMoreLabelText: string

  mySubscription: any;
  constructor(
    public router: Router,
    private calculatorService: CalculatorService,
    private dataService: DataService,
    public cognitoAWSService: CognitoApisServiceService,
    public textService: TextService,
    public offlineService: OfflineService
  ) {
    this.dataService.getHomePageData.subscribe((resp) => {
      this.router.navigateByUrl("calc/home");
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
    if (localStorage.getItem("isFirstSignup")) {
      this.dataService.showSettingsPopup = true;
      localStorage.removeItem("isFirstSignup")
    }
  }

  ngOnInit(): void {
    this.getPageData();
  }
  async getPageData() {
    this.dataService.showLoader = true;

    if (this.offlineService.isItOfflineMode) {
      let response = await this.calculatorService.getHomePageOffline();
      this.dataService.showLoader = false;
      this.setPageDataResponse(response);
    } else {
      this.calculatorService.getHomePage().subscribe(
        (response) => {
          this.dataService.showLoader = false;
          console.log(response);

          this.setPageDataResponse(response);
        },
        (error) => {
          this.dataService.showLoader = false;
          console.log(error);
        }
      );
    }
  }

  setPageDataResponse(response) {
    const { user, content, regions, languages, externalTools } =
      response.result.page_data;
    const {
      first_name: firstName,
      last_name: lastName,
      region: userRegion,
      language: userLanguage,
      units: userUnits,
    } = user;
    const { links } = content;
    this.dataService.user_name = this.buildUserName(firstName, lastName);
    this.content = content;
    this.productsAndSolutionsLink = links[0];
    this.dataService.regions_list = regions;
    this.region = userRegion;
    this.getSelectedRegion();
    this.dataService.selected_region =
      this.dataService.regions_list[this.selectedRegionIndex];
    this.dataService.languages_list = languages;
    this.dataService.selected_language = this.dataService.languages_list.find(
      (value) => {
        return value.row_id === userLanguage.toString();
      }
    );
    this.getTexts();
    this.dataService.selected_units =
      Number(userUnits) == 1
        ? this.dataService.units_list[0]
        : this.dataService.units_list[1];
    this.externalTools = externalTools;
  }

  buildUserName(firstName: string, lastName: string): string {
    return `${firstName} ${lastName}`;
  }

  getTexts() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      this.dataService.showLoader = false;
      let response = this.textService.getResourceTextOffline();
      this.setTextResponse({
        result: response,
        success: response ? true : false,
      });
    } else {
      this.textService
        .getResourceText(this.dataService.selected_language.row_id)
        .subscribe((res) => {
          this.dataService.showLoader = false;
          this.setTextResponse(res);
        });
    }
  }
  setTextResponse(res) {
    if (res.success) {
      this.textService.translation_text = res.result.translations
        ? res.result.translations
        : res.result;
      localStorage.setItem(
        "resource_txt",
        JSON.stringify(this.textService.translation_text)
      );
      this.textService.setUnitsDictionary();
      this.setReadMoreLabelText()
      this.setExternalToolCards();
      this.setCalcsCards();
    }
  }
  leftContent(): boolean {
    return (
      this.content?.title ||
      this.content?.subtitle ||
      this.content?.body ||
      this.content?.links?.length > 0
    );
  }

  getSelectedRegion() {
    this.dataService.regions_list.forEach((region, index) => {
      if (region["region_id"] === this.region) {
        this.selectedRegionIndex = index;
      }
    });
  }

  setCalcsCards() {
    this.calcsCards = calcsCardsData.reduce((acc, cardData) => {
      let { calcName, isDisabled, titleKey, textKey } = cardData;
      // build calc card
      const title: string = this.textService.getText(
        this.EtransaltionGroups.HOME,
        titleKey
      );
      const text: string = this.textService.getText(
        this.EtransaltionGroups.HOME,
        textKey
      );

      return [...acc, { title, text, calcName, isDisabled }];
    }, [] as CalculatorCard[]);
  }

  setExternalToolCards() {
    const toolsNames: ExternalToolName[] = this.getExternalToolsNames();
    const externalToolsCards: ExternalToolCard[] = [];
    toolsNames.forEach((name) => {
      // build external tool card
      if (externalToolCardDataMap[name]) {
        const iconName: string =
          externalToolCardDataMap[name].iconName ?? "default";
        const text: string = this.textService.getText(
          this.EtransaltionGroups.HOME,
          externalToolCardDataMap[name].textKey
        );
        const link = externalToolCardDataMap[name].link;
        externalToolsCards.push({ title: name, iconName, text, link });
      }
    });

    this.externalToolsCards = externalToolsCards;
  }

  setReadMoreLabelText(isReadMore = true) {
    const textKey = isReadMore ? "home_read_more" : "home_read_less";
    this.readMoreLabelText = this.textService.getText(
      EtransaltionGroups.HOME,
      textKey
    );
  }

  getExternalToolsNames(): ExternalToolName[] {
    return this.externalTools.map(({ name }) => name);
  }

  onChecked(isChecked: boolean) {
    this.setReadMoreLabelText(!isChecked);
  }
  
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
