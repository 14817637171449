import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpService } from './http.service';
import { ErrorService } from './error.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpHandler {
  headerSubscriber: any;
  private baseUrl = environment.url + '/api/v1/';
  public headers = new HttpHeaders();

  constructor(
    public http: HttpClient,
    public httpService: HttpService,
    public errorService: ErrorService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let tokenKey = 'Authorization';

    if (localStorage.getItem(tokenKey)) {
      this.headers = this.headers.set(
        'Authorization',
        localStorage.getItem(tokenKey)
      );
    }

    this.headerSubscriber = this.httpService.headers.subscribe(updatedValue => {
      if (updatedValue) {
        this.headers = this.headers.set(updatedValue.key, updatedValue.value);
      }
    });
  }

  public download(
    url: string,
    params: HttpParams = undefined,
    isAdmin?: boolean
  ): Observable<any> {
    this.setToken();
    return this.http
      .get(this.baseUrl + url, {
        params: params,
        headers: this.headers,
        withCredentials: true,
        responseType: 'blob'
      })
      .pipe(catchError(this.errorService.handleError));
  }

  public get(
    url: string,
    params: HttpParams = undefined,
    isAdmin?: boolean
  ): Observable<any> {
    this.setToken();
    return this.http
      .get(this.baseUrl + url, {
        params: params,
        headers: this.headers,
        withCredentials: true
      })
      .pipe(catchError(this.errorService.handleError));
  }

  public delete(url: string, params: HttpParams = undefined): Observable<any> {
    this.setToken();
    return this.http.delete(this.baseUrl + url, {
      params: params,
      headers: this.headers
    });
  }

  public put(url: string, body: any, isAdmin?: boolean): Observable<any> {
    this.setToken();
    return this.http.put(this.baseUrl + url, body, {
      headers: this.headers,
      withCredentials: true
    });
  }

  public patch(url: string, body: any, isAdmin?: boolean): Observable<any> {
    this.setToken();

    return this.http
      .patch(this.baseUrl + url, body, {
        headers: this.headers,
        withCredentials: true
      })
      .pipe(catchError(this.errorService.handleError));
  }

  public post(
    url: string,
    body: any,
    params: HttpParams = undefined,
    isAdmin?: boolean
  ): Observable<any> {
    this.setToken();
    let header_for_formData;
    if (url == 'help/contact') {
      header_for_formData = new HttpHeaders({});
      header_for_formData = header_for_formData.set(
        'Authorization',
        localStorage.getItem('Authorization')
      );
    }
    if (url === 'admin/global/content') {
      header_for_formData = new HttpHeaders({});
    }
    return this.http
      .post(this.baseUrl + url, body, {
        params: params,
        headers: header_for_formData ? header_for_formData : this.headers,
        withCredentials: true
      })
      .pipe(catchError(this.errorService.handleError));
  }

  public request(
    method: string,
    url: string,
    params?: HttpParams,
    body?: any,
    isAdmin?: boolean
  ): Observable<any> {
    this.setToken();
    return this.http
      .request(method, this.baseUrl + url, { body, headers: this.headers })
      .pipe(catchError(this.errorService.handleError));
  }

  ngOnDestroy(): void {
    if (this.headerSubscriber) {
      this.headerSubscriber.unsubscribe();
    }
  }


  public setToken() {
    let tokenKey = 'Authorization';
    if (localStorage.getItem(tokenKey)) {
      this.headers = this.headers.set(
        'Authorization',
        localStorage.getItem(tokenKey)
      );
    }
  }
}
