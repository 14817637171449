export const MATRIX_SPACING_VALUES_METRIC = [
  0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.7, 0.8, 0.9,
  1.0,
];

export const MATRIX_SPACING_VALUES_US = [
  0.3, 0.5, 0.6, 0.8, 1.0, 1.1, 1.3, 1.4, 1.6, 1.8, 2.0, 2.3, 2.6, 2.9, 3.2,
];

export const SPACING_MATRIX_VALIDATION_MIN = 0.001;
export const SPACING_MATRIX_VALIDATION_MAX = 100;
