import { AbsValveCalculationProcess } from "./AbsValveCalculationProcess";
import { EUnits } from "../../../../enums/Units.enum";
import { ECalculationWarnings } from "../../../errors_and_warnings/warnings";

export class PressureLossForValve extends AbsValveCalculationProcess {
  public calculate(calculationData: any) {
    const { units, valve, flowRate } = calculationData;
    const {
      diameter_inch,
      diameter_mm,
      kv,
      cv,
      valve_family,
      valve_model,
      valve_material,
      maximum_flow_rate,
      minimum_flow_rate,
    } = valve;

    calculationData.notes = [];
    calculationData.warnings = [];
    calculationData.errors = [];

    const { notes, warnings, errors } = calculationData;

    this.addDefaultNoAnglesIncludedNote(notes);

    const pressureLoss = this.calcPressureLossForValve(calculationData);

    this.checkFlowRateWithinValveRange(calculationData);

    const valves = [
      {
        ...(units === EUnits.US_UNITS && { cv }),
        ...(units === EUnits.METRIC && { kv }),
        diameter_inch,
        diameter_mm,
        valve_family,
        valve_model,
        valve_material,
        maximum_flow_rate,
        minimum_flow_rate,
      },
    ];

    const calcResults = {
      // valves,
      calculationResults: { valves, flowRate, pressureLoss, notes, warnings, errors },
    };

    return { calcResults };
  }

  /**
   * Calculate pressure loss for valve
   */
  protected calcPressureLossForValve(calculationData: any) {
    const pressureLoss = Number(
      this.getPressureLoss(calculationData).toFixed(2)
    );

    return pressureLoss;
  }

  /**
   * Check flow rate is within valve range and add warning if not.
   * smaller than valve maximum flow rate
   * larger than valve minimum flow rate
   */
  protected checkFlowRateWithinValveRange = (calculationData: any) => {
    const { valve, flowRate, warnings } = calculationData;
    const { minimum_flow_rate, maximum_flow_rate } = valve;
    if (flowRate < minimum_flow_rate || flowRate > maximum_flow_rate) {
      this.addWarning(
        warnings,
        ECalculationWarnings.RECOMMENDED_FLOW_RATE_FOR_VALVE
      );
    }
  };
}
