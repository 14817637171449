import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Ecalculator, EtransaltionGroups } from 'src/app/shared/enum';
import { TextService } from 'src/app/services/text.service';
@Component({
  selector: 'app-submain-characteristics',
  templateUrl: './submain-characteristics.component.html',
  styleUrls: ['./submain-characteristics.component.scss']
})
export class SubmainCharacteristicsComponent implements OnInit {
  Ecalculator = Ecalculator;
  EtransaltionGroups = EtransaltionGroups;
  @Input() pipeTypeOptions;
  @Input() pipeClassOptions;
  @Input() nominalDiametersOptions;
  @Output() addNewSection = new EventEmitter<string>();
  @Output() deleteSectionFromCurrentSectionForms = new EventEmitter<string>();
  @Input() currentSectionForms: any = [];
  sectionItems: any[];
  @Input() queryIndex; //0 - Q1, 1 - Q2, 2-Q3
  maxSections: number = 10;
  constructor(
    public dataService: DataService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public textService: TextService
  ) { }

  ngOnInit(): void {
    if (this.dataService.isItMobile) {
      this.maxSections = 3;
    } else {
      this.maxSections = 10;
    }

    this.sectionItems = this.dataService.sectionItems;
  }

  ngAfterViewInit() {
    if (this.dataService.isItMobile) {
      setTimeout(() => {
        const element = document.querySelector('#top-submain');
        element.scrollIntoView();
      }, 100);
    }
    this.dataService.currentSectionOnCalculatorPage = 'submain';
    this.cdr.detectChanges();
  }

  sectionHasChangedUpdateNextSection() {
    this.currentSectionForms.forEach((element, index) => {
      if (index > 0) {
        this.dataService.updateSectionToASectionChanges.next();
      }
    });
  }

  addSection() {
    this.addNewSection.emit();
  }

  deleteSection(index) {
    this.deleteSectionFromCurrentSectionForms.emit(index);
  }

  checkInternalDiameterValidation(res) {
    let internal_diameter_prev_section = this.currentSectionForms[res[1] - 1]
      .value.pipeInternalDiameter;
    if (Number(res[0]) < Number(internal_diameter_prev_section)) {
      return 'true';
    } else {
      return 'false';
    }
  }

  calculateTotalLengthLeftForSection(sectionIndex) {
    this.dataService.totalLengthLeftForSections = Number(
      this.dataService.totalRealLength
    );
    this.currentSectionForms.forEach((section, index) => {
      if (index < sectionIndex) {
        this.dataService.totalLengthLeftForSections =
          Number(this.dataService.totalLengthLeftForSections) -
          Number(section.value.pipeSectionLength);
      }
    });
    if (this.dataService.totalLengthLeftForSections < 0) {
      this.dataService.totalLengthLeftForSections = 0;
    }
  }
}
