import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { OfflineService } from "src/app/services/offline.service";
import { TextService } from "src/app/services/text.service";
import { EtransaltionGroups } from "src/app/shared/enum";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-offline-toggle",
  templateUrl: "./offline-toggle.component.html",
  styleUrls: ["./offline-toggle.component.scss"],
})
export class OfflineToggleComponent implements OnInit, AfterViewInit {
  textSlide: String;
  environment = environment;
  EtransaltionGroups = EtransaltionGroups;
  constructor(
    public textService: TextService,
    public offlineService: OfflineService,
    public dataService: DataService
  ) {
    this.offlineService.turnHydroToOfflineMode.subscribe((res) => {
      this.setDataForOfflineMode();
    });
  }

  ngOnInit(): void {
    this.textService.setTextSource();
    this.setLabelTexts();
    if (
      !navigator.onLine &&
      this.offlineService.isItOfflineMode &&
      !localStorage.getItem("user")
    ) {
      //if there isnt internet conncection & no data saved on cache & on online mode
      //show error popup
      this.showErrorPopup();
    }
  }
  ngAfterViewInit(): void {
    // this.setLabelTexts()
  }

  setIsOfflineModeService() {
    if (this.offlineService.isItOfflineMode) {
      //user wants to switch to offline mode
      this.dataService.showConfirmationPopup = true;
    } else {
      //user wants to switch to online mode
      if (!navigator.onLine) {
        //if there isnt internet conncection
        //show error popup
        this.showErrorPopup();
      } else {
        localStorage.setItem(
          "isOffline",
          this.offlineService.isItOfflineMode.toString()
        );
        location.reload(); // update index.html (remove google-analytics)
      }
    }
  }

  setDataForOfflineMode() {
    this.dataService.showLoader = true;
    //get all data
    this.offlineService.getAllDBforCalculators().subscribe(
      (response) => {
        this.dataService.showLoader = false;
        let data = response.result.page_data;
        let text_resource = JSON.parse(localStorage.getItem("resource_txt"));
        // save data in local storage
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("pipes", JSON.stringify(data.pipes.pipes));
        localStorage.setItem(
          "onlineEmitters",
          JSON.stringify(data.onlineEmitters.emitters)
        );
        localStorage.setItem(
          "inlineEmitters",
          JSON.stringify(data.inlineEmitters.emitters)
        );
        localStorage.setItem("regions", JSON.stringify(data.regions));
        localStorage.setItem("languages", JSON.stringify(data.languages));
        localStorage.setItem(
          "HomePage_Content",
          JSON.stringify(data.HomePage_Content)
        );
        localStorage.setItem(
          "submain_calculation_inputs",
          JSON.stringify(data.submain_calculation_inputs)
        );
        localStorage.setItem("resource_txt", JSON.stringify(text_resource));
        localStorage.setItem(
          "flushing_velocities",
          JSON.stringify(data.flushing_table)
        );
        localStorage.setItem(
          "isOffline",
          this.offlineService.isItOfflineMode.toString()
        );
        location.reload(); // update index.html (remove google-analytics)
        // let pipes = JSON.parse(localStorage.getItem('inlineEmitters'))
      },
      (err) => {
        //in case the backend call not working show error popup
        this.showErrorPopup();
        this.dataService.showLoader = false;
      }
    );
  }

  showErrorPopup() {
    this.dataService.error_result_msg = this.textService.getText(
      EtransaltionGroups.OFFLINE,
      "offline_error_text"
    );
    this.dataService.showErrorPopup = true;
  }

  setLabelTexts() {
    //online label
    document.documentElement.style.cssText = `--online_label: '${this.textService.getText(
      EtransaltionGroups.OFFLINE,
      "offline_online_text"
    )}'; 

    --offline_label: '${this.textService.getText(
      EtransaltionGroups.OFFLINE,
      "offline_offline_text"
    )}';`;
  }
}
