import { LateralCalculationType } from "../../enums/LateralCalculationType.enum";
import { ICalculationProcess } from "../Interfaces/ICalculationProcess";
import { MaxLengthForPCEmitters } from "../CalculationProcess/Lateral/MaxLengthForPCEmitters";
import { MaxLengthForNonPCEmitters } from "../CalculationProcess/Lateral/MaxLengthForNonPCEmitters";
import { PressureLossForAGivenLateral } from "../CalculationProcess/Lateral/PressureLossForAGivenLateral";
import { FlowVariationAndEmissionUniformity } from "../CalculationProcess/Lateral/FlowVariationAndEmissionUniformity";

export class LateralCalculationsFactory{
    static getCalcProcess(calcType: LateralCalculationType): ICalculationProcess {
        let calcProcess: ICalculationProcess = null;
        switch (calcType) {
            case LateralCalculationType.MAX_LENGTH_FOR_PC_EMITTERS:
                calcProcess = new MaxLengthForPCEmitters();

                break;
            case LateralCalculationType.MAX_LENGTH_FOR_NON_PC_EMITTERS:
                calcProcess = new MaxLengthForNonPCEmitters();
            
                break;
            case LateralCalculationType.PRESSURE_LOSS_FOR_SELECTED_LATERAL:
                calcProcess = new PressureLossForAGivenLateral();

                break;

            case LateralCalculationType.FV_EU_FOR_SELECTED_LATERAL:
                calcProcess = new FlowVariationAndEmissionUniformity();
        
                break;

        }

        return calcProcess;
    }
}