import { AbsDAL } from "./Base/AbsDAL";
let AWS = require('aws-sdk');

export class FlushingVelocityDAL extends AbsDAL {
    public static readonly FLUSHING_VELOCITIES_TABLE_NAME = "FlushingVelocities";
    public static readonly Flushing_Velocity_PK = "FLUSHING_VELOCITIES";
    public static readonly LOCAL_STORAGE_FLUSHING_VELOCITIES_TABLE_NAME = "flushing_velocities";
    /**
     * Gets Flushing Velocity Table
     */
    public async getFlushingVelocityTable(isOffline) {
        try {
            if (isOffline) {
                let flushing_table_data = JSON.parse(localStorage.getItem(FlushingVelocityDAL.LOCAL_STORAGE_FLUSHING_VELOCITIES_TABLE_NAME))
                return flushing_table_data
            }
            let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
            let table = FlushingVelocityDAL.FLUSHING_VELOCITIES_TABLE_NAME;

            // Get all Regions params:
            let params = {
                TableName: table,
                KeyConditionExpression: 'pk = :hkey',
                ExpressionAttributeValues: {
                    ':hkey': FlushingVelocityDAL.Flushing_Velocity_PK
                }
            };
            let res = await docClient.query(params).promise();

            return res.Items;

        } catch (error) {
            throw error;
        }
    }
}