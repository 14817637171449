import { EmittersDAL } from "../DAL/EmittersDAL";
import { EmitterTypes } from "../enums/Lateral/emitterTypes.enum";
import { PipesDAL } from "../DAL/PipesDAL";
import { EUnits } from "../enums/Units.enum";
import { UnitsConverter } from "../UnitsConverter/UnitsConverter";
import { LanguagesDAL } from "../DAL/LanguagesDAL";
import { TranslationsDAL } from "../DAL/TranslationsDAL";
import { ELanguages } from "../enums/Languages.enum";
export class EmittersService {

    emittersDAL: EmittersDAL = new EmittersDAL();

    public async getEmittersForCalculation(emitterChars: any, lateralChars: any, flowRate: any, units: any, region: any, isOffline: boolean) {
        let emitters = [];
        let _this = this;
        let isConvertUnits = !emitterChars.isUnitsSet;
        let isRequestFromCalculatorAndUsUnits = units === 2

        await Promise.all(lateralChars.map(async (char) => {
            let isInlineEmitter = emitterChars.is_inline_emitter || emitterChars.isInlineEmitter;
            let type = emitterChars.type;
            let model = emitterChars.model;
            let nominalDiameter = char.nominal_diameter.toString() || emitterChars.nominalDiameter.toString();
            let pClass = isInlineEmitter ? char.wall_thickness.toString() || emitterChars.wallThickness.toString() : char.pipe_class || emitterChars.pipeClass;

            let emitter = await _this.getEmitter({ region, units, flowRate, type, model, nominalDiameter, pClass, isInlineEmitter, isConvertUnits, isRequestFromCalculatorAndUsUnits, isOffline });
            if (!emitter) {
                throw new Error("emitter does not exists");
            }
            emitter.section_length = char.section_length;

            if (isInlineEmitter) {
                // Take users input:
                emitter.internal_diameter = char.internal_diameter;
            }
            emitters.push(emitter);
        }))

        return emitters;
    }

    public async getEmitters(serviceData: any): Promise<any> {
        let filters = serviceData.filters || serviceData;
        filters.region = filters.region ? filters.region.toString() : PipesDAL.GLOBAL_REGION_STR;
        filters.isOffline = filters.isOffline || false;
        if (filters.isGlobalEmitter) {
            filters.region = PipesDAL.GLOBAL_REGION_STR;
        }
        let units = serviceData.units || EUnits.METRIC;
        filters.units = units;
        let isInlineEmitter = filters.isInlineEmitter || false;
        let emitters: any;

        if (isInlineEmitter) {
            emitters = await this.emittersDAL.getInlineEmitters(filters);
        }
        else {
            emitters = await this.emittersDAL.getOnlineEmitters(filters);
        }
        if (!emitters) {
            emitters = [];
        }
        if (emitters.length > 0 && units == EUnits.US_UNITS) {
            // convert emitters data to US-Units:
            UnitsConverter.convertEmittersToUSUnits(emitters, isInlineEmitter);
        }

        return { emitters, total_results: emitters.length };
    }

    public async getEmitter(filters: any) {
        let region = filters.region;
        let units = filters.units;
        let isInlineEmitter = filters.isInlineEmitter;
        let type = filters.type;
        let model = filters.model;
        let nominalDiameter = filters.nominalDiameter ? filters.nominalDiameter.toString() : null;
        let pClass = filters.wallThickness || filters.pClass;
        let flowRate = filters.flowRate.toString();
        let isOffline = filters.isOffline
        let isConvertUnits = filters.isConvertUnits;
        if (isConvertUnits !== false) {
            isConvertUnits = true;
        }
        let isRequestFromCalculatorAndUsUnits = filters.isRequestFromCalculatorAndUsUnits
        if (units == EUnits.US_UNITS && isConvertUnits && !isRequestFromCalculatorAndUsUnits) {
            // convert flow rate and nominal diameter:
            let converted = UnitsConverter.convertEmitterFiltersToMetric({ nominalDiameter, flowRate });
            nominalDiameter = converted.nominalDiameter;
            flowRate = converted.flowRate;
        }

        let emitter = await this.emittersDAL.getEmitter(region, isInlineEmitter, units, type, model, nominalDiameter, pClass, flowRate, isRequestFromCalculatorAndUsUnits, isOffline);

        if (isConvertUnits && emitter && units == EUnits.US_UNITS) {
            emitter = isInlineEmitter ? UnitsConverter.convertSingleInlineEmitterToUSUnits(emitter) : UnitsConverter.convertSingleOnlineEmitterToUSUnits(emitter);
        }

        return emitter;
    }

    public async getEmitterFlowRates(emitterType: any, model: any, region: any, units: any, isOffline: boolean) {
        let types = await this.emittersDAL.getAllEmittersFlowRates(emitterType, model, region, units, isOffline);
        return types;
    }

    public async getEmitterTypes(region: any, user: any, isOffline: boolean): Promise<any> {
        let translationsDAL: TranslationsDAL = new TranslationsDAL();
        let language = user.language;
        let types: any[] = []
        let tasks = [
            this.emittersDAL.getEmitterTypes(region, isOffline),
            translationsDAL.getTranslationsByLanguage(language, isOffline)
        ]

        let res = await Promise.all(tasks);
        let types_values = res[0];


        let types_result: any = []
        let translations = res[1];
        translations = translations.filter(tr => tr.group_id == 8); //only lateral calculator group
        // Set emitter name translation:
        types_values.forEach(type => {
            let idStr = this.getIdStrByType(type);
            let trans = translations.filter(t => t.translation_id_str == idStr)[0];
            let label = trans.value !== ' ' ? trans.value : type;
            types_result.push({ label, value: type })
        });


        return types_result;
    }

    public async getEmitterTypesDevided(region: any): Promise<any> {
        let types = {
            inline_emitters: ["Driplines"],
            online_emitters: ["Sprinklers", "Micro Sprinklers", "Online Drippers"]
        }
        return types;
    }

    public async getEmitterModelsByType(emitterType: any, region: any, isOffline: boolean): Promise<any> {
        let models = await this.emittersDAL.getEmitterModelsByType(emitterType, region, isOffline);
        return models;
    }

    public async getEmitterNominalDiameters(filters: any) {
        let diameters = await this.emittersDAL.getInlineEmittersNominalDiameters(filters);

        return diameters;
    }

    public async getInlineEmittersWallThickness(filters: any) {
        let models = await this.emittersDAL.getInlineEmittersWallThickness(filters);
        return models;
    }

    public async getInlineEmittersFlowRates(filters: any) {
        let flowrates = await this.emittersDAL.getInlineEmittersFlowRates(filters);;

        return flowrates;
    }

    public async getLateralInternalDiameter(filters: any, region: any, units: any) {
        filters.region = region;
        let diameters = await this.emittersDAL.getLateralInternalDiameter(filters, units);

        return diameters;
    }

    private getIdStrByType(emitter_type: string) {
        switch (emitter_type) {
            case "Driplines":
                return 'lateral_s1_emitter_type_driplines'
            case "Online Drippers":
                return 'lateral_s1_emitter_type_online_drippers'
            case "Sprinklers":
                return 'lateral_s1_emitter_type_sprinklers'
            case "Micro Sprinklers":
                return 'lateral_s1_emitter_type_micro_sprinklers'

            default:
                break;
        }
    }
}