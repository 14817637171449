import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { observable, of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { LateralService } from 'src/app/services/lateral.service';
import { OfflineService } from 'src/app/services/offline.service';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
@Component({
  selector: 'app-block-emitter',
  templateUrl: './block-emitter.component.html',
  styleUrls: ['./block-emitter.component.scss'],
})
export class BlockEmitterComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  @Input() emitterForm;
  @Input() modelForm;
  @Input() emitter_type_options = [];
  @Output() createQuestionsConfig = new EventEmitter<any>();
  disableModelSelect;

  constructor(
    public dataService: DataService,
    public lateralService: LateralService,
    public textService: TextService,
    public offlineService: OfflineService
  ) { }

  ngOnInit(): void {
    // this.modelForm.controls.emitter_type.touched
    this.onChanges();
    if (!this.modelForm.controls.model.valid) {
      this.disableModelSelect = true;
    }

    if (this.modelForm.valid) {
      if (
        this.dataService.checkIfItsInlineDripper(
          this.modelForm.controls.emitter_type.value.value
        ) &&
        !this.emitterForm.valid
      ) {
        this.emitterForm.controls.flow_rate.clearValidators();
        this.emitterForm.controls.flow_rate.reset();
        this.emitterForm.controls.flow_rate.updateValueAndValidity({
          emitEvent: false,
        });

        this.emitterForm.controls.spacing.reset();
        this.emitterForm.controls.spacing.clearValidators();
        this.emitterForm.controls.spacing.updateValueAndValidity({
          emitEvent: false,
        });
      }
    }
  }

  onChanges(): void {
    this.modelForm.controls.emitter_type.valueChanges.subscribe((value) => {
      this.dataService.inputs_contents_has_changed = true
      if (
        this.dataService.chosen_emitter_type != value.value &&
        this.dataService.chosen_emitter_type != '' &&
        value != null
      ) {
        setTimeout(() => {
          this.dataService.clearLateralCharacteristcsData.next();
          this.dataService.initiateQueries.next();
          this.dataService.resetElevation.next();
        });
      }
      this.dataService.chosen_emitter_type = value.value;
      if (this.dataService.selected_units.value === 2 && (this.dataService.chosen_emitter_type === 'Driplines' || this.dataService.chosen_emitter_type === 'Online Drippers')) {
        this.dataService.spacingMeasureUnit = 'm2'
      } else {
        this.dataService.spacingMeasureUnit = 'm'
      }
      this.getModelOptions();
      this.setValidatorsForEmitterForm();
    });

    this.modelForm.controls.model.valueChanges.subscribe((value) => {
      if (value != null) {
        //set model and value as an object
        let objectValue = this.dataService.model_options.filter(model => {
          return model.modelLabel === value
        })[0]
        this.dataService.chosen_model_object = objectValue
        setTimeout(() => {
          if (
            !this.dataService.model_options.includes(
              this.dataService.chosen_model_object
            )
          ) {
            this.dataService.chosen_model_object = null
          }
        });

        this.dataService.inputs_contents_has_changed = true
        if (objectValue != null) {
          if (
            objectValue.model != this.dataService.chosen_model &&
            this.dataService.chosen_model != ''
          ) {
            this.dataService.initiateQueries.next();
            if (
              this.dataService.checkIfItsInlineDripper(
                this.modelControls.emitter_type.value.value
              )
            ) {
              this.dataService.clearLateralCharacteristcsData.next();
            }
          }
          this.dataService.chosen_model = objectValue.model;
          setTimeout(() => {
            this.createQuestionsConfig.emit()
          });
        }
        if (this.modelControls.emitter_type.valid && objectValue != null) {
          this.getFlowRateOptions();
          this.setValidatorsForEmitterForm();
        }

      }
    });
  }

  async getModelOptions() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = await this.lateralService.getModelOptionsOffline(this.modelControls.emitter_type.value.value)
      this.dataService.showLoader = false;
      this.setResponseModelOptions(response)
    } else {

      this.lateralService
        .getModelOptions(this.modelControls.emitter_type.value.value)
        .subscribe((response) => {
          this.dataService.showLoader = false;
          this.setResponseModelOptions(response)
        });
    }
  }
  setResponseModelOptions(response) {
    if (response.success) {
      this.dataService.model_options = response.result.models;
      this.dataService.model_options_observable = of(response.result.models);
      if (
        !this.dataService.model_options.includes(
          this.dataService.chosen_model_object
        )
      ) {
        this.modelControls.model.reset();
        this.dataService.chosen_model_object = null
      }
      this.disableModelSelect = false;
    }
    if (this.modelForm.valid) {
      this.getFlowRateOptions();
    }
  }

  async getFlowRateOptions() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = await this.lateralService.getFlowRateOptionsForEmitterSectionOffline(this.modelControls.emitter_type.value.value, this.dataService.chosen_model_object.model)
      this.dataService.showLoader = false;
      this.setFlowRateOptionsForEmitterSection(response)
    } else {
      this.lateralService
        .getFlowRateOptionsForEmitterSection(
          this.modelControls.emitter_type.value.value,
          this.dataService.chosen_model_object.model
        )
        .subscribe((response) => {
          this.dataService.showLoader = false;
          this.setFlowRateOptionsForEmitterSection(response)
        });
    }

  }
  setFlowRateOptionsForEmitterSection(response) {
    if (response.success) {
      this.dataService.flow_rate_options = response.result.flow_rates;
      if (
        !this.dataService.flow_rate_options.includes(
          this.emitterControls.flow_rate.value
        )
      ) {
        this.emitterControls.flow_rate.reset();
      }
    }
  }

  setValidatorsForEmitterForm() {
    if (
      this.dataService.checkIfItsInlineDripper(
        this.dataService.chosen_emitter_type
      )
    ) {
      this.emitterForm.controls.flow_rate.clearValidators();
      this.emitterForm.controls.flow_rate.reset();
      this.emitterForm.controls.flow_rate.updateValueAndValidity({
        emitEvent: false,
      });

      this.emitterForm.controls.spacing.reset();
      this.emitterForm.controls.spacing.clearValidators();
      this.emitterForm.controls.spacing.updateValueAndValidity({
        emitEvent: false,
      });
    } else {
      this.emitterForm.controls.flow_rate.reset();
      this.emitterForm.controls.flow_rate.setValidators([
        Validators.compose([Validators.required]),
      ]);
      this.emitterForm.controls.flow_rate.updateValueAndValidity({
        emitEvent: false,
      });
      this.emitterForm.controls.spacing.reset();
      this.emitterForm.controls.spacing.setValidators([
        Validators.compose([
          Validators.required,
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.min(0.001),
          Validators.max(100),
        ]),
      ]);
      this.emitterForm.controls.spacing.updateValueAndValidity({
        emitEvent: false,
      });
    }
  }

  get emitterControls() {
    return this.emitterForm.controls;
  }

  get modelControls() {
    return this.modelForm.controls;
  }
}
