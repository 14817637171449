import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoApisServiceService } from 'src/app/services/cognito-apis-service.service';
import { DataService } from 'src/app/services/data.service';
import { OfflineService } from 'src/app/services/offline.service';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
@Component({
  selector: 'app-calc-navbar-mobile',
  templateUrl: './calc-navbar-mobile.component.html',
  styleUrls: ['./calc-navbar-mobile.component.scss']
})
export class CalcNavbarMobileComponent implements OnInit {
  showFiller = false;
  showHelpSubPages = false;
  EtransaltionGroups = EtransaltionGroups;
  @Output() sidenavClose = new EventEmitter();

  constructor(
    public dataService: DataService,
    public router: Router,
    public cognitoAWSService: CognitoApisServiceService,
    public textService: TextService,
    public offlineService: OfflineService
  ) { }

  ngOnInit(): void {
    this.textService.setTextSource();
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  };

  public openSettings() {
    // this.dataService.showSettingsPopup = true;
  }

  public async signOut() {
    let response = await this.cognitoAWSService.signOut();
    if (response.success) {
      localStorage.clear();
      this.router.navigateByUrl('login');
    } else {
      this.dataService.error_result_msg = response.error.message;
      this.dataService.showErrorPopup = true;
    }
  }
}
