import { MarketingContentService } from "../services/MarketingContentService";
import { ErrorMsgs } from "../models/Errors/ErrorMsgs";
import { CalculationsService } from "../services/CalculationsService";
import { SubmainCalculationType } from "../calculator/enums/SubmainCalculationType.enum";
import { CalculationInputsService } from "../services/CalculationInputsService";
import { EPublicity } from "../enums/Publicity.enum";
import { ECalculationInputs } from "../enums/calculationInputs.enum";
import { GeneralService } from "../services/GeneralService";
import { EPublicityStatus } from "../enums/PublicityStatus.enum";
import { AbsRequestHandler } from "../handlers/Base/AbsRequestHandler";

export class HomePageHandler extends AbsRequestHandler {
	/**
	 * Gets Home Page Data
	 */
	public async getHomePageData(reqData: any) {
		try {
			let service = new MarketingContentService();
			let generalService = new GeneralService();
			let user = reqData.user;
			let regionId = user.region;
			let languageId=user.language
			let isOffline = reqData.isOffline || false

			let regions: any = await generalService.getRegions(user, isOffline);
			let languages: any = await generalService.getLanguages(isOffline);
			let region = regions.find(region => {
				return region.region_id == regionId;
			});
			if (!region) {
				throw new Error("Region does not exists");
			}
			let content = null;
			let serviceData = {
				filters: {
					publicity: region.content_to_show == EPublicity.LOCAL ? EPublicity.LOCAL : EPublicity.GLOBAL,
					region: region.content_to_show == EPublicity.LOCAL ? regionId : null,
					language: region.languages && region.languages.includes(languageId) ? languageId: null
				}, isOffline
			};

			if (region.content_to_show > 0) {
				content = await service.getContent(serviceData);
			}
			let pageData = {
				content,
				regions,
				user,
				languages
			};

			return { pageData };
		} catch (error) {
			throw new ErrorMsgs(
				"Internal server error - Error occured while serving page data",
				error.message
			);
		}
	}

	/**
	 * Gets Home Page Settings Data
	 */
	public async getSettingsPageData(reqData: any) {
		try {
			let user = reqData.user
			let isOffline = reqData.isOffline || false
			let service = new GeneralService();
			let tasks: any = [
				// Get Regions:
				await service.getRegions(user, isOffline),
				// Get Languages:
				await service.getLanguages(isOffline)
			];

			let res = await Promise.all(tasks);
			let regions: any = res[0];
			let languages: any = res[1];
			user = reqData.user

			let settings = {
				user,
				regions,
				languages
			}

			return { settings };
		} catch (error) {
			throw new ErrorMsgs(
				"Internal server error - Error occured while serving page data",
				error.message
			);
		}
	}

	// --------------------------------------------- RESULT BUILDERS -----------------------------------------------------

	public buildSettingsResult(resultData: any) {
		let result = { settings: resultData.settings };

		return result;
	}
}
