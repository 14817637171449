import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-field-component',
  templateUrl: './field-component.component.html',
  styleUrls: ['./field-component.component.scss']
})
export class FieldComponentComponent implements OnInit {
  @Input() field_label;
  @Input() showImgTooltip = true;
  @Input() imgSrc;
  @Input() tooltipText;
  @Input() totalCount;
  @Input() control: FormControl;
  @Input() chooseTextarea = false;
  @Input() isItDisable = false;
  @Input() placeHolderText: string = 'Enter text here...';
  @Input() isItSelect = false;
  @Input() options: any[];
  @Input() option_value_to_show = '';
  selectPlaceholder: string;
  tempPlaceholder: string;

  selected;
  constructor() {
    if (this.totalCount) {
      this.totalCount = Number(this.totalCount);
    }
    this.selectPlaceholder = this.placeHolderText;
  }

  ngOnInit(): void {
    this.tempPlaceholder = this.placeHolderText;
  }

  onFocus() {
    this.placeHolderText = '';
  }

  onBlur() {
    this.placeHolderText = this.tempPlaceholder;
  }
}
