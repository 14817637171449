import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ViewEncapsulation
} from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-snack-bar-component',
  templateUrl: './snack-bar-component.component.html',
  styleUrls: ['./snack-bar-component.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnackBarComponent implements OnInit {
  configSuccess: MatSnackBarConfig = {
    horizontalPosition: 'right',
    verticalPosition: 'bottom'
  };
  constructor(
    public dataService: DataService,
    public snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {
    this.dataService.initSnackBar.subscribe(val => {
      this.initSnackBar();
    });
  }

  ngOnInit(): void {
  }

  initSnackBar() {
    this.snackBar.openFromComponent(dataComponent, {
      data: 'Sample data',
      ...this.configSuccess
    });
  }
}
@Component({
  selector: 'snack-bar-component-data',
  templateUrl: './snack-bar-component-data.component.html',
  styleUrls: ['./snack-bar-component.component.scss']
})
export class dataComponent {
  constructor(
    public dataService: DataService,
    public snackBarRef: MatSnackBarRef<dataComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  action() {
    if (this.dataService.showCancelButtonSnackBar) {
      this.dataService.resetBlockCharacteristics.next();
    }
    this.snackBarRef.dismiss();
  }
  cancel() {
    this.snackBarRef.dismiss();
  }
}
