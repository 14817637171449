import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-calc-navbar',
  templateUrl: './calc-navbar.component.html',
  styleUrls: ['./calc-navbar.component.scss']
})
export class CalcNavbarComponent implements OnInit {
  showFiller = false;
  EtransaltionGroups = EtransaltionGroups;
  constructor(
    public dataService: DataService,
    public route: Router,
    public textService: TextService
  ) {}

  ngOnInit(): void {
    this.textService.setTextSource();
  }

  hideFiller(selectedUrl: string) {
    if (!this.route.url.includes(selectedUrl)) {
      this.showFiller = false;
    }
  }
}
