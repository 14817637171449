import { BaseCalculatorHandler } from "./Calculator.handler.base";
import { PipesService } from "../../services/PipesService";
import { ErrorMsgs } from "../../models/Errors/ErrorMsgs";
import { CalculationsService } from "../../services/CalculationsService";
import { SubmainCalculationType } from "../../calculator/enums/SubmainCalculationType.enum";
import { CalculationInputsService } from "../../services/CalculationInputsService";
import { EPublicity } from "../../enums/Publicity.enum";
import { GeneralService } from "../../services/GeneralService";
import { CalculatorTypes } from "../../calculator/enums/calculatorTypes.enum";

export class SubmainCalculatorHandler extends BaseCalculatorHandler {
    public getAllCalculatorsData(reqData: any) {
        throw new Error("Method not implemented.");
    }

    public async calculate(reqData: any) {
        reqData.calculator = CalculatorTypes.SUBMAIN;

        return super.calculate(reqData);
    }

    /**
     * Gets Claculator Page Data
     * Gets Pipes details lists - Types, Classes and internal diameters
     * 
     */
    public async getCalculatorPageData(reqData: any = null) { //ORAN make sure fron send isOffline
        let pipesService = new PipesService();
        let calculationInputsService = new CalculationInputsService();
        let generalService = new GeneralService();
        let user = reqData.user;
        let region = user.region;
        let units = user.units;
        let pipeRelevants = {
            isReleventForSubmain: true,
        }
        let filters = {
            publicity: EPublicity.LOCAL,
            region: region
        }
        let isOffline = reqData.isOffline || false
        // Get Submain Charactaristics Lists Items:
        let tasks = [
            // Get Pipes Types:
            pipesService.getPipeTypes({ region, units, pipeRelevants, isOffline }),
            // Get Pipes Classes:
            pipesService.getPipeClasses({ region, units, pipeRelevants, isOffline }),
            // Get Pipes Nominal Diameter:
            pipesService.getPipeNominalDiameters({ region, units, pipeRelevants, isOffline }),
            // Get Local Settigns:
            calculationInputsService.getCalculationInputs({ filters, isOffline }),
            // Get Recommended flushing velocity table::
            generalService.getFlushingVelocityTable(units, isOffline),
            // Get all Regions:
            generalService.getRegions(user, isOffline),
            // Get languages:
            generalService.getLanguages(isOffline)
        ]

        let res = await Promise.all(tasks);
        let types = res[0];
        let classes = res[1];
        let diameters = res[2];
        let calcInputs = res[3];
        let flushingVelocities = res[4];
        let regions = res[5];
        let languages = res[6];
        let pageData: any = {
            submain_charactaristics_data: {
                types: types,
                classes: classes,
                nominal_diameters: diameters,
                calculation_inputs: calcInputs,
                flushing_velocities: flushingVelocities,
                regions,
                user,
                languages
            }
        };



        return { pageData };
    }

    /**
     * getSectionCharactaristics
     * Gets Section Charactaristics items - Types, Classes and internal diameters
     * 
     */
    public async getSectionCharactaristics(reqData: any) {
        let pipesService = new PipesService();
        let user = reqData.user;
        let region = user.region;
        let units = user.units;
        let sectionCharac = reqData.sectionCharac;
        let pipeRelevants = {
            isReleventForSubmain: true,
        }
        let isOffline = sectionCharac.isOffline || false

        // Get Submain Charactaristics Lists Items:
        let tasks = [
            // Get Pipes Types:
            pipesService.getPipeTypes({ region, units, pClass: sectionCharac.class, nominalDiameter: sectionCharac.diameter, pipeRelevants, isOffline }),
            // // Get Pipes Classes:
            pipesService.getPipeClasses({ region, units, pType: sectionCharac.type, nominalDiameter: sectionCharac.diameter, pipeRelevants, isOffline }),
            // // Get Pipes Nominal Diameter:
            pipesService.getPipeNominalDiameters({ region, units, pType: sectionCharac.type, pClass: sectionCharac.class, pipeRelevants, isOffline })
        ]

        let res = await Promise.all(tasks);
        let types = res[0];
        let classes = res[1];
        let diameters = res[2];

        let section_charactaristics: any = {
            types: types,
            classes: classes,
            nominal_diameters: diameters
        };



        return { section_charactaristics };
    }

    /**
     * calcBlockFlowRate
     * calc Block Flow Rate - for a given block shape and last lateral/total flow rate
     * 
     */
    public async calcBlockFlowRate(reqData: any) {
        let calculationsService = new CalculationsService();
        let blockFlowRateData = reqData.blockFlowRateData;
        let user = reqData.user;
        let units = user.units;
        let flowRate: any = calculationsService.calcSubmainBlockFlowRate(units, blockFlowRateData.isReqtangular, blockFlowRateData.lastLateralFlowRate, blockFlowRateData.totalLateralFlowRate, blockFlowRateData.numOfLaterals, blockFlowRateData.firstLateralLength, blockFlowRateData.lastLateralLength);
        if (!flowRate && flowRate != 0) {
            throw new ErrorMsgs('Error - Error occured while calculating block flow rate');
        }

        return { flowRate };
    }

    /**
     * getPipeInternalDiameter
     * getPipeInternalDiameter - for a given pipe type, class, nominal diameter
     * 
     */
    public async getPipeInternalDiameter(reqDate: any) {
        let pipesService = new PipesService();
        let user = reqDate.user;
        let region = user.region;
        let units = user.units;
        let blockFlowRateData = reqDate.sectionCharac;
        let isOffline = blockFlowRateData.isOffline || false

        let { internalDiameter, roughness }: any = await pipesService.getPipeInternalDiameterAndRoghness(region, blockFlowRateData.type, blockFlowRateData.class, blockFlowRateData.diameter, units, isOffline);
        if (!internalDiameter || internalDiameter < 0) {
            throw new ErrorMsgs('Error - Error occured while retrieving pipe internal diameter');
        }

        return { internalDiameter };
    }

    public async getPipesDataFromDB(pipeChars: any, calcType: SubmainCalculationType, units: any, region: any, isOffline: boolean) {
        let pipes: any = [];

        switch (calcType) {
            case SubmainCalculationType.PRESSURE_LOSS_FOR_SELECTED_PIPE:
            case SubmainCalculationType.PIPES_MAX_LENGTH:
                pipes = await this.getPipesDataForPressureLossCalc(pipeChars, region, units, isOffline);
                break;
            case SubmainCalculationType.PIPES_DIAMETERS:
                pipes = await this.getPipesDataForPipesDiametersCalc(pipeChars, region, units, isOffline);

                break;
        }

        return pipes;
    }



    // ----------------------------------------- CALCULATION METHODS -------------------------------------

    protected async getPipesDataForPipesDiametersCalc(pipeChars: any, region: any, units: any, isOffline: boolean): Promise<any> {
        try {
            let isRequestFromCalculatorAndUsUnits = units === 2

            // Get all diameters:
            let pipes = await this.getPipes(region, pipeChars[0].type, pipeChars[0].class, null, isOffline, isRequestFromCalculatorAndUsUnits);
            if (pipes.pipes.length > 0) {
                pipes.pipes.sort((p1, p2) => p1.internal_diameter - p2.internal_diameter);
            }

            return pipes;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    protected async getPipesDataForPressureLossCalc(pipeChars: any, region: any, units: any, isOffline: boolean): Promise<any> {
        try {
            let pipes: any = [];
            let _this = this;
            let isRequestFromCalculatorAndUsUnits = units === 2

            await Promise.all(pipeChars.map(async (pchar: any) => {
                let p = await _this.getPipe(pchar, region, isOffline, isRequestFromCalculatorAndUsUnits);
                if (!p) {
                    throw new Error("Error - pipe doesnt exists")
                }
                let roughness = p.roughness || p.pipe_roughness;
                roughness = Number(roughness) > Number(SubmainCalculatorHandler.EMITTER_MAX_ROUUGHNESS) ? SubmainCalculatorHandler.EMITTER_MAX_ROUUGHNESS : roughness;
                pchar.roughness = Number(roughness);
                pchar.kd_local_friction_factor = p.kd_local_friction_factor;
                pchar.max_allowable_design_pressure = p.max_allowable_design_pressure;
                pchar.mri_max_pressure_meter = p.mri_max_pressure_meter;
                pchar.max_velocity = p.max_velocity;

                pipes.push(pchar)
            }));
            return pipes;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    protected getUserCalcData(calculationReqData: any) {
        let calculationData: any = {};

        calculationData.frictionFormula = calculationReqData.frictionFormula;
        calculationData.flushingVelocity = calculationReqData.flushingVelocity || null;
        calculationData.pipeRoughnessChw = calculationReqData.pipeRoughnessChw;

        calculationData.blockChars = calculationReqData.blockChars;
        calculationData.topographyChars = calculationReqData.topographyChars;
        calculationData.pipeChars = calculationReqData.pipeChars;
        calculationData.maxPressureLoss = calculationReqData.maxPressureAllowed;
        calculationData.units = calculationReqData.units;

        calculationData.isOffline = calculationReqData.isOffline || false;

        return calculationData;
    }

    protected async getDevicesData(calculationData: any, calculationReqData: any) {
        let isOffline = calculationReqData.isOffline || false
        // Get pipes data from DB: 
        let pipes = await this.getPipesDataFromDB(calculationData.pipeChars, calculationReqData.calcType, calculationReqData.units, calculationReqData.region, isOffline);
        if (!pipes || pipes.total_results == 0) {
            throw new ErrorMsgs('Error - No pipes found that matches properties received', null, true);
        }
        // Load pipes on calculationData: 
        calculationData.pipeChars = pipes;
        calculationData.numOfPipesFromUser = calculationReqData.pipeChars[0].number_of_pipe_sections;

    }

    // --------------------------------------------- RESULT BUILDERS -----------------------------------------------------

    public buildPipesSectionCharactaristicsResult(resultData: any) {
        let result = { pipes_section_charactaristics: resultData.section_charactaristics };

        return result;
    }

    public buildBlockFlowRateResult(resultData: any) {
        let result = { flow_rate: Number(resultData.flowRate.toFixed(2)) };

        return result;
    }

    public buildCalculationResult(resultData: any) {
        let calculation_results = resultData.calcResults;
        let isSucceeded: boolean = calculation_results.errors.length <= 0;
        let flushing_results = resultData.flushing;
        let isFlatTopo: any = true;

        if (isSucceeded) {
            resultData.calcResults.slopes.forEach(slope => {
                if (slope.HeightDiffInMeters != 0) {
                    isFlatTopo = false;
                }
            });
        }

        let endPressure;
        if (!isSucceeded) {
            endPressure = null;
        }
        else {
            endPressure = calculation_results.calculationResults.endPressure ? Number(calculation_results.calculationResults.endPressure).toFixed(2) : Number(calculation_results.segments[calculation_results.segments.length - 1].EndPressure.toFixed(2));
        }
        // Calculation results:
        let numOfLaterals = (isSucceeded && calculation_results.calculationResults.numOfLaterals) ? Number(calculation_results.calculationResults.numOfLaterals.toFixed(2)) : null;
        let calculationResults = isSucceeded ? {
            submain_pressure_loss: Number(calculation_results.calculationResults.totalPressureLoss.toFixed(2)),
            submain_pipe_type: calculation_results.segments[0].PipeSection.Type,
            submain_length: calculation_results.calculationResults.totalLength ? Number(calculation_results.calculationResults.totalLength.toFixed(2)) : Number(calculation_results.calculationResults.submainLength.toFixed(2)),
            num_of_laterals: numOfLaterals,
            inlet_pressure: calculation_results.calculationResults.inletPressure ? Number((calculation_results.calculationResults.inletPressure).toFixed(2)) : Number((calculation_results.segments[0].InletPressure).toFixed(2)),
            end_pressure: endPressure,
            inlet_flow_rate: Number(calculation_results.segments[0].FlowRate.toFixed(3)),
            max_pressure: isFlatTopo == false ? Number(calculation_results.calculationResults.totalMaxPressure.toFixed(2)) : null,
            min_pressure: isFlatTopo == false ? Number(calculation_results.calculationResults.totalMinPressure.toFixed(2)) : null,
            max_velocity: Number(calculation_results.calculationResults.maxVelocity.toFixed(2)),
            warnings: calculation_results.calculationResults.warnings || [],
            errors: calculation_results.calculationResults.errors || []
        } : { errors: calculation_results.errors };

        // Flushing:
        numOfLaterals = (isSucceeded && flushing_results && flushing_results.flushingResults.numOfLaterals) ? Number(flushing_results.flushingResults.numOfLaterals.toFixed(2)) : null;
        let flushingResults = (flushing_results && isSucceeded) ? {
            pressure_loss: Number(flushing_results.flushingResults.totalPressureLoss.toFixed(2)),
            inlet_pressure: Number(flushing_results.flushingPipes[0].InletPressure.toFixed(2)),
            inlet_flow_rate: Number(flushing_results.flushingSegments[0].FlowRate.toFixed(3)),
            max_pressure: Number(flushing_results.flushingResults.totalMaxPressure.toFixed(2)),
            flushing_velocity: Number(flushing_results.flushingResults.maxVelocity.toFixed(2)),
            flushing_end_velocity: Number(Number(flushing_results.flushingResults.endVelocity).toFixed(2)),
            end_pressure: Number(Number(flushing_results.flushingResults.endPressure).toFixed(2)),
            last_flow_rate: Number(Number(flushing_results.flushingResults.lastFlowRate).toFixed(2)),
        } : { errors: flushing_results ? flushing_results.errors : [] };

        // Flushing with inlet pressure from user:
        let flushingResultsByUserInletPressure = (flushing_results && isSucceeded && flushing_results.flushingResults.result_for_user_inlet_pressure) ?
            {
                pressure_loss: Number(flushing_results.flushingResults.result_for_user_inlet_pressure.totalPressureLossForUserInletPressureInput.toFixed(2)),
                inlet_pressure: Number(flushing_results.flushingResults.result_for_user_inlet_pressure.totalMaxPressureForUserInletPressureInput.toFixed(2)),
                inlet_flow_rate: Number(flushing_results.flushingSegments[flushing_results.flushingResults.result_for_user_inlet_pressure.segment_index_for_desired_inlet_pressure].FlowRate.toFixed(3)),
                max_pressure: Number(flushing_results.flushingResults.result_for_user_inlet_pressure.totalMaxPressureForUserInletPressureInput.toFixed(2)),
                flushing_velocity: Number(flushing_results.flushingResults.result_for_user_inlet_pressure.maxVelocityForUserInletPressureInput.toFixed(2)),
                totalLength: Number(Number(flushing_results.flushingResults.result_for_user_inlet_pressure.totalLengthForUserInletPressureInput).toFixed(2)),
                flushing_end_velocity: Number(Number(flushing_results.flushingResults.result_for_user_inlet_pressure.endVelocityForUserInletPressureInput).toFixed(2)),
                end_pressure: Number(Number(flushing_results.flushingResults.result_for_user_inlet_pressure.endPressureForUserInletPressureInput).toFixed(2)),
                last_flow_rate: Number(Number(flushing_results.flushingResults.result_for_user_inlet_pressure.lastFlowRateForUserInletPressureInput).toFixed(2)),
            }
            : { errors: flushing_results ? flushing_results.errors : [] };

        let minHeight = 0;
        let maxHeight = 0;
        let slopeSum: number = 0;
        let metersSum = 0;
        let slops_points = [{ x: 0, y: 0, high_diff_meters: 0 }];
        let pressure_points = [];
        let flow_rate_points = [];

        if (isSucceeded) {
            // First segment Inlet pressure:
            pressure_points.push({ x: 0, y: Number(calculation_results.segments[0].InletPressure) })

            for (let index = 0; index < calculation_results.slopes.length; index++) {
                let slope = calculation_results.slopes[index];
                slopeSum += slope.HeightDiffInMeters;
                metersSum += slope.SectionRealLength;

                maxHeight = slopeSum > maxHeight ? slopeSum : maxHeight;
                minHeight = slopeSum < minHeight ? slopeSum : minHeight;
                slops_points.push({ x: metersSum, y: Number(slopeSum.toFixed(2)), high_diff_meters: slope.HeightDiffInMeters });
            }
            let sum = 0;
            let spacing = calculation_results.segments[0].Length;

            for (let index = 0; index < calculation_results.segments.length; index++) {
                let seg = calculation_results.segments[index];
                // sum += seg.Length;
                sum = Math.round((sum + seg.Length) * 1e12) / 1e12
                pressure_points.push({ x: sum, y: Number(seg.EndPressure) })
                if (Math.round((sum % Number(spacing)) * 1e12) / 1e12 == 0) {
                    flow_rate_points.push({ x: sum, y: Number(seg.FlowRate) });
                }
            }
        }

        let graphs_data = isSucceeded ? {
            slopes_and_pressure: {
                min_height: Number(minHeight.toFixed(2)),
                max_height: Number(maxHeight.toFixed(2)),
                max_working_pressure: Number(calculation_results.calculationResults.totalMaxPressure.toFixed(2)),
                min_working_pressure: Number(calculation_results.calculationResults.totalMinPressure.toFixed(2)),
                points_on_graph: {
                    slopes: slops_points,
                    pressure: pressure_points,
                }
            },
            flow_rate: {
                max_flow_rate: Number(calculation_results.segments[0].FlowRate.toFixed(2)),
                min_flow_rate: Number(calculation_results.segments[calculation_results.segments.length - 1].FlowRate.toFixed(2)),
                points_on_graph: {
                    flow_rate: flow_rate_points,
                }
            },
        } : null;

        let result = {
            calculation_success: isSucceeded,
            calculation_results: calculationResults,
            calculation_extra_details: {
                pipes: isSucceeded ? calculation_results.pipes : [],
                slopes: isSucceeded ? calculation_results.slopes : [],
            },
            graphs_data: graphs_data,
            flushing_results: flushingResults,
            flushing_results_for_users_inlet_pressure_input: flushingResultsByUserInletPressure
        };

        return result;
    }
}