enum ECalculationErrors {
    NONE = 0,
    NO_RESULTS,
    NEGATIVE_INLET_PRESSURE,
    PIPE_MAX_ALLOWED_PRESSURE,
    INLET_PRESSURE_BELOW_TECHNICAL_PRESSURE,
    NEGATIVE_END_PRESSURE,
}

let errors = [
    { Id: ECalculationErrors.NONE, error: `NONE` },
    { Id: ECalculationErrors.NO_RESULTS, error: `Calculation ended with no matching results to user inputs.` },
    { Id: ECalculationErrors.NEGATIVE_INLET_PRESSURE, error: `Error - Pipe inlet pressure is negative.` },
    { Id: ECalculationErrors.PIPE_MAX_ALLOWED_PRESSURE, error: `Pressure exceeds the pipe's maximum allowed working pressure, recommended to change pipe's class or type or change flushing velocity.` },
    { Id: ECalculationErrors.INLET_PRESSURE_BELOW_TECHNICAL_PRESSURE, error: `Recommended to improve pressure loss/ flow variation/ emission uniformity input in step 2 or change inlet pressure. Current inlet pressure is too low to comply with step 2 input.` },
    { Id: ECalculationErrors.NEGATIVE_END_PRESSURE, error: `Error - Pipe end pressure is negative.` },
];

class CalculationErrors {
    public static get(errorId: ECalculationErrors) {
        return errors[errorId];
    }
}

export { CalculationErrors, ECalculationErrors }
