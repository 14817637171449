import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";
import { CalculatorService } from "src/app/services/calculator.service";
import { DataService } from "src/app/services/data.service";
import { IrrigationService } from "src/app/services/irrigation.service";
import { OfflineService } from "src/app/services/offline.service";
import { TextService } from "src/app/services/text.service";
import {
  Ecalculator,
  EIrrigationSteps,
  EtransaltionGroups,
} from "src/app/shared/enum";
import {
  CalculatorSection,
  DeviceLessCalcTableDataUnit,
} from "src/app/shared/types";
import {
  CALCULATOR_SECTION,
} from "src/app/shared/consts";
import {
  DeviceLessCalcCharacteristicsLabelConfig,
  IrrigationCharacteristicsLabels,
} from "src/app/shared/characteristics/calc-labels.types";

@Component({
  selector: "app-irrigation-calculator-page",
  templateUrl: "./irrigation-calculator-page.component.html",
  styleUrls: ["./irrigation-calculator-page.component.scss"],
})
export class IrrigationCalculatorPageComponent implements OnInit {
  user_id;
  Ecalculator = Ecalculator;

  // for mobile
  calculator_section = CALCULATOR_SECTION;
  show_section_mobile: CalculatorSection = CALCULATOR_SECTION.IRRIGATION;

  eTranslationsGroups = EtransaltionGroups;
  eIrrigationSteps = EIrrigationSteps;

  distanceValidationMax: number =
    this.dataService.selected_units === this.dataService.units_list[1]
      ? 3000
      : 1000;

  irrigationForm: FormGroup;
  showResults: boolean = false;
  isIrrigationPropsInitiated: boolean = false;
  labels: IrrigationCharacteristicsLabels;

  tableColumnsNames: string[] = ["irrigationRate"];

  emptyDeviceLessCalcTableDataUnit: DeviceLessCalcTableDataUnit = {
    headerDisplay: "",
    units: "",
    result: 0,
  };

  tableData: {
    irrigationRate: DeviceLessCalcTableDataUnit;
  } = {
    irrigationRate: this.emptyDeviceLessCalcTableDataUnit,
  };

  // needed when continue to shift calc
  irrigationRate: number;

  noteForResult: string;

  mySubscription: Subscription;

  constructor(
    public router: Router,
    public dataService: DataService,
    public textService: TextService,
    public calculatorService: CalculatorService,
    public offlineService: OfflineService,
    public irrigationService: IrrigationService,
    private fb: FormBuilder
  ) {
    this.dataService.resetIrrigationCalculator.subscribe((res) => {
      this.router.navigateByUrl("calc/irrigation");
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit(): void {
    this.getPageDataAndInitProps();
  }

  async getPageDataAndInitProps() {
    this.dataService.showLoader = true;
    if (!this.offlineService.isItOfflineMode) {
      this.calculatorService
        .getCalculatorPageDataDeviceLessCalcs()
        .subscribe((resp) => {
          this.dataService.showLoader = false;
          this.setResponsePageData(resp);
          this.initIrrigationProps();
        });
    }
  }

  initIrrigationProps() {
    this.initValidatorsOnFormFields();
    this.setInitialTableData();
    this.setCharacteristicsLabels();
    this.isIrrigationPropsInitiated = true;
  }

  setResponsePageData(resp) {
    if (resp.success) {
      this.user_id = resp.result.page_data.user.id;
      this.dataService.user_name =
        resp.result.page_data.user.first_name +
        " " +
        resp.result.page_data.user.last_name;

      this.dataService.regions_list = resp.result.page_data.regions;
      this.dataService.selected_units = this.dataService.units_list.find(
        (value) => {
          return value.value === Number(resp.result.page_data.user.units);
        }
      );
      this.dataService.selected_region = this.dataService.regions_list.find(
        (value) => {
          return resp.result.page_data.user.region === value.region_id;
        }
      );
      this.dataService.languages_list = resp.result.page_data.languages;
      this.dataService.selected_language = this.dataService.languages_list.find(
        (value) => {
          return (
            value.row_id === resp.result.page_data.user.language.toString()
          );
        }
      );
    }
  }

  initValidatorsOnFormFields() {
    this.irrigationForm = this.fb.group({
      emitterFlowRate: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.001),
              Validators.max(50000),
            ]),
          ],
          updateOn: "blur",
        },
      ],
      distanceBetweenEmitters: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.01),
              Validators.max(this.distanceValidationMax),
            ]),
          ],
          updateOn: "blur",
        },
      ],
      distanceBetweenRows: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.01),
              Validators.max(this.distanceValidationMax),
            ]),
          ],
          updateOn: "blur",
        },
      ],
      irrigationLinesPerRow: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.01),
              Validators.max(100),
            ]),
          ],
          updateOn: "change",
        },
      ],
    });
  }

  setInitialTableData() {
    this.tableData["irrigationRate"] = {
      headerDisplay: this.textService.getText(
        this.eTranslationsGroups.IRRIGATION,
        "results_irrigation_rate_label"
      ),
      units: this.textService.getUnitsAsText("mmh"),
      result: 0,
    };
  }

  setCharacteristicsLabels() {
    const labelsConfig: DeviceLessCalcCharacteristicsLabelConfig[] =
      this.irrigationService.getIrrigationLabelsConfig();
    const labels: IrrigationCharacteristicsLabels = labelsConfig.reduce(
      (acc, labelObj) => {
        const name: string = labelObj.ObjName;
        const label: string = this.textService.getText(
          EtransaltionGroups.IRRIGATION,
          labelObj.TextKey
        );
        const units: string = labelObj.unitsKey
          ? this.textService.getUnitsAsText(labelObj.unitsKey)
          : labelObj.unitsText || "";

        acc[name] = { label, units };
        return acc;
      },
      {} as IrrigationCharacteristicsLabels
    );
    this.labels = labels;
  }

  calculate() {
    // get input values
    const emitterFlowRate = this.getFormControlValues("emitterFlowRate");
    const distanceBetweenEmitters = this.getFormControlValues(
      "distanceBetweenEmitters"
    );
    const distanceBetweenRows = this.getFormControlValues(
      "distanceBetweenRows"
    );
    const irrigationLinesPerRow = this.getFormControlValues(
      "irrigationLinesPerRow"
    );

    const units = this.dataService.selected_units.value;

    const calcResult = this.irrigationService.calcIrrigation(
      emitterFlowRate,
      irrigationLinesPerRow,
      distanceBetweenEmitters,
      distanceBetweenRows,
      units
    );

    // update tableData
    this.tableData["irrigationRate"].result = calcResult;
    // update irrigation rate  (needed when continue to shift)
    this.irrigationRate = calcResult;
    //update note for result
    this.noteForResult = this.textService.getText(
      EtransaltionGroups.IRRIGATION,
      "irrigation_shift_note"
    );

    this.showResults = true;
  }

  getFormControlValues(controlName: string) {
    return this.irrigationForm.get(controlName).value;
  }

  nextStepMobile() {
    switch (this.show_section_mobile) {
      case this.calculator_section.IRRIGATION:
        this.show_section_mobile = this.calculator_section.RESULT;
        this.calculate();
        break;
      default:
        break;
    }
  }

  backStepMobile() {
    switch (this.show_section_mobile) {
      case this.calculator_section.RESULT:
        this.show_section_mobile = this.calculator_section.IRRIGATION;
        this.scrollToTop();
        break;
      default:
        break;
    }
  }

  validationByStep() {
    switch (this.show_section_mobile) {
      case this.calculator_section.IRRIGATION:
        return (
          this.irrigationForm.controls.emitterFlowRate.status === "INVALID" ||
          this.irrigationForm.controls.distanceBetweenEmitters.status ===
            "INVALID" ||
          this.irrigationForm.controls.distanceBetweenRows.status ===
            "INVALID" ||
          this.irrigationForm.controls.irrigationLinesPerRow.status ===
            "INVALID"
        );
      default:
        break;
    }
  }

  onClickContinueToShift() {
    // update irrigation rate on data service (needed when continue to shift)
    this.dataService.irrigationRate = this.irrigationRate;
    this.router.navigateByUrl("calc/shift");
  }

  scrollToTop() {
    setTimeout(() => {
      const element_elevation = document.querySelector("#scroll_top");
      element_elevation.scrollIntoView({ behavior: "smooth" });
    });
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
