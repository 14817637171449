import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { SubmainService } from 'src/app/services/submain.service';
import {
  Ecalculator,
  EqueryTypes,
  EemitterType,
  EtransaltionGroups,
  Eunits
} from 'src/app/shared/enum';
import { LateralService } from 'src/app/services/lateral.service';
import { TextService } from 'src/app/services/text.service';
import { MainlineService } from 'src/app/services/mainline.service';
import { UnitsConverterService } from 'src/app/services/units-converter.service';
import { OfflineService } from 'src/app/services/offline.service';

@Component({
  selector: 'app-pipe-section',
  templateUrl: './pipe-section.component.html',
  styleUrls: ['./pipe-section.component.scss']
})
export class PipeSectionComponent implements OnInit {

  constructor(
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    public submainService: SubmainService,
    public lateralService: LateralService,
    public textService: TextService,
    public mainlineService: MainlineService,
    public offlineService: OfflineService,
  ) {
    this.dataService.updateSectionToASectionChanges.subscribe((res) => {
      this.updateSectionToASectionChanges();
    });
    this.dataService.updateClassOptions.subscribe(res => {
      if (this.controls.pipe_material.valid) {
        this.setDropDownsForMainline('type')

      }
    })
    this.dataService.updateNominalOption.subscribe(sectionIndex => {
      setTimeout(() => {
        if (sectionIndex === this.index && this.controls.pipe_material.valid && this.controls.pipe_class.valid) {
          this.setDropDownsForMainline('class')

        }
      });

    })
  }

  EtransaltionGroups = EtransaltionGroups;
  EemitterType = EemitterType;
  EqueryTypes = EqueryTypes;
  Ecalculator = Ecalculator;
  Eunits = Eunits
  UnitsConverterService = UnitsConverterService
  @Input() selectedSection;
  @Input() sectionForm: FormGroup;
  @Input() pipeTypeOptions: any[];
  @Input() nominalDiametersOptions;
  @Input() pipeClassOptions;
  @Input() index;
  @Output() deleteSection = new EventEmitter<string>();
  @Output() calculateTotalLengthLeftForSection = new EventEmitter<string>();
  @Output() sectionAHasChanged = new EventEmitter<string>();
  @Input() prevSectionInternalDiameter = null;
  @Input() prevSectionNominalDiameter = null
  @Input() queryIndex; //0 -Q1, 1 - Q2, 2-Q3
  @Input() calculatorIndex;
  @Input() chosen_emitter_type;
  @Input() chosen_model;
  @Output() getFlowRateOptions = new EventEmitter<string>();
  @Input() isItFlushingSection = false;

  InternalDiameterSmallerThenPreviousSection: boolean = true;
  currentInternalDiameter: number;

  nominal_diameter_label;
  internal_diameter_label;
  pipe_length_label;
  length_label;
  section_title
  pipe_material_control_name
  pipe_class_control_name
  pipe_nominal_control_name
  pipe_internal_control_name
  pipe_length_control_name
  pipe_roughness_control_name
  // max limit for usa units (feet) is 5000 and for metric is 1000
  lengthValidationMax = this.dataService.selected_units === this.dataService.units_list[1] ? 5000: 1000

  ngOnInit(): void {
    this.nominal_diameter_label = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.calculatorIndex === Ecalculator.submain
          ? this.EtransaltionGroups.SUBMAIN
          : this.EtransaltionGroups.LATERAL,
        this.calculatorIndex === Ecalculator.submain
          ? 'submain_s3_nominal_diameter'
          : 'lateral_s3_nominal_diameter'
      ),
      'mm'
    );
    this.internal_diameter_label = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.calculatorIndex === Ecalculator.submain
          ? this.EtransaltionGroups.SUBMAIN
          : this.EtransaltionGroups.LATERAL,
        this.calculatorIndex === Ecalculator.submain
          ? 'submain_s3_internal_diameter'
          : 'lateral_s3_internal_diameter'
      ),
      'mm'
    );
    this.length_label = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.EtransaltionGroups.LATERAL,
        'lateral_s3_length'
      ),
      'm'
    );
    switch (this.calculatorIndex) {
      case Ecalculator.submain:
        this.initSubmainSection();
        this.section_title = this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          "submain_s3_section"
        )
        this.pipe_length_label = this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_s3_section_length'
          ),
          'm'
        );
        this.pipe_material_control_name = 'pipeType'
        this.pipe_class_control_name = 'pipeClass'
        this.pipe_nominal_control_name = 'nominalDiameter'
        this.pipe_internal_control_name = 'pipeInternalDiameter'
        this.pipe_length_control_name = 'pipeSectionLength'
        break;
      case Ecalculator.lateral:
        this.initLateralSection();
        this.section_title = this.textService.getText(
          EtransaltionGroups.LATERAL,
          "lateral_s3_charac_sections_title"
        )
        break;
      case Ecalculator.mainline:
        this.initMainlineSection()
        this.section_title = this.isItFlushingSection ? this.textService.getText(
          this.EtransaltionGroups.MAINLINE,
          'mainline_characteristic_section_title_flushing'
        ) :
          this.textService.getText(
            this.EtransaltionGroups.MAINLINE,
            'mainline_characteristic_section_title'
          )
        this.pipe_length_label = this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.MAINLINE,
            'mainline_characteristic_length_label'
          ),
          'm'
        );
        this.pipe_material_control_name = 'pipe_material'
        this.pipe_class_control_name = 'pipe_class'
        this.pipe_nominal_control_name = 'nominal_diameter'
        this.pipe_internal_control_name = 'internal_diameter'
        this.pipe_length_control_name = 'length'
        this.pipe_roughness_control_name = 'roughness'
        break;
      default:
        break;
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  initSubmainSection() {
    if (this.queryIndex == 0) {
      this.onChangesQuery1();
      if (this.selectedSection.letter === 'A') {
        if (
          this.sectionForm.value.pipeSectionLength === '' ||
          this.sectionForm.value.pipeSectionLength === null
        ) {
          this.sectionForm
            .get('pipeSectionLength')
            .setValue(this.dataService.totalRealLength, { emitEvent: false });
        }

        if (this.sectionForm.controls.nominalDiameter.valid) {
          this.setDropDowns('type');
        }
      } else {
        if (
          this.sectionForm.value.pipeType === '' ||
          this.sectionForm.value.pipeType === null
        ) {
          this.sectionForm
            .get('pipeType')
            .setValue(this.dataService.sectionAchosenPipeType, {
              emitEvent: false,
            });
        }

        if (
          this.sectionForm.value.pipeSectionLength === '' ||
          this.sectionForm.value.pipeSectionLength === null
        ) {
          this.sectionForm
            .get('pipeSectionLength')
            .setValue(0, { emitEvent: false });
          this.calculateTotalLengthLeftForSection.emit(this.selectedSection.id);
          this.sectionForm
            .get('pipeSectionLength')
            .setValue(this.dataService.totalLengthLeftForSections, {
              emitEvent: false,
            });
        
            setTimeout(() => {
              this.dataService.checkWarningByAllSectiosSubmain.next()              
            });
           
        }
        this.sectionForm
          .get('pipeClass')
          .setValue(this.dataService.sectionAchosenPipeClass);
      }
    }
    if (this.queryIndex == 1) {
      this.onChangesQuery2();
    }
    if (this.queryIndex == 2) {
      if (this.sectionForm.controls.nominalDiameter.valid) {
        this.setDropDowns('type');
      }
      this.onChangesQuery1();
    }
  }

  initLateralSection() {
    this.onChangesLateralCalculator();
    if (this.queryIndex === this.EqueryTypes.maximum_length || this.EqueryTypes.matrix) {  
      this.controls['length'].reset();
      this.controls['length'].clearValidators();
      this.controls['length'].updateValueAndValidity();
    } else {
      this.controls['length'].setValidators([
        Validators.compose([
          Validators.required,
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.min(0.1),
          Validators.max(this.lengthValidationMax),
        ]),
      ]);
      this.controls['length'].updateValueAndValidity();
    }

    if (this.dataService.checkIfItsInlineDripper(this.chosen_emitter_type)) {
      this.controls.pipe_material.reset();
      this.controls.pipe_material.clearValidators();
      this.controls.pipe_material.updateValueAndValidity({
        emitEvent: false,
      });
    } else {
      this.controls.pipe_material.setValidators([
        Validators.compose([Validators.required]),
      ]);
      this.controls.pipe_material.updateValueAndValidity({
        emitEvent: false,
      });
      if (this.queryIndex == 0 || this.queryIndex == 3) {
        if (this.selectedSection.letter != 'A') {
          if (
            this.sectionForm.value.pipe_material === '' ||
            this.sectionForm.value.pipe_material === null
          ) {
            this.sectionForm
              .get('pipe_material')
              .setValue(this.dataService.sectionAchosenPipeMaterial, {
                emitEvent: false,
              });
          }

          this.sectionForm
            .get('wall_thickness')
            .setValue(this.dataService.sectionAchosenPipeWellThickness);
        }
      }
      if (this.sectionForm.controls.nominal_diameter.valid) {
        this.setDropDowns('wall_thickness');
      }
    }
  }

  initMainlineSection() {
    if (this.selectedSection.letter === 'A') {
      if (this.controls.pipe_material.valid) {
        this.setDropDownsForMainline('type');
      }
    } else {
      if (!this.controls.pipe_material.value || this.controls.pipe_material.value == '') {
        this.controls.pipe_material.setValue(this.dataService.sectionAchosenPipeTypeMainline, { emitEvent: false })
      }

      if ((!this.controls.pipe_class.value || this.controls.pipe_class.value == '') && this.queryIndex != EqueryTypes.pipes_diameter) {
        if (!this.dataService.sectionAchosenPipeClassMainline) {
          this.setDropDownsForMainline('type');
        }

        this.controls.pipe_class.setValue(this.dataService.sectionAchosenPipeClassMainline, { emitEvent: false })
      }

    }
    if (this.controls.pipe_material.valid && this.controls.pipe_class.valid && this.queryIndex != EqueryTypes.pipes_diameter) {
      setTimeout(() => {
        this.setDropDownsForMainline('class');
      });
    }
    this.onChangesMainlineCalculator()
  }

  onChangesLateralCalculator() {
    this.sectionForm.valueChanges.subscribe((value => {
      this.dataService.inputs_contents_has_changed = true
    }))
    this.sectionForm.get('pipe_material').valueChanges.subscribe((value) => {
      if (this.selectedSection.letter == 'A') {
        this.dataService.sectionAchosenPipeMaterial = value;

        this.sectionAHasChanged.emit();
      }
      this.setDropDowns('pipe_material');
    });
    this.sectionForm.get('wall_thickness').valueChanges.subscribe((value) => {
      if (this.selectedSection.letter == 'A') {
        this.dataService.sectionAchosenPipeWellThickness = value;
      }
      this.setDropDowns('wall_thickness');
      setTimeout(() => {
        if (
          this.dataService.checkIfItsInlineDripper(this.chosen_emitter_type) && this.sectionForm.valid
        ) {

          this.sectionForm
          this.getFlowRateOptions.emit();
        }
      });
    });
    this.sectionForm.get('nominal_diameter').valueChanges.subscribe((value) => {
      this.setDropDowns('nominal_diameter');
      setTimeout(() => {
        if (
          this.dataService.checkIfItsInlineDripper(this.chosen_emitter_type) && this.sectionForm.valid
        ) {
          this.getFlowRateOptions.emit();
        }
      });
    });
    this.sectionForm
      .get('internal_diameter')
      .valueChanges.subscribe((value) => {
        if (this.selectedSection.letter != 'A') {
          this.InternalDiameterSmallerThenPreviousSection =
            Number(this.prevSectionInternalDiameter) > Number(value);
        }
        setTimeout(() => {
          if (
            this.dataService.checkIfItsInlineDripper(this.chosen_emitter_type) && this.sectionForm.valid
          ) {
            this.getFlowRateOptions.emit();
          }
        });
      });
    this.sectionForm.get('length').valueChanges.subscribe((value) => {
      setTimeout(() => {
        if (
          this.dataService.checkIfItsInlineDripper(this.chosen_emitter_type) && this.sectionForm.valid
        ) {
          this.getFlowRateOptions.emit();
        }
        this.dataService.calculateTotalLengthLateral.next()
      });
    });
  }

  onChangesQuery1(): void {
    this.sectionForm.valueChanges.subscribe((val) => {
      this.dataService.inputs_contents_has_changed = true
      if (
        this.sectionForm.get('pipeType').valid &&
        this.sectionForm.get('nominalDiameter').valid &&
        this.sectionForm.get('pipeClass').valid &&
        this.queryIndex != EqueryTypes.pipes_diameter
      ) {
        this.setValueForPipeInternalDiameter();
      }
      if (this.dataService.currentSectionOnCalculatorPage != 'result') {
      }

      if (this.sectionForm.valid) {
        this.dataService.reloadSectionsIlustration.next();
      }
    });
    this.sectionForm.get('pipeType').valueChanges.subscribe((value) => {
      if (this.selectedSection.letter == 'A') {
        this.dataService.sectionAchosenPipeType = value;

        this.sectionAHasChanged.emit();
      }
      this.sectionForm.get('pipeClass').setValue('', { emitEvent: false });
      this.dataService.sectionAchosenPipeClass = '';
      this.sectionForm
        .get('nominalDiameter')
        .setValue('', { emitEvent: false });
      this.setDropDowns('type');
    });

    this.sectionForm
      .get('nominalDiameter')
      .valueChanges.subscribe(([prev, next]: [any, any]) => {
        this.setDropDowns('nominal');
      });

    this.sectionForm.get('pipeClass').valueChanges.subscribe((value) => {
      if (this.selectedSection.letter == 'A') {
        this.dataService.sectionAchosenPipeClass = value;
      }
      this.setDropDowns('class');
    });

    this.sectionForm
      .get('pipeInternalDiameter')
      .valueChanges.subscribe((value) => {
        if (this.selectedSection.letter != 'A') {
          this.InternalDiameterSmallerThenPreviousSection =
            Number(this.prevSectionInternalDiameter) > Number(value);
        }
      });

    this.sectionForm.controls.pipeSectionLength.valueChanges.subscribe(value => {
      setTimeout(() => {
        this.dataService.checkWarningByAllSectiosSubmain.next()
      });
    })
  }

  setPipeInternalDiameterValidator() {
    let control_name =
      this.calculatorIndex == this.Ecalculator.submain
        ? 'pipeInternalDiameter'
        : 'internal_diameter';
    this.sectionForm
      .get(control_name)
      .setValidators([
        Validators.max(this.currentInternalDiameter * 1.2),
        Validators.min(this.currentInternalDiameter * 0.8),
        Validators.required,
      ]);
    this.sectionForm
      .get(control_name)
      .updateValueAndValidity({ emitEvent: false });
  }

  checkError(controlName: string) {
    if (!this.InternalDiameterSmallerThenPreviousSection) {
      return `Enter value smaller than previous section.`;
    }
    if (this.sectionForm.controls[controlName].errors) {
      const theErrors = this.sectionForm.controls[controlName].errors;
      if (theErrors.required) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'field_required'
        );
      }
      if (theErrors.minlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );

        return this.textService.addValueToText(
          text,
          theErrors.minlength.requiredLength
        );
      }
      if (theErrors.maxlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );
        return this.textService.addValueToText(
          text,
          theErrors.maxlength.requiredLength
        );
      }
      if (theErrors.min) {
        return (
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_s3_internal_validation_min'
          ) +
          ' ' +
          this.currentInternalDiameter
        );
      }
      if (theErrors.max) {
        return (
          this.textService.getText(
            this.EtransaltionGroups.SUBMAIN,
            'submain_s3_internal_validation_max'
          ) +
          ' ' +
          this.currentInternalDiameter
        );
      }
      if (theErrors.pattern) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'invalid_pattern'
        );
      }
      if (theErrors.notEqual) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'password_validation'
        );
      }
    }
  }

  onChangesQuery2() {
    this.sectionForm.valueChanges.subscribe((value => {
      this.dataService.inputs_contents_has_changed = true
    }))
    this.sectionForm.get('pipeType').valueChanges.subscribe((value) => {
      this.setDropDowns('type');
    });
  }

  setValueForPipeInternalDiameter() {
    this.dataService.showLoader = true;
    switch (this.calculatorIndex) {
      case this.Ecalculator.submain:
        if (this.offlineService.isItOfflineMode) {
          this.setValueForPipeInternalDiameterSubmain()
        } else {
          this.submainService
            .getPipeInternalDiameter(
              this.sectionForm.get('pipeType').value,
              this.sectionForm.get('pipeClass').value,
              this.sectionForm.get('nominalDiameter').value.value
            )
            .subscribe(
              (resp) => {
                this.dataService.showLoader = false;
                this.setResponsePipeInternalDiameterSubmain(resp)
              },
              (err) => {
                this.dataService.showLoader = false;
                if (err.message) {
                  this.dataService.error_result_msg = err.message;
                }
                if (err.ErrorToClient) {
                  this.dataService.error_result_msg = err.ErrorToClient;
                }
                this.dataService.showErrorPopup = true;
              }
            );
        }
        break;
      case this.Ecalculator.lateral:
        if (this.offlineService.isItOfflineMode) {
          this.setValueForPipeInternalDiameterLateralOffline()
        } else {
          this.lateralService
            .getInternalDiameter(
              this.dataService.checkIfItsInlineDripper(this.chosen_emitter_type),
              this.chosen_emitter_type,
              this.chosen_model,
              '',
              this.controls.wall_thickness.value,
              this.controls.pipe_material.value === null
                ? ''
                : this.controls.pipe_material.value,
              this.controls.nominal_diameter.value.value
            )
            .subscribe(
              (response) => {
                this.dataService.showLoader = false;
                this.setResponsePipeInternalDiameterLateral(response)
              },
              (err) => {
                this.dataService.showLoader = false;
                if (err.message) {
                  this.dataService.error_result_msg = err.message;
                }
                if (err.ErrorToClient) {
                  this.dataService.error_result_msg = err.ErrorToClient;
                }
                this.dataService.showErrorPopup = true;
              }
            );

        }
        break;
      case this.Ecalculator.mainline:
        if (this.offlineService.isItOfflineMode) {
          this.setValueForPipeInternalDiameterMainlineOffline()
        } else {
          this.mainlineService
            .getPipeInternalDiameter(
              this.controls.pipe_material.value,
              this.controls.pipe_class.value,
              this.controls.nominal_diameter.value.value,
            )
            .subscribe(
              (resp) => {
                this.dataService.showLoader = false;
                this.setResponsePipeInternalDiameterMainline(resp)
              },
              (err) => {
                this.dataService.showLoader = false;
                if (err.message) {
                  this.dataService.error_result_msg = err.message;
                }
                if (err.ErrorToClient) {
                  this.dataService.error_result_msg = err.ErrorToClient;
                }
                this.dataService.showErrorPopup = true;
              }
            );
        }
        break;
      default:
        break;
    }
  }

  async setValueForPipeInternalDiameterSubmain() {
    let response = await this.submainService.getPipeInternalDiameterOffline(this.sectionForm.get('pipeType').value,
      this.sectionForm.get('pipeClass').value,
      this.sectionForm.get('nominalDiameter').value.value)

    this.dataService.showLoader = false;
    this.setResponsePipeInternalDiameterSubmain(response)
  }
  setResponsePipeInternalDiameterSubmain(resp) {
    if (resp.success) {
      this.sectionForm
        .get('pipeInternalDiameter')
        .setValue(Number(Number(resp.result.internal_diameter).toFixed(2)), { emitEvent: false });

      this.currentInternalDiameter = Number(Number(resp.result.internal_diameter).toFixed(2));
      this.setPipeInternalDiameterValidator();
      this.sectionForm.updateValueAndValidity({ emitEvent: false });
      if (this.selectedSection.letter != 'A') {
        this.InternalDiameterSmallerThenPreviousSection =
          Number(this.prevSectionInternalDiameter) >
          Number(this.sectionForm.value.pipeInternalDiameter);
      }
      if (this.sectionForm.valid) {
        this.dataService.reloadSectionsIlustration.next();
      }
    }
  }

  async setValueForPipeInternalDiameterLateralOffline() {
    let response = await this.lateralService.getInternalDiameterOffline(this.dataService.checkIfItsInlineDripper(this.chosen_emitter_type),
      this.chosen_emitter_type,
      this.chosen_model,
      '',
      this.controls.wall_thickness.value,
      this.controls.pipe_material.value === null
        ? ''
        : this.controls.pipe_material.value,
      this.controls.nominal_diameter.value.value)

    this.dataService.showLoader = false;
    this.setResponsePipeInternalDiameterLateral(response)
  }

  setResponsePipeInternalDiameterLateral(response) {
    if (response.success) {
      this.sectionForm
        .get('internal_diameter')
        .setValue(Number(Number(response.result.internal_diameter).toFixed(2)), {
          emitEvent: false,
        });
      if (
        this.dataService.checkIfItsInlineDripper(
          this.chosen_emitter_type
        )
      ) {
        setTimeout(() => {
          this.getFlowRateOptions.emit();
        });
      }
      this.currentInternalDiameter = Number(Number(response.result.internal_diameter).toFixed(2));
      this.setPipeInternalDiameterValidator();
      this.sectionForm.updateValueAndValidity({ emitEvent: false });
      if (this.selectedSection.letter != 'A') {
        this.InternalDiameterSmallerThenPreviousSection =
          Number(this.prevSectionInternalDiameter) >
          Number(this.sectionForm.value.internal_diameter);
      }
    }
  }

  async setValueForPipeInternalDiameterMainlineOffline() {
    let response = await this.mainlineService.getPipeInternalDiameterOffline(this.controls.pipe_material.value,
      this.controls.pipe_class.value,
      this.controls.nominal_diameter.value.value)

    this.dataService.showLoader = false;
    this.setResponsePipeInternalDiameterMainline(response)
  }
  setResponsePipeInternalDiameterMainline(resp) {
    if (resp.success) {
      this.controls.roughness.setValue(Number(resp.result.roughness))
      this.controls.internal_diameter.setValue(Number(Number(resp.result.internal_diameter).toFixed(2)), { emitEvent: false });

      this.currentInternalDiameter = Number(Number(resp.result.internal_diameter).toFixed(2));
      this.setPipeInternalDiameterValidator();
      this.sectionForm.updateValueAndValidity({ emitEvent: false });
      if (this.selectedSection.letter != 'A') {
        this.InternalDiameterSmallerThenPreviousSection =
          Number(this.prevSectionInternalDiameter) >
          Number(this.sectionForm.value.internal_diameter);
      }
      if (this.sectionForm.valid) {
        this.dataService.updateMainlineSectionsForIlustration.next();
      }
    }
  }
  async setDropDowns(whichDropdownChanged) {
    this.dataService.showLoader = true;
    switch (this.calculatorIndex) {
      case this.Ecalculator.submain:
        if (this.offlineService.isItOfflineMode) {
          this.setDropDownsOfflineSubmain(whichDropdownChanged)
        } else {
          this.submainService
            .getSubmainSectionDropdowns(
              this.sectionForm.get('pipeType').value,
              whichDropdownChanged != 'type'
                ? this.sectionForm.get('pipeClass').value
                : '',
              whichDropdownChanged === 'nominal'
                ? this.sectionForm.get('nominalDiameter').value.value
                : ''
            )
            .subscribe(
              (resp) => {
                this.dataService.showLoader = false;
                this.setDropedownsResponseSubmain(resp, whichDropdownChanged)
              },
              (err) => {
                this.dataService.showLoader = false;
                if (err.message) {
                  this.dataService.error_result_msg = err.message;
                }
                if (err.ErrorToClient) {
                  this.dataService.error_result_msg = err.ErrorToClient;
                }
                this.dataService.showErrorPopup = true;
              }
            );
        }
        break;
      case this.Ecalculator.lateral:
        this.setDropDownsForLateral(whichDropdownChanged);
        break;
      case this.Ecalculator.mainline:
        this.setDropDownsForMainline(whichDropdownChanged);
        break;
      default:
        break;
    }
  }

  async setDropDownsOfflineSubmain(whichDropdownChanged) {
    let response = await this.submainService
      .getSubmainSectionDropdownsOffline(
        this.sectionForm.get('pipeType').value,
        whichDropdownChanged != 'type'
          ? this.sectionForm.get('pipeClass').value
          : '',
        whichDropdownChanged === 'nominal'
          ? this.sectionForm.get('nominalDiameter').value.value
          : ''
      )
    this.dataService.showLoader = false;
    this.setDropedownsResponseSubmain(response, whichDropdownChanged)
  }
  setDropedownsResponseSubmain(resp, whichDropdownChanged) {
    if (resp.success) {
      if (whichDropdownChanged == 'type') {
        this.pipeClassOptions =
          resp.result.pipes_section_charactaristics.classes;
        if (
          !this.pipeClassOptions.includes(
            this.sectionForm.get('pipeClass').value
          )
        ) {
          this.sectionForm
            .get('pipeClass')
            .setValue('', { emitEvent: false });
        }
      }

      if (
        whichDropdownChanged != 'nominal' &&
        (this.queryIndex == 0 || this.queryIndex == 2)
      ) {
        this.nominalDiametersOptions =
          resp.result.pipes_section_charactaristics.nominal_diameters;
        this.pipeClassOptions =
          resp.result.pipes_section_charactaristics.classes;

        //find if the chosen nominal exsits in the new nominal options
        let nominal_user_choise = this.nominalDiametersOptions.filter(nominal => nominal.value === this.controls.nominalDiameter.value.value)[0];
        this.controls.nominalDiameter.setValue(nominal_user_choise ? nominal_user_choise : '', { emitEvent: false });
      }
      if (
        this.sectionForm.get('pipeType').valid &&
        this.sectionForm.get('nominalDiameter').valid &&
        this.sectionForm.get('pipeClass').valid &&
        this.queryIndex != EqueryTypes.pipes_diameter
      ) {
        this.setValueForPipeInternalDiameter();
      }
    }

  }

  async setDropDownsForLateral(whichDropdownChanged) {
    this.dataService.showLoader = true
    if (this.dataService.checkIfItsInlineDripper(this.chosen_emitter_type)) {
      if (this.offlineService.isItOfflineMode) {
        let response = await this.lateralService.getFlowRateOptionsForEmitterInlineEmittersOffline(
          this.chosen_emitter_type,
          this.chosen_model,
          '',
          [this.controls.nominal_diameter.value.value],
          whichDropdownChanged == 'wall_thickness'
            ? [this.controls.wall_thickness.value]
            : ''
        )
        this.dataService.showLoader = false
        this.setDropDownsForLateralInlineEmittersResposne(response, whichDropdownChanged)
      } else {
        this.lateralService
          .getFlowRateOptionsForEmitterInlineEmitters(
            this.chosen_emitter_type,
            this.chosen_model,
            '',
            [this.controls.nominal_diameter.value.value],
            whichDropdownChanged == 'wall_thickness'
              ? [this.controls.wall_thickness.value]
              : ''
          )
          .subscribe((resp) => {
            this.dataService.showLoader = false;
            this.setDropDownsForLateralInlineEmittersResposne(resp, whichDropdownChanged)
          });
      }
    } else {
      if (this.offlineService.isItOfflineMode) {
        let response = await this.lateralService.getFlowRateOptionsForEmitterOnlineEmittersOffline(
          this.controls.pipe_material.value,
          whichDropdownChanged != 'pipe_material'
            ? this.controls.wall_thickness.value
            : '',
          whichDropdownChanged === 'nominal_diameter'
            ? this.controls.nominal_diameter.value.value
            : '',
          this.EemitterType[this.dataService.chosen_emitter_type]
        )
        this.dataService.showLoader = false;
        this.setDropDownsForLateralOnlineEmittersResponse(response, whichDropdownChanged)
      } else {
        this.lateralService
          .getFlowRateOptionsForEmitterOnlineEmitters(
            this.controls.pipe_material.value,
            whichDropdownChanged != 'pipe_material'
              ? this.controls.wall_thickness.value
              : '',
            whichDropdownChanged === 'nominal_diameter'
              ? this.controls.nominal_diameter.value.value
              : '',
            this.EemitterType[this.dataService.chosen_emitter_type]
          )
          .subscribe((response) => {
            this.dataService.showLoader = false;
            this.setDropDownsForLateralOnlineEmittersResponse(response, whichDropdownChanged)
          });
      }
    }
  }

  setDropDownsForLateralInlineEmittersResposne(resp, whichDropdownChanged) {
    if (resp.success) {
      if (whichDropdownChanged == 'nominal_diameter') {
        this.pipeClassOptions = resp.result.lateral_chars.wall_thickess;
        if (
          !this.pipeClassOptions.includes(
            this.controls.wall_thickness.value
          )
        ) {
          this.controls.wall_thickness.reset();
        }
      }
      if (
        this.controls.wall_thickness.valid &&
        this.controls.nominal_diameter.valid
      ) {
        this.setValueForPipeInternalDiameter();
      }
    }
  }

  setDropDownsForLateralOnlineEmittersResponse(response, whichDropdownChanged) {
    if (whichDropdownChanged == 'pipe_material') {
      this.pipeClassOptions =
        response.result.pipes_section_charactaristics.classes;
      if (
        !this.pipeClassOptions.includes(
          this.sectionForm.get('wall_thickness').value
        )
      ) {
        this.sectionForm
          .get('wall_thickness')
          .setValue('', { emitEvent: false });
      }
    }

    if (whichDropdownChanged != 'nominal_diameter') {
      this.nominalDiametersOptions =
        response.result.pipes_section_charactaristics.nominal_diameters;
      this.pipeClassOptions =
        response.result.pipes_section_charactaristics.classes;

      //find if the chosen nominal exsits in the new nominal options
      let nominal_user_choise = this.nominalDiametersOptions.filter(nominal => nominal.value === this.sectionForm.get('nominal_diameter').value.value)[0];
      this.sectionForm
        .get('nominal_diameter')
        .setValue(nominal_user_choise ? nominal_user_choise : '', { emitEvent: false });
    }
    if (
      this.sectionForm.get('pipe_material').valid &&
      this.sectionForm.get('nominal_diameter').valid &&
      this.sectionForm.get('wall_thickness').valid
    ) {
      this.setValueForPipeInternalDiameter();
    }
  }

  async setDropDownsForMainline(whichDropdownChanged) {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = await this.mainlineService
        .getMainlineSectionDropdownsOffline(
          this.controls.pipe_material.value,
          whichDropdownChanged != 'type'
            ? this.controls.pipe_class.value
            : '',
          whichDropdownChanged === 'nominal'
            ? this.controls.nominal_diameter.value.value
            : '', Number(this.prevSectionNominalDiameter)
        )
      this.dataService.showLoader = false
      this.setResponseDropDownsForMainline(whichDropdownChanged, response)
    } else {
      this.mainlineService
        .getMainlineSectionDropdowns(
          this.controls.pipe_material.value,
          whichDropdownChanged != 'type'
            ? this.controls.pipe_class.value
            : '',
          whichDropdownChanged === 'nominal'
            ? this.controls.nominal_diameter.value.value
            : '', Number(this.prevSectionNominalDiameter)
        )
        .subscribe(
          (resp) => {
            this.dataService.showLoader = false;
            this.setResponseDropDownsForMainline(whichDropdownChanged, resp)
          },
          (err) => {
            this.dataService.showLoader = false;
            if (err.message) {
              this.dataService.error_result_msg = err.message;
            }
            if (err.ErrorToClient) {
              this.dataService.error_result_msg = err.ErrorToClient;
            }
            this.dataService.showErrorPopup = true;
          }
        );
    }
  }
  setResponseDropDownsForMainline(whichDropdownChanged, resp) {
    if (resp.success) {
      if (whichDropdownChanged == 'type' || whichDropdownChanged == 'class') {
        this.pipeClassOptions =
          resp.result.pipes_section_charactaristics.classes;
        if (!this.pipeClassOptions.includes(this.controls.pipe_class.value)
        ) {
          this.controls.pipe_class.setValue('', { emitEvent: false });
        }
      }

      if (
        whichDropdownChanged != 'nominal' && this.queryIndex == EqueryTypes.pressure_loss
      ) {
        this.nominalDiametersOptions =
          resp.result.pipes_section_charactaristics.nominal_diameters;

        //find if the chosen nominal exsits in the new nominal options
        let nominal_user_choise = this.nominalDiametersOptions.filter(nominal => nominal.value === this.controls.nominal_diameter.value.value)[0];
        this.controls.nominal_diameter.setValue(nominal_user_choise ? nominal_user_choise : '', { emitEvent: false });
      }
      if (
        this.controls.pipe_material.valid &&
        this.controls.nominal_diameter.valid &&
        this.controls.pipe_class.valid &&
        this.queryIndex != EqueryTypes.pipes_diameter &&
        this.controls.pipe_class.value != '' &&
        this.controls.nominal_diameter.value != ''
      ) {
        this.setValueForPipeInternalDiameter();
      }
    }
  }

  onChangesMainlineCalculator() {
    this.sectionForm.valueChanges.subscribe((val) => {
      if (this.sectionForm.valid) {
        this.dataService.updateMainlineSectionsForIlustration.next() //update section and reload ilustration
      }
      this.dataService.inputs_contents_has_changed = true
    });
    this.controls.pipe_material.valueChanges.subscribe(value => {
      if (this.selectedSection.letter == 'A') {
        this.dataService.sectionAchosenPipeTypeMainline = value;
      }
      if (value != null && value != '' && this.queryIndex != EqueryTypes.pipes_diameter) {
        if (this.controls.pipe_class.valid) {
          this.setDropDownsForMainline('class');
        } else {
          this.setDropDownsForMainline('type');
        }
      }
    })
    this.controls.pipe_class.valueChanges.subscribe(value => {
      if (this.selectedSection.letter == 'A') {
        this.dataService.sectionAchosenPipeClassMainline = value;
      }
      if (value != null && value != '') {
        this.setDropDownsForMainline('class');
      }
    })
    this.controls.nominal_diameter.valueChanges.subscribe(value => {
      if (value != null && value != '') {
        this.setDropDownsForMainline('nominal');
        //reload nominal options for next section if exists
        this.dataService.updateNominalOption.next(this.index + 1)
      }
    })
    this.controls.internal_diameter.valueChanges.subscribe(value => {
      if (this.selectedSection.letter != 'A') {
        this.InternalDiameterSmallerThenPreviousSection =
          Number(this.prevSectionInternalDiameter) > Number(value);
      }
    })
    this.controls.length.valueChanges.subscribe(value => {
      this.dataService.calculateTotalLengthMainline.next()
    })
  }

  get controls() {
    return this.sectionForm.controls;
  }

  delete() {
    this.deleteSection.emit(this.index);
  }

  updateSectionToASectionChanges() {
    switch (this.calculatorIndex) {
      case Ecalculator.submain:
        this.sectionForm.controls.pipeType.setValue(
          this.dataService.sectionAchosenPipeType,
          { emitEvent: false }
        );
        this.sectionForm.controls.pipeClass.setValue('', { emitEvent: false });
        this.dataService.sectionAchosenPipeClass = ''
        this.sectionForm.controls.nominalDiameter.setValue('', {
          emitEvent: false,
        });
        this.setDropDowns('type')
        break;
      case Ecalculator.lateral:
        this.sectionForm.controls.pipe_material.setValue(
          this.dataService.sectionAchosenPipeMaterial,
          { emitEvent: false }
        );
        this.sectionForm.controls.wall_thickness.setValue('', {
          emitEvent: false,
        });
        this.dataService.sectionAchosenPipeWellThickness = ''

        this.sectionForm.controls.nominal_diameter.setValue('', {
          emitEvent: false,
        });
        this.setDropDownsForLateral('pipe_material')
        break;
      case Ecalculator.mainline:
        this.sectionForm.controls.pipe_material.setValue(
          this.dataService.sectionAchosenPipeTypeMainline,
          { emitEvent: false }
        );
        this.sectionForm.controls.pipe_class.setValue('', {
          emitEvent: false,
        });
        this.dataService.sectionAchosenPipeClassMainline = ''
        this.sectionForm.controls.nominal_diameter.setValue('', {
          emitEvent: false,
        });
        break;
      default:
        break;
    }

  }
}