export class SlopeSection{
    // Static Data
    Id: number;
    HeightDiffInMeters: number;
    HeightDiffPrecent: number;

    // User Section Data:
    SectionLengthOnMap: number;
    SectionRealLength: number;
    
    InletPressure: number;
    EndPressure: number;

    NumOfSegmentsInSection: number;
}