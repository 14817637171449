import { AbsDAL } from "./Base/AbsDAL";
let AWS = require('aws-sdk');

export class LanguagesDAL extends AbsDAL {
    public static readonly LANGUAGES_PK = "LANGUAGES";
    public static readonly LANGUAGES_TABLE_NAME = "Languages";
    public static readonly LOCAL_STORAGE_LANGUAGES_TABLE_NAME = "languages";

    /**
     * Gets all languages
     * 
     */
    public async getLanguages(isOffline: boolean) {
        if (isOffline) {
            let table_data = JSON.parse(localStorage.getItem(LanguagesDAL.LOCAL_STORAGE_LANGUAGES_TABLE_NAME))
            return table_data
        }
        let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
        let table = LanguagesDAL.LANGUAGES_TABLE_NAME;

        // Get all Regions params:
        let params = {
            TableName: table,
            KeyConditionExpression: 'pk = :hkey',
            ExpressionAttributeValues: {
                ':hkey': LanguagesDAL.LANGUAGES_PK
            }
        };
        let res = await docClient.query(params).promise();

        return res.Items;
    }
}