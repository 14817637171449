import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { PopupTypes } from 'src/app/models/popuptype';
import { CognitoApisServiceService } from 'src/app/services/cognito-apis-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  messagePopup = PopupTypes.message;
  passwordPopup = PopupTypes.password;
  errorPopup = PopupTypes.error;
  siteUnderMaintenancePopup = PopupTypes.siteUnderMaintenancePopup;
  resetPasswordPopup = PopupTypes.resetPassword;
  selected_language;
  languages;
  signInForm: FormGroup;
  formGroupObject = {};
  emailRegx = /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
  isFirstSignup = false
  constructor(
    public authService: AuthService,
    public dataService: DataService,
    public cognitoAWSService: CognitoApisServiceService,
    public router: Router,
    public httpService: HttpService,
    public textService: TextService,
    public analyticsService: GoogleAnalyticsService,
    private route: ActivatedRoute
  ) {
    this.dataService.signInUser.subscribe((response) => {
      this.submit(response.email, response.password);
    });
    this.dataService.resendEmailForSignUp.subscribe((res) => {
      this.resendEmail();
    });
    // this.dataService.confirmAcountForSignUp.subscribe((code) => {
    //   this.confirmEmail(code);
    // });
  }

  async ngOnInit(): Promise<void> {
    this.getPageData();
    this.generateForm();
    this.forgotPasswordMessage();
    this.cognitoAWSService.updateConfiguration();
    let isLoggedIn = await this.cognitoAWSService.checkAccessTokenValid();
    if (isLoggedIn) {
      this.router.navigateByUrl('calc/home', { skipLocationChange: true }).then(() => {
        this.router.navigate(['calc/home']);
      });
    }

    //if routed to login page from signup page
    this.route.queryParams
      .subscribe(params => {
        if (params.email) {
          this.signInForm.controls.email.setValue(params.email)
        }
        if (params.isFirstSignup) {
          localStorage.setItem("isFirstSignup", JSON.stringify(true))
          this.isFirstSignup = true
        }
      }
      );

  }
  getPageData() {
    this.dataService.showLoader = false;
    this.authService.getSignInPageData().subscribe((response) => {
      if (response.success) {
        this.languages = response.result.page_data.languages;
        this.selected_language = this.languages[0];
        this.getTexts();
      }
    });
  }
  getTexts() {
    this.dataService.showLoader = true;
    this.textService
      .getResourceText(this.selected_language.row_id)
      .subscribe((res) => {
        this.dataService.showLoader = false;
        if (res.success) {
          this.textService.translation_text = res.result.translations;
          localStorage.setItem(
            'resource_txt',
            JSON.stringify(this.textService.translation_text)
          );
          this.textService.setUnitsDictionary();
        }
      });
  }

  generateForm() {
    this.formGroupObject['email'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(this.emailRegx),
    ]);
    this.formGroupObject['password'] = new FormControl(null, [
      Validators.required,
      Validators.maxLength(50),
    ]);

    this.signInForm = new FormGroup(this.formGroupObject);
  }

  get controls() {
    return this.signInForm.controls;
  }

  forgotPasswordMessage() {
    this.dataService.editPopupData = {
      messageTitle: this.textService.getText(
        this.EtransaltionGroups.SIGNIN,
        'forgot_pass_question'
      ),
      messageText: `We've .`,
    };
  }

  checkError(controlName: string) {
    if (this.signInForm.controls[controlName].errors) {
      const theErrors = this.signInForm.controls[controlName].errors;
      if (theErrors.required) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'field_required'
        );
      }
      if (theErrors.minlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );

        return this.textService.addValueToText(
          text,
          theErrors.minlength.requiredLength
        );
      }
      if (theErrors.maxlength) {
        let text = this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'characters_number'
        );
        return this.textService.addValueToText(
          text,
          theErrors.maxlength.requiredLength
        );
      }
      if (theErrors.pattern) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'invalid_pattern'
        );
      }
      if (theErrors.notEqual) {
        return this.textService.getText(
          this.EtransaltionGroups.VALIDATION,
          'password_validation'
        );
      }
    }
  }

  async submit(email, password) {

    if (this.signInForm.valid) {
      this.dataService.showLoader = true
      let response = await this.cognitoAWSService.signIn(email, password).then(response => {
        this.dataService.showLoader = false;
        if (response.success) {
          let userData = response.user;
          this.analyticsEvent(userData.username)
          const idToken = 'Bearer ' + userData.signInUserSession.idToken.jwtToken;
          const accessToken = userData.signInUserSession.accessToken.jwtToken;
          const refreshToken = userData.signInUserSession.refreshToken.token;
          this.saveInLocalstorage(idToken, accessToken, refreshToken);
          this.httpService.updateHeader('Authorization', idToken);
          this.router.navigateByUrl('calc/home', { skipLocationChange: true }).then(() => {
            this.router.navigate(['calc/home']);
          });
          if (this.router.url.includes('login')) {
            location.reload();
          }
        } else {
          switch (response.error.code) {
            case "UserNotConfirmedException":
              this.dataService.editPopupData = {
                messageTitle: this.textService.getText(
                  this.EtransaltionGroups.SIGNUP,
                  'confirm_account'
                ),
                messageText: `We've sent a verification email with a code to ${this.signInForm.controls.email.value} Please open you email and copy the code you will find there to the below field to activate your account.`,
              };
              this.dataService.showMessagePopup = true;
              break;
            case "UserNotFoundException":
              this.dataService.error_result_msg = this.textService.getText(
                this.EtransaltionGroups.SIGNIN,
                'user_dont_exist_error'
              );
              this.dataService.showErrorPopup = true;
              break;

            default:
              this.dataService.error_result_msg = response.error.message;
              this.dataService.showErrorPopup = true;
              break;
          }
        }

      });

    }
  }

  saveInLocalstorage(idToken, accessToken, refreshToken) {
    localStorage.setItem('Authorization', idToken);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }

  analyticsEvent(user_id) {
    let device = '';
    if (!localStorage.getItem('device_check')) {
      innerWidth = window.innerWidth;
      if (innerWidth > 1030) {
        device = 'laptop';
      } else {
        if (innerWidth > 768) {
          device = 'tablet';
        } else {
          device = 'mobile';
        }
      }
      localStorage.setItem('device_check', '1');
      this.analyticsService.sendEvent(device, 'site_entrance', device, user_id);
    }
  }

  resendEmail() {
    this.cognitoAWSService.resendConfirmationCode(
      this.signInForm.controls.email.value
    );
  }
  async confirmEmail(code) {
    let response = await this.cognitoAWSService.confirmUserCognito(
      this.signInForm.controls.email.value,
      code
    );

    if (response.success) {
      this.dataService.showMessagePopup = false;
      this.submit(this.signInForm.controls.email.value, this.signInForm.controls.password.value)
    } else {
      this.dataService.errorTxtConfirmAcount = response.error.message;
    }
  }
}
