export enum PopupTypes {
  warning,
  error,
  clear,
  pipe,
  delete,
  edit,
  radio,
  message,
  password,
  resetPassword,
  settings,
  delete_global,
  show_data,
  ilustration,
  siteUnderMaintenancePopup,
  graph,
  offline_confirmation
}

export class BasePopup {
  PopupTypes = PopupTypes;
}
