export enum Einputs_fields {
  pressure_loss = 0,
  calculation_method,
  flushing_velocity,
  submain_pipe_length,
  distance_between_laterals,
  num_of_laterals,
  first_lateral_length,
  last_lateral_length,
  inlet_pressure,
  end_pressure,
  block_flow_rate,
  last_lateral_flow_rate,
  height_diff_meters,
  heigth_diff_precent
}

export enum Eunits {
  metric = 1,
  us_untis
}

export enum Ecalculator {
  submain = 1,
  lateral,
  mainline,
  valve,
  irrigation,
  shift,
  energy
}

export enum EforgotPasswordSteps {
  send_email = 1,
  change_passwords
}

export enum EqueryTypes {
  pressure_loss = 0,
  pipes_diameter,
  maximum_length,
  flow_variation_and_emission_uniformity,
  matrix
}

export enum EvalveQueryTypes {
  pressure_loss = 0,
  valve_diameter,
  cavitation_risk,
  quick_relief_valve
}

export enum EemitterType {
  'Driplines' = 1,
  'Online Drippers',
  'Sprinklers',
  'Micro Sprinklers'
}

export enum EtransaltionGroups {
  SUBMAIN = 1,
  LAYOUT,
  HOME,
  SIGNUP,
  SIGNIN,
  CONTACT_US,
  HELP,
  LATERAL,
  UNITS,
  MENU,
  SETTIGNS,
  VALIDATION,
  MOBILE,
  MAINLINE,
  OFFLINE,
  VALVE,
  IRRIGATION,
  SHIFT,
  ENERGY
}

export enum EsubmainSteps {
  'question' = 1,
  'block',
  'submain',
  'result'
}

export enum ElateralSteps {
  'emitter' = 1,
  'question',
  'lateral',
  'block',
  'result'
}

export enum EmainlineSteps {
  'question' = 1,
  'pipe',
  'elevation',
  'result'
}

export enum EvalveSteps {
  'question' = 1,
  'valve',
  'result'
}

export enum EIrrigationSteps {
  'irrigation' = 1,
  'result'
}

export enum EShiftSteps {
  'shift' = 1,
  'result'
}

export enum EenergySteps {
  'energyFirstPage' = 1,
  'energySecondPage',
  'result'
}

export enum ShiftMathAction {
  None = "none",
  Floor = "floor",
  Ceil = "ceil",
  RoundToZero = "zero",
  RoundToOne = "one",
}

export enum ShiftNote {
  RoundUp = 'shift_rounded_up_note',
  NotValid = 'shift_not_valid_note'
}


