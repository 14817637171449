export enum Regions {
    "Global" = 0,
    "Global (corporate)" = 1,
    "USA",
    "Mexico",
    "Peru/Ecuador/Colombia",
    "Chile",
    "Brazil/Argentina",
    "West-Europe",
    "East-Europe",
    "Turkey",
    "Africa",
    "South-Africa",
    "India",
    "China",
    "Netherlands",
    "Asia-Pacific",
    "Australia",
    "Middle-East"
}