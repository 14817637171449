import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Einputs_fields, EtransaltionGroups } from 'src/app/shared/enum';
import { Validators } from '@angular/forms';
import { TextService } from 'src/app/services/text.service';
@Component({
  selector: 'app-block-question-lateral',
  templateUrl: './block-question.component.html',
  styleUrls: ['./block-question.component.scss'],
})
export class BlockQuestionComponent implements OnInit {
  @Input() questionData: any;
  @Input() calculatorIndex;
  Einputs_fields = Einputs_fields;
  EtransaltionGroups = EtransaltionGroups;
  showFvEuTooltip = false;
  @Output() emitSelected: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public dataService: DataService,
    public textService: TextService
  ) { }
  ngOnInit(): void { }

  ngAfterViewInit(): void {
    if (this.dataService.isItMobile) {
      window.scrollTo(0, 0);
    }
  }

  setValidators(inputName) {
    if (inputName == 'control') {
      this.questionData.control.setValidators([
        Validators.compose([
          Validators.required,

          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.min(0),
          Validators.max(15),
        ]),
      ]);
      this.questionData.control.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
      this.questionData.control_2.clearValidators();
      this.questionData.control_2.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    } else {
      this.questionData.control_2.setValidators([
        Validators.compose([
          Validators.required,
          Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
          Validators.min(80),
          Validators.max(100)
        ]),
      ]);
      this.questionData.control_2.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
      this.questionData.control.clearValidators();
      this.questionData.control.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
  }
}
