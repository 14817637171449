import { Component, OnInit, Input } from '@angular/core';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
@Component({
  selector: 'app-video-tutorials',
  templateUrl: './video-tutorials.component.html',
  styleUrls: ['./video-tutorials.component.scss']
})
export class VideoTutorialsComponent implements OnInit {
  @Input() videos_data: any;
  EtransaltionGroups = EtransaltionGroups;
  constructor(public textService: TextService) {}

  ngOnInit(): void {
    this.textService.setTextSource();
  }
}
