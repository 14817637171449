import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-desktop',
  templateUrl: './footer-desktop.component.html',
  styleUrls: ['./footer-desktop.component.scss']
})
export class FooterDesktopComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  constructor(public dataService: DataService,
    public textService: TextService) { }

  ngOnInit(): void {

  }

}
