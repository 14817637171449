import { Injectable, OnDestroy } from '@angular/core';
import { HttpHandler } from './httpHandler';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { OfflineService } from './offline.service';
import { SubmainCalculatorHandler } from '../../hydrocalc-code/handlers/Calculators/SubmainCalculator.handler';


@Injectable({
  providedIn: 'root'
})
export class SubmainService extends HttpHandler {
  private url = 'calculator/submain';
  submainCalculatorHandler = new SubmainCalculatorHandler()
  constructor(
    public router: Router,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService,
    public offlineService: OfflineService
  ) {
    super(_http, httpService, errorService);
  }

  calcBlockFlowRate(
    last_lateral_flow_rate,
    total_lateral_flow_rate,
    is_rectangular,
    number_of_laterals,
    last_lateral_length,
    first_lateral_length,
  ) {
    const url = this.url + '/blockflowrate';
    let body = {
      last_lateral_flow_rate: last_lateral_flow_rate,
      total_lateral_flow_rate: total_lateral_flow_rate,
      is_rectangular: is_rectangular,
      number_of_laterals: number_of_laterals,
      last_lateral_length: last_lateral_length,
      first_lateral_length: first_lateral_length
    };

    return this.post(url, body);
  }

  getSubmainSectionDropdowns(type, pipe_class, nominal_diameters) {
    const url = this.url + '/sectioncharactaristics';
    let body = {
      type: type,
      class: pipe_class,
      nominal_diameter: nominal_diameters
    };

    return this.post(url, body);
  }

  getPipeInternalDiameter(type, pipe_class, nominal_diameters) {
    const url = this.url + '/pipediameter';

    let body = {
      type: type,
      class: pipe_class,
      nominal_diameter: nominal_diameters
    };

    return this.post(url, body);
  }

  calculate(body) {
    const url = this.url;

    return this.post(url, body);
  }
  //---------------------------- OFFLINE METHODS -----------------------------------
  async calcBlockFlowRateOffline(
    last_lateral_flow_rate,
    total_lateral_flow_rate,
    is_rectangular,
    number_of_laterals,
    last_lateral_length,
    first_lateral_length,
  ) {

    let user: any = JSON.parse(localStorage.getItem('user'));
    //build request body for handler
    let request = {
      user,
      blockFlowRateData: {
        lastLateralFlowRate: last_lateral_flow_rate || null,
        totalLateralFlowRate: total_lateral_flow_rate || null,
        isReqtangular: is_rectangular,
        numOfLaterals: number_of_laterals,
        lastLateralLength: Number(last_lateral_length),
        firstLateralLength: Number(first_lateral_length),
        isOffline: this.offlineService.isItOfflineMode || false
      }
    };

    let result = await this.submainCalculatorHandler.calcBlockFlowRate(request)
    let response = this.submainCalculatorHandler.buildBlockFlowRateResult(result)

    return { result: response, success: response.flow_rate ? true : false }
  }

  async getSubmainSectionDropdownsOffline(type, pipe_class, nominal_diameters) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    //build request body for handler
    let request = {
      user,
      sectionCharac: {
        type: type || null,
        class: pipe_class || null,
        diameter: nominal_diameters || null,
        previous_section_nominal_diameter_selcection: null,
        isOffline: this.offlineService.isItOfflineMode || false
      }

    }
    let result = await this.submainCalculatorHandler.getSectionCharactaristics(request)
    return { result: this.submainCalculatorHandler.buildPipesSectionCharactaristicsResult(result), success: result.section_charactaristics ? true : false }
  }

  async getPipeInternalDiameterOffline(type, pipe_class, nominal_diameters) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    //build request body for handler
    let request = {
      user,
      sectionCharac: {
        type,
        class: pipe_class,
        diameter: nominal_diameters,
        isOffline: this.offlineService.isItOfflineMode || false
      }
    };
    let result = await this.submainCalculatorHandler.getPipeInternalDiameter(request)
    return { result: this.submainCalculatorHandler.buildInternalDiameterResult(result), success: result.internalDiameter ? true : false }

  }

  async calculateOffline(body) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    //build request body for handler
    let request = {
      calculationData: {
        calcType: body.calculation_type || null,
        frictionFormula: body.friction_loss_formula || null,
        isFlushingMode: body.is_flushing || false,
        flushingVelocity: Number(body.flushing_velocity || null),
        maxPressureAllowed: body.max_allowed_pressure_loss || null,
        blockChars: body.block_characteristics,
        topographyChars: body.topography_characteristics,
        pipeChars: body.pipe_characteristics,
        isOffline: this.offlineService.isItOfflineMode || false
      },
      user
    };
    let result = await this.submainCalculatorHandler.calculate(request)
    let response = this.submainCalculatorHandler.buildCalculationResult(result)
    return { result: response, success: response.calculation_success }
  }
}
