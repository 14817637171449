import { Component, Input, OnInit } from "@angular/core";
import { TextService } from "src/app/services/text.service";
import { EtransaltionGroups } from "src/app/shared/enum";

@Component({
  selector: "app-matrix-table",
  templateUrl: "./matrix-table.component.html",
  styleUrls: ["./matrix-table.component.scss"],
})
export class MatrixTableComponent implements OnInit {
  @Input() tableColumns: number[];
  @Input() keysForSlopes: string[];
  @Input() results: any;
  @Input() type: string;
  spacingHeaderText: string;
  slopeHeaderText: string;
  EtransaltionGroups = EtransaltionGroups;

  constructor(public textService: TextService) {}

  ngOnInit(): void {
    this.setTableTexts();
  }

  setTableTexts() {
    this.slopeHeaderText = this.textService.getText(
      EtransaltionGroups.LATERAL,
      "lateral_matrix_results_slope"
    );
    this.spacingHeaderText = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        EtransaltionGroups.LATERAL,
        "lateral_matrix_results_spacing"
      ),
      "m"
    );
  }
}
