import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpHandler } from './httpHandler';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataService } from './data.service';
import { OfflineService } from './offline.service';
import { MainlineCalculatorHandler } from 'src/hydrocalc-code/handlers/Calculators/MainlineCalculator.handler';
@Injectable({
  providedIn: 'root'
})
export class MainlineService extends HttpHandler {
  private url = 'calculator/mainline';
  mainlineCalculatorHandler = new MainlineCalculatorHandler()
  constructor(
    public router: Router,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService,
    public offlineService: OfflineService
  ) {
    super(_http, httpService, errorService);
  }
  getMainlineSectionDropdowns(type, pipe_class, nominal_diameters, prevSectionNominalDiameter) {
    const url = this.url + '/sectioncharactaristics';
    let body = {
      type: type,
      class: pipe_class,
      nominal_diameter: nominal_diameters,
      previous_section_nominal_diameter_selcection: Number(prevSectionNominalDiameter)
    };
    return this.post(url, body);
  }
  async getMainlineSectionDropdownsOffline(type, pipe_class, nominal_diameters, prevSectionNominalDiameter) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    let request = {
      user,
      sectionCharac: {
        type: type,
        class: pipe_class,
        nominal_diameter: nominal_diameters,
        previous_section_nominal_diameter_selcection: Number(prevSectionNominalDiameter),
        isOffline: this.offlineService.isItOfflineMode || false,
      }
    };
    let result = await this.mainlineCalculatorHandler.getSectionCharactaristics(request)
    let response = this.mainlineCalculatorHandler.buildPipesSectionCharactaristicsResult(result)
    return { result: response, success: response.pipes_section_charactaristics ? true : false }
  }
  getPipeInternalDiameter(type, pipe_class, nominal_diameters) {
    const url = this.url + '/pipediameter';

    let body = {
      type: type,
      class: pipe_class,
      nominal_diameter: nominal_diameters
    };

    return this.post(url, body);
  }
  async getPipeInternalDiameterOffline(type, pipe_class, nominal_diameters) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    let request = {
      user,
      sectionCharac: {
        type: type,
        class: pipe_class,
        diameter: nominal_diameters,
        isOffline: this.offlineService.isItOfflineMode || false,
      }
    };

    let result = await this.mainlineCalculatorHandler.getPipeInternalDiameterAndRoghness(request)
    let response = this.mainlineCalculatorHandler.buildInternalDiameterAndRoghness(result)
    return { result: response, success: response.internal_diameter ? true : false }
  }

  calculate(body) {
    const url = this.url;
    return this.post(url, body);
  }

  async calculateOffline(body) {
    let user: any = JSON.parse(localStorage.getItem('user'));
    //build request body for handler
    let request = {
      calculationData: {
        calcType: body.calculation_type,
        frictionFormula: body.friction_loss_formula,
        isFlushingMode: body.is_flushing || false,
        pipeRoughnessChw: body.pipe_roughness_chw || null,
        flushingVelocity: body.flushing_velocity || null,
        addSectionForFlushing: body.addition_section_for_flushing || null,
        isFlushingWithClosedValves: body.is_flushing_with_closed_valves,
        maxPressureAllowed: body.max_allowed_pressure_loss,
        maxAllowedVelocity: body.max_allowed_velocity,
        blockChars: body.block_characteristics,
        topographyChars: body.topography_characteristics,
        pipeChars: body.pipe_characteristics,
        isOffline: this.offlineService.isItOfflineMode || false
      },
      user
    };
    let result = await this.mainlineCalculatorHandler.calculate(request)
    let response = this.mainlineCalculatorHandler.buildCalculationResult(result)
    return { result: response, success: response.calculation_success }
  }
}
