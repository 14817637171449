import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import * as c3 from 'c3';
import { Ecalculator, Eunits } from 'src/app/shared/enum';
@Component({
  selector: 'app-ilustration-result',
  templateUrl: './ilustration-result.component.html',
  styleUrls: ['./ilustration-result.component.scss'],
})
export class IlustrationResultComponent implements OnInit {
  Ecalculator = Ecalculator
  Eunits = Eunits
  @Input() pipes: any = [];
  @Input() slopes: any[];
  axis_text_right
  axis_text_left
  yArray: any[] = [];
  xArray: any[] = [];
  regions = [];
  colors = [
    '#5b19c0',
    '#ffba00',
    '#fd0000',
    '#cce6ff',
    '#00c7d3',
    '#10abce',
    '#f96284',
    '#787ff8',
    '#84c7f6',
    '#7ae1af',
  ];
  chart;

  // updatedXArray: any[];
  // updatedYarray: any[];
  updatedDataObject: any = {};
  graphMaxSize: number;

  constructor(public dataService: DataService) {
    this.dataService.reloadIlustrationResult.subscribe((res) => {
      setTimeout(() => {
        this.initialzeRegionsOnGraph();
        this.updatePointsColor();
        this.initialzeIlustration();
      });
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.initialzeRegionsOnGraph();
    this.updatePointsColor();
    this.initialzeIlustration();
  }

  initialzeIlustration() {
    this.chart = c3.generate({
      bindto: '#result-ilustration',
      size: {
        height: 250,
      },
      interaction: {
        //dont show tooltip on data points
        enabled: false,
      },
      legend: {
        //dont show MIKRA
        show: false,
      },
      data: {
        xs: this.updatedDataObject,
        columns: [...this.xArray, ...this.yArray],
      },
      color: {
        pattern: [
          '#7AE1AF',
          '#84C7F6',
          '#787FF8',
          '#F96284',
          '#10ABCE',
          '#00C7D3',
        ],
      },
      regions: this.regions,
      point: {
        show: true,
        r: 6,
      },
      grid: {
        lines: {
          front: false,
        },
        y: {
          lines: [{ value: 0 }],
        },
      },
      axis: {
        x: {
          max: this.graphMaxSize,
          tick: {
            outer: false,
            rotate: -0.1,
            multiline: false,
          },
        },
      },
    });
  }

  initialzeRegionsOnGraph() {
    this.yArray = [];
    this.xArray = [];
    this.regions = [];
    let prevHeight: number = 0;
    let start: Number = 0;
    this.pipes.forEach((element, index) => {
      this.regions.push({
        start: start,
        end: Number(element.SectionLength) + Number(start),
        class: 'fill-' + index,
        label: `(${this.dataService.sectionItems[index].letter}) ${element.NominalDiameter}${Number(this.dataService.selected_units.value) === Eunits.metric ? 'mm' : 'inch'}`,
        // label: `(${this.dataService.sectionItems[index].letter})`,
        // opacity: 0.2,
      });
      start = Number(element.SectionLength) + Number(start);
    });
    this.updatedDataObject = {};
    for (let index = 0; index + 1 < this.slopes.length; index++) {
      this.xArray.push([
        'x' + String(index),
        this.slopes[index].x,
        this.slopes[index + 1].x,
      ]);
      this.yArray.push([
        'data' + String(index),
        prevHeight,
        this.slopes[index + 1].high_diff_meters + prevHeight,
      ]);
      this.updatedDataObject['data' + String(index)] = 'x' + String(index);
      this.graphMaxSize = this.slopes[index + 1].x;
      prevHeight += this.slopes[index + 1].high_diff_meters;
    }
  }

  updatePointsColor() {
    document.documentElement.style.setProperty('--circle-1', '#84C7F6');
    document.documentElement.style.setProperty('--circle-2', '#787FF8');
    document.documentElement.style.setProperty('--circle-3', '#F96284');
    document.documentElement.style.setProperty('--circle-4', '#10ABCE');
    document.documentElement.style.setProperty('--circle-5', '#00C7D3');
    switch (this.slopes.length) {
      case 2:
        document.documentElement.style.setProperty('--circle-1', 'none');
        break;
      case 3:
        document.documentElement.style.setProperty('--circle-2', 'none');
        break;
      case 4:
        document.documentElement.style.setProperty('--circle-3', 'none');
        break;
      case 5:
        document.documentElement.style.setProperty('--circle-4', 'none');
      case 6:
        document.documentElement.style.setProperty('--circle-5', 'none');
        break;
        break;
      default:
        break;
    }
  }

  onResize(event) {
    setTimeout(() => {
      this.initialzeRegionsOnGraph();
      this.updatePointsColor();
      this.chart.destroy();
      this.initialzeIlustration();
    });
  }


}
