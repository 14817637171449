import { Component, OnInit, Input } from '@angular/core';
import * as c3 from 'c3';
import { DataService } from 'src/app/services/data.service';
import { Ecalculator } from 'src/app/shared/enum';
@Component({
  selector: 'app-ilustration-slope',
  templateUrl: './ilustration-slope.component.html',
  styleUrls: ['./ilustration-slope.component.scss'],
})
export class IlustrationSlopeComponent implements OnInit {
  Ecalculator = Ecalculator;
  @Input() currentSlopeForms: any = [];
  @Input() graphMaxSize: number;
  @Input() x_line_text;
  @Input() calculatorIndex;
  @Input() axis_text_left

  yArray: [string, ...c3.Primitive[]] = ['data1'];
  xArray: [string, ...c3.Primitive[]] = ['x'];
  chart;

  updatedXArray: any[];
  updatedYarray: any[];
  updatedDataObject: any = {};

  constructor(public dataService: DataService) {
    this.dataService.reloadSlopesIlustration.subscribe((res) => {
      if (this.calculatorIndex === this.Ecalculator.lateral) {
        this.graphMaxSize = Number(this.dataService.totalLateralLength);
      }

      this.updateIlustration();
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.initialzePointsOnGraph();
    this.updatePointsColor();
    this.initialzeIlustration();
    this.splitGraphIntoMultiLine(this.xArray, this.yArray);
    this.chart.load({
      xs: this.updatedDataObject,
      columns: [...this.updatedXArray, ...this.updatedYarray],
    });
  }

  initialzeIlustration() {
    this.chart = c3.generate({
      bindto: '#slopes-ilustration',
      size: {
        height: 250,
      },
      axis: {
        x: {
          max: this.graphMaxSize,
          tick: {
            outer: false,
            rotate: -0.1,
            multiline: false,
          },
        },
      },
      interaction: {
        //dont show tooltip on data points
        enabled: false,
      },
      legend: {
        //dont show MIKRA
        show: false,
      },
      data: {
        xs: {
          data1: 'x',
        },
        columns: [this.xArray, this.yArray],
      },

      color: {
        pattern: [
          '#7AE1AF',
          '#84C7F6',
          '#787FF8',
          '#F96284',
          '#10ABCE',
          '#00C7D3',
        ],
      },
      point: {
        show: true,
        r: 6,
      },
      grid: {
        lines: {
          front: false,
        },
        // x: {
        //   lines: [
        //     { value: 20, class: 'fill-1' },
        //     { value: 80, class: 'fill-2' },
        //     { value: 100, class: 'fill-3' },
        //     { value: 129, class: 'fill-4' },
        //     { value: 150, class: 'fill-5' }
        //   ]
        // },
        y: {
          lines: [{ value: 0 }],
        },
      },
    });
  }

  initialzePointsOnGraph() {
    let xDistance = 0;
    this.xArray = ['x', 0];
    this.yArray = ['data1', 0];
    this.currentSlopeForms.forEach((slope) => {
      xDistance = Number(xDistance) + Number(slope.value.mapLength);

      this.graphMaxSize = Number(this.graphMaxSize);
      this.yArray.push(Number(slope.value.heightDiffM));
      this.xArray.push(xDistance);
    });
  }

  updateIlustration() {
    setTimeout(() => {
      this.chart.destroy();
      this.updatePointsColor();
      this.initialzePointsOnGraph();
      this.initialzeIlustration();
      this.splitGraphIntoMultiLine(this.xArray, this.yArray);
      this.chart.load({
        xs: this.updatedDataObject,
        columns: [...this.updatedXArray, ...this.updatedYarray],
      });
    });
  }

  splitGraphIntoMultiLine(xArray: any[], yArray: any[]) {
    let tempXArray: any[] = [];
    let tempYArray: any[] = [];
    let tempDataObject: any = {};
    let prevHeight: number = 0;
    for (let x = 1; x + 1 < xArray.length; x++) {
      tempXArray.push(['x' + String(x), xArray[x], xArray[x + 1]]);
      tempYArray.push([
        'data' + String(x),
        x > 1 ? prevHeight : yArray[x],
        yArray[x + 1] + prevHeight,
      ]);
      prevHeight += yArray[x + 1];
      tempDataObject['data' + String(x)] = 'x' + String(x);
    }

    this.updatedXArray = tempXArray;
    this.updatedYarray = tempYArray;
    this.updatedDataObject = tempDataObject;
  }

  updatePointsColor() {
    document.documentElement.style.setProperty('--circle-1', '#84C7F6');
    document.documentElement.style.setProperty('--circle-2', '#787FF8');
    document.documentElement.style.setProperty('--circle-3', '#F96284');
    document.documentElement.style.setProperty('--circle-4', '#10ABCE');
    document.documentElement.style.setProperty('--circle-5', '#00C7D3');
    switch (this.currentSlopeForms.length) {
      case 1:
        document.documentElement.style.setProperty('--circle-1', 'none');
        break;
      case 2:
        document.documentElement.style.setProperty('--circle-2', 'none');
        break;
      case 3:
        document.documentElement.style.setProperty('--circle-3', 'none');
        break;
      case 4:
        document.documentElement.style.setProperty('--circle-4', 'none');
        break;
      case 5:
        document.documentElement.style.setProperty('--circle-5', 'none');
        break;
      default:
        break;
    }
  }

  onResize(event) {
    this.updateIlustration();
  }
}
