export abstract class AbsDAL {
    public static readonly GLOBAL_REGION_STR = "GLOBAL";
    public static readonly EMPTY_DB_FIELD = "-1";

    /**
    * AccessDB
    * Access DB base db request flow handler
    * 
    * @param {any} procName The name of the Stored procedure to exec
    * @param {any} procArgs Stored procedure Args
    * @param {any} loadInputsOnDBRequest Load Args as Inputs Function
    * @param {any} mapResults map result Function
    * 
    * @returns {Promise<any>}  Mapped DB Result
    */
    public async AccessDB(procName: string, procArgs: any, loadInputsOnDBRequest: Function, mapResults: Function): Promise<any> {

        return null;
    }

    public getTableName(tableName: string) {
        let envExtention = process.env.DynamoDB_TableExtention || "";

        return tableName + envExtention;
    }

    public getEnvName() {
        let env = process.env.NODE_ENV
        if (env == 'netafim-test') {
            env = 'netafimDev';
        }

        if (env == 'production') {
            env = 'netafimProd'
        }

        return env
    }

    // ------------------------------------------- COMMON RESULT MAPPERS --------------------------------------------

    /**
    * Map Display Models Result
    * 
    * @param {any} result result
    * 
    * @returns {DisplayModel}  Model to desiplay  
    */
    protected MapToDisplayModelsResult(result: any) {

        return null;
    }

    /**
    * Map Result To Num Of Results
    * 
    * @param {any} result result
    * 
    * @returns {any}  Num of results
    */
    protected MapResultToNumOfResults(result: any) {
        let mapped = result.recordset[0];
        if (!mapped) {
            return null;
        }
        mapped = { NumOfResults: mapped.numOfRecords }
        return mapped;
    }

    protected secodSort(a, b) {
        if (a === b) {
            return 0;
        }
        else if (a > b) {
            return -1;
        }
        else {
            return 1;
        }
    }
}