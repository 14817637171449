import { Injectable } from "@angular/core";
import { ShiftMathAction } from "../shared/enum";

@Injectable({
  providedIn: "root",
})
export class ShiftService {
  private MILLIMETER_TO_INCH: number = 0.0393701;

  constructor() {}

  calcNumberOfShifts(
    waterPeakDemand: number,
    dailyIrrigationTime: number,
    irrigationRate: number,
    units: number
  ): { shifts: number; action: ShiftMathAction } {
    const _waterPeakDemand: number =
      units === 1 ? waterPeakDemand : waterPeakDemand / this.MILLIMETER_TO_INCH;
    const _irrigationRate: number =
      units === 1 ? irrigationRate : irrigationRate / this.MILLIMETER_TO_INCH;

    const denominator: number = _waterPeakDemand / _irrigationRate;

    // intermediate result
    const numberToPerformActionOn: number = dailyIrrigationTime / denominator;

    const actionToPerform: ShiftMathAction = this.decideShiftMathAction(
      numberToPerformActionOn
    );

    // actual number of shifts
    return {
      shifts: this.getNumberOfShifts(actionToPerform, numberToPerformActionOn),
      action: actionToPerform,
    };
  }

  private decideShiftMathAction(
    numberToPerformActionOn: number
  ): ShiftMathAction {
    const numberAsStr: string = numberToPerformActionOn.toString();
    const indexOfDot: number = numberAsStr.indexOf(".");

    // if number is not a fracture then no need to round
    if (indexOfDot === -1) {
      return ShiftMathAction.None;
    }

    // check if number is less than 1.
    const isNumberSmallerThanOne: boolean = +numberAsStr < 1 ? true : false;
    // if so then:
    if (isNumberSmallerThanOne) {
      const action =
        numberToPerformActionOn < 0.8
          ? ShiftMathAction.RoundToZero
          : ShiftMathAction.RoundToOne;

      return action;
    } else {
      // get the first number (digit) after dot
      const firstDigitAfterDot: number = +numberAsStr.substring(
        indexOfDot + 1,
        indexOfDot + 2
      );

      const action =
        firstDigitAfterDot < 8 ? ShiftMathAction.Floor : ShiftMathAction.Ceil;

      return action;
    }
  }

  private getNumberOfShifts(
    action: ShiftMathAction,
    numberToPerformActionOn: number
  ): number {
    switch (action) {
      case ShiftMathAction.None:
        return numberToPerformActionOn;

      case ShiftMathAction.Ceil:
        return Math.ceil(numberToPerformActionOn);

      case ShiftMathAction.Floor:
        return Math.floor(numberToPerformActionOn);

      case ShiftMathAction.RoundToOne:
        return 1;

      case ShiftMathAction.RoundToZero:
        return 0;
    }
  }
}
