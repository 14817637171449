import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
// import { PopupTypes } from '../models/popuptype';
import { HttpHandler } from './httpHandler';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpService } from './http.service';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpHandler {
  private url = 'auth';
  constructor(
    public router: Router,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService
  ) {
    super(_http, httpService, errorService);
  }

  getSignUpPageData() {
    const url = this.url + '/signup';
    return this.get(url);
  }

  getSignInPageData() {
    const url = this.url + '/signin';
    return this.get(url);
  }

  sendUserData(body) {
    const url = this.url + '/signup';
    return this.post(url, body);
  }
}
