import { AbsDAL } from "./Base/AbsDAL";

let AWS = require('aws-sdk');

export class RegionsDAL extends AbsDAL {
    public static readonly REGIONS_PK = "REGIONS";
    public static readonly REGIONS_TABLE_NAME = "Regions";
    public static readonly COUNTRIES_TABLE_NAME = "Countries";
    public static readonly COUNTRIES_PK = "COUNTRY";
    public static readonly LOCAL_STORAGE_REGIONS_TABLE_NAME = 'regions'

    /**
     * Gets all regions
     * 
     */
    public async getRegions(isOffline: boolean = false) {
        if (isOffline) {
            let regions_data = JSON.parse(localStorage.getItem(RegionsDAL.LOCAL_STORAGE_REGIONS_TABLE_NAME))
            return regions_data
        }
        let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
        let table = this.getTableName(RegionsDAL.REGIONS_TABLE_NAME);

        // Get all Regions params:
        let params = {
            TableName: table,
            KeyConditionExpression: 'pk = :hkey',
            ExpressionAttributeValues: {
                ':hkey': RegionsDAL.REGIONS_PK
            }
        };
        let res = await docClient.query(params).promise();
        let regions = res.Items;
        regions = regions.sort((reg1, reg2) => (reg1.region_name > reg2.region_name) ? 1 : ((reg2.region_name > reg1.region_name) ? -1 : 0));

        return regions;
    }

    /**
     * Get region
     * 
     */
    public async getRegion(regionId: any) {
        let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
        let table = this.getTableName(RegionsDAL.REGIONS_TABLE_NAME);


        // Get all Regions params:
        let params = {
            TableName: table,
            KeyConditionExpression: 'pk = :hkey and region_id= :skey',
            ExpressionAttributeValues: {
                ':hkey': RegionsDAL.REGIONS_PK,
                ':skey': regionId.toString()
            }
        };
        let res = await docClient.query(params).promise();

        return res.Items[0];
    }

    /**
     * Update region
     * 
     */
    public async updateRegion(updateData: any) {
        let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
        let table = this.getTableName(RegionsDAL.REGIONS_TABLE_NAME);

        let regionId = updateData.regionId.toString();;
        let contentToShow = updateData.content;

        // Get all Regions params:
        let params = {
            TableName: table,
            Key: {
                'pk': RegionsDAL.REGIONS_PK,
                'region_id': regionId
            },
            UpdateExpression: 'set content_to_show = :val1',
            ExpressionAttributeValues: {
                ":val1": contentToShow,
            },
            ReturnValues: 'ALL_NEW'
        };

        let res = await docClient.update(params).promise();

        return true
    }

    /**
  * Gets all countries
  * 
  */
    public async getCountries() {
        let docClient = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1", apiVersion: '2012-08-10' });
        let table = RegionsDAL.COUNTRIES_TABLE_NAME;

        // Get all countries params:
        let params = {
            TableName: table,
            KeyConditionExpression: 'pk = :hkey',
            ExpressionAttributeValues: {
                ':hkey': RegionsDAL.COUNTRIES_PK
            }
        };
        let res = await docClient.query(params).promise();
        let countries = res.Items;
        countries = countries.sort((country1, country2) => (country1.country_name > country2.country_name) ? 1 : ((country1.country_name > country2.country_name) ? -1 : 0));

        return countries;
    }
}
