import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  @Input() onClickData: any;
  @Output() onClickEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
