enum ECalculationNotes {
  NONE = 0,
  NO_ANGLES_INCLUDED_IN_CALCULATION,
  NO_VALVE_FOUND_VALVE_DIAMETER_CALCULATION,
}

let notes = [
  { id: ECalculationNotes.NONE, warning: `NONE`, id_translation: -1 },
  {
    id: ECalculationNotes.NO_ANGLES_INCLUDED_IN_CALCULATION,
    note: `Head loss calculation is only for the valve itself. It doesn't include the angles before and/or after the valve.`,
    // random id by me. need to update
    id_translation: 10000000,
  },
  {
    id: ECalculationNotes.NO_VALVE_FOUND_VALVE_DIAMETER_CALCULATION,
    note: `There is no Valve match your search criteria.`,
    // random id by me. need to update
    id_translation: 10000001,
  },
];

class CalculationNotes {
  public static get(noteId: ECalculationNotes) {
    return notes[noteId];
  }
}

export { CalculationNotes, ECalculationNotes };
