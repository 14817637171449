import { Injectable, OnDestroy } from '@angular/core';
import { HttpHandler } from './httpHandler';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GraphService extends HttpHandler {
  title: string
  legend_pressure: string
  legend_elevation: string
  legend_max_pressure: string
  calculatorIndex: number
  pipes_info: any
  graphID: string
  oneYAxis: boolean
  length: any
  graphData: any

  constructor(
    public router: Router,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService
  ) {
    super(_http, httpService, errorService);
  }
}
