import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { PopupTypes } from 'src/app/models/popuptype';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calculator-main',
  templateUrl: './calculator-main.component.html',
  styleUrls: ['./calculator-main.component.scss'],
})
export class CalculatorMainComponent implements OnInit {
  @ViewChild('mobileMenu') mobileMenu;
  sideNavOpen = true;
  settingsPopup = PopupTypes.settings;
  passwordPopup = PopupTypes.password;
  graphPopup = PopupTypes.graph
  resetPasswordPopup = PopupTypes.resetPassword;
  confirmationPopup = PopupTypes.offline_confirmation
  ilustration_submain_mobile = PopupTypes.ilustration;
  constructor(public dataService: DataService, public router: Router) { }

  ngOnInit(): void { }

  onSideNavClose() {
    this.mobileMenu.close();
  }

  onMenuToggle() {
    this.mobileMenu.toggle();
  }

checkIfCalcUrl(url:string){
const calcs=['submain','lateral','valve','energy']
return calcs.some(calc=>url.includes(calc))
}
}
