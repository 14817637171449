import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
@Component({
  selector: 'app-ilustration-popup',
  templateUrl: './ilustration-popup.component.html',
  styleUrls: ['./ilustration-popup.component.scss']
})
export class IlustrationPopupComponent implements OnInit {
  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {}
  EtransaltionGroups = EtransaltionGroups;
  ngOnInit(): void {}
}
