import { CALCULATOR_SECTION } from "./consts";

export const MENU_OPTIONS = Object.freeze({
  HOME: "home",
  LATERAL: "lateral",
  SUBMAIN: "submain",
  SAVE: "save",
  ABOUT: "about",
  HELP: "help",
});

// export const CALCULATOR_SECTION = Object.freeze({
//   EMITTER: "emitter",
//   BLOCK: "block",
//   QUESTION: "question",
//   SUBMAIN: "submain",
//   LATERAL: "lateral",
//   RESULT: "result",
//   PIPE: "pipe",
//   ELEVATION: "elevation",
//   VALVE: "valve",
//   IRRIGATION: "irrigation",
//   SHIFT: "shift",
//   ENERGY_FIRST: "energyFirstPage",
//   ENERGY_SECOND: "energySecondPage",
// });

export const SUBMAIN_CALCULATOR_QUESTIONS = Object.freeze({
  PRESSURE_LOSS_FOR_GIVEN_PIPE: 0,
  PIPES_DIAMETER: 1,
  PIPES_LENGTH: 2,
});

// valve calc types
export type ValveDiameters = { diameter_inch: string; diameter_mm: string };


// result components types

// DeviceLessCalcResult types
export interface DeviceLessCalcTableDataUnit {
  headerDisplay: string;
  units: string;
  result: number;
}


export type CalculatorSection =
  typeof CALCULATOR_SECTION[keyof typeof CALCULATOR_SECTION];
