import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { LateralService } from 'src/app/services/lateral.service';
import { TextService } from 'src/app/services/text.service';
import { UnitsConverterService } from 'src/app/services/units-converter.service';
import { Eunits, Ecalculator, EemitterType, EqueryTypes, EtransaltionGroups } from 'src/app/shared/enum';

@Component({
  selector: 'app-mainline-characteristics',
  templateUrl: './mainline-characteristics.component.html',
  styleUrls: ['./mainline-characteristics.component.scss']
})
export class MainlineCharacteristicsComponent implements OnInit {
  EemitterType = EemitterType;
  Ecalculator = Ecalculator;
  EqueryTypes = EqueryTypes;
  EtransaltionGroups = EtransaltionGroups;
  Eunits = Eunits
  UnitsConverterService = UnitsConverterService

  @Input() queryIndex;
  @Input() currentMainlineSectionsForms: any[];
  @Input() pressureForm;
  @Output() addNewSection = new EventEmitter<string>();
  @Output() deleteSectionFromCurrentSectionForms = new EventEmitter<string>();
  @Input() pipeMaterialOptions;
  @Input() pipeClassOptions;
  @Input() nominalDiametersOptions;
  @Input() FlushingSectionForm
  @Input() mainlineSectionsForIlustrations
  @Input() user_id //for analytics events
  maxSections: number = 10;
  sectionItems: any[];
  whichPressureInputDisable = ''
  constructor(public dataService: DataService,
    public lateralService: LateralService,
    public textService: TextService, public analyticsService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    if (this.dataService.isItMobile) {
      this.maxSections = 3;
    } else {
      this.maxSections = 10;
    }
    this.sectionItems = this.dataService.sectionItems;
    this.pressureFormOnChange()
  }
  sectionHasChangedUpdateNextSection() {
    this.currentMainlineSectionsForms.forEach((element, index) => {
      if (index > 0) {
        this.dataService.updateSectionToASectionChanges.next();
      }
    });
    if (this.FlushingSectionForm) {
      this.dataService.updateSectionToASectionChanges.next();
    }
  }

  addSection() {
    this.addNewSection.emit();
  }

  deleteSection(index) {
    this.deleteSectionFromCurrentSectionForms.emit(index);
  }

  checkInternalDiameterValidation(res) {
    let internal_diameter_prev_section = this.currentMainlineSectionsForms[res[1] - 1]
      .value.pipeInternalDiameter;
    if (Number(res[0]) < Number(internal_diameter_prev_section)) {
      return 'true';
    } else {
      return 'false';
    }
  }
  pressureFormOnChange() {
    this.pressureControls.inlet_pressure.valueChanges.subscribe((val) => {
      this.dataService.inputs_contents_has_changed = true
      if (val && val != '' && this.pressureControls.inlet_pressure.valid) {
        this.whichPressureInputDisable = 'end_pressure'
        this.pressureControls.end_pressure.clearValidators();
        this.pressureControls.end_pressure.reset('', { onlySelf: true, emitEvent: false });
        this.pressureControls.end_pressure.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      } else {
        this.whichPressureInputDisable = ''
        this.pressureControls.end_pressure.setValidators([
          Validators.compose([
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(UnitsConverterService.metersToPSI(1, this.dataService.selected_units.value == this.Eunits.us_untis)),
            Validators.max(UnitsConverterService.metersToPSI(1000, this.dataService.selected_units.value == this.Eunits.us_untis))
          ]),
        ])
        this.pressureControls.end_pressure.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      }

      setTimeout(() => {
        if (
          this.pressureForm.controls.inlet_pressure.hasError('max') ||
          this.pressureForm.controls.inlet_pressure.hasError('min')
        ) {
          this.analyticsService.sendEvent(
            'lateral',
            'inlet_pressure_out_of_range',
            'validation_error', this.user_id
          );
        }
      });
    })
    this.pressureControls.end_pressure.valueChanges.subscribe((val) => {
      this.dataService.inputs_contents_has_changed = true
      if (val && val != '' && this.pressureControls.end_pressure.valid) {
        this.whichPressureInputDisable = 'inlet_pressure'
        this.pressureControls.inlet_pressure.clearValidators();
        this.pressureControls.inlet_pressure.reset('', { onlySelf: true, emitEvent: false });
        this.pressureControls.inlet_pressure.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      } else {
        this.whichPressureInputDisable = ''
        this.pressureControls.inlet_pressure.setValidators([
          Validators.compose([
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(UnitsConverterService.metersToPSI(1, this.dataService.selected_units.value == this.Eunits.us_untis)),
            Validators.max(UnitsConverterService.metersToPSI(1000, this.dataService.selected_units.value == this.Eunits.us_untis))
          ]),
        ])
        this.pressureControls.inlet_pressure.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      }
      setTimeout(() => {
        if (
          this.pressureForm.controls.end_pressure.hasError('max') ||
          this.pressureForm.controls.end_pressure.hasError('min')
        ) {
          this.analyticsService.sendEvent(
            'lateral',
            'end_pressure_out_of_range',
            'validation_error', this.user_id
          );
        }
      });
    })
  }

  calculateTotalLengthLeftForSection(sectionIndex) {
    this.dataService.totalLengthMainline = 0
    this.currentMainlineSectionsForms.forEach((section, index) => {
      this.dataService.totalLengthMainline += section.value.length
    });
    this.dataService.totalLengthLeftForMainlineSections = this.dataService.totalLengthMainline
    this.currentMainlineSectionsForms.forEach((section, index) => {
      if (index < sectionIndex) {
        this.dataService.totalLengthLeftForMainlineSections =
          Number(this.dataService.totalLengthLeftForMainlineSections) -
          Number(section.value.length);
      }
    });
    if (this.dataService.totalLengthLeftForMainlineSections < 0) {
      this.dataService.totalLengthLeftForMainlineSections = 0;
    }
  }

  get pressureControls() {
    return this.pressureForm.controls;
  }
}
