import { BaseCalculatorHandler } from "./Calculator.handler.base";
import { ErrorMsgs } from "../../models/Errors/ErrorMsgs";
import { PipesService } from "../../services/PipesService";
import { CalculationInputsService } from "../../services/CalculationInputsService";
import { GeneralService } from "../../services/GeneralService";
import { EPublicity } from "../../enums/Publicity.enum";
import { EmittersService } from "../../services/EmittersService";
import { EmitterTypes } from "../../enums/Lateral/emitterTypes.enum";
import { CalculatorTypes } from "../../calculator/enums/calculatorTypes.enum";

export class LateralCalculatorHandler extends BaseCalculatorHandler {
    public getAllCalculatorsData(reqData: any) {
        throw new Error("Method not implemented.");
    }
    public async calculate(reqData: any) {
        reqData.calculator = CalculatorTypes.LATERAL;

        return super.calculate(reqData);
    }

    /**
    * Gets Lateral Claculator Page Data
    * Gets page lists 
    */
    public async getCalculatorPageData(reqData: any = null) {
        let emittersService = new EmittersService();
        let calculationInputsService = new CalculationInputsService();
        let generalService = new GeneralService();
        let user = reqData.user;
        let region = user.region;
        let isOffline = reqData.isOffline || false
        let filters = {
            publicity: EPublicity.LOCAL,
            region: region
        }

        // Get Lateral Charactaristics Lists Items:
        let tasks = [
            // Get Local Settigns:
            // calculationInputsService.getCalculationInputs({ filters }),
            // Get all Regions:
            generalService.getRegions(user, isOffline),
            // Get languages:
            generalService.getLanguages(isOffline),
            // Get Emitter types:
            emittersService.getEmitterTypes(region, user, isOffline),
            // Get Emitter types devided to inline/online:
            emittersService.getEmitterTypesDevided(region)
        ]

        let res = await Promise.all(tasks);
        // let calcInputs = res[0] || [];
        let regions = res[0] || [];
        let languages = res[1] || [];
        let emitter_types = res[2] || [];
        let emitters = res[3] || [];

        let pageData: any = {
            lateral_charactaristics: {
                emitter_types,
                emitters,
                // calculation_inputs: calcInputs,
                regions,
                user,
                languages
            }
        };

        return { pageData };
    }

    /**
     * Get Emitter Models By Type
     */
    public async getEmitterModelsByType(reqData: any = null) {
        let emittersService = new EmittersService();
        let user = reqData.user;
        let region = user.region;
        let emitterType = reqData.emitterType;
        let isOffline = reqData.isOffline || false

        let models = await emittersService.getEmitterModelsByType(emitterType, region, isOffline);
        if (!models) {
            throw new ErrorMsgs(`Invalid data received - no models were found`);
        }
        return { models };
    }

    /**
     * Get Emitter Flow rate By Type and model
     */
    public async getEmitterFlowRates(reqData: any = null) {
        let emittersService = new EmittersService();
        let user = reqData.user;
        let region = user.region;
        let units = user.units;
        let emitterType = reqData.emitterType;
        let model = reqData.model;
        let isOffline = reqData.isOffline || false;

        let flowRates = await emittersService.getEmitterFlowRates(emitterType, model, region, units, isOffline);
        if (!flowRates) {
            throw new ErrorMsgs(`Invalid data received - no flowRates were found`);
        }

        return { flowRates };
    }

    /**
     * Get Lateral Charactaristics For Inline Emitters
     */
    public async getLateralCharactaristicsForInlineEmitters(reqData: any = null) {
        let emittersService = new EmittersService();
        let user = reqData.user;
        let region = user.region;
        let units = user.units;

        let emitterType = reqData.emitterType;
        let model = reqData.model;
        let flowRate = reqData.lateralCharacs.flowRate;

        let diameters = reqData.lateralCharacs.nominalDiameters;
        let wallThickness = reqData.lateralCharacs.wallThickness;

        let isOffline = reqData.lateralCharacs.isOffline || false

        let filters = { region, units, emitterType, model, flowRate, diameters, wallThickness, isOffline }

        // Get Lateral Charactaristics Lists Items:
        let tasks = [
            // Get emitters nominal Diameters:
            emittersService.getEmitterNominalDiameters(filters),
            // Get inline emitters wall thicknesses:
            emittersService.getInlineEmittersWallThickness(filters),
            // Get emitters flowRates:
            emittersService.getInlineEmittersFlowRates(filters)
        ]

        let res = await Promise.all(tasks);
        let nominalDiameters = res[0];
        let wallThicknesses = res[1];
        let flowRates = res[2];

        let section_charactaristics: any = {
            nominalDiameters, wallThicknesses, flowRates
        };

        return { section_charactaristics };
    }

    /**
     * getSectionCharactaristics
     * Gets Section Charactaristics items - Types, Classes and internal diameters
     * 
     */
    public async getSectionCharactaristicsForOnlineEmitters(reqData: any) {
        let pipesService = new PipesService();
        let user = reqData.user;
        let region = user.region;
        let units = user.units;
        let sectionCharac = reqData.sectionCharac;
        let emitterType = reqData.emitterType;
        let pipeRelevants = {
            isReleventForMicroSprinklers: emitterType == EmitterTypes["Micro Sprinklers"],
            isReleventForOnlineDrippers: emitterType == EmitterTypes["Online Drippers"],
            isReleventForSprinklers: emitterType == EmitterTypes["Sprinklers"],
        }
        let isOffline = sectionCharac.isOffline || false

        // Get Submain Charactaristics Lists Items:
        let tasks = [
            // Get Pipes Types:
            pipesService.getPipeTypes({ region, units, pClass: sectionCharac.class, nominalDiameter: sectionCharac.diameter, pipeRelevants, isOffline }),
            // // Get Pipes Classes:
            pipesService.getPipeClasses({ region, units, pType: sectionCharac.type, nominalDiameter: sectionCharac.diameter, pipeRelevants, isOffline }),
            // // Get Pipes Nominal Diameter:
            pipesService.getPipeNominalDiameters({ region, units, pType: sectionCharac.type, pClass: sectionCharac.class, pipeRelevants, isOffline })
        ]

        let res = await Promise.all(tasks);
        let types = res[0];
        let classes = res[1];
        let diameters = res[2];

        let section_charactaristics: any = {
            types: types,
            classes: classes,
            nominal_diameters: diameters
        };

        return { section_charactaristics };
    }

    /**
     * Get Lateral Internal Diameter
     */
    public async getLateralInternalDiameter(reqData: any = null) {
        let emittersService = new EmittersService();
        let pipesService = new PipesService();

        let user = reqData.user;
        let region = user.region;
        let units = user.units;
        let internalDiameter;
        let roughness
        let filters = reqData.filters;
        let isInlineEmitter = filters.isInlineEmitter;
        let isOffline = filters.isOffline || false
        filters.isOffline = isOffline

        if (isInlineEmitter) {
            // Inline emitter - get internal diameter from Inline emitters table:
            internalDiameter = await emittersService.getLateralInternalDiameter(filters, region, units);
        }
        else {
            // Online emitter - get internal diameter from pipes table:
            let result = await pipesService.getPipeInternalDiameterAndRoghness(region, filters.pipeMaterial, filters.pipeClass, filters.nominalDiameter, units, isOffline);
            internalDiameter = result.internalDiameter
        }
        if (!internalDiameter) {
            throw new ErrorMsgs(`Invalid data received - no matching emitters or pipes found`);
        }
        return { internalDiameter: Number(internalDiameter).toFixed(3) };
    }

    /**
     * Get Lateral Emitter pressures
     */
    public async getLateralEmitterPressures(reqData: any = null) {
        let service = new EmittersService();


        let user = reqData.user;
        let region = user.region;
        let units = user.units;
        let isRequestFromCalculatorAndUsUnits = units === 2
        let filters = reqData.filters;
        let { isInlineEmitter, type, model, nominalDiameter, wallThickness, flowRate } = filters;
        let isOffline = filters.isOffline || false;
        let emitter = await service.getEmitter({ region, units, isInlineEmitter, type, model, nominalDiameter, pClass: wallThickness, flowRate, isRequestFromCalculatorAndUsUnits, isOffline })
        if (!emitter) {
            throw new ErrorMsgs(`Emitter doesnt exists`);
        }
        let maxDesignPressure = Number(emitter.max_design_pressure);
        let minDesignPressure = Number(emitter.min_design_pressure);

        let emitterPressures = {
            min_technical_pressure: Number(emitter.min_technical_pressure),
            max_technical_pressure: Number(emitter.max_technical_pressure),
            min_design_pressure: minDesignPressure,
            max_design_pressure: maxDesignPressure
        }

        return { emitterPressures };
    }
    // ---------------------------------- CALCULATION METHODS ------------------------------

    protected getUserCalcData(calculationReqData: any) {
        let calculationData: any = {};
        calculationData.units = calculationReqData.units;
        calculationData.frictionFormula = calculationReqData.frictionFormula;
        calculationData.flushingVelocity = calculationReqData.flushingVelocity || null;
        calculationData.flushingPressure = calculationReqData.flushingPressure
        calculationData.pipeRoughnessChw = calculationReqData.pipeRoughnessChw;

        calculationData.maxPressureLoss = calculationReqData.maxAllowedPressureLoss;
        calculationData.maxAllowedFlowVariation = calculationReqData.maxAllowedFlowVariation;
        calculationData.maxAllowedEmissionUniformity = calculationReqData.maxAllowedEmissionUniformity;

        calculationData.emitterChars = calculationReqData.emitterChars;
        calculationData.blockChars = calculationReqData.blockChars;
        calculationData.topographyChars = calculationReqData.topographyChars;
        calculationData.pipeChars = calculationReqData.pipeChars;

        calculationData.isOffline = calculationReqData.isOffline || false;

        return calculationData;
    }

    protected async getDevicesData(calculationData: any, calculationReqData: any) {

        let emitters: any = [];
        let pipes: any = [];
        let res = await this.getEmitterAndPipeFromDBForLateralQueries(calculationData, calculationReqData.calcType, calculationReqData.region);

        emitters = res.emitters;
        pipes = res.pipes;

        // Load pipes and emitters on calculationData: 
        calculationData.pipeChars = pipes;
        calculationData.emitterChars = emitters;
        return { pipes, emitters }
    }

    public async getPipesDataFromDB(pipeChars: any, calcType: any, units: any, region: any, isOffline: boolean) {
        let pipes: any = [];
        let _this = this;
        let isRequestFromCalculatorAndUsUnits = units === 2

        await Promise.all(pipeChars.map(async (pchar: any) => {
            pchar.pipe_class = pchar.pipe_class.toString();
            let p = await _this.getPipe(pchar, region, isOffline, isRequestFromCalculatorAndUsUnits);
            if (!p) {
                throw new Error("Error - pipe doesnt exists")
            }
            // Take user's input:
            p.internal_diameter = pchar.internal_diameter;
            p.segment_length = pchar.section_length;

            pipes.push(p)
        }));



        return pipes;
    }

    // --------------------------------------------- PRIVATE METHODS -----------------------------------------------------
    private async getEmitterAndPipeFromDBForLateralQueries(calculationData: any, calcType: any, region: any) {
        try {
            let emitterChars = calculationData.emitterChars;
            let lateralChars = calculationData.pipeChars;
            emitterChars.isUnitsSet = calculationData.isUnitsSet;
            let isInlineEmitter = emitterChars.is_inline_emitter;
            let units = calculationData.units;
            let flowRate = calculationData.blockChars.last_lateral_flow_rate;
            let isOffline = calculationData.isOffline || false

            let tasks = [
                // Get Emitter data from db:
                this.getEmittersFromDB({ emitterChars, lateralChars, flowRate, units, region, isOffline }),
            ]
            if (!isInlineEmitter) {
                // Get Pipe data from db: 
                tasks.push(this.getPipesDataFromDB(calculationData.pipeChars, calcType, units, region, isOffline)) //ADD OFFLINE
            }

            let res = await Promise.all(tasks);
            let emitters = res[0];
            // let emitter = emitters[0];

            if (!emitters || emitters.length <= 0) {
                throw new Error('Error - Emitter doesnt exists');
            }

            let pipes = isInlineEmitter == false ? res[1] : this.getDataFromInlineEmitter(emitters, lateralChars);

            if (!pipes) {
                throw new Error('Error - Pipe doesnt exists');
            }

            return { emitters, pipes };
        } catch (error) {
            throw new Error(error.message)
        }
    }

    private getDataFromInlineEmitter(emitters: any, lateralChars: any) {
        let pipes = [];

        emitters.forEach(emitter => {
            let roughness = Number(emitter.roughness) > Number(LateralCalculatorHandler.EMITTER_MAX_ROUUGHNESS) ? LateralCalculatorHandler.EMITTER_MAX_ROUUGHNESS : emitter.roughness;
            let pipe: any = {
                pipe_class: emitter.wall_thickness,
                nominal_diameter: Number(emitter.nominal_diameter),
                nominal_diameter_US: Number(emitter.nominal_diameter_US),
                internal_diameter: Number(emitter.internal_diameter),
                internal_diameter_US: Number(emitter.internal_diameter_US),
                kd_local_friction_factor: Number(emitter.kd_local_friction_factor),
                max_design_pressure: Number(emitter.max_design_pressure),
                max_design_pressure_US: Number(emitter.max_design_pressure_US),
                max_allowable_technical_pressure: Number(emitter.max_technical_pressure),
                max_allowable_technical_pressure_US: Number(emitter.max_technical_pressure_US),
                pipe_roughness: Number(roughness),
                segment_length: Number(emitter.section_length)
            };

            pipes.push(pipe);
        });
        return pipes
    }

    private async getEmittersFromDB(emittersData: any) {
        let service = new EmittersService();
        let { emitterChars, lateralChars, flowRate, units, region, isOffline } = emittersData;

        let emitters = await service.getEmittersForCalculation(emitterChars, lateralChars, flowRate, units, region, isOffline);
        return emitters;
    }

    // --------------------------------------------- RESULT BUILDERS -----------------------------------------------------

    public buildCalculationResult(resultData: any) {
        let calculation_results = resultData.calcResults;
        let isSucceeded: boolean = calculation_results.errors.length <= 0;
        let flushing_results = resultData.flushing;
        let emitter = calculation_results.emitter;
        let isFlatTopo: any = true;
        if (isSucceeded) {
            resultData.calcResults.slopes.forEach(slope => {
                if (slope.HeightDiffInMeters != 0) {
                    isFlatTopo = false;
                }
            });
        }


        // let endPressure = isSucceeded ? Number(calculation_results.segments[calculation_results.segments.length - 1].EndPressure.toFixed(2)) : null;

        let endPressure = isSucceeded ? Number(calculation_results.calculationResults.endPressure.toFixed(2)) : null;
        endPressure = isSucceeded ? Number(endPressure.toFixed(2)) : null;


        let totalLength = isSucceeded ? calculation_results.calculationResults.totalLength : null;
        // Calculation results:
        let kd = isSucceeded ? calculation_results.pipes[0].KD || emitter.KD : 0;
        kd = Number(Number(kd).toFixed(2));
        let numOfEmitters = (isSucceeded && calculation_results.calculationResults.numOfEmitters) ? Number(calculation_results.calculationResults.numOfEmitters.toFixed(2)) : null;
        let calculationResults = isSucceeded ? {
            total_length: Number(totalLength.toFixed(2)),
            pressure_loss: Number(calculation_results.calculationResults.totalPressureLoss.toFixed(2)),
            flow_variation: calculation_results.calculationResults.flowVariation ? Number(calculation_results.calculationResults.flowVariation.toFixed(2)) : 0,
            emission_uniformity: Number(calculation_results.calculationResults.emissionUniformity.toFixed(1)),
            inlet_pressure: calculation_results.calculationResults.inletPressure ? Number((calculation_results.calculationResults.inletPressure).toFixed(2)) : Number((calculation_results.segments[0].InletPressure).toFixed(2)),
            end_pressure: endPressure,
            max_pressure: isFlatTopo == false ? Number(calculation_results.calculationResults.totalMaxPressure.toFixed(2)) : null,
            min_pressure: isFlatTopo == false ? Number(calculation_results.calculationResults.totalMinPressure.toFixed(2)) : null,
            inlet_flow_rate: Number((calculation_results.segments[0].FlowRate * 1000).toFixed(3)),
            emitter_avg_flow_rate: Number(calculation_results.calculationResults.emittersAvgFlowRate.toFixed(3)),
            emitter_kd: kd,
            max_velocity: Number(calculation_results.calculationResults.maxVelocity.toFixed(2)),
            travel_time: Number(calculation_results.calculationResults.travelTime.toFixed(0)),
            num_of_emitters: numOfEmitters,
            warnings: calculation_results.calculationResults.warnings || [],
            errors: calculation_results.calculationResults.errors || []
        } : { errors: calculation_results.errors };

        // Flushing:
        if ((flushing_results && isSucceeded)) {
            numOfEmitters = (flushing_results && flushing_results.flushingResults.numOfLaterals) ? Number(flushing_results.flushingResults.numOfLaterals.toFixed(2)) : null;
        }
        let flushingResults = (flushing_results && isSucceeded) ? {
            pressure_loss: Number(flushing_results.flushingResults.totalPressureLoss.toFixed(2)),
            inlet_pressure: flushing_results.flushingSegments[0].InletPressure ? Number(flushing_results.flushingSegments[0].InletPressure.toFixed(2)) : null,
            inlet_flow_rate: Number((flushing_results.flushingSegments[0].FlowRate * 1000).toFixed(3)),
            max_pressure: Number(flushing_results.flushingResults.totalMaxPressure.toFixed(2)),
            flushing_velocity: Number(flushing_results.flushingResults.maxVelocity.toFixed(2)),
            flushing_end_velocity: Number(Number(flushing_results.flushingResults.endVelocity).toFixed(2)),
            end_pressure: Number(Number(flushing_results.flushingResults.endPressure).toFixed(2)),
            last_flow_rate: Number(Number(flushing_results.flushingResults.lastFlowRate * 1000).toFixed(2)),
        } : { errors: flushing_results ? flushing_results.errors : [] };

        // Flushing with inlet pressure from user:
        let flushingResultsByUserInletPressure = (flushing_results && isSucceeded && flushing_results.flushingResults.result_for_user_inlet_pressure) ?
            {
                pressure_loss: Number(flushing_results.flushingResults.result_for_user_inlet_pressure.totalPressureLossForUserInletPressureInput.toFixed(2)),
                inlet_pressure: Number(flushing_results.flushingResults.result_for_user_inlet_pressure.totalMaxPressureForUserInletPressureInput.toFixed(2)),
                inlet_flow_rate: Number((flushing_results.flushingSegments[flushing_results.flushingResults.result_for_user_inlet_pressure.segment_index_for_desired_inlet_pressure].FlowRate * 1000).toFixed(3)),
                max_pressure: Number(flushing_results.flushingResults.result_for_user_inlet_pressure.totalMaxPressureForUserInletPressureInput.toFixed(2)),
                flushing_velocity: Number(flushing_results.flushingResults.result_for_user_inlet_pressure.maxVelocityForUserInletPressureInput.toFixed(2)),
                totalLength: Number(Number(flushing_results.flushingResults.result_for_user_inlet_pressure.totalLengthForUserInletPressureInput).toFixed(2)),
                flushing_end_velocity: Number(Number(flushing_results.flushingResults.result_for_user_inlet_pressure.endVelocityForUserInletPressureInput).toFixed(2)),
                end_pressure: Number(Number(flushing_results.flushingResults.result_for_user_inlet_pressure.endPressureForUserInletPressureInput).toFixed(2)),
                last_flow_rate: Number(Number(flushing_results.flushingResults.result_for_user_inlet_pressure.lastFlowRateForUserInletPressureInput * 1000).toFixed(2)),
            }
            : { errors: flushing_results ? flushing_results.errors : [] };

        let minHeight = 0;
        let maxHeight = 0;
        let slopeSum: number = 0;
        let metersSum = 0;
        let slops_points = [{ x: 0, y: 0, high_diff_meters: 0 }];
        let pressure_points = [];
        let flow_rate_points = [];
        if (isSucceeded) {
            // First segment Inlet pressure:
            pressure_points.push({ x: 0, y: Number(calculation_results.segments[0].InletPressure) })

            for (let index = 0; index < calculation_results.slopes.length; index++) {
                let slope = calculation_results.slopes[index];
                slopeSum += slope.HeightDiffInMeters;
                metersSum += slope.SectionRealLength;

                maxHeight = slopeSum > maxHeight ? slopeSum : maxHeight;
                minHeight = slopeSum < minHeight ? slopeSum : minHeight;
                slops_points.push({ x: metersSum, y: Number(slopeSum.toFixed(2)), high_diff_meters: slope.HeightDiffInMeters });
            }
            let sum = 0;
            let spacing = calculation_results.segments[0].Length;

            for (let index = 0; index < calculation_results.segments.length; index++) {
                let seg = calculation_results.segments[index];
                // sum += seg.Length;
                sum = Math.round((sum + seg.Length) * 1e12) / 1e12
                pressure_points.push({ x: sum, y: Number(seg.EndPressure) })
                if (Math.round((sum % Number(spacing)) * 1e12) / 1e12 == 0) {
                    flow_rate_points.push({ x: sum, y: Number((seg.AtomicFlowRate * 1000)) });
                }
            }
        }


        let graphs_data = isSucceeded ? {
            slopes_and_pressure: {
                min_height: Number(minHeight.toFixed(2)),
                max_height: Number(maxHeight.toFixed(2)),
                max_working_pressure: Number(calculation_results.calculationResults.totalMaxPressure.toFixed(2)),
                min_working_pressure: Number(calculation_results.calculationResults.totalMinPressure.toFixed(2)),
                points_on_graph: {
                    slopes: slops_points,
                    pressure: pressure_points,
                }
            },
            flow_rate: {
                max_flow_rate: Number(calculation_results.segments[0].FlowRate.toFixed(2)),
                min_flow_rate: Number(calculation_results.segments[calculation_results.segments.length - 1].FlowRate.toFixed(2)),
                points_on_graph: {
                    flow_rate: flow_rate_points,
                }
            },
        } : null;

        let result = {
            calculation_success: isSucceeded,
            calculation_results: calculationResults,
            calculation_extra_details: {
                pipes: calculation_results.pipes,
                slopes: calculation_results.slopes
            },
            graphs_data: graphs_data,
            flushing_results: flushingResults,
            flushing_results_for_users_inlet_pressure_input: flushingResultsByUserInletPressure
        };

        return result;
    }

    public buildEmitterModelsResult(resultData: any) {
        let result = { models: resultData.models };

        return result;
    }

    public buildEmitterPressuresResult(resultData: any) {
        let result = { last_emitter_pressures: resultData.emitterPressures };

        return result;
    }

    public buildEmitterFlowRatesResult(resultData: any) {
        let result = { flow_rates: resultData.flowRates };

        return result;
    }

    public buildLateralCharasteristicsResult(resultData: any) {
        let result = {
            lateral_chars: {
                nominal_diameters: resultData.section_charactaristics.nominalDiameters,
                wall_thickess: resultData.section_charactaristics.wallThicknesses,
                flow_rates: resultData.section_charactaristics.flowRates
            }
        };

        return result;
    }
    // --------------------------------------------- HELPERS -----------------------------------------------------
    public buildPipesSectionCharactaristicsResult(resultData: any) {
        let result = { pipes_section_charactaristics: resultData.section_charactaristics };

        return result;
    }

}

