import { PipesDAL } from "../DAL/PipesDAL";
import { EUnits } from "../enums/Units.enum";
import { UnitsConverter } from "../UnitsConverter/UnitsConverter";

export class PipesService {

    pipesDAL: PipesDAL = new PipesDAL();

    public async getPipeTypes(filters: any): Promise<any> {
        let { region, units, pClass, nominalDiameter, pipeRelevants } = filters;
        let isOffline = filters.isOffline || false

        let types = await this.pipesDAL.getPipeTypes(region, pClass, nominalDiameter, units, pipeRelevants, isOffline);
        return types;
    }

    public async getPipeClasses(filters: any): Promise<any> {
        let { region, units, pType, nominalDiameter, pipeRelevants } = filters;
        let isOffline = filters.isOffline || false

        let classes = await this.pipesDAL.getPipeClasses(region, pType, nominalDiameter, units, pipeRelevants, isOffline);
        return classes;
    }

    public async getPipeNominalDiameters(filters: any): Promise<any> {
        let { region, units, pType, pClass, pipeRelevants, previous_section_nominal_diameter_selcection } = filters;
        let isOffline = filters.isOffline || false
        let diameters: any = await this.pipesDAL.getPipeNominalDiameters(region, pType, pClass, units, pipeRelevants, previous_section_nominal_diameter_selcection, isOffline);

        return diameters;
    }

    public async getPipeInternalDiameterAndRoghness(region: any, type: any, pClass: any, diameter: any, units: any, isOffline: boolean): Promise<any> {
        let { internalDiameter, roughness } = await this.pipesDAL.getPipeInternalDiameterAndRoghness(region, type, pClass, diameter, units, isOffline);

        return { internalDiameter: Number(internalDiameter).toFixed(3), roughness };
    }

    public async getPipe(serviceData: any): Promise<any> {
        let type: any = serviceData.type ? serviceData.type : serviceData.materialType;
        let pClass: any = serviceData.pClass;
        let diameter = serviceData.nominalDiameter;
        let region: boolean = serviceData.region;
        let isGlobalPipe: boolean = region == null;
        let isRequestFromCalculatorAndUsUnits = serviceData.isRequestFromCalculatorAndUsUnits
        let isOffline = serviceData.isOffline || false

        let pipe = await this.pipesDAL.getPipe(type, pClass, diameter, isGlobalPipe, region, isRequestFromCalculatorAndUsUnits, isOffline);
        if (pipe && isRequestFromCalculatorAndUsUnits) {
            // convert pipes data to US-Units:
            UnitsConverter.convertPipesToUSUnits([pipe]);
        }
        return pipe;
    }

    public async getPipes(serviceData: any, isItCalculator: boolean = false): Promise<any> {
        let filters = serviceData.filters || serviceData;
        let pagination = serviceData.pagination;
        let isOffline = serviceData.isOffline || false;
        let isRequestFromCalculatorAndUsUnits = serviceData.isRequestFromCalculatorAndUsUnits
        filters.region = filters.region ? filters.region.toString() : PipesDAL.GLOBAL_REGION_STR;

        let units = serviceData.units || EUnits.METRIC;
        filters.units = units;

        let pipes: any = await this.pipesDAL.getPipes(filters, pagination, isItCalculator, isOffline);
        if (pipes.pipes.length > 0 && (units == EUnits.US_UNITS || isRequestFromCalculatorAndUsUnits)) {
            // convert pipes data to US-Units:
            UnitsConverter.convertPipesToUSUnits(pipes.pipes);
        }
        return pipes;
    }

}