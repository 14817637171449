import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import {
  Einputs_fields,
  Ecalculator,
  EtransaltionGroups,
  EqueryTypes
} from 'src/app/shared/enum';
import { TextService } from 'src/app/services/text.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';


@Component({
  selector: 'app-slope',
  templateUrl: './slope.component.html',
  styleUrls: ['./slope.component.scss']
})
export class SlopeComponent implements OnInit {
  Einputs_fields = Einputs_fields;
  Ecalculator = Ecalculator;
  EtransaltionGroups = EtransaltionGroups;
  @Input() selectedTopography: any;
  @Input() selectedSlope: any;
  @Input() slopeForm: FormGroup;
  @Input() index;
  @Input() pipeLength;
  @Output() deleteSlope = new EventEmitter<string>();
  @Output() calculateTotalLengthLeftForSlopes = new EventEmitter<string>();
  heightDiffChangeToReadonly = '';
  @Input() mapLengthInvalid = false;
  @Input() queryIndex;
  @Input() length_of_map_previous_slope;
  @Input() calculatorIndex;
  @Input() user_id //for analytics events

  label_heightDiffP;
  label_heightDiffM;
  validation_length
  constructor(private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    public dataService: DataService,
    public textService: TextService,
    public analyticsService: GoogleAnalyticsService) {
    this.dataService.validationForMapLengthSlope.subscribe(res => {
      this.validateMapLength(this.slopeForm.value.mapLength);
    });
  }


  ngOnInit(): void {
    this.onChange();
    this.initializeForm();
    this.setValidatorsByQueryIndex();
    this.set_fields_label();
    this.set_validator_label()
  }

  set_fields_label() {
    switch (this.calculatorIndex) {
      case this.Ecalculator.lateral:
        this.label_heightDiffP = this.textService.getText(
          this.EtransaltionGroups.LATERAL, 'lateral_s4_dif_in_precent');
        break;
      case this.Ecalculator.submain:
        this.label_heightDiffP = this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_s2_hight_dif_percent'
        );
        break;
      case this.Ecalculator.mainline:
        this.label_heightDiffP = this.textService.getText(
          this.EtransaltionGroups.MAINLINE,
          'mainline_hight_dif_percent_label'
        );
        break;

      default:
        break;
    }

    this.label_heightDiffM = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.calculatorIndex === this.Ecalculator.lateral
          ? this.EtransaltionGroups.LATERAL
          : this.EtransaltionGroups.SUBMAIN,
        this.calculatorIndex === this.Ecalculator.lateral
          ? 'lateral_s4_dif_in_meters'
          : 'submain_s2_hight_dif_distance'
      ),
      'm'
    );
  }
  set_validator_label() {
    this.validation_length = this.getAlertMessage()
  }

  getAlertMessage() {
    switch (this.calculatorIndex) {
      case Ecalculator.submain:
        return this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          "submain_elevation_length_alert"
        )
        break;
      case Ecalculator.lateral:
        return this.textService.getText(
          this.EtransaltionGroups.LATERAL,
          "lateral_elevation_length_alert"
        )
        break;
      case Ecalculator.mainline:
        return this.textService.getText(
          this.EtransaltionGroups.MAINLINE,
          "mainline_elevation_length_alert"
        )
        break;
      default:
        return null
        break;
    }
  }

  initializeForm() {
    if (!this.slopeForm.valid) {
      if (this.selectedTopography === 'Fixed Slope') {
        this.slopeForm.controls['heightDiffP'].setValidators([
          Validators.compose([
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              this.calculatorIndex === this.Ecalculator.submain
                ? this.dataService.composeMinMaxValidator(
                  this.dataService.submain_inputs_data[
                  this.Einputs_fields.heigth_diff_precent
                  ],
                  'min'
                ) : -100
            ),
            Validators.max(
              this.calculatorIndex === this.Ecalculator.submain
                ? this.dataService.composeMinMaxValidator(
                  this.dataService.submain_inputs_data[
                  this.Einputs_fields.heigth_diff_precent
                  ],
                  'max'
                ) : 100

            )
          ])
        ]);
        this.slopeForm.controls['heightDiffP'].updateValueAndValidity();
      }

      if (this.selectedTopography === 'Multiple Elevations') {
        this.slopeForm.controls['heightDiffP'].setValidators([
          Validators.compose([
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(!(this.calculatorIndex == Ecalculator.mainline && this.queryIndex === EqueryTypes.pressure_loss) ? -15 : -100),
            Validators.max(!(this.calculatorIndex == Ecalculator.mainline && this.queryIndex === EqueryTypes.pressure_loss) ? 15 : 100)
          ])
        ]);
        this.slopeForm.controls['heightDiffP'].updateValueAndValidity();
        if (this.queryIndex != 2 && this.selectedSlope.letter != 'A') {
          this.InitMapLengthField();
        }
      }
    }
  }
  InitMapLengthField() {
    let length =
      Number(this.dataService.totalLengthLeftForSlopes) -
      Number(this.length_of_map_previous_slope);
    this.slopeForm.controls.mapLength.setValue(
      Number(length) > 0 && Number(this.length_of_map_previous_slope) > 0
        ? length
        : 0,
      { emitEvent: false }
    );
  }
  setValidatorsByQueryIndex() {

    if (!this.slopeForm.valid) {
      if (this.queryIndex === 2) {
        this.slopeForm.controls.mapLength.reset();
        this.slopeForm.controls['mapLength'].clearValidators();
        this.slopeForm.get('mapLength').setValue('', { emitEvent: false });
        this.slopeForm.controls['mapLength'].updateValueAndValidity();

        this.slopeForm.controls.heightDiffM.reset();
        this.slopeForm.controls['heightDiffM'].clearValidators();
        this.slopeForm.get('heightDiffM').setValue('', { emitEvent: false });
        this.slopeForm.controls['heightDiffM'].updateValueAndValidity();

        this.slopeForm.controls.realLength.reset();
      } else {
        this.slopeForm.controls['mapLength'].setValidators([
          Validators.compose([
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(
              //fix with page data
              this.calculatorIndex === this.Ecalculator.submain
                ? this.dataService.composeMinMaxValidator(
                  this.dataService.submain_inputs_data[
                  this.Einputs_fields.submain_pipe_length
                  ],
                  'min'
                ) : 0
            ),
            Validators.max(
              this.dataService.composeMinMaxValidator(
                this.calculatorIndex === this.Ecalculator.submain
                  ? this.dataService.submain_inputs_data[
                  this.Einputs_fields.submain_pipe_length
                  ] : 1000
                ,
                'max'
              )
            )
          ])
        ]);
        this.slopeForm.controls['mapLength'].updateValueAndValidity();

        this.slopeForm.controls['heightDiffM'].setValidators([
          Validators.compose([
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required
          ])
        ]);
        this.slopeForm.controls['heightDiffM'].updateValueAndValidity();
      }
    }
  }

  onChange(): void {
    this.slopeForm.valueChanges.subscribe(value => {
      setTimeout(() => {
        if (this.slopeForm.valid) {
          this.dataService.reloadSlopesIlustration.next();
        }
      });
      this.dataService.inputs_contents_has_changed = true
    });
    this.slopeForm.get('heightDiffM').valueChanges.subscribe(value => {
      setTimeout(() => {
        if (
          this.slopeForm.controls.heightDiffM.hasError('max') ||
          this.slopeForm.controls.heightDiffM.hasError('min')
        ) {
          this.analyticsService.sendEvent(
            this.calculatorIndex == 1 ? 'submain' : 'lateral',
            'height_difference_meters_out_of_range',
            'validation_error', this.user_id
          );
        }
      });

      if (this.slopeForm.get('heightDiffM').valid) {
        this.calcualteHeightDiffP();
      } else {
        this.heightDiffChangeToReadonly = '';
        this.slopeForm.get('heightDiffP').reset();
        this.slopeForm.get('realLength').reset();
      }
    });
    this.slopeForm.get('heightDiffP').valueChanges.subscribe(value => {
      setTimeout(() => {
        if (
          this.slopeForm.controls.heightDiffP.hasError('max') ||
          this.slopeForm.controls.heightDiffP.hasError('min')
        ) {
          this.analyticsService.sendEvent(
            this.calculatorIndex == this.Ecalculator.submain ? 'submain' : 'lateral',
            'height_difference_percentage_out_of_range',
            'validation_error', this.user_id
          );
        }
      });
      if (this.queryIndex != 2) {
        this.heightDiffPValidation();
        if (this.slopeForm.get('heightDiffP').valid) {
          this.calcualteHeightDiffM();
        } else {
          this.heightDiffChangeToReadonly = '';
          this.slopeForm.get('heightDiffM').setValue('', { emitEvent: false });
          this.slopeForm.get('realLength').setValue('', {});
        }
      }
    });

    this.slopeForm.get('mapLength').valueChanges.subscribe(value => {
      if (this.slopeForm.controls.mapLength.valid) {
        this.validateMapLength(this.slopeForm.get('mapLength').value);
        if (this.heightDiffChangeToReadonly == 'heightDiffM') {
          this.calcualteHeightDiffM();
        }
        if (this.heightDiffChangeToReadonly == 'heightDiffP') {
          this.calcualteHeightDiffP();
          this.heightDiffPValidation();
        }
      } else {
        this.heightDiffChangeToReadonly = '';
        this.slopeForm.get('heightDiffM').reset();
        this.slopeForm.get('realLength').reset();
        this.slopeForm.get('heightDiffP').reset();
      }
      this.dataService.slopeMapLengthHasChanged.next(this.selectedSlope.id);
      setTimeout(() => {
        let isItInvalid = this.checkSumOfSlopes()
        if (isItInvalid) {

          this.dataService.alert_msg_text = this.getAlertMessage()
          this.dataService.showAlertMessage = true;
          this.dataService.showAlertInRed = true
        }
      });
    });

    this.slopeForm.controls.realLength.valueChanges.subscribe(value => {
      setTimeout(() => {
        this.dataService.calculateSubmainRealLength.next()
      });
    })
  }

  delete() {
    this.deleteSlope.emit(this.index);
  }

  public validateMapLength(length) {
    this.calculateTotalLengthLeftForSlopes.emit(this.selectedSlope.id);
    this.mapLengthInvalid = false;
    let total = this.dataService.totalLengthLeftForSlopes;
    if (+length > +total) {
      this.mapLengthInvalid = true;
      if (this.dataService.selectedTopography === 'Multiple Elevations') {
        this.dataService.alert_msg_text = this.getAlertMessage()
        this.dataService.showAlertMessage = true;
        this.dataService.showAlertInRed = true
      }
    } else {
      this.mapLengthInvalid = false;
    }
    this.cdr.detectChanges();
    this.slopeForm.updateValueAndValidity();
  }

  heightDiffPValidation() {
    if (
      this.controls.heightDiffP.hasError('max') ||
      this.controls.heightDiffP.hasError('min') ||
      this.controls.heightDiffP.hasError('pattern')
    ) {
      if (this.selectedTopography === 'Multiple Elevations') {
        // setTimeout(() => {
        this.dataService.snackBarText = this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_elevation_more_than_15'
        );

        this.dataService.snackBarAcceptText = this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_s2_block_shape_snackbar_accept'
        );
        this.dataService.snackBarCancelText = this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_s2_block_shape_snackbar_cancel'
        );
        this.dataService.initSnackBar.next();
        // });
      }
    }
  }
  calcualteHeightDiffM() {
    this.slopeForm
      .get('heightDiffM')
      .setValue(
        (
          (this.slopeForm.get('heightDiffP').value *
            this.slopeForm.get('mapLength').value) /
          100
        ).toFixed(2),
        { emitEvent: false }
      );
    this.heightDiffChangeToReadonly = 'heightDiffM';

    //Real length=length on map*(1+height difference %)
    this.slopeForm
      .get('realLength')
      .setValue(
        Math.abs(this.slopeForm.value.heightDiffP) > 15
          ? Math.sqrt(
            Math.pow(this.slopeForm.get('mapLength').value, 2) +
            Math.pow(this.slopeForm.get('heightDiffM').value, 2)
          ).toFixed(2)
          : this.slopeForm.value.mapLength,
        {}
      );
  }

  calcualteHeightDiffP() {
    this.slopeForm
      .get('heightDiffP')
      .setValue(
        (
          (this.slopeForm.get('heightDiffM').value /
            this.slopeForm.get('mapLength').value) *
          100
        ).toFixed(2),
        { emitEvent: false }
      );
    this.heightDiffChangeToReadonly = 'heightDiffP';
    this.heightDiffPValidation();
    //Real length=length on map*(1+height difference %)
    this.slopeForm
      .get('realLength')
      .setValue(
        Math.abs(this.slopeForm.value.heightDiffP) > 15
          ? Math.sqrt(
            Math.pow(this.slopeForm.get('mapLength').value, 2) +
            Math.pow(this.slopeForm.get('heightDiffM').value, 2)
          ).toFixed(2)
          : this.slopeForm.value.mapLength,
        {}
      );
  }

  checkSumOfSlopes() {
    switch (this.calculatorIndex) {
      case Ecalculator.lateral:
        return (
          +this.dataService.totalSlopesLength >
          +this.dataService.totalLateralLength &&
          this.selectedTopography == 'Multiple Elevations'
        );
        break;
      case Ecalculator.mainline:
        return (
          +this.dataService.totalSlopesLength >
          +this.dataService.totalLengthMainline &&
          this.selectedTopography == 'Multiple Elevations'
        );
        break;
      default:
        break;
    }

  }

  get controls() {
    return this.slopeForm.controls;
  }

}
