import { Component, Input, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { Ecalculator, EtransaltionGroups } from "src/app/shared/enum";

@Component({
  selector: "app-device-less-calc-results",
  templateUrl: "./device-less-calc-results.component.html",
  styleUrls: ["./device-less-calc-results.component.scss"],
})
export class DeviceLessCalcResultsComponent implements OnInit {
  @Input() resultsTitle: string;
  @Input() tableData: any;
  @Input() tableColumnsNames: string[];
  @Input() calculatorIndex: number;
  @Input() note: string;

  Ecalculator = Ecalculator;
  EtransaltionGroups = EtransaltionGroups;

  noteImgSrcPath: string;

  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {}

  isOneResultDisplay() {
    const calcTypes = [Ecalculator.irrigation, Ecalculator.shift];
    return calcTypes.includes(this.calculatorIndex);
  }

  ngOnInit(): void {
    this.setImgSrcPath()
  }

  setImgSrcPath() {
    this.noteImgSrcPath = `../../../../assets/img/${
      this.calculatorIndex !== Ecalculator.shift
        ? "info-icon"
        : "alert-icon-red"
    }.svg`;
  }
}
