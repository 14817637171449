import { Component, OnInit } from '@angular/core';
import { CalculatorService } from '../../../services/calculator.service';
import { DataService } from 'src/app/services/data.service';
import { TextService } from 'src/app/services/text.service';
import { EtransaltionGroups } from 'src/app/shared/enum';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-calc-help',
  templateUrl: './calc-help.component.html',
  styleUrls: ['./calc-help.component.scss']
})
export class CalcHelpComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  faqData: any;
  contactTopics: any;
  videos_data;
  mySubscription;
  constructor(
    private calculatorService: CalculatorService,
    public dataService: DataService,
    public textService: TextService,
    public router: Router
  ) {
    this.dataService.getHelpPageData.subscribe(res => {
      this.router.navigateByUrl('calc/help');
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit(): void {
    this.getPageData();
  }
  getPageData() {
    this.calculatorService.getFaq().subscribe(
      response => {
        this.dataService.user_name =
          response.result.page_data.user.first_name +
          ' ' +
          response.result.page_data.user.last_name;
        this.faqData = response.result.page_data.QATopics;
        this.videos_data = response.result.page_data.videos;

        this.contactTopics = response.result.page_data.contactUsTopics;
        this.dataService.regions_list = response.result.page_data.regions;

        this.dataService.selected_region = this.dataService.regions_list.find(
          value => {
            return response.result.page_data.user.region === value.region_id;
          }
        );
        this.dataService.languages_list = response.result.page_data.languages;
        this.dataService.selected_language = this.dataService.languages_list.find(
          value => {
            return (
              value.row_id ===
              response.result.page_data.user.language.toString()
            );
          }
        );
        this.getTexts();
      },
      error => {
        console.log(error);
      }
    );
  }
  getTexts() {
    this.dataService.showLoader = true;
    this.textService
      .getResourceText(this.dataService.selected_language.row_id)
      .subscribe(res => {
        this.dataService.showLoader = false;
        if (res.success) {
          this.textService.translation_text = res.result.translations;
          localStorage.setItem(
            'resource_txt',
            JSON.stringify(this.textService.translation_text)
          );
          this.textService.setUnitsDictionary();
        }
      });
  }
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
