import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { EenergySteps, EtransaltionGroups } from "src/app/shared/enum";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { OfflineService } from "src/app/services/offline.service";
import { CalculatorService } from "src/app/services/calculator.service";
import { EnergyService } from "src/app/services/energy.service";
import { NavigationEnd, Router } from "@angular/router";
import {
  CalculatorSection,
  DeviceLessCalcTableDataUnit,
} from "src/app/shared/types";
import {
  CALCULATOR_SECTION,
} from "src/app/shared/consts";
import {  DeviceLessCalcCharacteristicsLabelConfig,EnergyCharacteristicsLabels} from "src/app/shared/characteristics/calc-labels.types"
import { Subscription } from "rxjs";

@Component({
  selector: "app-energy-calculator-page",
  templateUrl: "./energy-calculator-page.component.html",
  styleUrls: ["./energy-calculator-page.component.scss"],
})
export class EnergyCalculatorPageComponent implements OnInit {
  user_id; // for analytics events

  // for mobile
  calculator_section = CALCULATOR_SECTION;
  show_section_mobile: CalculatorSection = CALCULATOR_SECTION.ENERGY_FIRST;

  energyForm: FormGroup;
  showResults: boolean = false;
  isEnergyPropsInitiated: boolean = false;
  mySubscription: Subscription;

  tableColumnsNames: string[] = [
    "annualTotalEnergy",
    "annualTotalEnergyCost",
    "presentValueSeriesFactor",
    "energyInvestedAlongPeriod",
    "unitValueInvestment",
  ];

  emptyDeviceLessCalcTableDataUnit: DeviceLessCalcTableDataUnit = {
    headerDisplay: "",
    units: "",
    result: 0,
  };

  tableData: {
    annualTotalEnergy: DeviceLessCalcTableDataUnit;
    annualTotalEnergyCost: DeviceLessCalcTableDataUnit;
    presentValueSeriesFactor: DeviceLessCalcTableDataUnit;
    energyInvestedAlongPeriod: DeviceLessCalcTableDataUnit;
    unitValueInvestment: DeviceLessCalcTableDataUnit;
  } = {
    annualTotalEnergy: this.emptyDeviceLessCalcTableDataUnit,
    annualTotalEnergyCost: this.emptyDeviceLessCalcTableDataUnit,
    presentValueSeriesFactor: this.emptyDeviceLessCalcTableDataUnit,
    energyInvestedAlongPeriod: this.emptyDeviceLessCalcTableDataUnit,
    unitValueInvestment: this.emptyDeviceLessCalcTableDataUnit,
  };

  labels: EnergyCharacteristicsLabels;

  EtransaltionGroups = EtransaltionGroups;
  EenergySteps = EenergySteps;

  constructor(
    public router: Router,
    public dataService: DataService,
    public textService: TextService,
    public calculatorService: CalculatorService,
    public offlineService: OfflineService,
    public energyService: EnergyService,
    private fb: FormBuilder
  ) {
    this.dataService.resetEnergyCalculator.subscribe((res) => {
      this.router.navigateByUrl("calc/energy");
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit(): void {
    this.getPageDataAndInitProps();
  }

  async getPageDataAndInitProps() {
    this.dataService.showLoader = true;
    if (!this.offlineService.isItOfflineMode) {
      this.calculatorService
        .getCalculatorPageDataDeviceLessCalcs()
        .subscribe((resp) => {
          this.dataService.showLoader = false;
          this.setResponsePageData(resp);
          this.initEnergyProps();
        });
    }
  }

  setResponsePageData(resp) {
    if (resp.success) {
      this.user_id = resp.result.page_data.user.id;
      this.dataService.user_name =
        resp.result.page_data.user.first_name +
        " " +
        resp.result.page_data.user.last_name;

      this.dataService.regions_list = resp.result.page_data.regions;
      this.dataService.selected_units = this.dataService.units_list.find(
        (value) => {
          return value.value === Number(resp.result.page_data.user.units);
        }
      );
      this.dataService.selected_region = this.dataService.regions_list.find(
        (value) => {
          return resp.result.page_data.user.region === value.region_id;
        }
      );
      this.dataService.languages_list = resp.result.page_data.languages;
      this.dataService.selected_language = this.dataService.languages_list.find(
        (value) => {
          return (
            value.row_id === resp.result.page_data.user.language.toString()
          );
        }
      );
    }
  }

  initEnergyProps() {
    this.initValidatorsOnFormFields();
    this.setInitialTableData();
    this.setCharacteristicsLabels();
    this.isEnergyPropsInitiated = true;
  }

  initValidatorsOnFormFields() {
    this.energyForm = this.fb.group({
      energyFieldArea: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.001),
              Validators.max(10000),
            ]),
          ],
          updateOn: "blur",
        },
      ],
      energyAnnualWaterConsumption: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.01),
              Validators.max(10000000),
            ]),
          ],
          updateOn: "blur",
        },
      ],
      energyPumpPressure: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.01),
              Validators.max(1000),
            ]),
          ],
          updateOn: "blur",
        },
      ],
      energyPumpEfficiency: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.01),
              Validators.max(100),
            ]),
          ],
          updateOn: "blur",
        },
      ],
      energyCostOfKwPerHour: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.01),
              Validators.max(10000),
            ]),
          ],
          updateOn: "blur",
        },
      ],
      energyInterestValue: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.01),
              Validators.max(100),
            ]),
          ],
          updateOn: "blur",
        },
      ],
      energyPeriodOfYears: [
        "",
        {
          validators: [
            Validators.compose([
              Validators.required,
              Validators.pattern("^\\d+(\\.\\d+)*$"),
              Validators.min(0.01),
              Validators.max(100),
            ]),
          ],
          updateOn: "change",
        },
      ],
    });
  }

  setInitialTableData() {
    this.tableData["annualTotalEnergy"] = {
      headerDisplay: this.textService.getText(
        EtransaltionGroups.ENERGY,
        "results_annual_total_energy_label"
      ),
      units: "(KwHr)",
      result: 0,
    };
    this.tableData["annualTotalEnergyCost"] = {
      headerDisplay: this.textService.getText(
        EtransaltionGroups.ENERGY,
        "results_annual_total_energy_cost_label"
      ),
      units: "($)",
      result: 0,
    };

    this.tableData["presentValueSeriesFactor"] = {
      headerDisplay: this.textService.getText(
        EtransaltionGroups.ENERGY,
        "results_pvsf_label"
      ),
      units: "",
      result: 0,
    };
    this.tableData["energyInvestedAlongPeriod"] = {
      headerDisplay: this.textService.getText(
        EtransaltionGroups.ENERGY,
        "results_energy_invested_label"
      ),
      units: "($)",
      result: 0,
    };
    this.tableData["unitValueInvestment"] = {
      headerDisplay: this.textService.getText(
        EtransaltionGroups.ENERGY,
        "results_units_value_label"
      ),
      units:
        this.dataService.selected_units.value === 1
          ? "(UVI $/m)"
          : "(UVI $/psi)",
      result: 0,
    };
  }

  setCharacteristicsLabels() {
    const labelsConfig: DeviceLessCalcCharacteristicsLabelConfig[] =
      this.energyService.getEnergyLabelsConfig();
    const labels: EnergyCharacteristicsLabels = labelsConfig.reduce(
      (acc, labelObj) => {
        const name: string = labelObj.ObjName;
        const label: string = this.textService.getText(
          EtransaltionGroups.ENERGY,
          labelObj.TextKey
        );
        const units: string = labelObj.unitsKey
          ? this.textService.getUnitsAsText(labelObj.unitsKey)
          : labelObj.unitsText || "";

        acc[name] = { label, units };
        return acc;
      },
      {} as EnergyCharacteristicsLabels
    );
    this.labels = labels;
  }

  calculate() {
    // get input values
    const energyFieldArea = this.getFormControlValues("energyFieldArea");
    const energyAnnualWaterConsumption = this.getFormControlValues(
      "energyAnnualWaterConsumption"
    );
    const energyPumpPressure = this.getFormControlValues("energyPumpPressure");
    const energyPumpEfficiency = this.getFormControlValues(
      "energyPumpEfficiency"
    );
    const energyCostOfKwPerHour = this.getFormControlValues(
      "energyCostOfKwPerHour"
    );
    const energyInterestValue = this.getFormControlValues(
      "energyInterestValue"
    );
    const energyPeriodOfYears = this.getFormControlValues(
      "energyPeriodOfYears"
    );

    const units = this.dataService.selected_units.value;

    const calcResults = this.performCalculations(
      energyFieldArea,
      energyAnnualWaterConsumption,
      energyPumpPressure,
      energyPumpEfficiency,
      energyCostOfKwPerHour,
      energyInterestValue,
      energyPeriodOfYears,
      units
    );

    // update tableData
    calcResults.forEach(
      ({ resultName, value }) => (this.tableData[resultName].result = value)
    );

    this.showResults = true;
  }

  getFormControlValues(controlName: string) {
    return this.energyForm.get(controlName).value;
  }

  // returns an array of calculation results
  performCalculations(
    energyFieldArea,
    energyAnnualWaterConsumption,
    energyPumpPressure,
    energyPumpEfficiency,
    energyCostOfKwPerHour,
    energyInterestValue,
    energyPeriodOfYears,
    units
  ) {
    const annualTotalEnergy = this.energyService.calcAnnualTotalEnergy(
      energyFieldArea,
      energyAnnualWaterConsumption,
      energyPumpPressure,
      energyPumpEfficiency,
      units
    );

    const annualTotalEnergyCost = this.energyService.calcAnnualTotalEnergyCost(
      annualTotalEnergy,
      energyCostOfKwPerHour
    );

    const presentValueSeriesFactor = this.energyService.calcPVSF(
      energyInterestValue,
      energyPeriodOfYears
    );

    const energyInvestedAlongPeriod =
      this.energyService.calcEnergyInvestedAlongPeriod(
        energyInterestValue,
        energyPeriodOfYears,
        annualTotalEnergyCost
      );

    const unitValueInvestment = this.energyService.calcUVI(
      energyInterestValue,
      energyPeriodOfYears,
      annualTotalEnergyCost,
      energyPumpPressure,
      units
    );

    return [
      { resultName: "annualTotalEnergy", value: annualTotalEnergy },
      { resultName: "annualTotalEnergyCost", value: annualTotalEnergyCost },
      {
        resultName: "presentValueSeriesFactor",
        value: presentValueSeriesFactor,
      },
      {
        resultName: "energyInvestedAlongPeriod",
        value: energyInvestedAlongPeriod,
      },
      { resultName: "unitValueInvestment", value: unitValueInvestment },
    ];
  }

  nextStepMobile() {
    switch (this.show_section_mobile) {
      case this.calculator_section.ENERGY_FIRST:
        this.show_section_mobile = this.calculator_section.ENERGY_SECOND;
        this.scrollToTop();
        break;

      case this.calculator_section.ENERGY_SECOND:
        this.show_section_mobile = this.calculator_section.RESULT;
        this.calculate();
        break;

      default:
        break;
    }
  }

  backStepMobile() {
    switch (this.show_section_mobile) {
      case this.calculator_section.RESULT:
        this.show_section_mobile = this.calculator_section.ENERGY_SECOND;
        this.scrollToTop();
        break;
      case this.calculator_section.ENERGY_SECOND:
        this.show_section_mobile = this.calculator_section.ENERGY_FIRST;
        this.scrollToTop();
        break;
      default:
        this.show_section_mobile = this.calculator_section.ENERGY_FIRST;
        break;
    }
  }

  validationByStep() {
    switch (this.show_section_mobile) {
      case this.calculator_section.ENERGY_FIRST:
        return (
          this.energyForm.controls.energyFieldArea.status === "INVALID" ||
          this.energyForm.controls.energyAnnualWaterConsumption.status ===
            "INVALID" ||
          this.energyForm.controls.energyPumpPressure.status === "INVALID" ||
          this.energyForm.controls.energyPumpEfficiency.status === "INVALID"
        );
      case this.calculator_section.ENERGY_SECOND:
        return (
          this.energyForm.controls.energyCostOfKwPerHour.status === "INVALID" ||
          this.energyForm.controls.energyInterestValue.status === "INVALID" ||
          this.energyForm.controls.energyPeriodOfYears.status === "INVALID"
        );
      default:
        break;
    }
  }

  onClickBackToHome() {
    this.router.navigateByUrl("calc/home");
  }

  scrollToTop() {
    setTimeout(() => {
      const element_elevation = document.querySelector("#scroll_top");
      element_elevation.scrollIntoView({ behavior: "smooth" });
    });
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
